import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export type EffectProps = {
  filter?: CSSProperties['filter']
  backdropFilter?: CSSProperties['backdropFilter']
  boxShadow?: CSSProperties['boxShadow']
  opacity?: CSSProperties['opacity']
}

export const effectStyles = (props: EffectProps) => {
  const { filter, backdropFilter, boxShadow, opacity } = props

  return css`
    filter: ${filter};
    backdrop-filter: ${backdropFilter};
    box-shadow: ${boxShadow};
    opacity: ${opacity};
  `
}
