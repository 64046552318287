import { Text, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'

type Props = {
  title: string
  brand: string
  titleLineCount: 1 | 2 | 3
}

export const ProductTitle = ({ title, brand, titleLineCount = 2 }: Props) => {
  return (
    <VStack spacing={2} width="100%">
      {brand && (
        <Text
          fontColor="primary"
          fontSize={12}
          fontWeight="light"
          lineHeight={parseSize(14)}
        >
          [{brand}]
        </Text>
      )}
      <Text
        fontSize={13}
        fontColor="gray.800"
        wordBreak="break-all"
        lineHeight={1.2}
        lineClamp={titleLineCount}
      >
        {title}
      </Text>
    </VStack>
  )
}
