import styled from '@emotion/styled'
import { HStack, Text, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { TextComponentProps } from '@src/components/common'
import { colors } from '@src/styles/theme'

type NoticeInfoFieldProps = {
  label: string
  value?: React.ReactNode
  list?: string[]
}

export const NoticeInfoField = ({ label, value }: NoticeInfoFieldProps) => {
  return (
    <HStack spacing={8} alignItems="flex-start">
      <NoticeInfoLabel>{label}</NoticeInfoLabel>
      <NoticeInfoValue whiteSpace="pre-wrap" wordBreak="break-all">
        {value}
      </NoticeInfoValue>
    </HStack>
  )
}

export const NoticeInfoListField = ({ label, list }: NoticeInfoFieldProps) => {
  return (
    <VStack>
      <NoticeInfoLabel maxWidth={'100%'}>{label}</NoticeInfoLabel>
      <ul>
        {list?.map((value, index) => (
          <NoticeInfoListItem key={index}>{value}</NoticeInfoListItem>
        ))}
      </ul>
    </VStack>
  )
}

export const NoticeInfoLabel = ({
  children,
  ...textProps
}: TextComponentProps) => {
  return (
    <Text
      fontSize={13}
      minWidth={'33%'}
      maxWidth={'33%'}
      lineClamp={2}
      {...textProps}
    >
      {children}
    </Text>
  )
}

export const NoticeInfoValue = ({
  children,
  ...textProps
}: TextComponentProps) => {
  return (
    <Text fontSize={13} fontColor={'gray.700'} lineHeight={1.8} {...textProps}>
      {children}
    </Text>
  )
}

const NoticeInfoListItem = styled(Text)`
  font-size: ${parseSize(13)};
  color: ${colors.gray[700]};
  line-height: 1.8;
  display: list-item;
  list-style: disc;
  margin-left: ${parseSize(16)};
`
