import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import { Size } from '../types'
import { parseSize } from '../utils'

export type LayoutProps = {
  display?: CSSProperties['display']
  boxSizing?: CSSProperties['boxSizing']
  transform?: CSSProperties['transform']

  size?: Size

  width?: Size
  minWidth?: Size
  maxWidth?: Size

  height?: Size
  minHeight?: Size
  maxHeight?: Size

  overflow?: CSSProperties['overflow']
  overflowX?: CSSProperties['overflowX']
  overflowY?: CSSProperties['overflowY']
}

export const layoutStyles = (props: LayoutProps) => () => {
  const width = props.size || props.width
  const height = props.size || props.height
  const {
    display,
    boxSizing,
    transform,
    minWidth,
    maxWidth,
    minHeight,
    maxHeight,
    overflow,
    overflowX,
    overflowY,
  } = props

  return css`
    display: ${display};
    box-sizing: ${boxSizing};
    transform: ${transform};
    width: ${parseSize(width)};
    height: ${parseSize(height)};
    min-width: ${parseSize(minWidth)};
    max-width: ${parseSize(maxWidth)};
    min-height: ${parseSize(minHeight)};
    max-height: ${parseSize(maxHeight)};
    overflow: ${overflow};
    overflow-x: ${overflowX};
    overflow-y: ${overflowY};
  `
}
