import { CardClient } from '@api/card-client'
import { queryKeys } from '@api/constants/query-keys'
import { MyCardTypeEnum } from '@src/types/card'
import { useQuery } from '@tanstack/react-query'
import { isLogin } from '@src/utils/is-login'

export const useUnreceivedCardCount = () => {
  return useQuery(
    [queryKeys.card.myCards, 'unreceived-card-count'],
    () => CardClient.getMyCards({ cardType: MyCardTypeEnum.GIFT_RECEIVING }),
    {
      enabled: isLogin(),
    }
  )
}
