import { useCreateCartLineMutation } from '@api/hooks/cart/use-create-cart-line'
import styled from '@emotion/styled'
import { routes } from '@src/common/constants/routes'
import { storageKeys } from '@src/common/constants/storage-keys'
import { parseSize } from '@src/components/basic/utils'
import { Button, DrawerFooter } from '@src/components/common'
import { useAlert } from '@src/contexts/alert-context'
import { AlertType } from '@src/contexts/alert-context/alert'
import { useNavigateWithRedirectPath } from '@src/hook/use-navigate-with-redirect-path'
import { sessionStorageService } from '@src/services/storage-service'
import { maxScreenWidth, zIndices } from '@src/styles/theme'
import { CartLineDto } from '@src/types/cart'
import { Product } from '@src/types/product'
import { isLogin } from '@src/utils/is-login'
import { useRouter } from 'next/router'
import {
  useSkuCounts,
  useSkuCountsUpdater,
} from './_contexts/sku-counts-context'

type Props = {
  isOptionDrawerOpen?: boolean
  openOptionDrawer: () => void
  closeOptionDrawer: () => void
  productDetail: Product
}

export const ProductDetailFooter = ({
  isOptionDrawerOpen,
  openOptionDrawer,
  closeOptionDrawer,
  productDetail,
}: Props) => {
  const { skuCounts } = useSkuCounts()
  const { resetSkuCounts } = useSkuCountsUpdater()
  const createCartLineMutation = useCreateCartLineMutation()
  const { pushWithRedirectPath } = useNavigateWithRedirectPath()
  const router = useRouter()
  const showAlert = useAlert()

  const buttonClickValidation = () => {
    if (!isOptionDrawerOpen) {
      openOptionDrawer()
      return false
    }

    if (!Object.keys(skuCounts).length) {
      showAlert({
        content: '상품을 선택해주세요.',
        buttons: [{ label: '확인' }],
      })
      return false
    }

    return true
  }

  const handleDirectOrder = () => {
    const isValid = buttonClickValidation()
    if (!isValid) return

    closeOptionDrawer()

    const directOrderDetail = {
      productId: productDetail.id,
      skus: Object.entries(skuCounts).map(([skuId, quantity]) => ({
        skuId,
        quantity,
      })),
    }

    if (!isLogin()) {
      return showAlert({
        content: `로그인하지 않으셨습니다.\n로그인 후 주문하시겠습니까?`,
        buttons: [
          {
            label: '로그인',
            onClick: () => {
              pushWithRedirectPath({
                to: 'login',
                redirectPath: routes.shopping.directOrder,
              })
              sessionStorageService.set(
                storageKeys.directOrderDetail,
                directOrderDetail
              )
            },
          },
        ],
      })
    }

    sessionStorageService.set(storageKeys.directOrderDetail, directOrderDetail)
    router.push(routes.shopping.directOrder)
  }

  const handleAddToCart = () => {
    const isValid = buttonClickValidation()
    if (!isValid) {
      return
    }

    closeOptionDrawer()

    const cartLines: CartLineDto[] = Object.entries(skuCounts).map(
      ([skuId, quantity]) => ({
        productId: productDetail.id,
        skuId,
        quantity,
        isSelected: true,
      })
    )

    if (!isLogin()) {
      showAlert({
        content:
          '비회원은 장바구니를 사용할 수 없습니다.\n바로 구매하시겠습니까?',
        buttons: [
          {
            label: '로그인',
            variant: 'outline',
            onClick: () => {
              pushWithRedirectPath({
                to: 'login',
                redirectPath: routes.shopping.cart,
              })
              sessionStorageService.set(
                storageKeys.cartLines,
                JSON.stringify(cartLines)
              )
            },
          },
        ],
      })
      return
    }

    createCartLineMutation.mutate({ lines: cartLines })
    showAlert({
      content: '장바구니에 상품을 담았습니다.',
      type: AlertType.Success,
      buttons: [
        {
          label: '장바구니로 이동',
          onClick: () => router.push(routes.shopping.cart),
          variant: 'outline',
        },
        {
          label: '쇼핑 계속하기',
          onClick: resetSkuCounts,
        },
      ],
    })
  }

  return (
    <Container>
      <DrawerFooter>
        {!productDetail.isSoldOut && productDetail.isSelling && (
          <>
            {/* {productDetail.isTangible && !productDetail.hasMid && (
              <Button
                label="장바구니 담기"
                variant="outline"
                onClick={handleAddToCart}
              />
            )} */}
            <Button label="구매하기" onClick={handleDirectOrder} />
          </>
        )}
        {!productDetail.isSelling && (
          <Button label="구매할 수 없는 상품입니다" disabled />
        )}
        {productDetail.isSelling && productDetail.isSoldOut && (
          <Button label="SOLD OUT" disabled />
        )}
      </DrawerFooter>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  max-width: ${parseSize(maxScreenWidth)};
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  z-index: ${zIndices.above};
`
