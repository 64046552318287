import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import { Size } from '../types'
import { parseSize } from '../utils'

export type GridProps = {
  gridTemplateColumns?: CSSProperties['gridTemplateColumns']
  gridTemplateRows?: CSSProperties['gridTemplateRows']
  gridAutoColumns?: CSSProperties['gridAutoColumns']
  gridAutoRows?: CSSProperties['gridAutoRows']
  gridGap?: Size
  gridColumnGap?: Size
  gridRowGap?: Size
}

export const gridStyles = (props: GridProps) => {
  const {
    gridTemplateColumns,
    gridTemplateRows,
    gridAutoColumns,
    gridAutoRows,
    gridGap,
    gridColumnGap,
    gridRowGap,
  } = props

  return css`
    grid-template-columns: ${gridTemplateColumns};
    grid-template-rows: ${gridTemplateRows};
    grid-auto-columns: ${gridAutoColumns};
    grid-auto-rows: ${gridAutoRows};
    grid-gap: ${parseSize(gridGap)};
    grid-column-gap: ${parseSize(gridColumnGap)};
    grid-row-gap: ${parseSize(gridRowGap)};
  `
}
