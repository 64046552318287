import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { ProductPageSettingDto, ProductPageSettings } from '@src/types/channel'
import { useQuery } from '@tanstack/react-query'

export const useProductPageSettingsQuery = () => {
  return useQuery(
    [queryKeys.channel.productPageSettings],
    () => getProductPageSetting(),
    {
      staleTime: staleTime.infinity,
      select: (data) => new ProductPageSettings(data),
    }
  )
}

const getProductPageSetting = () => {
  return api.channelClient.get<ProductPageSettingDto>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/product-page-setting`
  )
}
