import {
  BaseProductDto,
  InventoryStatusEnum,
  ProductClassEnum,
  SalesStatusEnum,
} from '../dto'

export class BaseProduct {
  public readonly id: string
  public readonly images: string[]
  public readonly title: string
  public readonly brand: string
  public readonly minSellPrice: number
  public readonly marketPrice: number
  public readonly wishlistId: number
  public readonly timesaleAppliedNow: boolean
  public readonly inventoryStatus: InventoryStatusEnum
  public readonly productClass: ProductClassEnum
  public readonly salesStatus: SalesStatusEnum

  public readonly isSoldOut: boolean
  public readonly isShortage: boolean
  public readonly isSelling: boolean
  public readonly isTangible: boolean
  public readonly isVoucher: boolean

  constructor(dto: BaseProductDto) {
    this.id = dto.id
    this.images = dto.images
    this.title = dto.title
    this.brand = dto.brand
    this.minSellPrice = dto.minSellPrice
    this.marketPrice = dto.marketPrice
    this.wishlistId = dto.wishlistId
    this.timesaleAppliedNow = dto.timesaleAppliedNow
    this.inventoryStatus = dto.inventoryStatus || InventoryStatusEnum.Enough
    this.productClass = dto.productClass || ProductClassEnum.Tangible
    this.salesStatus = dto.salesStatus || SalesStatusEnum.Selling

    this.isSoldOut = this._getIsSoldOut()
    this.isShortage = this._getIsShortage()
    this.isSelling = this._getIsSelling()
    this.isTangible = this._getIsTangible()
    this.isVoucher = this._getIsVoucher()
  }

  private _getIsSoldOut() {
    return this.inventoryStatus === InventoryStatusEnum.SoldOut
  }

  private _getIsShortage() {
    return this.inventoryStatus === InventoryStatusEnum.Shortage
  }

  protected _getIsSelling() {
    return this.salesStatus === SalesStatusEnum.Selling
  }

  private _getIsTangible() {
    return this.productClass === ProductClassEnum.Tangible
  }

  private _getIsVoucher() {
    return this.productClass === ProductClassEnum.GiftCard
  }
}
