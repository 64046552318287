import { VStack } from '@src/components/basic'
import { Accordion } from '@src/components/common'
import { DeliveryInfo } from '@src/types/product'
import { formatAmount } from '@src/utils/format-amount'
import { getAdditionalDeliveryFeeNotice } from '../../../../utils/get-additional-delivery-fee-notice'
import { NoticeInfoField, NoticeInfoLabel, NoticeInfoValue } from './common'

type DeliveryInfoProps = {
  deliveryInfo: DeliveryInfo
}

export const DeliveryInfoSection = ({ deliveryInfo }: DeliveryInfoProps) => {
  const {
    baseDeliveryFee,
    refundDeliveryFee,
    exchangeDeliveryFee,
    defaultCarrier,
    refundCarrier,
    releaseAddress,
    refundAddress,
    refundExchangeDeliveryPolicy,
    deliveryGroup,
    division3JejuFee,
    division3OutsideJejuFee,
  } = deliveryInfo

  return (
    <Accordion label={'배송/교환/반품 안내'} labelBoxProps={{ paddingY: 12 }}>
      <VStack spacing={16}>
        <VStack spacing={8}>
          <NoticeInfoField
            label="배송비"
            value={
              <>
                기본 {formatAmount(baseDeliveryFee)}원{'\n'}
                {getAdditionalDeliveryFeeNotice(deliveryInfo) || ''}
              </>
            }
          />
          <NoticeInfoField
            label="반품 배송비"
            value={`${formatAmount(refundDeliveryFee)}원`}
          />
          <NoticeInfoField
            label="교환 배송비"
            value={`${formatAmount(exchangeDeliveryFee)}원`}
          />
          <NoticeInfoField
            label="제주 추가 배송비"
            value={`${formatAmount(
              deliveryGroup?.division3JejuFee || division3JejuFee
            )}원`}
          />
          <NoticeInfoField
            label="제주 외 도서 산간 추가 배송비"
            value={`${formatAmount(
              deliveryGroup?.division3OutsideJejuFee || division3OutsideJejuFee
            )}원`}
          />
          <NoticeInfoField label="기본 택배사" value={defaultCarrier.name} />
          <NoticeInfoField label="반품 택배사" value={refundCarrier.name} />
          <NoticeInfoField label="출고지" value={releaseAddress.fullAddress} />
          <NoticeInfoField
            label="반품 보내실 곳"
            value={refundAddress.fullAddress}
          />
        </VStack>
        {refundExchangeDeliveryPolicy && (
          <VStack>
            <NoticeInfoLabel>반품/교환 정책</NoticeInfoLabel>
            <NoticeInfoValue>{refundExchangeDeliveryPolicy}</NoticeInfoValue>
          </VStack>
        )}
      </VStack>
    </Accordion>
  )
}
