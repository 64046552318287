import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  FlexBoxProps,
  flexBoxStyles,
  LayoutProps,
  layoutStyles,
  PositionProps,
  positionStyles,
  SpaceProps,
  spaceStyles,
} from './core-styles'
import { Size } from './types'
import { parseSize } from './utils'

export type StackProps = LayoutProps &
  SpaceProps &
  PositionProps &
  FlexBoxProps & {
    spacing?: Size
  }

const commonStackStyles = css`
  display: flex;
`

export const VStack = styled.div<StackProps>`
  ${commonStackStyles};
  align-items: stretch;
  ${(props) => layoutStyles(props)}
  ${(props) => spaceStyles(props)}
  ${(props) => positionStyles(props)}
  ${(props) => flexBoxStyles(props)}

  flex-direction: column;
  row-gap: ${({ spacing }) => parseSize(spacing)};
`

export const HStack = styled.div<StackProps>`
  ${commonStackStyles};
  align-items: center;
  ${(props) => layoutStyles(props)}
  ${(props) => spaceStyles(props)}
  ${(props) => positionStyles(props)}
  ${(props) => flexBoxStyles(props)}

  flex-direction: row;
  column-gap: ${({ spacing }) => parseSize(spacing)};
`
