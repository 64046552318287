import { SkuStatusEnum } from '@src/types/transaction'
import { createContext, useContext, useMemo, useState } from 'react'

type ProviderProps = {
  children?: React.ReactNode
}

type ContextValue = {
  tempSkuStatusMap: Map<string, SkuStatusEnum>
  setTempSkuStatus: (skuId: string, skuStatus: SkuStatusEnum) => void
}

const TempSkuStatusMapContext = createContext<ContextValue | null>(null)

export const TempSkuStatusMapProvider = ({ children }: ProviderProps) => {
  const [tempSkuStatusMap, setTempSkuStatusMap] = useState<
    Map<string, SkuStatusEnum>
  >(new Map())

  const setTempSkuStatus = (skuId: string, skuStatus: SkuStatusEnum) => {
    setTempSkuStatusMap((prev) => prev.set(skuId, skuStatus))
  }

  const contextValue = useMemo<ContextValue>(
    () => ({
      tempSkuStatusMap,
      setTempSkuStatus,
    }),
    [tempSkuStatusMap]
  )

  return (
    <TempSkuStatusMapContext.Provider value={contextValue}>
      {children}
    </TempSkuStatusMapContext.Provider>
  )
}

export const useTempSkuStatusMap = () => {
  const contextValue = useContext(TempSkuStatusMapContext)
  if (!contextValue) {
    throw new Error('Cannot found TempSkuStatusMapProvider!')
  }

  return contextValue
}
