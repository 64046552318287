const base = {
  home: '/',
} as const

const info = {
  notice: '/info/notice',
  privacyPolicy: '/info/privacy-policy',
  termsOfService: '/info/terms-of-service',
  uzzimMoney: '/info/uzzim-money',
  faq: '/info/faq',
  inquiry: '/info/inquiry',
  inquiryCreate: '/info/inquiry/create',
  inquiryEdit: '/info/inquiry/edit',
} as const

const shopping = {
  home: '/shopping',
  productDetail: '/shopping/product-detail',
  cart: '/shopping/cart',
  category: '/shopping/category',
  categoryResult: '/shopping/category/result',
  search: '/shopping/search',
  cartOrder: '/shopping/order',
  directOrder: '/shopping/order/direct',
  orderSuccess: '/shopping/order/success',
  orderFail: '/shopping/order/fail',
  custom: '/shopping/[...customPage]',
} as const

const my = {
  home: '/my',
  login: '/my/login',
  loginProcess: '/my/login/process',
  signUp: '/my/sign-up',
  signUpFail: '/my/sign-up/fail',
  settings: '/my/settings',
  settingsEditMyInfo: '/my/settings/edit-my-info',
  withdrawUser: '/my/settings/withdraw-user',
  withdrawUserSuccess: '/my/settings/withdraw-user/success',
  withdrawUserFail: '/my/settings/withdraw-user/fail',
  cards: '/my/cards',
  cardDeleteSuccess: '/my/cards/delete-success',
  cardDeleteFail: '/my/cards/delete-fail',
  giftReceivedHistory: '/my/gift/received-history',
  giftSentHistory: '/my/gift/sent-history',
  order: {
    history: '/my/order/history',
    claims: '/my/order/claims',
    purchaseConfirmSuccess: '/my/order/purchase-confirm/success',
    purchaseConfirmFail: '/my/order/purchase-confirm/fail',
    cancelRequest: '/my/order/cancel-request',
    cancelRequestSuccess: '/my/order/cancel-request/success',
    cancelRequestFail: '/my/order/cancel-request/fail',
    exChangeRequest: '/my/order/exchange-request',
    exchangeRequestSuccess: '/my/order/exchange-request/success',
    exchangeRequestFail: '/my/order/exchange-request/fail',
    refundRequest: '/my/order/refund-request',
    refundRequestSuccess: '/my/order/refund-request/success',
    refundRequestFail: '/my/order/refund-request/fail',
  },
  address: '/my/address',
  wishlist: '/my/wishlist',
  reviews: '/my/reviews',
  productInquiries: '/my/product-inquiries',
  notification: '/my/notification',
  notificationSettings: '/my/notification/settings',
  uzzimMoneyHistory: '/my/uzzim-money/history',
  uzzimMoneyWithdraw: '/my/uzzim-money/withdraw',
  uzzimMoneyWithdrawSuccess: '/my/uzzim-money/withdraw/success',
  uzzimMoneyWithdrawFail: '/my/uzzim-money/withdraw/fail',
  verification: '/my/settings/verification',
  verificationProcess: '/my/settings/verification/process',
  verificationFail: '/my/settings/verification/fail',
  verificationMismatchPhoneNumber:
    '/my/settings/verification/mismatch-phone-number',
  uzzimMoneyCharge: '/my/uzzim-money/charge',
  uzzimMoneyChargeSuccess: '/my/uzzim-money/charge/success',
  uzzimMoneyChargeFail: '/my/uzzim-money/charge/fail',
  openBanking: '/my/open-banking',
  openBankingAddAccountProcess: '/my/open-banking/add-account/process',
  openBankingAddAccountFail: '/my/open-banking/add-account/fail',
} as const

const card = {
  chargeCard: '/card/charge',
  chargeCardSuccess: '/card/charge/success',
  chargeCardFail: '/card/charge/fail',
  sendGift: '/card/send-gift',
  sendGiftBasic: '/card/send-gift/basic',
  sendGiftSuccess: '/card/send-gift/success',
  sendGiftFail: '/card/send-gift/fail',
  cancelledOrRegistered: '/card/receive-gift/cancelled-or-registered',
  exchangeCard: '/card/exchange',
  exchangeCardSuccess: '/card/exchange/success',
  exchangeCardFail: '/card/exchange/fail',
  receiveGift: '/card/receive-gift',
  receiveGiftFail: '/card/receive-gift/fail',
  alreadyReceived: '/card/receive-gift/already',
  history: '/card/history',
  refundHistory: '/card/history/refund-history',
  refundCardHistory: '/card/history/refund-card-history',
} as const

const cardShop = {
  home: '/card-shop',
} as const

const voucher = {
  home: '/voucher',
} as const

const error = {
  blocked: '/error/blocked',
} as const

const studio = {
  imageStudio: '/image-studio', // TODO: this will be deprecated soon
  uzzimStudio: {
    home: '/uzzim-studio',
    new: '/uzzim-studio/new',
    image: '/uzzim-studio/image',
    publishSuccess: '/uzzim-studio/publish/success',
  },
} as const

export const routes = {
  base,
  info,
  cardShop,
  shopping,
  my,
  card,
  voucher,
  error,
  studio,
} as const
