import { ErrorCodeEnum } from '@src/common/constants/errors'
import {
  QueryKey,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

export enum HttpStatusCode {
  OK = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export type ApiListResult<T> = {
  readonly page: number
  readonly total: number
  readonly first: boolean
  readonly results: Array<T>
  readonly totalPage: number
  readonly nextAvailable: boolean
  readonly numOfPage: number
}

export type ApiListResult2<T> = {
  readonly items: Array<T>
  readonly total: number
  readonly page: number
  readonly itemsPerPage: number
}

export type ApiListResult3<T> = ApiListResult2<T> & {
  readonly hasNextPage: boolean // 다음 페이지 여부
}

// FIXME: (Mia) 알림페이지의 하모니 api 사용을 위하여 임시로 추가한 타입입니다. 유찜 알림 api 업데이트 후 이 타입을 삭제하여 주시기 바랍니다.
export type TempApiListResult<T> = {
  readonly page: number
  readonly total: number
  readonly items: Array<T>
  readonly isReadTotal: number
  readonly isUnreadTotal: number
  readonly itemsPerPage: number
}

export type BaseParams<Ordering = string> = {
  readonly itemsPerPage?: number
  readonly page?: number
  readonly ordering?: Ordering
}

export type OtpRequest<T> = {
  otp: string
  data: T
}

export type QueryOptions<
  TData = unknown,
  TSelectedData = TData,
  TErrorData = unknown,
  TQueryKey extends QueryKey = any[]
> = Omit<
  UseQueryOptions<TData, AxiosError<TErrorData>, TSelectedData, TQueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
>

export type InfiniteQueryOptions<
  TData = unknown,
  TSelectedData = TData,
  TErrorData = unknown,
  TQueryKey extends QueryKey = any[]
> = Omit<
  UseInfiniteQueryOptions<
    TData,
    AxiosError<TErrorData>,
    TSelectedData,
    TData,
    TQueryKey
  >,
  'queryKey' | 'queryFn'
>

export type MutationOptions<
  TData = unknown,
  TVariables = unknown,
  TErrorData = unknown
> = UseMutationOptions<TData, AxiosError<TErrorData>, TVariables>

export type UzzimError = { code: ErrorCodeEnum }
