import styled from '@emotion/styled'
import { useToggle } from '@src/hook/use-toggle'
import { colors, letterSpacings, zIndices } from '@src/styles/theme'
import { Selectable } from '@src/types/util-types/selectable'
import { useEffect, useState } from 'react'
import { Box, HStack, VStack } from '../../basic'
import { Size } from '../../basic/types'
import { parseSize } from '../../basic/utils'
import { ExpandButton } from '../expand-button'

type Props<T> = {
  placeholder?: string
  options: Selectable<T>[]
  initialSelected?: Selectable<T>
  onChange?: (selectedValue: T) => void
  maxHeight?: Size
  readOnly?: boolean
}

export const Select = <T,>({
  placeholder,
  options,
  initialSelected,
  onChange,
  maxHeight = 200,
  readOnly,
}: Props<T>) => {
  const [selected, setSelected] = useState<Selectable<T> | null>(
    initialSelected || null
  )
  const [isOpen, toggleIsOpen] = useToggle()

  useEffect(() => {
    setSelected(initialSelected || null)
  }, [initialSelected])

  const handleSelect = (selected: Selectable<T>) => {
    if (selected.disabled) {
      return
    }

    setSelected(selected)
    onChange && onChange(selected.value)
    toggleIsOpen()
  }

  return (
    <Container>
      {isOpen && (
        <Box
          position="fixed"
          inset="0"
          zIndex="above"
          onPointerDown={toggleIsOpen}
        />
      )}

      <SelectBox isOpen={isOpen} readOnly={readOnly}>
        <Trigger
          type="button"
          onClick={toggleIsOpen}
          isSelected={!!selected}
          disabled={readOnly}
          data-et={'open-delivery-request-list'}
        >
          <HStack justifyContent="space-between" paddingX={12} height={40}>
            <span>{!selected ? placeholder : selected.label}</span>
            <ExpandButton expanded={isOpen} />
          </HStack>
        </Trigger>
        <Options isOpen={isOpen} maxHeight={maxHeight}>
          <VStack paddingX={16} alignItems="flex-start">
            {options.map(
              (option, index) =>
                (!selected || option.value !== selected.value) && (
                  <OptionItem
                    key={index}
                    onClick={() => handleSelect(option)}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </OptionItem>
                )
            )}
          </VStack>
        </Options>
      </SelectBox>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: ${parseSize(40)};
`

const SelectBox = styled.div<{ isOpen?: boolean; readOnly?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndices.above};

  background-color: ${({ readOnly }) =>
    readOnly ? colors.gray[100] : colors.white};
  border: ${parseSize(1)} solid
    ${({ isOpen }) => (isOpen ? colors.black : colors.gray[200])};
`

const Trigger = styled.button<{ isSelected?: boolean }>`
  width: 100%;
  color: ${({ isSelected }) => (isSelected ? colors.black : colors.gray[300])};
  font-weight: 400;
  font-size: 0.8125rem;
  letter-spacing: -0.04em;
`

const Options = styled.div<{ isOpen?: boolean; maxHeight?: Size }>`
  transition: max-height 0.2s;
  max-height: ${({ isOpen, maxHeight }) => parseSize(isOpen ? maxHeight : 0)};
  overflow: auto;
`

const OptionItem = styled.button`
  width: 100%;
  min-height: ${parseSize(40)};
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: ${parseSize(14)};
  line-height: 1;
  letter-spacing: ${letterSpacings.wide};
  color: ${colors.gray[800]};

  :disabled {
    color: ${colors.gray[200]};
  }
`

export default Select
