import styled from '@emotion/styled'
import { Text, TextProps } from '@src/components/basic'
import { colors } from '@src/styles/theme'

type Props = TextProps & {
  children?: React.ReactNode
  required?: boolean
}

export const Label = ({ children, required, ...textProps }: Props) => {
  return (
    <StyledLabel
      as="label"
      fontWeight="light"
      fontSize={12}
      letterSpacing="wide"
      color="black"
      {...textProps}
      required={required}
    >
      {children}
    </StyledLabel>
  )
}

const StyledLabel = styled(Text)<Pick<Props, 'required'>>`
  ::after {
    content: '*';
    color: ${colors.required};
    display: ${({ required }) => (required ? 'inline-block' : 'none')};
  }
`

export default Label
