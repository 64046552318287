import { shoppingImages } from '@src/common/constants/images'
import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { Drawer, DrawerHeader, Icon, KakaoScript } from '@src/components/common'
import { useToast } from '@src/contexts/toast-context'
import { ModalProps } from '@src/hook/use-modal'
import { copyToClipboard } from '@src/utils/copy-to-clipboard'
import { shareKakaoMessage } from '@src/utils/share-kakao'
import Image from 'next/legacy/image'

type Props = ModalProps & {
  productId: string
  productTitle: string
  productImageUrl: string
}

export const ShareDrawer = ({
  isOpen,
  onClose,
  productId,
  productTitle,
  productImageUrl,
}: Props) => {
  const showToast = useToast()

  const getUrlToShare = () => {
    const { origin } = location
    const path = `${routes.shopping.productDetail}/${productId}`
    const url = `${origin}${path}`
    return url
  }

  const handleClickCopy = () => {
    copyToClipboard(getUrlToShare(), () => {
      showToast('클립보드에 복사되었습니다.')
      onClose()
    })
  }

  const handleClickShareKakao = () => {
    if (!window.Kakao) {
      return
    }

    shareKakaoMessage({
      url: getUrlToShare(),
      title: productTitle,
      imageUrl: productImageUrl,
      buttonLabel: '바로가기',
    })

    onClose()
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <KakaoScript />
      <DrawerHeader title="공유하기" onClose={onClose} />
      <HStack
        spacing={32}
        paddingTop={32}
        paddingBottom={60}
        justifyContent="center"
      >
        <button onClick={handleClickCopy}>
          <VStack spacing={8} alignItems="center">
            <Box
              centered
              backgroundColor="gray.100"
              borderRadius={12}
              size={88}
            >
              <Icon iconName="link" color="black" size={36} />
            </Box>
            <Text fontSize={12} fontColor="gray.500" fontWeight="light">
              Copy URL
            </Text>
          </VStack>
        </button>
        <button onClick={handleClickShareKakao}>
          <VStack spacing={8} alignItems="center">
            <Box
              centered
              backgroundColor="gray.100"
              borderRadius={12}
              size={88}
            >
              <Image
                src={shoppingImages.kakaoLogoIcon}
                alt="kakao-share"
                width={48}
                height={48}
                layout="fixed"
              />
            </Box>
            <Text fontSize={12} fontColor="gray.500" fontWeight="light">
              Kakaotalk
            </Text>
          </VStack>
        </button>
      </HStack>
    </Drawer>
  )
}
