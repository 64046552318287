import { useProductInquiryQuery } from '@api/hooks/product-inquiry/use-product-inquiry'
import { useProductInquiryAnswersQuery } from '@api/hooks/product-inquiry/use-product-inquiry-answers'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import {
  Accordion,
  FullDrawer,
  FullDrawerHeader,
  HDivider,
  LoadingSpinner,
  PageWrapper,
} from '@src/components/common'
import { ModalProps } from '@src/hook/use-modal'
import { AnswererTypeEnum } from '@src/types/product-inquiry'
import { DateFormatTypes, formatDate } from '@src/utils/format-date'
import { InquiryProductInfo } from '../inquiry-product-info'

type Props = ModalProps & {
  inquiryId: string
  title?: string
}

export const InquiryDetailDrawer = ({
  isOpen,
  onClose,
  inquiryId,
  title = 'Q&A 답글보기',
}: Props) => {
  const productInquiryQuery = useProductInquiryQuery(inquiryId)
  const productInquiryAnswersQuery = useProductInquiryAnswersQuery(inquiryId)

  if (!productInquiryQuery.isSuccess) {
    return (
      <FullDrawer isOpen={isOpen}>
        <FullDrawerHeader title={title} onClose={onClose} />
        <LoadingSpinner />
      </FullDrawer>
    )
  }

  const {
    productTitle,
    productBrand,
    productImages,
    title: inquiryTitle,
    content: inquiryContents,
    productSkuTitle,
  } = productInquiryQuery.data

  return (
    <FullDrawer isOpen={isOpen}>
      <FullDrawerHeader title={title} onClose={onClose} />
      <PageWrapper>
        <Box padding={16}>
          <InquiryProductInfo
            title={productTitle}
            brand={productBrand}
            skuTitle={productSkuTitle}
            imageUrl={productImages[0]}
          />
        </Box>
        <HDivider thickness={1} backgroundColor="gray.100" />
        <VStack padding={16}>
          <Text fontSize={14}>{inquiryTitle}</Text>
          <Text fontSize={12} fontWeight="light">
            {inquiryContents}
          </Text>
        </VStack>
        <HDivider backgroundColor="gray.100" />
        <VStack>
          {productInquiryAnswersQuery.data?.map((answer, index) => (
            <Accordion
              key={answer.id}
              label={`답변${index + 1}`}
              initialExpanded={index === 0}
            >
              <VStack spacing={8}>
                <HStack justifyContent="space-between">
                  <AnswerInfoText>
                    {answer.answererType === AnswererTypeEnum.Seller &&
                      '[판매자]'}
                    {answer.answererType === AnswererTypeEnum.Supplier &&
                      '[상품공급자]'}
                  </AnswerInfoText>
                  <AnswerInfoText>
                    {formatDate(answer.updateDt, DateFormatTypes.DateOnly)}
                  </AnswerInfoText>
                </HStack>
                <Text
                  fontSize={13}
                  fontWeight="light"
                  letterSpacing="wide"
                  fontColor="gray.700"
                >
                  {answer.answerContents}
                </Text>
              </VStack>
            </Accordion>
          ))}
        </VStack>
      </PageWrapper>
    </FullDrawer>
  )
}

const AnswerInfoText = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Text
      fontSize={13}
      fontWeight="light"
      letterSpacing="wide"
      fontColor="purple.dark.200"
    >
      {children}
    </Text>
  )
}
