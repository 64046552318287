import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { colors, zIndices } from '@src/styles/theme'

type Props = {
  children?: React.ReactNode
  hasShadow?: boolean
}

export const DrawerFooter = ({ children, hasShadow }: Props) => {
  return <Footer hasShadow={hasShadow}>{children}</Footer>
}

const Footer = styled.footer<Omit<Props, 'children'>>`
  padding: 0.5rem 1rem 1rem;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  background-color: ${colors.white};
  box-shadow: ${({ hasShadow }) =>
    hasShadow ? '0px -2px 8px rgba(0, 0, 0, 0.08)' : ''};
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: ${zIndices.layout};
`
