import { Box, Text, VStack } from '@src/components/basic'
import { BorderedBox } from '../bordered-box'
import { Chip } from '../chip'

type Props = {
  label?: string
  infoValue?: React.ReactNode
  subInfoValue?: React.ReactNode
}

export const ResultInfo = ({ label, infoValue, subInfoValue }: Props) => {
  return (
    <VStack spacing={8} alignItems="center">
      {label && (
        <BorderedBox padding={8} borderRadius="full">
          <Text fontWeight="light" fontSize={12} lineHeight={1}>
            {label}
          </Text>
        </BorderedBox>
      )}
      {infoValue && (
        <Text fontSize={14} fontWeight="medium" lineHeight={1.3}>
          {infoValue}
        </Text>
      )}
      {subInfoValue && (
        <Text
          fontSize={12}
          fontWeight="light"
          fontColor="gray.900"
          textAlign="center"
        >
          {subInfoValue}
        </Text>
      )}
    </VStack>
  )
}
