export enum SkuButtonTypeEnum {
  DeliveryTracking = 'delivery-tracking',
  CancelOrder = 'cancel-order',
  ConfirmPurchase = 'confirm-purchase',
  RefundRequest = 'refund-request',
  ExchangeRequest = 'exchange-request',
  ConfirmPurchaseDisabled = 'confirm-purchase-disabled',
  RefundRequestDisabled = 'refund-request-disabled',
  ExchangeRequestDisabled = 'exchange-request-disabled',
  CancelOrderCompleted = 'cancel-order-completed',
  ExchangeRequestCompleted = 'exchange-request-completed',
  RefundRequestCompleted = 'refund-request-completed',
  WriteReview = 'write-review',
  ReviewCompleted = 'review-completed',
}

export const skuButtonLabelMap: Record<SkuButtonTypeEnum, string> = {
  [SkuButtonTypeEnum.DeliveryTracking]: '배송조회',
  [SkuButtonTypeEnum.CancelOrder]: '주문취소',
  [SkuButtonTypeEnum.ConfirmPurchase]: '구매확정',
  [SkuButtonTypeEnum.RefundRequest]: '반품요청',
  [SkuButtonTypeEnum.ExchangeRequestDisabled]: '교환요청',
  [SkuButtonTypeEnum.ConfirmPurchaseDisabled]: '구매확정',
  [SkuButtonTypeEnum.RefundRequestDisabled]: '반품요청',
  [SkuButtonTypeEnum.ExchangeRequest]: '교환요청',
  [SkuButtonTypeEnum.CancelOrderCompleted]: '주문취소 요청 완료',
  [SkuButtonTypeEnum.ExchangeRequestCompleted]: '교환요청 완료',
  [SkuButtonTypeEnum.RefundRequestCompleted]: '반품요청 완료',
  [SkuButtonTypeEnum.WriteReview]: '리뷰 작성하기',
  [SkuButtonTypeEnum.ReviewCompleted]: '리뷰 작성완료',
}
