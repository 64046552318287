export type ProductInquiryAnswerDto = {
  id: string
  createDt: string
  updateDt: string
  channelId: string
  productInquiryId: string
  answererId: string
  answerContents: string
  name: string
  answererType: AnswererTypeEnum
}

export enum AnswererTypeEnum {
  Supplier = 'supplier',
  Seller = 'seller',
}
