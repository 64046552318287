import styled from '@emotion/styled'
import { Color, Size } from '@src/components/basic/types'
import { parseSize } from '@src/components/basic/utils'
import { colors } from '@src/styles/theme'
import { defaultCardHeight, defaultCardWidth } from '../card'

type Props = {
  width?: Size
  height?: Size
  backgroundColor?: Color
  borderRadius?: Size
}

export const GrayBox = ({
  width = defaultCardWidth,
  height = defaultCardHeight,
  backgroundColor,
  borderRadius,
}: Props) => {
  return (
    <Box
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
    />
  )
}

const Box = styled.div<Props>`
  width: ${({ width }) => parseSize(width)};
  height: ${({ height }) => parseSize(height)};
  background-color: ${({ backgroundColor = colors.gray[100] }) =>
    backgroundColor};
  border-radius: ${({ borderRadius = 4 }) => parseSize(borderRadius)};
  position: relative;
  overflow: hidden;

  @keyframes skeleton-gradient {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: skeleton-gradient 1.8s infinite ease-in-out;
  }
`
