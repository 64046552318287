export enum ErrorCodeEnum {
  // Login
  emailNotFound = 1001,
  phoneNumberNotFound = 1002,
  pathNaverExists = 1010,
  pathKakaoExists = 1011,
  bcCardUserNotSynced = 1004,
  harmonyUserNotSynced = 1005,
  phoneNumberChangedFromOauth = 1009,

  // Verification
  phoneNumberMismatch = 1012,

  // Withdraw Validation
  unusedBalanceExist = 1013,
  unreceivedGiftsExist = 1014,

  // User
  blockedUser = 1106,
  requestedWithdrawalUser = 1107,
  withdrawalUser = 1108,

  // Passcode
  passcodeAttemptExceed = 1502,

  // Skin
  skinNameInvalid = 2001,
  skinNameDuplicated = 2002,

  // Other
  errorCodePrioritiesOverlap = 4001,
  errorCodeOpenBankingResponse = 4002,
  errorCodeNotEnoughDaysForOpenBankingAccountLinkage = 4007,
  errorCodeUnauthorized = 4011,
  errorCodeNotSupported = 4031,
  errorCodeAlreadyRemoved = 4032,
  errorCodeEmailNotFound = 4033,
  errorCodeOtherActiveProvider = 4034,
  errorCodeInvalidAceessKey = 4035,
  errorCodeCiNotSet = 4036,
  errorCodeUserTypeNotSet = 4037,
  errorCodeUserNotFound = 4041,
  errorCodeGroupNotFound = 4044,
  errorCodeCardNotFound = 4045,
  errorCodeCardHistoryNotFound = 4046,
  errorCodeMoneyAmountNotFound = 4047,
  errorCodeCardMoneyExist = 4048,
  errorCodeInvalidCardStatus = 4049,
  errorCodeOpenBankingAccountNotFound = 40410,
  errorCodeLimitExceed = 40411,
  errorCodeLimitLessThanTheMin = 40412,
  errorCodePayHistoryNotFound = 40413,
  errorCodeNotEnoughCardMoney = 4061,
  errorCodeNotEnoughUzzimMoney = 4062,
  errorCodeUnkownError = 5000,
  errorCodeCommunicationWithBc = 5001,
  errorCodeCardOwnerNotFound = 5002,
  errorCodeOpenBankingInfoNotSet = 5031,
  errorCodeEncryptionRelated = 5032,

  errorCodeInvalidBankCode = 8002,

  errorCodeOpenBankingPrivacyExposed = 9800,
  errorCodeOpenBankingVoicePhishing = 9801,
}

export const errorMessages: Record<ErrorCodeEnum, string> = {
  [ErrorCodeEnum.emailNotFound]: '',
  [ErrorCodeEnum.phoneNumberNotFound]: '',
  [ErrorCodeEnum.pathNaverExists]:
    '입력하신 정보로 가입된 네이버 계정이 있습니다.\n다른 소셜 계정을 사용하여 로그인해주세요.',
  [ErrorCodeEnum.pathKakaoExists]:
    '입력하신 정보로 가입된 카카오 계정이 있습니다.\n다른 소셜 계정을 사용하여 로그인해주세요.',
  [ErrorCodeEnum.bcCardUserNotSynced]: '',
  [ErrorCodeEnum.harmonyUserNotSynced]: '',
  [ErrorCodeEnum.phoneNumberChangedFromOauth]:
    '소셜 계정과 기존 유찜 계정에 저장된\n휴대폰번호가 일치하지 않습니다.\n고객센터({csPhoneNumber})로 문의 바랍니다.',

  // Verification
  [ErrorCodeEnum.phoneNumberMismatch]:
    '오픈뱅킹 서비스를 이용하려면\n유찜 계정과 본인인증 휴대폰번호가 일치해야 합니다.',

  // Withdraw Validation
  [ErrorCodeEnum.unusedBalanceExist]: '',
  [ErrorCodeEnum.unreceivedGiftsExist]: '',

  // User
  [ErrorCodeEnum.blockedUser]:
    '보이스피싱 등 금융사기나 부정결제 사고 등의\n이상 거래가 감지되어 서비스 내 기능이\n차단되었습니다.\n\n문의사항은 {csPhoneNumber}로 연락바랍니다.',
  [ErrorCodeEnum.requestedWithdrawalUser]: `회원탈퇴 요청을 한 계정입니다.\n탈퇴 요청을 취소하는 경우\n{csPhoneNumber}로 문의바랍니다.`,
  [ErrorCodeEnum.withdrawalUser]: `이미 탈퇴한 회원입니다.\n탈퇴 후 재가입 불가로 문의사항은\n{csPhoneNumber}로 문의바랍니다.`,

  // Passcode
  [ErrorCodeEnum.passcodeAttemptExceed]:
    '비밀번호 5회 입력 실패로\n비밀번호 재설정을 진행합니다.',

  // Skin
  [ErrorCodeEnum.skinNameInvalid]: '',
  [ErrorCodeEnum.skinNameDuplicated]: '',

  // Other
  [ErrorCodeEnum.errorCodePrioritiesOverlap]: '',
  [ErrorCodeEnum.errorCodeOpenBankingResponse]: '',
  [ErrorCodeEnum.errorCodeNotEnoughDaysForOpenBankingAccountLinkage]: '',
  [ErrorCodeEnum.errorCodeUnauthorized]: '',
  [ErrorCodeEnum.errorCodeNotSupported]: '',
  [ErrorCodeEnum.errorCodeAlreadyRemoved]: '',
  [ErrorCodeEnum.errorCodeEmailNotFound]: '',
  [ErrorCodeEnum.errorCodeOtherActiveProvider]: '',
  [ErrorCodeEnum.errorCodeInvalidAceessKey]: '',
  [ErrorCodeEnum.errorCodeCiNotSet]: '',
  [ErrorCodeEnum.errorCodeUserTypeNotSet]: '',
  [ErrorCodeEnum.errorCodeUserNotFound]: '',
  [ErrorCodeEnum.errorCodeGroupNotFound]: '',
  [ErrorCodeEnum.errorCodeCardNotFound]: '',
  [ErrorCodeEnum.errorCodeCardHistoryNotFound]: '',
  [ErrorCodeEnum.errorCodeMoneyAmountNotFound]: '',
  [ErrorCodeEnum.errorCodeCardMoneyExist]: '',
  [ErrorCodeEnum.errorCodeInvalidCardStatus]: '',
  [ErrorCodeEnum.errorCodeOpenBankingAccountNotFound]: '',
  [ErrorCodeEnum.errorCodeLimitExceed]: '',
  [ErrorCodeEnum.errorCodeLimitLessThanTheMin]: '',
  [ErrorCodeEnum.errorCodePayHistoryNotFound]: '',
  [ErrorCodeEnum.errorCodeNotEnoughCardMoney]: '',
  [ErrorCodeEnum.errorCodeNotEnoughUzzimMoney]: '',
  [ErrorCodeEnum.errorCodeUnkownError]: '',
  [ErrorCodeEnum.errorCodeCommunicationWithBc]: '',
  [ErrorCodeEnum.errorCodeCardOwnerNotFound]: '',
  [ErrorCodeEnum.errorCodeOpenBankingInfoNotSet]: '',
  [ErrorCodeEnum.errorCodeEncryptionRelated]: '',
  [ErrorCodeEnum.errorCodeInvalidBankCode]: '',
  [ErrorCodeEnum.errorCodeOpenBankingPrivacyExposed]:
    '개인정보노출 계좌로 오픈뱅킹 이용에\n제한이 있어 충전이 불가하오니\n계좌 개설 지점에 확인해 주시기 바랍니다.\n',
  [ErrorCodeEnum.errorCodeOpenBankingVoicePhishing]:
    '보이스피싱 피해 발생 우려 계좌로\n오픈뱅킹 이용에 제한이 있어 충전이 불가하오니\n계좌 개설 지점에 확인해 주시기 바랍니다.\n',
} as const

export const allErrorCodes = Object.keys(errorMessages)
