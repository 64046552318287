import { SkuStatusEnum } from './transaction-sku.dto'

export type SkuLogDto = {
  initiatorId: string
  userNotes: UserNotesDto
  channelAdminNotes: UserNotesDto
  supplierNotes: SupplierNotes
  status: SkuStatusEnum
  initiatorName: string
  initiatorType: InitiatorType
  updateDt: string
  createDt: string
}

export type UserNotesDto = {
  note?: string
  attributeOptions?: {
    sellableSkuId: string
    title: string
  }
}

export type SupplierNotes = {
  note?: string
  carrierCode?: string
  carrierNumber?: string
}

export enum InitiatorType {
  Supplier = 'supplier',
  ChannelOwner = 'channel_owner',
  User = 'user',
  System = 'system',
  Seller = 'seller',
}
