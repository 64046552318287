import styled from '@emotion/styled'
import { Box, HStack, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { CustomImage } from '@src/components/common'
import { colors } from '@src/styles/theme'
import { InventoryStatusEnum, Product } from '@src/types/product'
import { useState } from 'react'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { DisableOverlay } from '../common/disabled-overlay'
import { SoldOutChip } from '../common/sold-out-chip'
import { TimeSaleChip } from '../common/time-sale-chip'
import { WishlistButton } from '../common/wishlist-button'

type ProductDetailImageProps = {
  productDetail: Product
}

export const ProductDetailImage = ({
  productDetail,
}: ProductDetailImageProps) => {
  const [slideIndex, setSlideIndex] = useState<number>(0)
  const { images, id, wishlistId, title, timesaleAppliedNow, inventoryStatus } =
    productDetail

  const isSingleImage = images.length === 1

  return (
    <VStack width="100%" overflow="hidden" flexShrink={0}>
      <Box display="block" position="relative">
        {isSingleImage && (
          <CustomImage
            src={images[0]}
            alt={title}
            layout="responsive"
            width={1}
            height={1}
          />
        )}
        {!isSingleImage && (
          <Swiper
            slidesPerView={1}
            centeredSlides={true}
            loop={true}
            onSlideChange={(swiper) => setSlideIndex(swiper.realIndex)}
          >
            {images.map((image, index) => {
              return (
                <SwiperSlide key={index}>
                  <CustomImage
                    src={image}
                    alt={title}
                    layout="responsive"
                    width={1}
                    height={1}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        )}
        {productDetail.isNotSellable && <DisableOverlay />}
        <Box position="absolute" bottom={16} right={16}>
          <WishlistButton
            productId={id}
            wishItemId={wishlistId}
            emptyIconColor="gray.500"
            hasShadow={false}
          />
        </Box>
        <ChipContainer
          isSoldOut={inventoryStatus === InventoryStatusEnum.SoldOut}
          isShortage={inventoryStatus === InventoryStatusEnum.Shortage}
          timesaleAppliedNow={timesaleAppliedNow}
        />
      </Box>
      <Box paddingX={16} paddingY={8}>
        <ImageSwipeIndicator
          index={productDetail.isNotSellable || isSingleImage ? -1 : slideIndex}
          imageCount={
            productDetail.isNotSellable || isSingleImage ? 1 : images.length
          }
        />
      </Box>
    </VStack>
  )
}

type ChipContainerProps = {
  isSoldOut?: boolean
  isShortage?: boolean
  timesaleAppliedNow?: boolean
}

const ChipContainer = ({
  isSoldOut,
  isShortage,
  timesaleAppliedNow,
}: ChipContainerProps) => {
  return (
    <HStack
      position="absolute"
      bottom={16}
      left={16}
      spacing={8}
      zIndex="above"
    >
      {(isSoldOut || isShortage) && <SoldOutChip isShortage={isShortage} />}
      {timesaleAppliedNow && <TimeSaleChip position="unset" isDetail />}
    </HStack>
  )
}

type ImageSwipeIndicatorProps = {
  index: number
  imageCount: number
}

const ImageSwipeIndicator = styled.div<ImageSwipeIndicatorProps>`
  width: 100%;
  height: ${parseSize(2)};
  background-color: ${colors.gray[200]};
  position: relative;

  ::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: ${colors.black};
    transform: ${({ index, imageCount }) =>
      `scaleX(${(index + 1) / imageCount})`};
    transform-origin: left center;
    transition: transform 0.1s;
  }
`
