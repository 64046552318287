import styled from '@emotion/styled'
import { LoadingSpinner } from '@src/components/common'
import { useEffect, useState } from 'react'
import { Router } from 'next/router'
import { zIndices } from '@src/styles/theme'
import { Box } from '@src/components/basic'

export const RouterLoadingSpinner = () => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const activateLoadingSpinner = () => {
      setIsLoading(true)
    }
    const deactivateLoadingSpinner = () => {
      setIsLoading(false)
    }
    Router.events.on('routeChangeStart', activateLoadingSpinner)
    Router.events.on('routeChangeError', deactivateLoadingSpinner)
    Router.events.on('beforeHistoryChange', deactivateLoadingSpinner)
    return () => {
      Router.events.off('routeChangeStart', activateLoadingSpinner)
      Router.events.off('routeChangeError', deactivateLoadingSpinner)
      Router.events.off('beforeHistoryChange', deactivateLoadingSpinner)
    }
  }, [])

  return (
    <>
      {isLoading && (
        <Box
          justifyContent={'center'}
          alignItems={'center'}
          position={'absolute'}
          inset={0}
        >
          <LoadingSpinner />
        </Box>
      )}
    </>
  )
}
