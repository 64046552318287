import { css } from '@emotion/react'
import { Size } from '../types'
import { parseSize } from '../utils'

export type SpaceProps = {
  margin?: Size | [Size, Size, Size, Size]
  marginX?: Size
  marginY?: Size
  marginLeft?: Size
  marginTop?: Size
  marginRight?: Size
  marginBottom?: Size

  padding?: Size | [Size, Size, Size, Size]
  paddingX?: Size
  paddingY?: Size
  paddingLeft?: Size
  paddingTop?: Size
  paddingRight?: Size
  paddingBottom?: Size
}

export const spaceStyles = (props: SpaceProps) => {
  const { margin, marginX, marginY, padding, paddingX, paddingY } = props

  const marginTop = props.marginTop || marginY || getSpaceValue(margin, 0)
  const marginRight = props.marginRight || marginX || getSpaceValue(margin, 1)
  const marginBottom = props.marginBottom || marginY || getSpaceValue(margin, 2)
  const marginLeft = props.marginLeft || marginX || getSpaceValue(margin, 3)

  const paddingTop = props.paddingTop || paddingY || getSpaceValue(padding, 0)
  const paddingRight =
    props.paddingRight || paddingX || getSpaceValue(padding, 1)
  const paddingBottom =
    props.paddingBottom || paddingY || getSpaceValue(padding, 2)
  const paddingLeft = props.paddingLeft || paddingX || getSpaceValue(padding, 3)

  return css`
    margin-left: ${parseSize(marginLeft)};
    margin-right: ${parseSize(marginRight)};
    margin-top: ${parseSize(marginTop)};
    margin-bottom: ${parseSize(marginBottom)};
    margin: ${!marginTop &&
    !marginBottom &&
    !marginLeft &&
    !marginRight &&
    margin};
    padding-left: ${parseSize(paddingLeft)};
    padding-right: ${parseSize(paddingRight)};
    padding-top: ${parseSize(paddingTop)};
    padding-bottom: ${parseSize(paddingBottom)};
    padding: ${!paddingTop &&
    !paddingBottom &&
    !paddingLeft &&
    !paddingRight &&
    padding};
  `
}

const getSpaceValue = (
  space: SpaceProps['margin'],
  index: number
): Size | undefined => {
  if (!Array.isArray(space)) {
    if (typeof space !== 'number') {
      return undefined
    }

    return space
  }

  return space[index]
}
