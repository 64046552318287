import { usePhotoReviewsInfiniteQuery } from '@api/hooks/review/use-photo-reviews'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { HDivider, LoadingSpinner } from '@src/components/common'
import { usePhotoReviewDetailDrawer } from '../_hooks/use-photo-review-detail'
import { PhotoItem } from './photo-item'
import { PhotoReviewDrawer } from './photo-review-detail/photo-review-drawer'

type Props = {
  productId: string
  skuId?: string
  onClickMoreButton: () => void
}

export const PhotoReviewPreview = ({
  productId,
  skuId,
  onClickMoreButton,
}: Props) => {
  const photoReviewsInfiniteQuery = usePhotoReviewsInfiniteQuery({
    productId,
    skuId,
    itemsPerPage: 4,
  })
  const {
    isPhotoReviewOpen,
    openPhotoReview,
    closePhotoReview,
    photoReviewIndex,
  } = usePhotoReviewDetailDrawer()

  if (!photoReviewsInfiniteQuery.data) {
    return (
      <Box height={80}>
        <LoadingSpinner />
      </Box>
    )
  }

  if (photoReviewsInfiniteQuery.total === 0) {
    return null
  }

  return (
    <>
      <VStack>
        <Box paddingY={12}>
          <HDivider backgroundColor="gray.100" thickness={1} />
        </Box>
        <VStack spacing={8}>
          <Text fontSize={12} fontWeight="medium" lineHeight={1.5}>
            포토리뷰 모아보기
          </Text>
          {photoReviewsInfiniteQuery.total <= 4 ? (
            <Box
              display="grid"
              gridTemplateColumns="repeat(4, 1fr)"
              columnGap={8}
            >
              {photoReviewsInfiniteQuery.data.map(
                (review, index) =>
                  !!review.images.mobile.length && (
                    <PhotoItem
                      key={review.id}
                      imageUrl={review.images.mobile[0]}
                      onClick={() => {
                        openPhotoReview(index)
                      }}
                    />
                  )
              )}
            </Box>
          ) : (
            <HStack spacing={8}>
              {photoReviewsInfiniteQuery.data
                .slice(0, 3)
                .map((review, index) => (
                  <PhotoItem
                    key={review.id}
                    imageUrl={review.images.mobile[0]}
                    onClick={() => {
                      openPhotoReview(index)
                    }}
                  />
                ))}
              <PhotoItem
                imageUrl={photoReviewsInfiniteQuery.data[3].images.mobile[0]}
                isMoreButton
                onClick={onClickMoreButton}
              />
            </HStack>
          )}
        </VStack>
      </VStack>
      <PhotoReviewDrawer
        isOpen={isPhotoReviewOpen}
        onClose={closePhotoReview}
        reviews={photoReviewsInfiniteQuery.data}
        initialIndex={photoReviewIndex}
      />
    </>
  )
}
