import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { ProductInquiryAnswerDto } from '@src/types/product-inquiry'
import { useQuery } from '@tanstack/react-query'

export const useProductInquiryAnswersQuery = (inquiryId: string) => {
  return useQuery(
    [queryKeys.product.productInquiryAnswers, inquiryId],
    () =>
      getProductInquiryAnswers({
        inquiryId,
      }),
    {
      keepPreviousData: true,
      enabled: !!inquiryId,
      staleTime: staleTime.long,
    }
  )
}

const getProductInquiryAnswers = ({ inquiryId }: { inquiryId: string }) => {
  return api.productClient.get<ProductInquiryAnswerDto[]>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/inquiry_answers/inquiry/${inquiryId}`
  )
}
