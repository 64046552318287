import styled from '@emotion/styled'
import { Selectable } from '@src/types/util-types/selectable'
import React, { CSSProperties, MouseEventHandler } from 'react'
import { HStack } from '../basic'
import { Clickable } from './clickable'

type Props<T> = {
  tabs: Selectable<T>[]
  selectedTab?: T
  onChange?: (selected: T) => void
  justifyContent?: CSSProperties['justifyContent']
}

const TabBarInner = <T,>(
  { tabs, selectedTab, onChange, justifyContent = 'flex-start' }: Props<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const scrollToItem: MouseEventHandler<HTMLDivElement> = (e) => {
    const item = e.currentTarget
    const parent = item.parentElement

    if (!parent) {
      return
    }

    const { x: itemPosX, width: itemWidth } = item.getBoundingClientRect()
    const { width: parentWidth } = parent?.getBoundingClientRect()
    const expectedPosX = parentWidth / 2 - itemWidth / 2
    parent.scrollLeft -= expectedPosX - itemPosX
  }

  return (
    <Container
      spacing={16}
      paddingX={16}
      justifyContent={justifyContent}
      ref={ref}
    >
      {tabs.map((tab, index) => (
        <Clickable
          key={index}
          onClick={(e) => {
            onChange && onChange(tab.value)
            scrollToItem(e)
          }}
        >
          <TabButton selected={selectedTab === tab.value}>
            {tab.label}
          </TabButton>
        </Clickable>
      ))}
    </Container>
  )
}

export const TabBar = React.forwardRef(TabBarInner) as <T>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof TabBarInner>

const Container = styled(HStack)`
  overflow-x: auto;
  scroll-behavior: smooth;
  border-bottom: 1px solid #f5f5f5;
  flex-shrink: 0;
`

const TabButton = styled.div<{ selected?: boolean }>`
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: -0.04em;

  padding: 0.75rem 0.25rem;
  white-space: nowrap;
  border-bottom: 0.25rem solid
    ${({ selected }) => (selected ? '#5f03d5' : 'transparent')};

  transition: border-color 0.3s;
`

export default TabBar
