import { Box, BoxProps } from '@src/components/basic'
import { useComputedHeight } from '@src/hook/use-computed-height'

type Props = BoxProps & {
  children?: React.ReactNode
  expanded?: boolean
}

export const ExpandableBox = ({
  children,
  expanded,
  minHeight = 0,
  ...boxProps
}: Props) => {
  const [ref, maxHeight] = useComputedHeight(9999)

  return (
    <Box
      ref={ref}
      {...boxProps}
      maxHeight={expanded ? maxHeight : minHeight}
      overflow="hidden"
      transition="max-height 0.2s"
    >
      {children}
    </Box>
  )
}
