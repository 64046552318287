import { ApiListResult2 } from '@api/types'
import { InfiniteData } from '@tanstack/react-query'

export const selectInfiniteData = <TDto, TInstance>(
  data: InfiniteData<ApiListResult2<TDto>>,
  instantiate: (dto: TDto) => TInstance
) => {
  return {
    pageParams: data.pageParams,
    pages: data.pages.map(({ items, ...value }) => ({
      ...value,
      items: items.map(instantiate),
    })),
  }
}
