import { Keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Size, ZIndex } from '@src/components/basic/types'
import { parseSize } from '@src/components/basic/utils'
import { ModalProps } from '@src/hook/use-modal'
import { colors, maxScreenWidth } from '@src/styles/theme'
import React from 'react'
import { moveDown, moveUp } from '../common/keyframes'
import { Overlay } from '../common/overlay'
import { OverlayWrapper } from '../common/overlay-wrapper'

type Props = ModalProps & {
  children?: React.ReactNode
  borderRadius?: Size
  height?: Size
  zIndex?: ZIndex
}

export const Drawer = ({
  isOpen,
  onClose,
  children,
  borderRadius = 16,
  height = 'auto',
  zIndex,
}: Props) => {
  return (
    <OverlayWrapper isOpen={isOpen} zIndex={zIndex}>
      <Overlay isOpen={isOpen} onClose={onClose} />
      <DrawerWrapper>
        <Container
          animation={isOpen ? moveUp : moveDown}
          borderRadius={borderRadius}
          height={height}
        >
          {children}
        </Container>
      </DrawerWrapper>
    </OverlayWrapper>
  )
}

const DrawerWrapper = styled.div`
  position: absolute;
  inset: 0;
  pointer-events: none;
`

type ContainerProps = Pick<Props, 'borderRadius' | 'height'> & {
  animation: Keyframes
}

const Container = styled.section<ContainerProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height};
  max-width: ${parseSize(maxScreenWidth)};
  margin: 0 auto;
  background-color: ${colors.white};
  border-top-left-radius: ${({ borderRadius }) => parseSize(borderRadius)};
  border-top-right-radius: ${({ borderRadius }) => parseSize(borderRadius)};
  pointer-events: auto;
  animation: 0.3s cubic-bezier(0, 0, 0.2, 1) ${({ animation }) => animation}
    forwards;
`
