import { Text, TextProps } from '@src/components/basic'
import { Color, Size } from '@src/components/basic/types'
import { Chip, ChipContainerProps } from '../chip'
import { Icon, IconName } from '../icon'

type Props = Pick<TextProps, 'fontSize' | 'fontWeight'> &
  Pick<
    ChipContainerProps,
    | 'background'
    | 'backgroundColor'
    | 'padding'
    | 'background'
    | 'borderColor'
    | 'color'
  > & {
    text: string
    iconName?: IconName
    iconColor?: Color
    iconSize?: Size
  }

export const Badge = ({
  iconName,
  iconColor = 'white',
  iconSize = 12,
  text,
  color = 'white',
  fontSize = 13,
  fontWeight = 'medium',
  padding = '8px 12px',
  background,
  backgroundColor,
  borderColor,
}: Props) => {
  return (
    <Chip
      background={background}
      backgroundColor={backgroundColor}
      color={color}
      borderColor={borderColor}
      padding={padding}
      width={'fit-content'}
      gap={2}
    >
      {iconName && (
        <Icon iconName={iconName} color={iconColor} size={iconSize} />
      )}
      <Text fontSize={fontSize} fontWeight={fontWeight}>
        {text}
      </Text>
    </Chip>
  )
}
