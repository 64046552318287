export const colors = {
  primary: '#770FFC',
  white: '#FFFFFF',
  black: '#000000',
  purple: {
    dark: {
      100: '#5F03D5',
      200: '#4902A3',
      300: '#320171',
      400: '#1C013E',
    },
    light: {
      100: '#9441FD',
      200: '#B074FD',
      300: '#CDA6FE',
      400: '#E9D8FF',
    },
  },
  gray: {
    100: '#F5F5F5',
    200: '#DBDBDB',
    300: '#C2C2C2',
    400: '#A8A8A8',
    500: '#8F8F8F',
    600: '#757575',
    700: '#5C5C5C',
    800: '#424242',
    900: '#292929',
  },
  yellow: {
    primary: '#FFC700',
  },
  error: '#CC1900',
  success: '#4BA257',
  required: '#F15700',
  star: '#FFC700',
  green: '#538300',
  gradient: {
    primary: 'linear-gradient(328.86deg, #4D00AF 24%, #FF3D47 134.67%)',
  },
} as const

export const baseFontSize = 16
export const maxScreenWidth = 860
export const topBarHeight = 52
export const bottomBarHeight = 72

export const fontWeights = {
  light: 300,
  semilight: 350,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
} as const

export const letterSpacings = {
  normal: '-0.04em',
  wide: '-0.03em',
  wider: '-0.01em',
  none: '0em',
} as const

export const zIndices = {
  auto: 'auto',
  below: -1,
  base: 0,
  above: 1,
  layout: 900,
  floating: 1000,
  dropdown: 1100,
  drawer: 1200,
  fullDrawer: 1300,
  modal: 1400,
  alert: 1500,
  toast: 2000,
} as const

export const cardBoxShadowStyles: Record<'none' | 'default' | 'light', string> =
  {
    none: 'none',
    default: '0px 1px 7px rgba(0, 0, 0, 0.3)',
    light: '0px 0px 6px rgba(0, 0, 0, 0.15)',
  } as const
