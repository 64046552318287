import { Box, HStack, VStack } from '@src/components/basic'
import React from 'react'
import { VDivider } from '../dividers'
import { GrayBox } from './gray-box'

export const CardListItemSkeleton = () => {
  return (
    <Box padding={[12, 16, 12, 16]} rowGap={12}>
      <Box alignItems={'center'} justifyContent={'center'}>
        <GrayBox borderRadius={10} />
      </Box>
      <HStack justifyContent={'space-between'}>
        <VStack spacing={6}>
          <GrayBox width={100} height={15} />
          <GrayBox width={200} height={15} />
        </VStack>
        <HStack spacing={8}>
          <VDivider thickness={1} length={42} />
          <VStack spacing={6} width={112} alignItems={'flex-start'}>
            <GrayBox width={50} height={15} />
            <GrayBox width={100} height={15} />
          </VStack>
        </HStack>
      </HStack>
    </Box>
  )
}
