import { VStack } from '@src/components/basic'
import { Product } from '@src/types/product'
import { DeliveryInfoSection } from './delivery-info-section'
import { NoticeInfoSection } from './notice-info-section'
import { PurchaseNoteSection } from './purchase-note-section'

type ProductDetailPurchaseInfoProps = {
  productDetail: Product
}

export const ProductDetailPurchaseInfo = ({
  productDetail,
}: ProductDetailPurchaseInfoProps) => {
  const { noticeId, noticeInfo, deliveryInfo, note, isTangible } = productDetail

  return (
    <VStack>
      {deliveryInfo && <DeliveryInfoSection deliveryInfo={deliveryInfo} />}
      <NoticeInfoSection noticeId={noticeId} noticeInfoData={noticeInfo} />
      {!!note && <PurchaseNoteSection note={note} />}
      {/* <SellerInfo sellerInfo={sellerInfo} isDisplay={!!sellerInfo} /> */}
    </VStack>
  )
}
