import { MidTypeEnum, PgSettingDto, PgTypeEnum } from '../dto'

export class PgSettings {
  public readonly id: number
  public readonly channelId: string
  public readonly name: string
  public readonly description: string
  public readonly isDefault: boolean
  public readonly midType: MidTypeEnum
  public readonly pgType: PgTypeEnum
  public readonly status: string

  public readonly isPointOnly: boolean

  constructor(dto: PgSettingDto) {
    this.id = dto.id
    this.channelId = dto.channelId
    this.name = dto.name
    this.description = dto.description
    this.isDefault = dto.isDefault
    this.midType = dto.midType
    this.pgType = dto.pgType
    this.status = dto.status

    this.isPointOnly = this._getIsPointOnly()
  }

  private _getIsPointOnly() {
    return this.midType === MidTypeEnum.PointOnlyAvailable
  }
}
