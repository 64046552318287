import { useAlert } from '@src/contexts/alert-context'
import { useRouter } from 'next/router'

export const useErrorAlert = () => {
  const showAlert = useAlert()
  const router = useRouter()

  const showErrorAlert = (errorMessage?: string) => {
    showAlert({
      content:
        errorMessage ||
        '예기치 못한 오류가 발생했습니다.\n잠시 후 다시 시도해주시기 바랍니다.',
      buttons: [
        {
          label: '확인',
          onClick: router.reload,
        },
      ],
    })
  }

  return { showErrorAlert }
}
