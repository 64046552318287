import { useMounted } from '@src/hook/use-mounted'
import { createPortal } from 'react-dom'

type Props = {
  children?: React.ReactNode
}

export const OverlayPortal = ({ children }: Props) => {
  const { mounted } = useMounted()

  if (!mounted) {
    return null
  }

  const toastPortal = document.getElementById('overlay-portal')

  if (!toastPortal) {
    return null
  }

  return createPortal(children, toastPortal)
}
