import { Box } from '@src/components/basic'
import { Clickable } from '../clickable'
import { Icon } from '../icon'

type PaginationButtonProps = {
  type: 'prev' | 'next'
  onClick: () => void
  disabled?: boolean
}

export const PaginationButton = ({
  type,
  onClick,
  disabled,
}: PaginationButtonProps) => {
  return (
    <Clickable onClick={onClick} disabled={disabled}>
      <Box
        border={[1, 'solid', 'gray.100']}
        borderRadius={3}
        width={52}
        height={28}
        centered
      >
        <Icon
          iconName={type === 'prev' ? 'back' : 'next'}
          color={disabled ? 'gray.200' : 'gray.500'}
          size={12}
        />
      </Box>
    </Clickable>
  )
}
