import styled from '@emotion/styled'
import { Box } from '@src/components/basic'
import { Size } from '@src/components/basic/types'
import { parseSize } from '@src/components/basic/utils'
import { aws } from '@src/config'
import { cardBoxShadowStyles } from '@src/styles/theme'
import Image from 'next/legacy/image'
import { useRef } from 'react'
import { CardImageSkeleton } from '../skeleton'
import { parseBoolean } from '@src/utils/parse-boolean'
import { CardComponentType, CardShadowType } from '@src/types/card'

export const blurImageUrl =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPMqwEAAV4A7NHO49AAAAAASUVORK5CYII='

export const refundCardImageUrl = `${aws.imageBaseUrl}/card/refund-card.png`

export const defaultCardWidth = 328
export const defaultCardHeight = 209

type Props = {
  cardComponentType: CardComponentType
  imageUrl: string
  horizontal?: boolean | string
  width?: Size
  height?: Size
  alt?: string
  onClick?: () => void
  isRefundCard?: boolean
  shadowType?: CardShadowType
}

export const Card = ({
  cardComponentType,
  imageUrl,
  horizontal = true,
  width = defaultCardWidth,
  height = defaultCardHeight,
  alt,
  onClick,
  isRefundCard,
  shadowType = 'default',
}: Props) => {
  const skeletonRef = useRef<HTMLDivElement>(null)

  const imageSize = {
    width: parseBoolean(horizontal) ? width : height,
    height: parseBoolean(horizontal) ? height : width,
  }

  return (
    <CardWrapper
      as={!!onClick ? 'button' : 'div'}
      onClick={onClick}
      width={cardComponentType === 'list' ? width : imageSize.width}
      height={cardComponentType === 'list' ? height : imageSize.height}
      position={'relative'}
      justifyContent={'center'}
      alignItems={'center'}
      borderRadius={10}
      overflow={'hidden'}
      boxShadow={cardBoxShadowStyles[shadowType]}
    >
      {cardComponentType === 'list' && (
        <IntermediateWrapper
          horizontal={parseBoolean(horizontal)}
          {...imageSize}
        >
          <Image
            src={imageUrl || blurImageUrl}
            layout={'fill'}
            objectFit={'cover'}
            alt={alt}
            onError={() => skeletonRef.current?.remove()}
            onLoadingComplete={() => skeletonRef.current?.remove()}
            style={{ transform: isRefundCard ? 'scale(1.06)' : undefined }}
          />
        </IntermediateWrapper>
      )}

      {cardComponentType === 'detail' && (
        <Image
          src={imageUrl || blurImageUrl}
          layout={'fill'}
          objectFit={'cover'}
          alt={alt}
          onError={() => skeletonRef.current?.remove()}
          onLoadingComplete={() => skeletonRef.current?.remove()}
          style={{ transform: isRefundCard ? 'scale(1.06)' : undefined }}
        />
      )}

      <CardImageSkeleton ref={skeletonRef} />
    </CardWrapper>
  )
}

type CardWrapperProps = Pick<Props, 'width' | 'height'>

type IntermediateWrapperProps = Pick<Props, 'width' | 'height'> & {
  horizontal: boolean
}

const CardWrapper = styled(Box)<CardWrapperProps>`
  width: ${({ width }) => parseSize(width)};
  height: ${({ height }) => parseSize(height)};
`

export const IntermediateWrapper = styled(Box)<IntermediateWrapperProps>`
  width: ${({ width }) => parseSize(width)};
  height: ${({ height }) => parseSize(height)};
  position: ${({ horizontal }) => (horizontal ? 'relative' : 'absolute')};
  transform: ${({ horizontal }) => !horizontal && 'rotate(-90deg)'};
`
