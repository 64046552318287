import { Box, Text } from '@src/components/basic'
import { Color } from '@src/components/basic/types'
import { Icon } from '@src/components/common'
import { DateFormatTypes, formatDate } from '@src/utils/format-date'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { UrlObject } from 'url'

export const todayDate = new Date()
export const todayYear = parseInt(
  formatDate(todayDate, DateFormatTypes.YearOnly)
)
export const todayMonth = parseInt(
  formatDate(todayDate, DateFormatTypes.MonthOnly)
)

type Props = {
  urlObj: UrlObject
  backgroundColor?: Color
  tab?: string
}

export const DateBox = ({
  urlObj,
  backgroundColor = 'gray.100',
  tab,
}: Props) => {
  const router = useRouter()
  const [currentYear, setCurrentYear] = useState<number>(todayYear)
  const [currentMonth, setCurrentMonth] = useState<number>(todayMonth)

  useEffect(() => {
    setCurrentYear(todayYear)
    setCurrentMonth(todayMonth)
  }, [tab])

  useEffect(() => {
    router.replace({
      pathname: urlObj.pathname,
      query: {
        ...router.query,
        year: currentYear,
        month: currentMonth,
      },
    })
  }, [currentYear, currentMonth])

  const hasNextYear = todayYear !== currentYear || todayMonth !== currentMonth

  const handlePreviousDate = () => {
    setCurrentYear(currentMonth === 1 ? currentYear - 1 : currentYear)
    setCurrentMonth(currentMonth === 1 ? 12 : currentMonth - 1)
  }

  const handleNextDate = () => {
    setCurrentYear(currentMonth === 12 ? currentYear + 1 : currentYear)
    setCurrentMonth(currentMonth === 12 ? 1 : currentMonth + 1)
  }

  return (
    <Box
      flexDirection={'row'}
      alignItems={'center'}
      padding={'16px 36px'}
      backgroundColor={backgroundColor}
    >
      <Icon
        iconName={'back'}
        size={16}
        color={'black'}
        onClick={handlePreviousDate}
      />
      <Box alignItems={'center'} flex={1}>
        <Text fontSize={15} fontWeight={'medium'}>
          {currentMonth < 10
            ? `${currentYear}.0${currentMonth}`
            : `${currentYear}.${currentMonth}`}
        </Text>
      </Box>
      {hasNextYear ? (
        <Icon
          iconName={'next'}
          size={16}
          color={'black'}
          onClick={handleNextDate}
        />
      ) : (
        <Box width={16}></Box>
      )}
    </Box>
  )
}
