import { DeliveryGroupInfoDto } from '@src/types/cart'
import {
  CarrierDto,
  DeliveryInfoDto,
  DeliveryMethodEnum,
  DeliveryPricingMethodEnum,
  DeliveryPricingUnitEnum,
} from '../dto'
import { BasicAddress } from '@src/types/address'

export class DeliveryInfo {
  public readonly isInstallationFee: boolean
  public readonly isBaseReleaseAddress: boolean
  public readonly releaseAddress: BasicAddress
  public readonly deliveryMethod: DeliveryMethodEnum
  public readonly deliveryPricingMethod: DeliveryPricingMethodEnum
  public readonly deliveryPricingUnit: DeliveryPricingUnitEnum
  public readonly baseDeliveryFee: number
  public readonly unitChargeStandard: number
  public readonly conditionalChargeStandard: number
  public readonly isGroupDelivery: boolean
  public readonly deliveryGroupId?: string
  public readonly deliveryGroup?: DeliveryGroupInfoDto
  public readonly isAdditionalPricingSet: boolean
  public readonly regionDivisionLevel: number
  public readonly division2Fee: number
  public readonly division3JejuFee: number
  public readonly division3OutsideJejuFee: number
  public readonly refundAddress: BasicAddress
  public readonly refundDeliveryFee: number
  public readonly refundDeliveryFeeIfFreeDelivery: number
  public readonly exchangeDeliveryFee: number
  public readonly refundExchangeDeliveryPolicy: string
  public readonly internationalShipmentPolicy: string
  public readonly defaultCarrier: CarrierDto
  public readonly defaultCarrierId: string
  public readonly refundCarrier: CarrierDto
  public readonly refundCarrierId: string

  constructor(dto: DeliveryInfoDto) {
    this.isInstallationFee = dto.isInstallationFee
    this.isBaseReleaseAddress = dto.isBaseReleaseAddress
    this.releaseAddress = new BasicAddress(dto.releaseAddress)
    this.deliveryMethod = dto.deliveryMethod
    this.deliveryPricingMethod = dto.deliveryPricingMethod
    this.deliveryPricingUnit = dto.deliveryPricingUnit
    this.baseDeliveryFee = dto.baseDeliveryFee
    this.unitChargeStandard = dto.unitChargeStandard
    this.conditionalChargeStandard = dto.conditionalChargeStandard
    this.isGroupDelivery = dto.isGroupDelivery
    this.deliveryGroupId = dto.deliveryGroupId
    this.deliveryGroup = dto.deliveryGroup
    this.isAdditionalPricingSet = dto.isAdditionalPricingSet
    this.regionDivisionLevel = dto.regionDivisionLevel
    this.division2Fee = dto.division2Fee
    this.division3JejuFee = dto.division3JejuFee
    this.division3OutsideJejuFee = dto.division3OutsideJejuFee
    this.refundAddress = new BasicAddress(dto.refundAddress)
    this.refundDeliveryFee = dto.refundDeliveryFee
    this.refundDeliveryFeeIfFreeDelivery = dto.refundDeliveryFeeIfFreeDelivery
    this.exchangeDeliveryFee = dto.exchangeDeliveryFee
    this.refundExchangeDeliveryPolicy = dto.refundExchangeDeliveryPolicy
    this.internationalShipmentPolicy = dto.internationalShipmentPolicy
    this.defaultCarrier = dto.defaultCarrier
    this.defaultCarrierId = dto.defaultCarrierId
    this.refundCarrier = dto.refundCarrier
    this.refundCarrierId = dto.refundCarrierId
  }
}
