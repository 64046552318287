import { aws } from '@src/config'
import Head from 'next/head'

export type SEOProps = {
  title?: string
  description?: string
  imageUrl?: string
}

export const SEO = ({ title, description, imageUrl }: SEOProps) => {
  const seoTitle = title
    ? `${title} | ${defaultMetaTags.title}`
    : defaultMetaTags.title
  const seoDescription = description || defaultMetaTags.description
  const seoImageUrl = imageUrl || defaultMetaTags.imageUrl

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta property="description" content={seoDescription} />
      <meta property="og:title" content={seoTitle} key="title" />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:type" content={defaultMetaTags.type} />
      <meta property="og:url" content={process.env.NEXT_PUBLIC_WEBSITE_URL} />
      <meta property="og:image" content={seoImageUrl} />
      <meta property="og:image:width" content={defaultMetaTags.imageWidth} />
      <meta property="og:image:height" content={defaultMetaTags.imageHeight} />
      <meta property="og:image:alt" content={defaultMetaTags.description} />
      <meta property="og:image:url" content={seoImageUrl} />
      <meta property="twitter:image" content={seoImageUrl} />
    </Head>
  )
}

const defaultMetaTags = {
  title: 'Uzzim 기프트',
  description: '가장 쓰기 쉬운 기프트로 마음을 전달해보세요.',
  type: 'website',
  imageWidth: '1200',
  imageHeight: '627',
  imageUrl: `${aws.imageBaseUrl}/og/uzzim_c2c.png`,
} as const

export const metaTags = {
  base: {
    cards: {
      title: '내 기프트박스',
    },
  },
  shopping: {
    home: {
      title: '유찜 쇼핑',
    },
  },
} as const
