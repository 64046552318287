import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { ApiListResult2, BaseParams } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { useCustomInfiniteQueryResult } from '@src/hook/use-custom-infinite-query-result'
import { ProductInquiry, ProductInquiryDto } from '@src/types/product-inquiry'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getNextPageParam } from '../_utils/get-next-page-param'
import { selectInfiniteData } from '../_utils/select-infinite-data'

export const getProductInquiriesQueryKey = (productId: string) => {
  return [queryKeys.product.productInquiries, productId]
}

export const useProductInquiriesInfiniteQuery = (productId: string) => {
  const query = useInfiniteQuery(
    getProductInquiriesQueryKey(productId),
    ({ pageParam = 1 }) =>
      getProductInquiries({
        productId,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage) => getNextPageParam(lastPage),
      enabled: !!productId,
      staleTime: staleTime.long,
      select: (data) =>
        selectInfiniteData(data, (dto) => new ProductInquiry(dto)),
    }
  )

  return useCustomInfiniteQueryResult(query)
}

const getProductInquiries = ({
  productId,
  ordering,
  page,
  itemsPerPage = 10,
}: {
  productId: string
} & BaseParams) => {
  return api.productClient.get<ApiListResult2<ProductInquiryDto>>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/products/${productId}/inquiries`,
    {
      params: {
        ordering,
        page,
        itemsPerPage,
      },
    }
  )
}
