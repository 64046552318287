import { CartProduct, DeliveryGroupDto, DeliveryGroupInfoDto } from '..'

export class DeliveryGroup {
  public readonly groupInfo: DeliveryGroupInfoDto
  public readonly products: CartProduct[]

  constructor(dto: DeliveryGroupDto) {
    this.groupInfo = dto.groupInfo
    this.products = dto.products.map((dto) => new CartProduct(dto))
  }
}
