import { useUserProfile } from '@api/hooks/auth/use-user-profile'
import { Box, Text, VStack } from '@src/components/basic'
import { Review } from '@src/types/review'
import { isLogin } from '@src/utils/is-login'
import { useMemo, useState } from 'react'
import { ReviewContent, ReviewContentProps } from './common/review-content'
import { PhotoReviewDrawer } from './photo-review/photo-review-detail/photo-review-drawer'

type ProductReviewListProps = {
  reviews: Review[]
  onClickReplies: ReviewContentProps['onClickReplies']
  onClickReport: ReviewContentProps['onClickReport']
}

export const ProductReviewList = ({
  reviews,
  onClickReplies,
  onClickReport,
}: ProductReviewListProps) => {
  const profile = useUserProfile({ enabled: isLogin() })
  const photoReviews = useMemo(
    () => reviews.filter((review) => review.images.mobile.length > 0),
    [reviews]
  )

  const [photoReviewIndex, setPhotoReviewIndex] = useState<number | null>(null)

  const handleClickPhoto = (reviewId: string) => {
    setPhotoReviewIndex(
      photoReviews.findIndex((photoReview) => photoReview.id === reviewId)
    )
  }

  if (reviews.length === 0) {
    return (
      <Box height={180} centered>
        <Text fontSize={14}>작성된 리뷰가 없습니다.</Text>
      </Box>
    )
  }

  return (
    <>
      <VStack>
        {reviews.map((review) => (
          <ReviewContent
            key={review.id}
            isMine={profile.data?.harmonyId === review.userId}
            review={review}
            ellipseComments
            onClickPhoto={
              review.images.mobile.length === 0 ? undefined : handleClickPhoto
            }
            onClickReplies={onClickReplies}
            onClickReport={onClickReport}
          />
        ))}
      </VStack>
      <PhotoReviewDrawer
        isOpen={photoReviewIndex !== null}
        onClose={() => setPhotoReviewIndex(null)}
        initialIndex={photoReviewIndex}
        reviews={photoReviews}
      />
    </>
  )
}
