import { useUpdateReviewLikeOrDislikeMutation } from '@api/hooks/review/use-update-review-like-or-dislike'
import { HStack, Text } from '@src/components/basic'
import { Icon } from '@src/components/common'
import { LikeOrDislike } from '@src/types/review'
import { useState } from 'react'

type Props = {
  likeCount: number
  dislikeCount: number
  userLikeOrDislike: LikeOrDislike
  reviewId: string
  disabled?: boolean
}

export const LikeDislike = ({
  likeCount,
  dislikeCount,
  userLikeOrDislike,
  reviewId,
  disabled,
}: Props) => {
  const [tempUserLikeOrDislike, setTempUserLikeOrDislike] =
    useState<LikeOrDislike>(userLikeOrDislike)
  const updateReviewLikeOrDislikeMutation =
    useUpdateReviewLikeOrDislikeMutation()

  const tempLikeCount =
    likeCount +
    (userLikeOrDislike !== tempUserLikeOrDislike
      ? userLikeOrDislike === 'like'
        ? -1
        : tempUserLikeOrDislike === 'like'
        ? 1
        : 0
      : 0)

  const tempDislikeCount =
    dislikeCount +
    (userLikeOrDislike !== tempUserLikeOrDislike
      ? userLikeOrDislike === 'dislike'
        ? -1
        : tempUserLikeOrDislike === 'dislike'
        ? 1
        : 0
      : 0)

  const handleClickLike = () => {
    if (disabled) {
      return
    }

    updateReviewLikeOrDislikeMutation.mutate({
      reviewId,
      likeOrDislike: 'like',
    })
    setTempUserLikeOrDislike(tempUserLikeOrDislike === 'like' ? null : 'like')
  }

  const handleClickDislike = () => {
    if (disabled) {
      return
    }

    updateReviewLikeOrDislikeMutation.mutate({
      reviewId,
      likeOrDislike: 'dislike',
    })
    setTempUserLikeOrDislike(
      tempUserLikeOrDislike === 'dislike' ? null : 'dislike'
    )
  }

  return (
    <HStack spacing={8}>
      <button
        onClick={handleClickLike}
        disabled={updateReviewLikeOrDislikeMutation.isLoading}
      >
        <HStack spacing={4}>
          <Icon
            iconName="like"
            variant={tempUserLikeOrDislike === 'like' ? 'filled' : 'outlined'}
            color="gray.700"
            size={16}
          />
          <Text fontSize={11} fontWeight="bold" lineHeight={1.2}>
            {tempLikeCount}
          </Text>
        </HStack>
      </button>

      <button
        onClick={handleClickDislike}
        disabled={updateReviewLikeOrDislikeMutation.isLoading}
      >
        <HStack spacing={4}>
          <Icon
            iconName="dislike"
            variant={
              tempUserLikeOrDislike === 'dislike' ? 'filled' : 'outlined'
            }
            color="gray.700"
            size={16}
          />
          <Text fontSize={11} fontWeight="bold" lineHeight={1.2}>
            {tempDislikeCount}
          </Text>
        </HStack>
      </button>
    </HStack>
  )
}
