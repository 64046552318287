import { useCreateReviewReportMutation } from '@api/hooks/review/use-create-review-report'
import { reasonForActionLabelMap } from '@src/common/constants/maps/reason-for-action-label'
import { Box, VStack } from '@src/components/basic'
import {
  Button,
  DrawerFooter,
  FullDrawer,
  FullDrawerHeader,
  PageWrapper,
  RadioGroup,
  SectionTitle,
  Textarea,
} from '@src/components/common'
import { useAlert } from '@src/contexts/alert-context'
import { AlertType } from '@src/contexts/alert-context/alert'
import { ModalProps } from '@src/hook/use-modal'
import { ReasonForActionEnum } from '@src/types/review'
import { Selectable } from '@src/types/util-types/selectable'
import { useState } from 'react'

const reportReasons: Selectable<ReasonForActionEnum>[] = Object.entries(
  reasonForActionLabelMap
).map(([reason, label]) => ({
  label,
  value: reason as ReasonForActionEnum,
}))

type Props = ModalProps & {
  reviewId?: string
}

export const ReviewReportDrawer = ({ isOpen, onClose, reviewId }: Props) => {
  const showAlert = useAlert()
  const [reason, setReason] = useState<ReasonForActionEnum>(
    reportReasons[0].value
  )
  const [reasonComments, setReasonComments] = useState<string>('')
  const createReviewReportMutation = useCreateReviewReportMutation({
    onSuccess: () =>
      showAlert({
        content: '리뷰 신고가 완료되었습니다.',
        buttons: [{ label: '확인' }],
        type: AlertType.Success,
      }),
    onError: () => {
      showAlert({
        content:
          '예기치 못한 오류가 발생했습니다.\n잠시 후 다시 시도해주시기 바랍니다.',
        buttons: [{ label: '확인' }],
      })
    },
  })

  const isSubmittable =
    reason !== ReasonForActionEnum.OtherReason ||
    (reason === ReasonForActionEnum.OtherReason && reasonComments)

  const handleChangeReason = (reason?: ReasonForActionEnum) => {
    if (!reason) {
      return
    }

    setReason(reason)
    setReasonComments('')
  }

  const handleSubmit = () => {
    if (!reviewId || !isSubmittable) {
      return
    }

    createReviewReportMutation.mutate({
      reviewId,
      reasonForAction: reason,
      comments: reasonComments,
    })
    onClose()
  }

  if (!reviewId) {
    return (
      <FullDrawer isOpen={isOpen}>
        <FullDrawerHeader title="리뷰 신고하기" onClose={onClose} />
      </FullDrawer>
    )
  }

  return (
    <FullDrawer isOpen={isOpen}>
      <FullDrawerHeader title="리뷰 신고하기" onClose={onClose} />
      <PageWrapper>
        <VStack spacing={18} paddingX={16} paddingTop={32} paddingBottom={8}>
          <SectionTitle>신고사유</SectionTitle>
          <RadioGroup
            items={reportReasons}
            initialSelected={reason}
            onChange={handleChangeReason}
            direction="vertical"
          />
        </VStack>
        {reason === ReasonForActionEnum.OtherReason && (
          <Box paddingLeft={40} paddingRight={16}>
            <Textarea
              rows={4}
              placeholder="신고사유를 입력해 주세요."
              onBlur={setReasonComments}
            />
          </Box>
        )}
      </PageWrapper>
      <DrawerFooter hasShadow>
        <Button variant="outline" label="취소" onClick={onClose} />
        <Button label="신고" onClick={handleSubmit} disabled={!isSubmittable} />
      </DrawerFooter>
    </FullDrawer>
  )
}
