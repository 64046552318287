import { environment } from '@src/config/environment'
import { ApiService } from './api.service'

export const api = {
  authClient: new ApiService(
    `https://api-auth${environment.harmonyEndpointSuffix}.harmonycvm.com`
  ),
  productClient: new ApiService(
    `https://api-product${environment.harmonyEndpointSuffix}.harmonycvm.com`
  ),
  channelClient: new ApiService(
    `https://api-channel${environment.harmonyEndpointSuffix}.harmonycvm.com`
  ),
  transactionClient: new ApiService(
    `https://api-transaction${environment.harmonyEndpointSuffix}.harmonycvm.com`
  ),
  reviewClient: new ApiService(
    `https://api-review${environment.harmonyEndpointSuffix}.harmonycvm.com`
  ),
  uzzimClient: new ApiService(environment.uzzimBaseUrl, {
    useCredentials: false,
    useSnakecaseBody: false,
  }),
}
