import { css } from '@emotion/react'
import { fontWeights } from '@src/styles/theme'
import { CSSProperties } from 'react'
import { Color, FontWeight, LetterSpacing, Size } from '../types'
import { parseColor, parseLetterSpacing, parseSize } from '../utils'

export type ParagraphProps = {
  color?: Color
  fontColor?: Color
  fontFamily?: CSSProperties['fontFamily']
  fontSize?: Size
  fontWeight?: FontWeight
  letterSpacing?: LetterSpacing
  textAlign?: CSSProperties['textAlign']
  whiteSpace?: CSSProperties['whiteSpace']
  wordBreak?: CSSProperties['wordBreak']
  lineHeight?: CSSProperties['lineHeight']
  textShadow?: CSSProperties['textShadow']
  lineClamp?: number
  lineThrough?: boolean
  underline?: boolean
}

export const paragraphStyles = (props: ParagraphProps) => {
  const {
    color,
    fontColor,
    fontFamily,
    fontSize,
    fontWeight,
    letterSpacing,
    textAlign,
    whiteSpace,
    wordBreak,
    lineHeight,
    textShadow,
    lineClamp,
    lineThrough,
    underline,
  } = props

  return css`
    color: ${parseColor(fontColor || color)};
    font-family: ${fontFamily};
    font-size: ${parseSize(fontSize)};
    font-weight: ${fontWeight && fontWeights[fontWeight]};
    letter-spacing: ${parseLetterSpacing(letterSpacing)};
    text-align: ${textAlign};
    white-space: ${whiteSpace};
    word-break: ${wordBreak};
    line-height: ${lineHeight};
    text-shadow: ${textShadow};
    text-decoration-line: ${lineThrough
      ? 'line-through'
      : underline
      ? 'underline'
      : undefined};
    ${lineClamp && lineClampStyles(lineClamp)}
  `
}

const lineClampStyles = (lineClamp: number) => {
  return css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lineClamp};
  `
}
