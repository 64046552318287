import { Box, HStack, Text, VStack } from '@src/components/basic'
import Image from 'next/legacy/image'

type Props = {
  title: string
  brand: string
  skuTitle?: string
  imageUrl: string
}

export const InquiryProductInfo = ({
  title,
  brand,
  skuTitle,
  imageUrl,
}: Props) => {
  return (
    <HStack spacing={8}>
      <Box>
        <Image
          src={imageUrl}
          alt="product-image"
          layout="fixed"
          width={60}
          height={60}
          objectFit="cover"
          unoptimized
        />
      </Box>
      <VStack spacing={8}>
        {brand && (
          <Text
            fontWeight="light"
            fontSize={12}
            fontColor="gray.600"
            lineHeight={1.1}
          >
            [{brand}]
          </Text>
        )}
        <VStack spacing={2}>
          <Text
            fontSize={13}
            fontColor="gray.800"
            lineClamp={2}
            lineHeight={1.2}
          >
            {title}
          </Text>
          {skuTitle && (
            <Text
              fontWeight="light"
              fontSize={12}
              fontColor="gray.600"
              lineHeight={1.3}
            >
              옵션: {skuTitle}
            </Text>
          )}
        </VStack>
      </VStack>
    </HStack>
  )
}
