import styled from '@emotion/styled'
import { Box, BoxProps, HStack, Text, VStack } from '@src/components/basic'
import { ExpandableBox, HDivider, Icon } from '@src/components/common'
import { DateFormatTypes, formatDate } from '@src/utils/format-date'

import { useState } from 'react'

type CardMessageProps = BoxProps & {
  giverName: string
  msgToReceiver: string
  updatedAt: Date
  sentCard?: boolean
}

export const CardMessage = ({
  giverName,
  msgToReceiver,
  updatedAt,
  sentCard = false,
  ...props
}: CardMessageProps) => {
  const [isMessageActive, setIsMessageActive] = useState<boolean>(true)

  return (
    <Box
      backgroundColor={'white'}
      borderRadius={10}
      border={['1px', 'solid', 'gray.200']}
      width={'100%'}
      marginBottom={24}
      {...props}
    >
      <VStack>
        <HStack justifyContent={'space-between'} padding={16}>
          <Text fontSize={13} whiteSpace="normal">
            {sentCard ? 'To. ' : 'From. '} {giverName}
          </Text>
          <Text fontSize={13} color={'gray.500'} fontWeight={'light'}>
            {formatDate(updatedAt, DateFormatTypes.DateAndTime)}
          </Text>
        </HStack>
        <ExpandableBox expanded={isMessageActive}>
          <StyledMessageContainer>
            <HDivider backgroundColor={'gray.100'} thickness={1} />
            <Text fontSize={15} fontWeight={'light'} wordBreak={'break-all'}>
              {msgToReceiver}
            </Text>
          </StyledMessageContainer>
        </ExpandableBox>
        <VStack alignItems={'center'}>
          <HDivider
            backgroundColor={'gray.100'}
            length={'calc(100% - 32px)'}
            thickness={1}
          />
          <HStack
            justifyContent={'center'}
            padding={16}
            onClick={() => setIsMessageActive(!isMessageActive)}
          >
            <Text fontSize={12} color={'gray.700'} fontWeight={'light'}>
              {isMessageActive ? '메시지 닫기' : '메시지 보기'}
            </Text>
            <Icon
              color={'gray.700'}
              size={18}
              iconName={isMessageActive ? 'expandLess' : 'expandMore'}
            />
          </HStack>
        </VStack>
      </VStack>
    </Box>
  )
}

const StyledMessageContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  gap: 16px;
  word-break: break-all;
`
