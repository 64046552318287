import { Box, BoxProps } from '@src/components/basic'
import { ComponentProps } from '@src/components/basic/types'

type Props = ComponentProps<BoxProps>
export const ResultContainer = ({ children, ...props }: Props) => {
  return (
    <Box backgroundColor="gray.100" rowGap={32} flex={1} centered {...props}>
      {children}
    </Box>
  )
}
