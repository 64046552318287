import styled from '@emotion/styled'
import { HStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { Icon } from '@src/components/common'
import { Logo } from '@src/components/layout/logo'
import { colors, fontWeights, topBarHeight, zIndices } from '@src/styles/theme'

export type FullDrawerHeaderProps = {
  title?: string
  onBack?: () => void
  onClose?: () => void
  hasLogo?: boolean
  hasNotification?: boolean
}

export const FullDrawerHeader = ({
  title,
  onBack,
  onClose,
  hasLogo,
  hasNotification,
}: FullDrawerHeaderProps) => {
  return (
    <Header>
      <HStack spacing={8} paddingX={16} height={topBarHeight}>
        {hasLogo && <Logo />}
        {onBack && (
          <button type="button" onClick={onBack}>
            <Icon iconName="back" color="black" size={20} />
          </button>
        )}
        <Title>{title}</Title>
        {onClose && (
          <button type="button" onClick={onClose}>
            <Icon iconName="close" color="black" size={24} />
          </button>
        )}
        {/* {hasNotification && (
          <Link href={routes.my.notification}>
            <a>
              <Icon
                iconName="notifications"
                variant="outlined"
                size={24}
                color="black"
              />
            </a>
          </Link>
        )} */}
      </HStack>
    </Header>
  )
}

const Header = styled.header`
  position: sticky;
  top: 0;
  z-index: ${zIndices.layout};
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.gray[100]};
`

const Title = styled.h1`
  margin: 0;
  font-weight: ${fontWeights.medium};
  font-size: ${parseSize(20)};
  line-height: 1.4;
  color: #770ffc;
  flex-grow: 1;
`
