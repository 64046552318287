import { BaseParams } from '@api/types'

export type GetProductReviewRequestDto = BaseParams & {
  productId: string
  ordering?: ReviewOrderingEnum
  age?: string
  score?: string
  gender?: GenderEnum
  sellableSkuId?: string
}

export enum GenderEnum {
  Male = 'M',
  Female = 'F',
  None = '',
}

export enum ReviewOrderingEnum {
  Like = '-like_count',
  Recent = '-create_dt',
  MaxScore = '-score',
  MinScore = 'score',
}
