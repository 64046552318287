import { useDesignThemeSettingsQuery } from '@api/hooks/channel/use-design-theme-settings'
import { Box, Text } from '@src/components/basic'

type Props = {
  isShortage?: boolean
}

export const SoldOutChip = ({ isShortage }: Props) => {
  const designThemeSettingsQuery = useDesignThemeSettingsQuery()

  if (!designThemeSettingsQuery.isSuccess) {
    return null
  }

  return (
    <Box
      backgroundColor="white"
      paddingX={12}
      height={28}
      borderRadius="full"
      border={[1, 'solid', 'black']}
      centered
    >
      <Text fontSize={13} fontWeight={'regular'} lineHeight={1}>
        {isShortage ? '품절임박' : '품절'}
      </Text>
    </Box>
  )
}
