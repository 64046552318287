import { Box } from '@src/components/basic'

export const DisableOverlay = () => {
  return (
    <Box
      position="absolute"
      inset="0"
      backgroundColor="rgba(255, 255, 255, 0.4)"
      zIndex="above"
    />
  )
}
