import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HStack, Text, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { colors, fontWeights, letterSpacings } from '@src/styles/theme'
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react'
import { Icon } from '../icon'
import { ErrorMessage } from '../text'

export const defaultMoneyAmountOptions: MoneyAmountOptionType[] = [
  { label: '1만원', amount: 10000 },
  { label: '5만원', amount: 50000 },
  { label: '10만원', amount: 100000 },
  { label: '20만원', amount: 200000 },
  { label: '50만원', amount: 500000 },
]

type MoneyAmountOptionType = {
  label: string
  amount: number
}

type Props = {
  value: number
  onChange: (value: string) => void
  placeholder?: string
  error?: boolean
  errorMessage?: string
  description?: string
  moneyAmountOptions?: MoneyAmountOptionType[]
}

export const MoneyInput = ({
  value,
  onChange,
  placeholder,
  error,
  errorMessage,
  description,
  moneyAmountOptions = defaultMoneyAmountOptions,
}: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [isFocused, setIsFocused] = useState<boolean>(false)

  useEffect(() => {
    if (value === 0 && !isFocused) {
      setInputValue('')
      return
    }

    if (value > 0 && !isFocused) {
      setInputValue(value.toLocaleString())
    }
  }, [value, isFocused])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    onChange(e.target.value)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const resetInput = () => {
    setIsFocused(true)
    setInputValue('')
    onChange('0')
  }

  const handleMoneyAmountClick = (clickedAmount: MoneyAmountOptionType) => {
    const amountSum = (value + clickedAmount.amount).toString()
    setIsFocused(false)
    setInputValue(amountSum)
    onChange(amountSum)
  }

  return (
    <VStack spacing={12}>
      <VStack spacing={4}>
        <InputWrapper error={error} value={value}>
          <StyledInput
            type={'text'}
            value={isFocused ? (value > 0 ? value : '') : inputValue}
            onChange={handleChange}
            onBlur={() => setIsFocused(false)}
            onFocus={handleFocus}
            placeholder={placeholder}
          />
          {!!value && (
            <ResetButton onClick={resetInput} tabIndex={-1}>
              <Icon iconName="cancel" size={20} color="gray.300" />
            </ResetButton>
          )}
        </InputWrapper>
        {!!value && error && errorMessage && (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
        {!!value && !error && description && (
          <Text color={'gray.800'} fontSize={12}>
            {description}
          </Text>
        )}
      </VStack>
      <HStack columnGap={8} justifyContent={'space-evenly'}>
        {moneyAmountOptions.map((money) => (
          <MoneyAmountButton
            key={money.amount}
            onClick={() => handleMoneyAmountClick(money)}
          >
            {money.label}
          </MoneyAmountButton>
        ))}
      </HStack>
    </VStack>
  )
}

const InputWrapper = styled.div<Pick<Props, 'error' | 'value'>>`
  width: 100%;
  border-bottom: ${parseSize(2)} solid ${colors.primary};
  background-color: ${colors.white};
  padding: ${parseSize(10)} ${parseSize(9)};
  padding-right: ${parseSize(12)};
  display: flex;
  align-items: center;
  column-gap: ${parseSize(8)};

  ${({ error, value }) => !!value && error && errorStyles}
`

const errorStyles = css`
  border-bottom: ${parseSize(2)} solid ${colors.error};
`

const StyledInput = styled.input`
  border: none;
  padding: 0;
  width: 100%;
  flex: 1;
  font-weight: ${fontWeights.bold};
  font-size: ${parseSize(20)};
  line-height: ${parseSize(16)};

  ::placeholder {
    font-weight: ${fontWeights.light};
    color: ${colors.gray[300]};
  }
  :focus {
    outline: none;
  }
`

const ResetButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
`

const MoneyAmountButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 10px;
  width: 60px;
  letter-spacing: ${letterSpacings.normal};
  font-size: ${parseSize(12)};
  font-weight: ${fontWeights.regular};
  color: ${colors.gray[800]};
  border-radius: 48px;
  border: 1px solid ${colors.gray[200]};

  :active {
    border: 1px solid ${colors.black};
  }
`
