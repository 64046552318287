import styled from '@emotion/styled'
import { Box, HStack, VStack } from '@src/components/basic'
import { ExpandButton } from '@src/components/common'
import { colors } from '@src/styles/theme'
import { ProductSkuDto } from '@src/types/product'
import { useState } from 'react'
import { useSkuCountsUpdater } from '../../../_contexts/sku-counts-context'
import { SkuItem } from './sku-item'

type Props = {
  skus: ProductSkuDto[]
  disabled?: boolean
}

export const SkuSelect = ({ skus, disabled }: Props) => {
  const { addSku } = useSkuCountsUpdater()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev)
  }

  const onSelectSku = (skuId: string) => {
    addSku(skuId)
    setIsOpen(false)
  }

  return (
    <Box position="relative" height={40} zIndex="above">
      <SelectBox isOpen={isOpen}>
        <Trigger onClick={toggleIsOpen} disabled={disabled}>
          <HStack
            justifyContent="space-between"
            alignItems="center"
            paddingX={12}
            height={40}
          >
            <span>상품을 선택하세요</span>
            <ExpandButton expanded={isOpen} />
          </HStack>
        </Trigger>
        <Options isOpen={isOpen}>
          <VStack paddingX={16} paddingBottom={8} spacing={16}>
            {skus.map((sku) => (
              <SkuItem key={sku.id} sku={sku} onClick={onSelectSku} />
            ))}
          </VStack>
        </Options>
      </SelectBox>
    </Box>
  )
}

const SelectBox = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  background-color: ${colors.white};
  border: 1px solid
    ${({ isOpen }) => (isOpen ? colors.black : colors.gray[200])};
`

const Trigger = styled.button`
  width: 100%;
  color: ${colors.gray[300]};
  font-size: 0.875rem;
  letter-spacing: -0.03em;

  :disabled {
    background-color: ${colors.gray[100]};
  }
`

const Options = styled.div<{ isOpen?: boolean }>`
  transition: max-height 0.2s;
  max-height: ${({ isOpen }) => (isOpen ? '11rem' : '0rem')};
  overflow: auto;
`
