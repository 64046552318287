import { useCustomerServiceInfoQuery } from '@api/hooks/channel/use-customer-service-info'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { Icon } from '@src/components/common'

type UnlinkedVoucherNotificationProps = {
  isCancelling?: boolean
}

export const UnlinkedVoucherNotification = ({
  isCancelling,
}: UnlinkedVoucherNotificationProps) => {
  const customerServiceInfoQuery = useCustomerServiceInfoQuery()

  const content = isCancelling
    ? `주문 취소 요청이 완료된 모바일 상품권은 판매자측에서 사용여부, 유효기간 등을 검토한 후 환불처리를 진행합니다. 환불까지는 약 3~5 영업일이 소요될 수 있습니다. 환불 처리 완료시, ‘최소요청’ 상태에서 ‘환불완료' 상태로 변경됩니다.`
    : `1~2 영업일 이내에 주문하신 모바일 상품권이 주문시 기입하신 휴대전화 번호로 문자 발송됩니다. 장기간 문자를 수신받지 못하신 경우, 고객센터(${customerServiceInfoQuery.data?.contactNumber})에 문의해주세요`

  return (
    <Box backgroundColor="gray.100" padding={16}>
      <VStack spacing={8}>
        <HStack spacing={4}>
          <Icon iconName="info" color="primary" size={18} />
          <Text fontWeight="bold" fontSize={14}>
            알려 드립니다!
          </Text>
        </HStack>
        <Text fontSize={14} fontColor="gray.700">
          {content}
        </Text>
      </VStack>
    </Box>
  )
}
