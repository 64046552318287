import { useModal } from '@src/hook/use-modal'
import { Product } from '@src/types/product'
import { SkuCountsProvider } from './_contexts/sku-counts-context'
import { OptionDrawer } from './option-drawer'
import { ProductDetailFooter } from './product-detail-footer'

type Props = {
  productDetail: Product
}

export const ProductDetailBottomBar = ({ productDetail }: Props) => {
  const [isOptionDrawerOpen, openOptionDrawer, closeOptionDrawer] = useModal()
  const isOptionDrawerDisabled =
    productDetail.isSoldOut || !productDetail.isSelling

  const toggleOptionDrawer = () => {
    if (isOptionDrawerDisabled) {
      return
    }

    if (isOptionDrawerOpen) {
      closeOptionDrawer()
      return
    }

    openOptionDrawer()
  }

  if (!productDetail) return null

  return (
    <SkuCountsProvider
      initialSkuId={
        (productDetail.skuList.length === 1 && productDetail.skuList[0].id) ||
        ''
      }
    >
      <ProductDetailFooter
        isOptionDrawerOpen={isOptionDrawerOpen}
        openOptionDrawer={openOptionDrawer}
        closeOptionDrawer={closeOptionDrawer}
        productDetail={productDetail}
      />
      <OptionDrawer
        isOpen={isOptionDrawerOpen}
        toggleIsOpen={toggleOptionDrawer}
        productDetail={productDetail}
        disabled={isOptionDrawerDisabled}
      />
    </SkuCountsProvider>
  )
}
