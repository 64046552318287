import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  LayoutProps,
  PositionProps,
  SpaceProps,
  BackgroundProps,
  FlexBoxProps,
  layoutStyles,
  positionStyles,
  spaceStyles,
  backgroundStyles,
  flexBoxStyles,
  TransitionProps,
  transitionStyles,
  EffectProps,
  effectStyles,
  BorderProps,
  borderStyles,
  GridProps,
  gridStyles,
} from './core-styles'

export type BoxProps = LayoutProps &
  PositionProps &
  SpaceProps &
  BackgroundProps &
  BorderProps &
  FlexBoxProps &
  EffectProps &
  TransitionProps &
  GridProps & {
    centered?: boolean
    absoluteCentered?: boolean
  }

export const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${({ centered }) => centered && centeredStyles}
  ${({ absoluteCentered }) => absoluteCentered && absoluteCenteredStyles}
  ${(props) => layoutStyles(props)}
  ${(props) => positionStyles(props)}
  ${(props) => spaceStyles(props)}
  ${(props) => backgroundStyles(props)}
  ${(props) => borderStyles(props)}
  ${(props) => flexBoxStyles(props)}
  ${(props) => effectStyles(props)}
  ${(props) => transitionStyles(props)}
  ${(props) => gridStyles(props)}
`

const centeredStyles = css`
  justify-content: center;
  align-items: center;
`

const absoluteCenteredStyles = css`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
