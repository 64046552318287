import { api } from '@api/index'
import { MutationOptions } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import {
  CreateProductInquiryRequestDto,
  ProductInquiryDto,
} from '@src/types/product-inquiry'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getProductInquiriesQueryKey } from './use-product-inquiries'

type Options = MutationOptions<ProductInquiryDto>

export const useCreateProductInquiryMutation = (options: Options = {}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (dto: CreateProductInquiryRequestDto) => createProductInquiry(dto),
    {
      ...options,
      onSuccess: (data, ...params) => {
        queryClient.invalidateQueries(
          getProductInquiriesQueryKey(data.productId)
        )
        options.onSuccess?.(data, ...params)
      },
    }
  )
}

const createProductInquiry = (dto: CreateProductInquiryRequestDto) => {
  return api.productClient.post<ProductInquiryDto>(
    `${environment.harmonyEndpoint}/${BasicSettings.getChannelId()}/inquiries`,
    dto
  )
}
