import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

type SkuCountsStateProps = {
  skuCounts: Map<string, number>
}

type SkuCountsUpdaterProps = {
  initialSkuId?: string
  setSkuCounts: Dispatch<SetStateAction<Map<string, number>>>
}

const SkuCountsStateContext = createContext<SkuCountsStateProps | null>(null)
const SkuCountsUpdaterContext = createContext<SkuCountsUpdaterProps | null>(
  null
)

type ProviderProps = {
  initialSkuId?: string
  children?: React.ReactNode
}

export const SkuCountsProvider = ({
  initialSkuId,
  children,
}: ProviderProps) => {
  const [skuCounts, setSkuCounts] = useState<Map<string, number>>(
    new Map<string, number>(initialSkuId ? [[initialSkuId, 1]] : [])
  )

  const updaterValue = useMemo(
    () => ({ setSkuCounts, initialSkuId }),
    [initialSkuId]
  )
  const stateValue = useMemo(() => ({ skuCounts }), [skuCounts])

  return (
    <SkuCountsUpdaterContext.Provider value={updaterValue}>
      <SkuCountsStateContext.Provider value={stateValue}>
        {children}
      </SkuCountsStateContext.Provider>
    </SkuCountsUpdaterContext.Provider>
  )
}

export const useSkuCounts = () => {
  const state = useContext(SkuCountsStateContext)
  if (!state) throw Error('Cannot found SkuCountsProvider.')

  const memoizedSkuCounts = useMemo(() => {
    return Object.fromEntries(state.skuCounts)
  }, [state.skuCounts])

  return { skuCounts: memoizedSkuCounts }
}

export const useSkuCountsUpdater = () => {
  const state = useContext(SkuCountsUpdaterContext)
  if (!state) throw Error('Cannot found SkuCountsProvider.')

  const { setSkuCounts, initialSkuId } = state

  const addSku = useCallback(
    (skuId: string) => {
      setSkuCounts((prevSkuCounts) => {
        prevSkuCounts = new Map(prevSkuCounts)
        if (!prevSkuCounts.has(skuId)) {
          prevSkuCounts.set(skuId, 0)
        }

        prevSkuCounts.set(skuId, (prevSkuCounts.get(skuId) || 0) + 1)
        return prevSkuCounts
      })
    },
    [setSkuCounts]
  )

  const subSku = useCallback(
    (skuId: string) => {
      setSkuCounts((prevSkuCounts) => {
        prevSkuCounts = new Map(prevSkuCounts)
        prevSkuCounts.set(skuId, (prevSkuCounts.get(skuId) || 0) - 1)
        if (prevSkuCounts.get(skuId) === 0) {
          prevSkuCounts.delete(skuId)
        }

        return prevSkuCounts
      })
    },
    [setSkuCounts]
  )

  const removeSku = useCallback(
    (skuId: string) => {
      setSkuCounts((prevSkuCounts) => {
        prevSkuCounts = new Map(prevSkuCounts)
        prevSkuCounts.delete(skuId)
        return prevSkuCounts
      })
    },
    [setSkuCounts]
  )

  const resetSkuCounts = useCallback(() => {
    setSkuCounts(
      new Map<string, number>(initialSkuId ? [[initialSkuId, 1]] : [])
    )
  }, [initialSkuId, setSkuCounts])

  return { addSku, subSku, removeSku, resetSkuCounts }
}
