import { ReasonForActionEnum } from '@src/types/review'

export const reasonForActionLabelMap: Record<ReasonForActionEnum, string> = {
  [ReasonForActionEnum.IrrelevantContent]: '상품과 관련 없는 내용',
  [ReasonForActionEnum.CommercialPromotion]: '상업적 홍보',
  [ReasonForActionEnum.PersonalDataLeakage]: '개인정보 노출 위험',
  [ReasonForActionEnum.ReproductionWithoutPermission]:
    '저작권 불법 도용 (타인의 글/사진 복제)',
  [ReasonForActionEnum.OtherReason]: '기타 (직접입력)',
} as const
