import {
  DeliveryPricingMethodEnum,
  DeliveryPricingUnitEnum,
  ProductClassEnum,
} from '@src/types/product'
import { getAdditionalDeliveryFeeNotice } from '@src/utils/get-additional-delivery-fee-notice'
import { CartProductDto, CartProductSku } from '..'

export class CartProduct {
  public readonly id: string
  public readonly skus: CartProductSku[]
  public readonly productClass: ProductClassEnum
  public readonly title: string
  public readonly baseDeliveryFee: number
  public readonly calculatedProductDeliveryFee: number
  public readonly calculatedProductPrice: number
  public readonly conditionalChargeStandard: number
  public readonly deliveryGroupId: string
  public readonly deliveryPricingMethod: DeliveryPricingMethodEnum
  public readonly deliveryPricingUnit: DeliveryPricingUnitEnum
  public readonly division2Fee: number
  public readonly division3JejuFee: number
  public readonly division3OutsideJejuFee: number
  public readonly divisionDeliveryFee: number
  public readonly image: string
  public readonly isAdditionalPricingSet: boolean
  public readonly isGroupDelivery: boolean
  public readonly isProductSelected: boolean
  public readonly marketPrice: number
  public readonly minSellPrice: number
  public readonly regionDivisionLevel: number
  public readonly unitChargeStandard: number
  public readonly pgSettingId: number | null

  public readonly additionalDeliveryFeeNotice: string | null

  constructor(dto: CartProductDto) {
    this.id = dto.productSellableId
    this.skus = dto.productSellableSkus.map((dto) => new CartProductSku(dto))
    this.productClass = dto.productClass
    this.title = dto.title
    this.baseDeliveryFee = dto.baseDeliveryFee
    this.calculatedProductDeliveryFee = dto.calculatedProductDeliveryFee
    this.calculatedProductPrice = dto.calculatedProductPrice
    this.conditionalChargeStandard = dto.conditionalChargeStandard
    this.deliveryGroupId = dto.deliveryGroupId
    this.deliveryPricingMethod = dto.deliveryPricingMethod
    this.deliveryPricingUnit = dto.deliveryPricingUnit
    this.division2Fee = dto.division2Fee
    this.division3JejuFee = dto.division3JejuFee
    this.division3OutsideJejuFee = dto.division3OutsideJejuFee
    this.divisionDeliveryFee = dto.divisionDeliveryFee
    this.image = dto.image
    this.isAdditionalPricingSet = dto.isAdditionalPricingSet
    this.isGroupDelivery = dto.isGroupDelivery
    this.isProductSelected = dto.isProductSelected
    this.marketPrice = dto.marketPrice
    this.minSellPrice = dto.minSellPrice
    this.regionDivisionLevel = dto.regionDivisionLevel
    this.unitChargeStandard = dto.unitChargeStandard
    this.pgSettingId = dto.pgSettingId

    this.additionalDeliveryFeeNotice = getAdditionalDeliveryFeeNotice(this)
  }
}
