import { PgTypeEnum } from './transaction.dto'

export type PgSettingDto = {
  id: number
  channelId: string
  name: string
  description: string
  isDefault: boolean
  midType: MidTypeEnum
  pgType: PgTypeEnum
  status: string
}

export enum MidTypeEnum {
  PointOnlyAvailable = 'point_only_available',
  AllAvailable = 'all_available',
}
