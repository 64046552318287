import styled from '@emotion/styled'
import CloseButton from '@src/assets/shopping/close_black_16.svg'
import Image from 'next/legacy/image'
import { useEffect, useState } from 'react'
import { HStack } from '../basic'
import { colors } from '@src/styles/theme'

export enum ToastVariant {
  Default,
  Success,
  Failure,
}

type Props = {
  id: number
  message: string
  onClose: (id: number) => void
  type: ToastVariant
}

const Toast = ({ id, message, onClose, type }: Props) => {
  const [isShow, setIsShow] = useState<boolean>(false)

  useEffect(() => setIsShow(true), [])

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose()
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  const handleClose = () => {
    setIsShow(false)
  }

  const handleTransitionEnd = () => {
    !isShow && onClose(id)
  }

  return (
    <ToastWrapper
      isShow={isShow}
      backgroundColor={backgroundColor[type]}
      onTransitionEnd={handleTransitionEnd}
      style={{ opacity: isShow ? 1 : 0 }}
    >
      <HStack spacing={16} justifyContent="space-between">
        {message}
        <button onClick={handleClose}>
          <Image
            src={CloseButton}
            alt=""
            layout="fixed"
            width={16}
            height={16}
          />
        </button>
      </HStack>
    </ToastWrapper>
  )
}

const ToastWrapper = styled.div<{ isShow: boolean; backgroundColor: string }>`
  width: 100%;
  padding: 0.625rem 0.75rem;
  background-color: ${({ backgroundColor }) => backgroundColor};

  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1;

  transition: opacity 0.3s;
  pointer-events: visible;

  button {
    display: flex;
  }
`

const backgroundColor = {
  [ToastVariant.Default]: colors.gray[900],
  [ToastVariant.Failure]: colors.error,
  [ToastVariant.Success]: colors.success,
}

export default Toast
