import { useBasicSettingsQuery } from '@api/hooks/channel/use-basic-settings'
import { useProductPageSettingsQuery } from '@api/hooks/channel/use-product-page-settings'
import { useProductsInfiniteQuery } from '@api/hooks/product/use-products'
import { Box, VStack } from '@src/components/basic'
import { HDivider, LoadingSpinner, SectionTitle } from '@src/components/common'
import { Product } from '@src/types/product'
import { SwipeProductList } from '../product-list/swipe'

type ProductDetailRecommendedProductsProps = {
  productDetail: Product
}

export const ProductDetailRecommendedProducts = ({
  productDetail,
}: ProductDetailRecommendedProductsProps) => {
  const basicSettingsQuery = useBasicSettingsQuery()
  const productPageSettingsQuery = useProductPageSettingsQuery()

  const products = useProductsInfiniteQuery(
    {
      categoryId: productPageSettingsQuery.data
        ?.isDisplayRecommendedListByCategory
        ? productDetail.channelCategoryId || undefined
        : undefined,
      sellerPortalId: productPageSettingsQuery.data
        ?.isDisplayRecommendedListByPartner
        ? productDetail.sellerPortalId
        : undefined,
      itemsPerPage: productPageSettingsQuery.data?.recProductCount,
      ordering: productPageSettingsQuery.data?.recListDisplaySortOrder,
      salesSettingType: basicSettingsQuery.data?.salesSettingType,
      isRecommended: true,
    },
    {
      enabled:
        basicSettingsQuery.isSuccess && productPageSettingsQuery.isSuccess,
    }
  )

  if (products.isLoading) {
    return (
      <Box centered height={80}>
        <LoadingSpinner />
      </Box>
    )
  }

  if (!products.data) {
    return null
  }

  return (
    <>
      <HDivider backgroundColor="gray.100" />
      <VStack spacing={8} marginTop={24} marginBottom={48} paddingBottom={16}>
        <Box padding={16}>
          <SectionTitle>이런 상품은 어떠세요?</SectionTitle>
        </Box>
        <SwipeProductList productList={products.data} />
      </VStack>
    </>
  )
}
