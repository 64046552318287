import { Box, BoxProps } from '@src/components/basic'
import React from 'react'

type Props = BoxProps & {
  children?: React.ReactNode
}

export const PageWrapper = React.forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    return (
      <Box data-id="page-wrapper" ref={ref} flex="1" {...props}>
        {children}
      </Box>
    )
  }
)

PageWrapper.displayName = 'PageWrapper'
