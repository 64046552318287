import { queryKeys } from '@api/constants/query-keys'
import { api } from '@api/index'
import { ApiListResult2, BaseParams } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { useCustomInfiniteQueryResult } from '@src/hook/use-custom-infinite-query-result'
import { ReviewDto } from '@src/types/review'
import { useInfiniteQuery } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { getNextPageParam } from '../_utils/get-next-page-param'

export const usePhotoReviewsInfiniteQuery = ({
  productId,
  skuId,
  itemsPerPage,
}: {
  productId: string
  skuId?: string
  itemsPerPage?: number
}) => {
  const query = useInfiniteQuery(
    [queryKeys.product.photoReviews, productId, itemsPerPage, skuId],
    () =>
      getPhotoReviews({
        productId,
        sellableSkuId: skuId,
        itemsPerPage,
      }),
    {
      keepPreviousData: true,
      enabled: !!productId,
      getNextPageParam: (lastPage) => getNextPageParam(lastPage),
    }
  )

  return useCustomInfiniteQueryResult(query)
}

const getPhotoReviews = ({
  productId,
  ...params
}: BaseParams & {
  productId: string
  sellableSkuId?: string
}) => {
  return api.reviewClient.get<ApiListResult2<ReviewDto>>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/products/${productId}/reviews/photos`,
    {
      params: snakecaseKeys(params),
    }
  )
}
