import styled from '@emotion/styled'
import { routes } from '@src/common/constants/routes'
import { Icon } from '@src/components/common'
import { aws } from '@src/config'
import { colors, fontWeights, topBarHeight, zIndices } from '@src/styles/theme'
import { useRouter } from 'next/router'
import { parseSize } from '../basic/utils'
import { useBackAction } from './_hooks/use-back-action'
import { Logo } from './logo'

export type TopNavbarProps = {
  title?: string
  isLogoActive?: boolean
  hasBack?: boolean
  hasClose?: boolean
  hasNotification?: boolean
  hasCustomNavbar?: boolean
  onClickBack?: () => void
}

export const TopNavbar = ({
  title,
  isLogoActive,
  hasBack = true,
  hasClose,
  hasNotification,
  hasCustomNavbar,
  onClickBack,
}: TopNavbarProps) => {
  const router = useRouter()
  const { handleClickBack } = useBackAction(onClickBack)

  const handleClose = () => {
    // NOTE: (James) this is only an edge case; if there is more if cases, please create a history context
    if (router.pathname === routes.card.receiveGift) {
      router.replace(routes.base.home)
      return
    }
    handleClickBack()
  }

  return (
    <>
      {!hasCustomNavbar && (
        <TopNavbarContainer>
          {hasBack && (
            <button onClick={handleClickBack} data-et={'route-back'}>
              <Icon iconName={'back'} color={'black'} size={20} />
            </button>
          )}
          {isLogoActive ? <Logo /> : <TopNavbarTitle>{title}</TopNavbarTitle>}
          {/*{hasNotification && (*/}
          {/*  <Image*/}
          {/*    src={bcCardLogoImageUrl}*/}
          {/*    width={100}*/}
          {/*    height={24}*/}
          {/*    alt="bc card logo"*/}
          {/*    priority*/}
          {/*  />*/}
          {/*  // NOTE: (James) it's very likely this notification button will come back*/}
          {/*  // <Link href={routes.my.notification}>*/}
          {/*  //   <a>*/}
          {/*  //     <Icon*/}
          {/*  //       iconName="notifications"*/}
          {/*  //       variant="outlined"*/}
          {/*  //       size={24}*/}
          {/*  //       color="black"*/}
          {/*  //     />*/}
          {/*  //   </a>*/}
          {/*  // </Link>*/}
          {/*)}*/}
          {hasClose && (
            <button onClick={handleClose} data-et={'close-full-drawer'}>
              <Icon iconName={'close'} color={'black'} size={24} />
            </button>
          )}
        </TopNavbarContainer>
      )}
    </>
  )
}

export const TopNavbarContainer = styled.nav`
  position: sticky;
  background-color: ${colors.white};
  z-index: ${zIndices.layout};
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 0 16px;
  min-height: ${parseSize(topBarHeight)};
  border-bottom: 1px solid ${colors.gray['100']};
`

export const TopNavbarTitle = styled.p`
  color: ${colors.primary};
  font-size: 20px;
  font-weight: ${fontWeights.medium};
  line-height: 24px;
  flex: 1;
`

const bcCardLogoImageUrl = `${aws.imageBaseUrl}/logo/bccard.webp`
