import { VStack } from '@src/components/basic'
import { Accordion } from '@src/components/common'
import { noticeInfoList } from '../_constants/notice-info-list'
import { NoticeInfoField, NoticeInfoLabel } from './common'

type NoticeInfoProps = {
  noticeId: string
  noticeInfoData: Record<string, string>
}

export const NoticeInfoSection = ({
  noticeId,
  noticeInfoData,
}: NoticeInfoProps) => {
  const noticeInfo = noticeInfoList.find((notice) => notice.value === noticeId)

  return (
    <Accordion label={'상품정보제공고시'} labelBoxProps={{ paddingY: 12 }}>
      <VStack spacing={8}>
        <NoticeInfoLabel maxWidth="100%">
          {noticeInfo?.label || ''}
        </NoticeInfoLabel>
        {Object.entries(noticeInfoData).map(([key, value]) => (
          <NoticeInfoField
            key={key}
            label={
              noticeInfo?.list.find((field) => field.key === key)?.label || ''
            }
            value={value.trim()}
          />
        ))}
      </VStack>
    </Accordion>
  )
}
