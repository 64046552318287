import { Box } from '@src/components/basic'
import { ZIndex } from '@src/components/basic/types'
import { useGlobalScrollControl } from '@src/contexts/global-scroll-control-context'
import { useEffect } from 'react'
import { Drawer } from './drawer'

export const fullDrawerName = 'scroll-container'

export type FullDrawerProps = {
  isOpen?: boolean
  zIndex?: ZIndex
  children?: React.ReactNode
}

export const FullDrawer = ({ isOpen, zIndex, children }: FullDrawerProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {}}
      height="100%"
      borderRadius="0"
      zIndex={zIndex}
    >
      <Box data-name={fullDrawerName} overflowY="auto" height="100%">
        {children}
      </Box>
      {isOpen && <FullDrawerIsOpenIndicator />}
    </Drawer>
  )
}

const FullDrawerIsOpenIndicator = () => {
  const { registerDrawer } = useGlobalScrollControl()

  useEffect(() => {
    registerDrawer(true)

    return () => {
      registerDrawer(false)
    }
  })

  return null
}
