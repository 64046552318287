import { useExtensionSettingsQuery } from '@api/hooks/channel/use-extension-settings'
import { useAddWishItemMutation } from '@api/hooks/wishlist/use-add-wish-item'
import { useDeleteWishItemMutation } from '@api/hooks/wishlist/use-delete-wish-item'
import styled from '@emotion/styled'
import { storageKeys } from '@src/common/constants/storage-keys'
import { Box } from '@src/components/basic'
import { Color } from '@src/components/basic/types'
import { parseSize } from '@src/components/basic/utils'
import { Icon } from '@src/components/common'
import { useTempWishlist } from '@src/contexts/temp-optimistic-data-context/wishlist'
import { useNavigateWithRedirectPath } from '@src/hook/use-navigate-with-redirect-path'
import { sessionStorageService } from '@src/services/storage-service'
import { zIndices } from '@src/styles/theme'
import { isLogin } from '@src/utils/is-login'
import { MouseEvent } from 'react'

type Props = {
  productId: string
  wishItemId?: number
  emptyIconColor?: Color
  hasShadow?: boolean
  hasBackground?: boolean
  disabled?: boolean
}

export const WishlistButton = (props: Props) => {
  const extensionSettingsQuery = useExtensionSettingsQuery()

  if (!extensionSettingsQuery.data?.isWishlistInstalled) {
    return null
  }

  return <WishlistButtonInner {...props} />
}

const WishlistButtonInner = ({
  productId,
  wishItemId,
  emptyIconColor = 'white',
  hasShadow = true,
  hasBackground = true,
  disabled,
}: Props) => {
  const { isWish, handleClickWishButton, isLoading } = useWishButton({
    productId,
    wishItemId,
    disabled: !!disabled,
  })

  return (
    <ButtonWrapper
      onClick={handleClickWishButton}
      disabled={isLoading}
      hasShadow={hasShadow}
      hasBackground={hasBackground}
    >
      {isLoading && (
        <Box opacity={0.4}>
          <Icon iconName="heart" color="primary" />
        </Box>
      )}
      {!isLoading && (
        <>
          {isWish && <Icon iconName="heart" color="primary" />}
          {!isWish && <Icon iconName="heartEmpty" color={emptyIconColor} />}
        </>
      )}
    </ButtonWrapper>
  )
}

const useWishButton = ({
  productId,
  wishItemId: initialWishItemId,
  disabled,
}: {
  productId: string
  wishItemId?: number
  disabled: boolean
}) => {
  const { tempWishlist, addToTempWishlist, deleteFromTempWishlist } =
    useTempWishlist()
  const addWishItemMutation = useAddWishItemMutation(productId, {
    onSuccess: (data) => {
      addToTempWishlist(productId, data.id)
    },
    onError: () => {
      deleteFromTempWishlist(productId)
    },
  })
  const deleteWishItemMutation = useDeleteWishItemMutation({
    onSuccess: () => {
      deleteFromTempWishlist(productId)
    },
    onError: (_, wishItemId) => {
      addToTempWishlist(productId, wishItemId)
    },
  })
  const { pushWithRedirectPath } = useNavigateWithRedirectPath()

  const tempWishItemId = tempWishlist.get(productId)
  const wishItemId =
    tempWishItemId === undefined ? initialWishItemId : tempWishItemId
  const isWish = !!wishItemId

  const handleClickWishButton = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (disabled) {
      return
    }

    if (!isLogin()) {
      sessionStorageService.set(storageKeys.toWishlist, productId)
      return pushWithRedirectPath({ to: 'login' })
    }

    if (isWish) {
      deleteWishItemMutation.mutate(wishItemId)
      return
    }

    addWishItemMutation.mutate(productId)
  }

  return {
    isWish,
    handleClickWishButton,
    isLoading:
      addWishItemMutation.isLoading || deleteWishItemMutation.isLoading,
  }
}

const ButtonWrapper = styled.button<
  Omit<Props, 'productId' | 'wishItemId' | 'emptyIconColor'>
>`
  width: ${({ hasBackground }) =>
    hasBackground ? parseSize(40) : parseSize(24)};
  height: ${({ hasBackground }) =>
    hasBackground ? parseSize(40) : parseSize(24)};
  background-color: rgba(255, 255, 255, 0.4);
  z-index: ${zIndices.above};
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};

  span {
    filter: ${({ hasShadow }) =>
      hasShadow && 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.31))'};
    cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  }
`
