import { Box, Text } from '@src/components/basic'
import { DateFormatTypes, formatDate } from '@src/utils/format-date'

const DayInKorean = ['일', '월', '화', '수', '목', '금', '토']

type Props = {
  date: Date
}

export const DateChip = ({ date }: Props) => {
  return (
    <Box
      backgroundColor={'gray.100'}
      borderRadius={32}
      padding={'4px 12px'}
      alignItems={'center'}
      justifyContent={'center'}
      maxWidth={106}
    >
      <Text color={'gray.600'} fontSize={12} fontWeight={'light'}>
        {formatDate(date, DateFormatTypes.DateOnly)}
        {` (${DayInKorean[new Date(date).getDay()]})`}
      </Text>
    </Box>
  )
}
