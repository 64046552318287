import React, { Component, ReactNode } from 'react'

type Props = {
  children?: ReactNode
  fallback: ReactNode
}

class ErrorBoundary extends Component<Props> {
  public state = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error) {
    return { hasError: true }
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback
    }

    return this.props.children
  }
}

export default ErrorBoundary
