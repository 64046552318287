import { useDirectOrderInfoQuery } from '@api/hooks/cart/use-direct-order-info'
import styled from '@emotion/styled'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { EmptyMessage, HDivider, LoadingSpinner } from '@src/components/common'
import { colors, fontWeights, letterSpacings } from '@src/styles/theme'
import { Product, ProductClassEnum } from '@src/types/product'
import { formatAmount } from '@src/utils/format-amount'
import { useMemo } from 'react'
import { useSkuCounts } from '../_contexts/sku-counts-context'

type Props = {
  productDetail: Product
}

export const DirectOrderCalculations = ({ productDetail }: Props) => {
  const { skuCounts } = useSkuCounts()
  const directOrderDetail = useMemo(() => {
    const skus = Object.entries(skuCounts).map(([skuId, quantity]) => ({
      skuId,
      quantity,
    }))

    return {
      productId: productDetail.id,
      skus,
    }
  }, [productDetail.id, skuCounts])
  const orderInfoQuery = useDirectOrderInfoQuery(directOrderDetail, {
    enabled: !productDetail.isSoldOut && productDetail.isSelling,
  })

  if (!orderInfoQuery.isSuccess) {
    return (
      <Box height={125} centered>
        {orderInfoQuery.isLoading && <LoadingSpinner />}
        {orderInfoQuery.isError && (
          <EmptyMessage message="결제정보를 불러올 수 없습니다." />
        )}
      </Box>
    )
  }

  const { finalCartCalculations } = orderInfoQuery.data
  const { totalProductPrice, totalDeliveryFee, totalPgAmountToPay } =
    finalCartCalculations

  return (
    <VStack paddingY={16} spacing={8} alignItems="flex-end">
      <VStack spacing={6} alignItems="flex-end">
        <HStack spacing={16} alignItems="center">
          <Text fontSize={14} fontColor="gray.600">
            총 상품금액
          </Text>
          <PaymentValue>{formatAmount(totalProductPrice || 0)}</PaymentValue>
        </HStack>
        {productDetail.productClass === ProductClassEnum.Tangible && (
          <HStack spacing={16} alignItems="center">
            <Text fontSize={14} fontColor="gray.600">
              총 배송비
            </Text>
            <PaymentValue>{formatAmount(totalDeliveryFee || 0)}</PaymentValue>
          </HStack>
        )}
      </VStack>
      <HDivider thickness={1} backgroundColor="gray.200" />
      <HStack spacing={8} alignItems="flex-end">
        <Text
          fontSize={16}
          fontWeight="bold"
          fontColor="primary"
          lineHeight={1.2}
        >
          결제예정금액
        </Text>
        <TotalPaymentValue>
          {formatAmount(totalPgAmountToPay || 0)}
        </TotalPaymentValue>
      </HStack>
    </VStack>
  )
}

const PaymentValue = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  min-width: ${parseSize(88)};
  font-weight: ${fontWeights.regular};
  font-size: ${parseSize(16)};
  letter-spacing: ${letterSpacings.wide};
  color: ${colors.black};

  ::after {
    content: '원';
  }
`

const TotalPaymentValue = styled.span`
  display: flex;
  align-items: flex-end;

  font-weight: ${fontWeights.bold};
  font-size: ${parseSize(20)};
  line-height: 1;
  letter-spacing: ${letterSpacings.wide};
  color: ${colors.black};

  ::after {
    content: '원';
    font-weight: ${fontWeights.regular};
    font-size: ${parseSize(16)};
    padding-bottom: ${parseSize(2)};
  }
`
