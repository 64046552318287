import styled from '@emotion/styled'
import SuccessIcon from '@src/assets/modal/check_circle_24.svg'
import WarningIcon from '@src/assets/modal/warning_24.svg'
import { ModalProps } from '@src/hook/use-modal'
import { zIndices } from '@src/styles/theme'
import Image from 'next/legacy/image'
import {
  Button,
  ButtonProps,
  Drawer,
  DrawerFooter,
} from '@src/components/common'

export enum AlertType {
  Warning,
  Success,
}

export type AlertProps = {
  type?: AlertType
  content?: React.ReactNode
  isUnderlineBoldText?: boolean
  hasHeaderIcon?: boolean
  buttons?: ButtonProps[]
  closeCallback?: () => void
}

type Props = ModalProps & AlertProps

const Alert = ({
  isOpen,
  onClose,
  hasHeaderIcon = true,
  type = AlertType.Warning,
  content = '',
  buttons = [],
  isUnderlineBoldText = true,
  closeCallback,
}: Props) => {
  const handleClose = () => {
    onClose()
    closeCallback && closeCallback()
  }

  return (
    <Drawer onClose={handleClose} isOpen={isOpen} zIndex="drawer">
      <Container>
        {hasHeaderIcon && (
          <Image
            src={type === AlertType.Success ? SuccessIcon : WarningIcon}
            alt=""
            layout="fixed"
            width={24}
            height={24}
          />
        )}
        <AlertContent isUnderlineBoldText={isUnderlineBoldText}>
          {content}
        </AlertContent>
      </Container>
      <DrawerFooter>
        {buttons.map((button, index) => (
          <Button
            key={index}
            {...button}
            data-et={`alert-button-${index}`}
            onClick={(e) => {
              handleClose()
              button.onClick && button.onClick(e)
            }}
          />
        ))}
      </DrawerFooter>
    </Drawer>
  )
}

const Container = styled.div`
  min-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  z-index: ${zIndices.toast};

  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
`

const AlertContent = styled.div<{ isUnderlineBoldText: boolean }>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
  letter-spacing: -0.04em;
  white-space: pre-wrap;

  span {
    font-weight: ${({ isUnderlineBoldText }) => isUnderlineBoldText && 700};
    text-decoration: ${({ isUnderlineBoldText }) =>
      isUnderlineBoldText && 'underline'};
  }
`

export default Alert
