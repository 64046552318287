import { css, Global } from '@emotion/react'
import { parseSize } from '@src/components/basic/utils'
import { baseFontSize, fontWeights, letterSpacings } from '@src/styles/theme'

export const CssReset = () => {
  return <Global styles={cssResetStyles} />
}

const cssResetStyles = css`
  html,
  body,
  #__next {
    height: 100%;
  }
  html {
    box-sizing: border-box;
    font-size: ${parseSize(baseFontSize)};
    font-weight: ${fontWeights.regular};
    font-family: Poppins, Noto Sans KR, -apple-system, BlinkMacSystemFont,
      Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
      Helvetica Neue, sans-serif;
    letter-spacing: ${letterSpacings.normal};
    line-height: 1.55;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
  }
  *,
  :after,
  :before {
    box-sizing: border-box;
  }
  /* disable scrollbar */
  * {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  *::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  ul,
  dl,
  dd,
  p,
  pre,
  blockquote {
    margin: 0;
    padding: 0;
    font-weight: inherit;
  }
  main {
    display: block;
  }
  ol,
  ul {
    list-style: none;
  }
  img {
    border-style: none;
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  button {
    cursor: pointer;
  }
  button:disabled {
    cursor: not-allowed;
  }
  a {
    background-color: transparent;
    color: inherit;
    outline: 0;
  }
  a,
  a:active,
  a:hover {
    text-decoration: none;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0;
    border: unset;
    background: unset;
  }
  textarea {
    resize: none;
  }
  input:focus,
  textarea:focus {
    outline: unset;
  }
  hr {
    border: unset;
    margin: 0;
  }
  input[type='file'] {
    display: none;
  }
  address {
    font-style: inherit;
  }
  @font-face {
    font-family: 'PuradakGentleGothicR';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.1/PuradakGentleGothicR.woff2')
      format('woff2');
    font-weight: normal;
    font-style: normal;
  }
`
