import { useUserProfile } from '@api/hooks/auth/use-user-profile'
import { useProductInquiryQuery } from '@api/hooks/product-inquiry/use-product-inquiry'
import { Box, VStack } from '@src/components/basic'
import {
  Button,
  DrawerFooter,
  FullDrawer,
  FullDrawerHeader,
  HDivider,
  Input,
  Label,
  LoadingSpinner,
  RadioGroup,
  Textarea,
} from '@src/components/common'
import Select from '@src/components/common/select'
import { ModalProps } from '@src/hook/use-modal'
import { CreateProductInquiryRequestDto } from '@src/types/product-inquiry'
import { Product } from '@src/types/product'
import { Selectable } from '@src/types/util-types/selectable'
import { isLogin } from '@src/utils/is-login'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { InquiryProductInfo } from '../inquiry-product-info'

const isDisplayOptions: Selectable<boolean>[] = [
  { label: '공개', value: true },
  { label: '비공개', value: false },
]

type Props = ModalProps & {
  productDetail?: Product
  inquiryId?: string
  onSubmit?: (dto: CreateProductInquiryRequestDto) => void
  isSubmitting?: boolean
}

export const InquiryFormDrawer = ({
  isOpen,
  onClose,
  productDetail,
  inquiryId,
  onSubmit,
  isSubmitting,
}: Props) => {
  const isEdit = !!inquiryId
  const productInquiry = useProductInquiryQuery(inquiryId)
  const userProfile = useUserProfile({
    enabled: isLogin(),
  })

  const {
    title,
    brand,
    images,
    skuList,
    id: productId,
    isManyOptions,
  } = productDetail || {}

  const options: Selectable<string>[] = useMemo(
    () =>
      skuList
        ? skuList.map((sku) => ({
            label: sku.title,
            value: sku.id,
          }))
        : [],
    [skuList]
  )

  const [selectedOption, setSelectedOption] = useState<string | null>('')
  const [isDisplayed, setIsDisplayed] = useState<boolean | undefined>(
    isDisplayOptions[0].value
  )
  const [inquiryTitle, setInquiryTitle] = useState<string>('')
  const [inquiryContents, setInquiryContents] = useState<string>('')

  const isSubmittable = selectedOption && inquiryTitle && inquiryContents

  const initialize = useCallback(() => {
    if (!productDetail) {
      return
    }

    if (productInquiry.data) {
      setSelectedOption(productInquiry.data.productSkuId)
      setIsDisplayed(productInquiry.data.isDisplayed)
      setInquiryTitle(productInquiry.data.title)
      setInquiryContents(productInquiry.data.content)
      return
    }

    setSelectedOption(isManyOptions ? null : options[0]?.value)
    setIsDisplayed(isDisplayOptions[0]?.value)
    setInquiryTitle('')
    setInquiryContents('')
  }, [isManyOptions, options, productDetail, productInquiry.data])

  const handleSubmit = () => {
    if (!isSubmittable || !productId || !userProfile.isSuccess) {
      return
    }

    const inquirydto: CreateProductInquiryRequestDto = {
      productId,
      productSkuId: selectedOption,
      inquiryTitle,
      inquiryContents,
      isDisplayed: !!isDisplayed,
      name: userProfile.data.name,
      nickname: userProfile.data.name,
      email: userProfile.data.email,
    }

    onSubmit && onSubmit(inquirydto)
  }

  useEffect(() => {
    if (isOpen) {
      initialize()
    }
  }, [initialize, isOpen])

  if (!productDetail || (isEdit && productInquiry.isLoading)) {
    return (
      <FullDrawer isOpen={isOpen}>
        <FullDrawerHeader
          title={isEdit ? '상품문의 수정' : '문의하기'}
          onClose={onClose}
        />
        <LoadingSpinner />
      </FullDrawer>
    )
  }

  return (
    <FullDrawer isOpen={isOpen}>
      <FullDrawerHeader
        title={isEdit ? '상품문의 수정' : '문의하기'}
        onClose={onClose}
      />
      <Box flex={1}>
        <VStack spacing={8} padding={16}>
          <InquiryProductInfo
            title={title || ''}
            brand={brand || ''}
            imageUrl={images?.[0] || ''}
          />
          {isManyOptions && (
            <Select
              options={options}
              onChange={setSelectedOption}
              placeholder="옵션을 선택하세요"
            />
          )}
        </VStack>
        <HDivider thickness={1} backgroundColor="gray.100" />
        <VStack spacing={24} paddingTop={22} paddingX={16}>
          <VStack spacing={4}>
            <Label required fontWeight="medium">
              공개여부
            </Label>
            <RadioGroup
              items={isDisplayOptions}
              initialSelected={isDisplayed}
              onChange={setIsDisplayed}
              spacing={80}
              itemSpacing={16}
            />
          </VStack>
          <VStack spacing={4}>
            <Label required fontWeight="medium">
              제목
            </Label>
            <Input
              value={inquiryTitle}
              onBlur={setInquiryTitle}
              placeholder="문의 제목을 입력해주세요"
            />
          </VStack>
          <VStack spacing={4}>
            <Label required fontWeight="medium">
              문의내용
            </Label>
            <Textarea
              value={inquiryContents}
              onBlur={setInquiryContents}
              placeholder="문의 내용"
            />
          </VStack>
        </VStack>
      </Box>
      <DrawerFooter hasShadow={true}>
        <Button
          label="취소"
          onClick={onClose}
          variant="outline"
          disabled={isSubmitting}
        />
        <Button
          label="완료"
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={!isSubmittable}
        />
      </DrawerFooter>
    </FullDrawer>
  )
}
