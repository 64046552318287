import { createContext, useContext, useRef } from 'react'

type ContextValue = {
  registerDrawer: (isOpen: boolean) => void
}

const GlobalScrollControlContext = createContext<ContextValue | null>(null)

type ProviderProps = {
  children?: React.ReactNode
}

export const GlobalScrollControlProvider = ({ children }: ProviderProps) => {
  const openedDrawerCount = useRef(0)

  const registerDrawer = (isOpen: boolean) => {
    openedDrawerCount.current += isOpen ? 1 : -1
    changeScrollActivation()
  }

  const changeScrollActivation = () => {
    const isDrawerOpen = openedDrawerCount.current > 0
    document.body.style.overflowY = isDrawerOpen ? 'hidden' : ''
  }

  return (
    <GlobalScrollControlContext.Provider value={{ registerDrawer }}>
      {children}
    </GlobalScrollControlContext.Provider>
  )
}

export const useGlobalScrollControl = () => {
  const value = useContext(GlobalScrollControlContext)

  if (value === null) {
    throw new Error('Cannot found GlobalScrollControlProvider!')
  }

  return value
}
