const isLocal = process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
const isStage = process.env.NEXT_PUBLIC_ENVIRONMENT === 'stage'
const isProd = process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'

export const environment = {
  isLocal,
  isStage,
  isProd,
  uzzimDomain: `${process.env.NEXT_PUBLIC_DOMAIN}`,
  channelId: `${process.env.NEXT_PUBLIC_CHANNEL_ID}`,
  harmonyEndpoint: `${process.env.NEXT_PUBLIC_HARMONY_ENDPOINT}`,
  uzzimBaseUrl: `${process.env.NEXT_PUBLIC_UZZIM_BASEURL}`,
  harmonyEndpointSuffix: isProd
    ? ''
    : `-${isLocal ? 'stage' : process.env.NEXT_PUBLIC_ENVIRONMENT}`,
}
