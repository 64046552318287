import { TempSkuStatusMapProvider } from './sku-status-map'
import { TempWishlistProvider } from './wishlist'

type TempOptimisticDataProviderProps = {
  children?: React.ReactNode
}

export const TempOptimisticDataProvider = ({
  children,
}: TempOptimisticDataProviderProps) => {
  return (
    <TempWishlistProvider>
      <TempSkuStatusMapProvider>{children}</TempSkuStatusMapProvider>
    </TempWishlistProvider>
  )
}
