import { shoppingImages } from '@src/common/constants/images'
import { Box, Text, VStack } from '@src/components/basic'
import { Color } from '@src/components/basic/types'
import Image from 'next/legacy/image'

type EmptyProductPlaceholderProps = {
  variant?: 'default' | 'box'
}

export const EmptyProductPlaceholder = ({
  variant = 'default',
}: EmptyProductPlaceholderProps) => {
  if (variant === 'box') {
    return (
      <Box paddingX={30}>
        <Box
          width="100%"
          paddingBottom="100%"
          backgroundColor="gray.100"
          position="relative"
        >
          <Box position="absolute" absoluteCentered width="100%">
            <PlaceholderContent fontColor="gray.400" />
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box paddingY={40} centered>
      <PlaceholderContent fontColor="gray.600" />
    </Box>
  )
}

type PlaceholderContentProps = {
  fontColor?: Color
}

const PlaceholderContent = ({
  fontColor = 'gray.600',
}: PlaceholderContentProps) => {
  return (
    <VStack spacing={16} alignItems="center">
      <Image
        src={shoppingImages.emptyProduct}
        alt="비어있는 상품 아이콘"
        layout="fixed"
        width={90}
        height={90}
      />
      <Text fontColor={fontColor} fontSize={14}>
        현재 진열된 상품이 없습니다
      </Text>
    </VStack>
  )
}
