import {
  AttributeTypeEnum,
  CardDivisionEnum,
  CardStatusEnum,
  CardTypeEnum,
} from '@src/types/card'

type Params = {
  cardDivision: CardDivisionEnum
  cardType: CardTypeEnum
  cardStatus?: CardStatusEnum
  attribute?: AttributeTypeEnum
}

// 마스터 카드
export const isMasterCard = ({ cardDivision }: Params) => {
  return cardDivision === CardDivisionEnum.MASTER
}

// 세컨더리 카드
export const isSecondaryCard = ({ cardDivision }: Params) => {
  return cardDivision === CardDivisionEnum.SECONDARY
}

// 선물받은 카드
export const isReceivedCard = ({ cardDivision, cardType }: Params) => {
  return (
    cardDivision === CardDivisionEnum.SECONDARY &&
    (cardType === CardTypeEnum.NORMAL ||
      cardType === CardTypeEnum.NORMAL_B2C ||
      cardType === CardTypeEnum.BASIC)
  )
}

// 선물받은 카드 중 등록된 카드
export const isReceivedOwnedCard = ({
  cardDivision,
  cardType,
  cardStatus,
}: Params) => {
  return (
    isReceivedCard({ cardDivision, cardType }) &&
    cardStatus === CardStatusEnum.Owned
  )
}

// 선물받은 카드 중 미등록 카드
export const isUnreceivedCard = ({
  cardDivision,
  cardType,
  cardStatus,
}: Params) => {
  return (
    isReceivedCard({ cardDivision, cardType }) &&
    cardStatus === CardStatusEnum.Receiving
  )
}

// 발행한 카드
export const isPublishedCard = ({ cardDivision, cardType }: Params) => {
  return (
    cardDivision === CardDivisionEnum.MASTER && cardType === CardTypeEnum.NORMAL
  )
}

// 기본 카드
export const isBasicCard = ({ cardDivision, cardType }: Params) => {
  return (
    cardDivision === CardDivisionEnum.MASTER && cardType === CardTypeEnum.BASIC
  )
}

// 환불카드
export const isRefundCard = ({ cardDivision }: Params) => {
  return cardDivision === CardDivisionEnum.REFUND
}

// 이벤트 리미티드 카드, 확정X
export const isEventLimitedCard = ({ cardDivision, cardType }: Params) => {
  return (
    cardDivision === CardDivisionEnum.SECONDARY &&
    cardType === CardTypeEnum.EVENT_LIMITED
  )
}

// 프라이빗 카드
export const isPrivateCard = ({ attribute }: Pick<Params, 'attribute'>) => {
  return attribute === AttributeTypeEnum.PRIVATE
}

// 마스터 프라이빗 카드 (직접 발행한 프라이빗 카드)
export const isMasterPrivateCard = ({
  cardDivision,
  attribute,
}: Pick<Params, 'attribute' | 'cardDivision'>) => {
  return (
    cardDivision === CardDivisionEnum.MASTER &&
    attribute === AttributeTypeEnum.PRIVATE
  )
}

// 세컨더리 프라이빗 카드 (선물받은 프라이빗 카드)
export const isSecondaryPrivateCard = ({
  cardDivision,
  attribute,
}: Pick<Params, 'attribute' | 'cardDivision'>) => {
  return (
    cardDivision === CardDivisionEnum.SECONDARY &&
    attribute === AttributeTypeEnum.PRIVATE
  )
}

// 퍼블릭 카드
export const isPublicCard = ({ attribute }: Pick<Params, 'attribute'>) => {
  return attribute === AttributeTypeEnum.PUBLIC
}
