import { HStack, Text, TextProps } from '@src/components/basic'
import { Size } from '@src/components/basic/types'
import { Clickable } from '../clickable'
import { Icon, IconName } from '../icon'

export type CheckboxProps = {
  label?: string
  checked?: boolean
  onChange?: (checked: boolean) => void
  checkboxType?: 'square' | 'circle'
  spacing?: Size
  labelTextProps?: TextProps
  checkboxSize?: Size
}

export const Checkbox = ({
  label,
  checked,
  onChange,
  checkboxType = 'square',
  spacing = 4,
  labelTextProps = {},
  checkboxSize = 20,
  ...props
}: CheckboxProps) => {
  const handleChange = () => {
    onChange && onChange(!checked)
  }

  return (
    <Clickable {...props} onClick={handleChange}>
      <HStack spacing={spacing}>
        <Icon
          iconName={getCheckboxIconName(checkboxType, !!checked)}
          color={checked ? 'black' : 'gray.300'}
          size={checkboxSize}
        />
        {label && (
          <Text fontSize={13} {...labelTextProps}>
            {label}
          </Text>
        )}
      </HStack>
    </Clickable>
  )
}

const getCheckboxIconName = (
  checkboxType: CheckboxProps['checkboxType'],
  checked: boolean
): IconName => {
  if (checkboxType === 'square') {
    return checked ? 'checkboxChecked' : 'checkboxBlank'
  }

  return checked ? 'checkboxCircleChecked' : 'checkboxCircleBlank'
}
