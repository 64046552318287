import styled from '@emotion/styled'
import { Size } from '@src/components/basic/types'
import { parseSize } from '@src/components/basic/utils'
import { colors } from '@src/styles/theme'
import React from 'react'
import { defaultCardHeight, defaultCardWidth } from '../card'

export const CardImageSkeleton = React.forwardRef<HTMLDivElement>(({}, ref) => {
  return <Box ref={ref} width={defaultCardWidth} height={defaultCardHeight} />
})

type StyledBoxProps = {
  width?: Size
  height?: Size
}

const Box = styled.div<StyledBoxProps>`
  width: ${({ width }) => parseSize(width)};
  height: ${({ height }) => parseSize(height)};
  background-color: ${colors.gray[100]};
  border-radius: ${parseSize(10)};
  overflow: hidden;

  @keyframes skeleton-gradient {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: skeleton-gradient 1.8s infinite ease-in-out;
  }
`

CardImageSkeleton.displayName = 'CardImageSkeleton'
