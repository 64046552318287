import { ProductsOrderingEnum } from '@src/types/product'

export type ProductPageSettingDto = {
  channelId: string
  bannerImages: string[]
  recListTitle: string
  recListDisplayType: ProductPageRecListDisplayTypeEnum
  recListDisplaySortOrder: ProductsOrderingEnum
  recProductCount: number
}
export enum ProductPageRecListDisplayTypeEnum {
  Category = 'category',
  Partner = 'partner',
}
