import { HStack, Text } from '@src/components/basic'
import { Color, FontWeight, Size } from '@src/components/basic/types'
import { formatAmount } from '@src/utils/format-amount'

type MoneyTextProps = {
  spacing?: Size
  moneyAmount: number | string
  moneyFontWeight?: FontWeight
  moneyFontSize?: Size
  moneyLineHeight?: number
  unitFontWeight?: FontWeight
  unitFontSize?: Size
  unit?: string
  fontColor?: Color
}

export const MoneyText = ({
  spacing = 1,
  moneyAmount = 0,
  moneyFontWeight = 'bold',
  moneyFontSize = 12,
  moneyLineHeight = 1,
  unitFontWeight = 'light',
  unitFontSize = 11,
  unit = '원',
  fontColor = 'black',
}: MoneyTextProps) => {
  return (
    <HStack spacing={spacing}>
      <Text
        fontWeight={moneyFontWeight}
        fontSize={moneyFontSize}
        lineHeight={moneyLineHeight}
        color={fontColor}
      >
        {formatAmount(moneyAmount)}
      </Text>
      <Text
        fontWeight={unitFontWeight}
        fontSize={unitFontSize}
        lineHeight={1}
        color={fontColor}
      >
        {unit}
      </Text>
    </HStack>
  )
}
