import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { parseZIndex } from '@src/components/basic/utils'
import { useToggle } from '@src/hook/use-toggle'
import { zIndices } from '@src/styles/theme'
import { Selectable } from '@src/types/util-types/selectable'
import { useEffect, useState } from 'react'
import { Box, HStack } from '../../basic'
import { ZIndex } from '../../basic/types'
import { Clickable } from '../clickable'
import { ExpandButton } from '../expand-button'
import { Icon } from '../icon'

type Props<T> = {
  renderTrigger?: (isOpen: boolean, selected?: Selectable<T>) => React.ReactNode
  options: Selectable<T>[]
  dropdownWidth?: string
  optionsWidth?: string
  paddingX?: number
  initialSelected?: Selectable<T>
  onChange?: (selectedValue: T) => void
  align?: 'left' | 'center' | 'right'
  zIndex?: ZIndex
}

export const Dropdown = <T,>({
  renderTrigger = renderDefaultTrigger,
  options,
  initialSelected,
  onChange,
  dropdownWidth = 'unset',
  optionsWidth = '8.5rem',
  paddingX = 16,
  align = 'center',
  zIndex = 'above',
}: Props<T>) => {
  const [isOpen, toggleIsOpen] = useToggle()
  const [selected, setSelected] = useState<Selectable<T> | undefined>(
    initialSelected
  )

  useEffect(() => {
    setSelected(initialSelected)
  }, [initialSelected])

  const handleChange = (selected: Selectable<T>) => {
    setSelected(selected)
    onChange && onChange(selected.value)
    toggleIsOpen()
  }

  return (
    <Container>
      <button
        type="button"
        onClick={toggleIsOpen}
        style={{ width: dropdownWidth }}
      >
        {renderTrigger(isOpen, selected)}
      </button>

      {isOpen && (
        <Box
          position="fixed"
          inset="0"
          zIndex={zIndex}
          onPointerDown={toggleIsOpen}
        />
      )}

      <OptionsContainer
        style={{ maxHeight: isOpen ? `${options.length * 2.5}rem` : 0 }}
        align={align}
        width={optionsWidth}
        zIndex={zIndex}
      >
        {options.map((option, index) => {
          return (
            <Clickable key={index} onClick={() => handleChange(option)}>
              <HStack
                justifyContent="space-between"
                width="100%"
                height={40}
                paddingX={paddingX}
                spacing={8}
              >
                <OptionContainerLabel
                  selected={selected?.value === option.value}
                >
                  {option.label}
                </OptionContainerLabel>
                {selected?.value === option.value && (
                  <Icon iconName="check" size={18} color="gray.900" />
                )}
              </HStack>
            </Clickable>
          )
        })}
      </OptionsContainer>
    </Container>
  )
}

const renderDefaultTrigger = <T,>(
  isOpen: boolean,
  selected?: Selectable<T>
) => {
  return (
    <HStack>
      <TriggerLabel>{selected?.label}</TriggerLabel>
      <ExpandButton expanded={isOpen} />
    </HStack>
  )
}

const TriggerLabel = styled.span`
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: -0.01em;
  color: #5c5c5c;
`

const Container = styled.div`
  display: flex;
  position: relative;
`

const alignStyles = {
  right: css`
    right: 0;
  `,
  left: css`
    left: 0;
  `,
  center: css`
    left: 50%;
    transform: translateX(-50%);
  `,
}

type OptionsContainerProps<T> = {
  align: Props<T>['align']
  width: string
  zIndex?: ZIndex
}

const OptionsContainer = styled.div<OptionsContainerProps<unknown>>`
  min-width: ${({ width }) => width};
  position: absolute;
  top: 100%;
  ${({ align }) => align && alignStyles[align]}

  z-index: ${({ zIndex }) => (zIndex ? parseZIndex(zIndex) : zIndices.above)};
  display: flex;
  flex-direction: column;

  background-color: #ffffff;
  box-shadow: inset 0 0 0 1px #dbdbdb;

  transition: max-height 0.2s;
  overflow: hidden;
`

const OptionContainerLabel = styled.span<{ selected?: boolean }>`
  font-size: 0.875rem;
  font-weight: ${({ selected }) => (selected ? '700' : '300')};
  letter-spacing: -0.03em;
  white-space: nowrap;
`
