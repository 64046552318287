import { useState } from 'react'

export const usePhotoReviewDetailDrawer = () => {
  const [photoReviewIndex, setPhotoReviewIndex] = useState<number | null>(null)
  const isPhotoReviewOpen = photoReviewIndex !== null

  const closePhotoReview = () => {
    setPhotoReviewIndex(null)
  }

  return {
    isPhotoReviewOpen,
    closePhotoReview,
    openPhotoReview: setPhotoReviewIndex,
    photoReviewIndex,
  }
}
