import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import { Size } from '../types'
import { parseSize } from '../utils'

export type FlexBoxProps = {
  justifyContent?: CSSProperties['justifyContent']
  alignItems?: CSSProperties['alignItems']

  flexWrap?: CSSProperties['flexWrap']
  flexDirection?: CSSProperties['flexDirection']

  flex?: CSSProperties['flex']
  flexGrow?: CSSProperties['flexGrow']
  flexShrink?: CSSProperties['flexShrink']

  gap?: Size
  rowGap?: Size
  columnGap?: Size
}

export const flexBoxStyles = (props: FlexBoxProps) => {
  const {
    justifyContent,
    alignItems,
    flexWrap,
    flexDirection,
    flex,
    flexGrow,
    flexShrink,
    gap,
    rowGap,
    columnGap,
  } = props

  return css`
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    flex-wrap: ${flexWrap};
    flex-direction: ${flexDirection};
    flex: ${flex};
    flex-grow: ${flexGrow};
    flex-shrink: ${flexShrink};
    gap: ${parseSize(gap)};
    row-gap: ${parseSize(rowGap)};
    column-gap: ${parseSize(columnGap)};
  `
}
