import styled from '@emotion/styled'
import { parseSize } from '@src/components/basic/utils'
import { OverlayPortal } from '@src/components/common/overlay/common/portal'
import Toast, { ToastVariant } from '@src/components/common/toast'
import { maxScreenWidth, zIndices } from '@src/styles/theme'
import { createContext, useCallback, useContext, useRef, useState } from 'react'

export type ToastItem = {
  message: string
  id: number
  type: ToastVariant
}

type ProviderProps = {
  children?: React.ReactNode
}

const ToastContext = createContext<
  ((message: string, type?: ToastVariant) => void) | null
>(null)

const ToastProvider = ({ children }: ProviderProps) => {
  const [toasts, setToasts] = useState<ToastItem[]>([])
  const counterRef = useRef<number>(0)

  const showToast = useCallback(
    (message: string, type: ToastVariant = ToastVariant.Default) => {
      counterRef.current += 1
      setToasts((prev) => [...prev, { id: counterRef.current, message, type }])
    },
    []
  )

  const removeToast = useCallback((id: number) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <OverlayPortal>
        <ToastContainer>
          {toasts.map((toast) => (
            <Toast
              key={toast.id}
              id={toast.id}
              message={toast.message}
              onClose={removeToast}
              type={toast.type}
            />
          ))}
        </ToastContainer>
      </OverlayPortal>
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const values = useContext(ToastContext)
  if (!values) {
    throw Error('Cannot found ToastProvider!')
  }

  return values
}

const ToastContainer = styled.div`
  max-width: ${parseSize(maxScreenWidth)};
  margin: 0 auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${parseSize(32)} ${parseSize(16)};
  display: flex;
  flex-direction: column-reverse;
  row-gap: 0.5rem;
  z-index: ${zIndices.toast};
  pointer-events: none;
`

export default ToastProvider
