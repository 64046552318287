import { SalesSettingTypeEnum } from '@src/types/channel'

export type GetProductsRequestDto = {
  categoryId?: string
  searchKeyword?: string
  minSellPrice?: number | null
  maxSellPrice?: number | null
  ordering?: ProductsOrderingEnum
  page?: number
  itemsPerPage?: number
  isRecommended?: boolean
  sellerPortalId?: string
  salesSettingType?: SalesSettingTypeEnum
}

export enum ProductsOrderingEnum {
  SoldCount = '-sold_count',
  ViewCount = '-view_count',
  Recent = '-approved_dt',
  MaxSellPrice = '-min_sell_price',
  MinSellPrice = 'min_sell_price',
  Rating = '-ratings',
}
