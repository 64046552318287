import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

type IsReady = {
  isReady?: boolean
}
type QueryValueTypes = string | undefined
type QueryValues<T, U> = T[keyof T] extends U ? U : T[keyof T]
type ReturnType<T> = T & IsReady

export const useUrlQuery = <
  T extends Record<string, QueryValues<T, QueryValueTypes>>
>(): ReturnType<T> => {
  const router = useRouter()
  const [urlQueries, setUrlQueries] = useState<T>({} as T)

  useEffect(() => {
    if (!router.isReady) {
      setUrlQueries({ ...urlQueries, isReady: false })
      return
    }

    setUrlQueries(() => {
      const keyValuePairs = Object.entries(router.query).map(([key, value]) => [
        key,
        value,
      ])
      const keyValuePairsObject = Object.fromEntries(keyValuePairs)
      return { ...keyValuePairsObject, isReady: true }
    })
  }, [router.isReady, router.query])

  return urlQueries
}
