import { User } from '@src/types/inquiry'

export enum CardStatusEnum {
  Receiving = 'RECEIVING', // 미등록_선물중
  Cancelled = 'CANCELLED', // 취소
  CancelledExpired = 'CANCELLED_EXPIRED', // 기한경과
  Owned = 'OWNED', // 등록_선물받음
  Deleted = 'DELETED', // 삭제
}

export enum MyCardSortEnum {
  Higher = 'HIGH',
  Lower = 'LOWER',
  Latest = 'LATEST',
}

export enum RefundActionCodeEnum {
  Refund = 'REFUND',
  GiftCancelFromBasicCard = 'GIFT_CANCEL_FROM_BASIC_CARD',
  ExchangeToUzzimMoney = 'EXCHANGE_TO_UZZIM_MONEY',
  PayProduct = 'PAY_PRODUCT',
}

export enum CardUsageActionCodeEnum {
  ReceivedBasicCard = 'RECEIVED_BASIC_CARD',
  ReceivedNormalCard = 'RECEIVED_NORMAL_CARD',
  ReceivedEventCard = 'RECEIVED_EVENT_CARD',
  ReceivedEventLimitedCard = 'RECEIVED_EVENT_LIMITED_CARD',
  SentBasicCard = 'SENT_BASIC_CARD',
  SentNormalCard = 'SENT_NORMAL_CARD',
  SentEventCard = 'SENT_EVENT_CARD',
  CancelledCard = 'CANCELLED_CARD',
  CancelledExpiredCard = 'CANCELLED_EXPIRED_CARD',
  ChargeMoneyToCard = 'CHARGE_MONEY_TO_CARD',
  ChargeMoneyToCardWhenGifting = 'CHARGE_MONEY_TO_CARD_WHEN_GIFTING',
  SelfChargedUzzimMoney = 'SELF_CHARGED_UZZIM_MONEY',
  AddedPurchasedCardWithMoney = 'ADDED_PURCHASED_CARD_WITH_MONEY',
  PurchasedProduct = 'PURCHASED_PRODUCT',
  ExchangeToUzzimMoney = 'EXCHANGE_TO_UZZIM_MONEY',
  ReceivedEventUzzimMoney = 'RECEIVED_EVENT_UZZIM_MONEY',
  PurchasedCardShop = 'PURCHASED_CARD_SHOP',
  CancelPurchaseProduct = 'CANCEL_PURCHASE_PRODUCT',
  WithdrewUzzimMoney = 'WITHDREW_UZZIM_MONEY',
  RefundUzzimMoney = 'REFUND_UZZIM_MONEY',
  RefundUzzimMoneyFromShopping = 'REFUND_UZZIM_MONEY_FROM_SHOPPING',
  RefundUzzimMoneyFromCardShop = 'REFUND_UZZIM_MONEY_FROM_CARD_SHOP',
  RefundUzzimMoneyFromSentBasicCancelled = 'REFUND_UZZIM_MONEY_FROM_SENT_BASIC_CANCELLED',
  BtocReceivedBasicCard = 'BTOC_RECEIVED_BASIC_CARD',
  BtocReceivedNormalCard = 'BTOC_RECEIVED_NORMAL_CARD',
  BtocReceivedEventCard = 'BTOC_RECEIVED_EVENT_CARD',
  BtocReceivedEventLimitedCard = 'BTOC_RECEIVED_EVENT_LIMITED_CARD',
  BtocSentBasicCard = 'BTOC_SENT_BASIC_CARD',
  BtocSentNormalCard = 'BTOC_SENT_NORMAL_CARD',
  BtocSentEventCard = 'BTOC_SENT_EVENT_CARD',
  BtocSentLimitedCard = 'BTOC_SENT_LIMITED_CARD',
  BtocChargeMoneyToCard = 'BTOC_CHARGE_MONEY_TO_CARD',
  BtocChargeMoneyToCardWhenGifting = 'BTOC_CHARGE_MONEY_TO_CARD_WHEN_GIFTING',
  BtocSelfChargedUzzimMoney = 'BTOC_SELF_CHARGED_UZZIM_MONEY',
  BtocChargedEventLimited = 'BTOC_CHARGED_EVENT_LIMITED',
  BtocChargedEvent = 'BTOC_CHARGED_EVENT',
  BtocRefundUzzimMoney = 'BTOC_REFUND_UZZIM_MONEY',
  BtocRefundUzzimMoneyFromCharged = 'BTOC_REFUND_UZZIM_MONEY_FROM_CHARGED',
  BtocRefundUzzimMoneyFromGiftCancelled = 'BTOC_REFUND_UZZIM_MONEY_FROM_GIFT_CANCELLED',
  BtocReservationCancelledCard = 'BTOC_RESERVATION_CANCELLED_CARD',
}

export enum MyCardTypeEnum {
  ALL = 'ALL',
  GIFT_OWNED = 'GIFT_OWNED', // 등록기프트 (선물받은 기프트를 등록한 경우) -> 선물받은기프트 탭 클릭 시 등록한 기프트 우선 목록 조회.
  GIFT_RECEIVING = 'GIFT_RECEIVING', // 미등록기프트 (선물받은 기프트를 등록하지 않은경우)
  PURCHASED = 'PURCHASED', // 구매한 기프트
  PUBLISH = 'PUBLISH', // 발행한 기프트
  REFUND = 'REFUND',
}

export enum AttributeTypeEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type Skin = {
  id: number
  createdAt: string
  updatedAt: string
  attribute: AttributeTypeEnum
  displayName: string
  masterOwner: User
  currentOwner: User
  status: boolean
  content: string
  mediaUrl: string
  thumbnailUrl: string
  horizontal: boolean
}

export enum SwipeTypesEnum {
  AD = 'AD',
  PROMOTION = 'PROMOTION',
  GIFT = 'GIFT',
  OWNED = 'OWNED',
}

export enum CardDivisionEnum {
  MASTER = 'MASTER',
  SECONDARY = 'SECONDARY',
  REFUND = 'REFUND',
}

export enum CardTypeEnum {
  BASIC = 'BASIC',
  EVENT = 'EVENT',
  EVENT_LIMITED = 'EVENT_LIMITED',
  NORMAL = 'NORMAL',
  NORMAL_B2C = 'NORMAL_B2C',
}

export type ReceivingGift = {
  giverName: string
  cardId: number
  senderCardId: number
}

export type CardDetailCommon = {
  cardId: number
  cardType: CardTypeEnum
  cardDivision: CardDivisionEnum
  horizontal: boolean
  mediaUrl: string
  thumbnailUrl: string
  cardName: string
}

export type GiftHistoryCardDetailCommon = {
  cardHistoryId: number
  mediaUrl: string
  thumbnailUrl: string
  giftAmount: number
  sendDate: string
  cardName: string
  msgToReceiver: string
  horizontal: boolean
  isImage: boolean
}
export type CardComponentType = 'list' | 'detail'
export type CardShadowType = 'none' | 'default' | 'light'
