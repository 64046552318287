import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { useQuery } from '@tanstack/react-query'
import { useBasicSettingsQuery } from './use-basic-settings'

export const useCustomerServiceInfoQuery = () => {
  const basicSettingsQuery = useBasicSettingsQuery()

  return useQuery(
    [queryKeys.channel.customerServiceInfo],
    () => basicSettingsQuery.data!.customerServiceInfo,
    {
      staleTime: staleTime.infinity,
      enabled: basicSettingsQuery.isSuccess,
    }
  )
}
