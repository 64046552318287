import { ProductClassEnum } from '@src/types/product'

export type TransactionSkuDto = {
  id: string
  title: string
  sellableProductId: string
  productTitle: string
  image: string
  status: SkuStatusEnum
  quantity: number
  sellPrice: number
  totalAmount: number
  pointAmount: number
  skuProductType?: ProductClassEnum.GiftCard
  skuProductClass: ProductClassEnum

  // 이하 상품권용 필드
  pinNumber?: string
  expirationDate?: string
  publisherName?: string
  publisherMethod?: PublisherMethodEnum
  giftcardCurrentAmount: number // 금액권이 아니면 -1
  giftcardOriginalAmount: number // 금액권이 아니면 -1
}

export enum SkuStatusEnum {
  // 실물상품
  PaymentRequired = 'payment_required', // 결제대기
  PaymentFailValidation = 'payment_fail_validation', // 결제인증 실패 (PG 인증 실패)
  PaymentFailInvalidPaymentTrial = 'payment_fail_invalid_payment_trial', // 잘못된 방식의 결제 유도
  PaymentFailTimeOut = 'payment_fail_time_out', // 결제실패 (입금안함)
  PaymentFailError = 'payment_fail_error', // 결제실패 (PG실패)
  CheckRequired = 'check_required', // 접수요망
  OrderFailCheckRejected = 'order_fail_check_rejected', // 주문실패 (접수거절)
  ShipRequired = 'ship_required', // 출고요망
  ShipOkDirectDelivery = 'ship_ok_direct_delivery', // 직접배송
  ShipOk = 'ship_ok', // 출고완료
  OrderFailShipRejected = 'order_fail_ship_rejected', // 주문실패 (출고거절)
  ShipDelay = 'ship_delay', // 출고지연
  DeliveryIng = 'delivery_ing', // 배송중
  DeliveryOk = 'delivery_ok', // 배송완료
  DeliveryDelay = 'delivery_delay', // 배송지연
  OrderFinished = 'order_finished', // 구매확정
  OrderCancelled = 'order_cancelled', // 주문취소
  RefundRequested = 'refund_requested', // 반품요청
  RefundChecked = 'refund_checked', // 반품접수완료(동의대기중)
  RefundAgreed = 'refund_agreed', // 반품요청동의 (반품 수령 대기중)
  RefundFailCheckRejected = 'refund_fail_check_rejected', // 반품거절 (접수거절)
  RefundFailAgreeRejected = 'refund_fail_agree_rejected', // 반품거절 (동의거절)
  RefundFailInspectRejected = 'refund_fail_inspect_rejected', // 반품거절 (검수부결)
  RefundFailInspectRejectedDo = 'refund_fail_inspect_rejected_do', // 반품거절 (검수부결 - 기존물품 배송완료)
  RefundFailInspectRejectedDd = 'refund_fail_inspect_rejected_dd', // 반품거절 (검수부결 - 기존물품 배송지연)
  RefundFailReturnNo = 'refund_fail_return_no', // 반품실패 (반품 미수령)
  RefundReturnOk = 'refund_return_ok', // 반품수령완료
  RefundInspectPass = 'refund_inspect_pass', // 반품검수통과
  RefundIngOrderCanceled = 'refund_ing_order_canceled', // 환불대기중 (주문취소)
  RefundFinishedNormal = 'refund_finished_normal', // 환불완료 (반품완료)
  RefundFinishedOrderCanceled = 'refund_finished_order_canceled', // 환불완료 (주문취소)
  RefundFinishedOrderCanceledConfirmed = 'refund_finished_order_canceled_confirmed', // 환불완료 (주문취소확인)
  RefundFinishedCheckRejected = 'refund_finished_check_rejected', // 환불완료 (접수거절)
  RefundFinishedShipRejected = 'refund_finished_ship_rejected', // 환불완료 (출고거절)
  ExchangeRequested = 'exchange_requested', // 교환요청
  ExchangeChecked = 'exchange_checked', // 교환접수완료(동의대기중)
  ExchangeAgreed = 'exchange_agreed', // 교환동의 (교환품 수령 대기중)
  ExchangeFailCheckRejected = 'exchange_fail_check_rejected', // 교환거절 (접수거절)
  ExchangeFailAgreeRejected = 'exchange_fail_agree_rejected', // 교환거절 (동의거절)
  ExchangeFailInspectRejected = 'exchange_fail_inspect_rejected', // 교환거절 (검수부결)
  ExchangeFailInspectRejectedDo = 'exchange_fail_inspect_rejected_do', // 교환거절 (검수부결 - 기존물품 배송완료)
  ExchangeFailInspectRejectedDd = 'exchange_fail_inspect_rejected_dd', // 교환거절 (검수부결 - 기존물품 배송지연)
  ExchangeFailReshipRejected = 'exchange_fail_reship_rejected', // 교환거절 (재출고거절)
  ExchangeFailReshipRejectedDo = 'exchange_fail_reship_rejected_do', // 교환거절 (새물품 출고거절-기존물품 재배송 완료)
  ExchangeFailReshipRejectedDd = 'exchange_fail_reship_rejected_dd', // 교환거절 (새물품 출고거절-기존물품 재배송 지연)
  ExchangeFailReturnNo = 'exchange_fail_return_no', // 교환실패 (교환품 미수령)
  ExchangeReturnOk = 'exchange_return_ok', // 교환품수령완료
  ExchangeInspectPass = 'exchange_inspect_pass', // 교환품검수통과
  ExchangeReshipOk = 'exchange_reship_ok', // 교환새물품 출고완료
  ExchangeReshipDelay = 'exchange_reship_delay', // 교환새물품 출고지연
  ExchangeDeliveryIng = 'exchange_delivery_ing', // 교환새물품 배송중
  ExchangeDeliveryOk = 'exchange_delivery_ok', // 교환새물품 배송완료
  ExchangeDeliveryDelay = 'exchange_delivery_delay', // 교환새물품 배송지연
  CheckRejected = 'check_rejected', // 접수 거절
  ShipRejected = 'ship_rejected', // 출고 거절
  OrderFinishedReviewed = 'order_finished_reviewed', // 구매확정

  // 상품권
  Received = 'received', // 사용가능(발급완료)
  Expired = 'expired', // 기간만료
  ExpiredConfirmed = 'expired_confirmed', // 환불완료 (기간만료)
  Used = 'used', // 사용완료(교환완료)
  UsedPartial = 'partially_used', //사용가능(부분사용)
  UsedConfirmed = 'used_confirmed', // 사용완료(기간만료)
  IssueCancelled = 'issue_cancelled', // 환불완료(발급취소)
  ManuallyIssueRequired = 'manually_issue_required', // 발행예정(미연동)
  ManuallyIssueConfirmed = 'manually_issue_confirmed', // 발행완료(미연동)
  ManuallyIssueCancelling = 'manually_issue_cancelling', // 취소요청(미연동)
  RefundFinishedManuallyIssueRejected = 'refund_finished_manually_issue_rejected', // 거절환불완료(미연동)
  RefundFinishedManuallyIssueCancelled = 'refund_finished_manually_issue_cancelled', // 취소환불완료(미연동)
}

export enum PublisherMethodEnum {
  Api = 'api',
  Manual = 'manual',
}
