import styled from '@emotion/styled'
import { parseSize } from '@src/components/basic/utils'
import { useEffect, useState } from 'react'
import { RadioButton } from './radio-button'
import { Selectable } from '@src/types/util-types/selectable'

export { RadioButton } from './radio-button'

type Props<T = unknown> = {
  items: Selectable<T>[]
  initialSelected?: T
  onChange?: (value?: T) => void
  direction?: 'horizontal' | 'vertical'
  spacing?: number
  itemSpacing?: number
}

export const RadioGroup = <T,>({
  items = [],
  initialSelected,
  onChange,
  direction = 'horizontal',
  spacing = 20,
  itemSpacing = 8,
}: Props<T>) => {
  const [selected, setSelected] = useState<T | undefined>(initialSelected)

  useEffect(() => {
    setSelected(initialSelected)
  }, [initialSelected])

  const handleSelect = (selected?: T) => {
    setSelected(selected)
    onChange && onChange(selected)
  }

  return (
    <Container direction={direction} spacing={spacing}>
      {items.map((item, index) => (
        <RadioButton
          key={index}
          {...item}
          spacing={itemSpacing}
          isChecked={selected === item.value}
          onClick={handleSelect}
          disabled={item.disabled}
        />
      ))}
    </Container>
  )
}

const Container = styled.div<Pick<Props, 'direction' | 'spacing'>>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'row' : 'column'};
  row-gap: ${({ direction, spacing }) =>
    direction === 'vertical' && parseSize(spacing)};
  column-gap: ${({ direction, spacing }) =>
    direction === 'horizontal' && parseSize(spacing)};
`
