import { routes } from '@src/common/constants/routes'
import { ExtensionCodeEnum, ExtensionInstallStatusDto } from '../dto'

const extensionRelatedPages: Record<ExtensionCodeEnum, string[]> = {
  [ExtensionCodeEnum.Wishlist]: [routes.my.wishlist],
  [ExtensionCodeEnum.Review]: [routes.my.reviews],
}

class ExtensionInstallStatus {
  public readonly isInstalled: boolean
  public readonly relatedPages: string[]

  constructor(installStatusDto: ExtensionInstallStatusDto) {
    this.isInstalled = installStatusDto.isInstalled
    this.relatedPages = extensionRelatedPages[installStatusDto.extensionCode]
  }
}

export default ExtensionInstallStatus
