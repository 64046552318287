import styled from '@emotion/styled'
import { Text, TextProps } from '@src/components/basic'
import { Size } from '@src/components/basic/types'
import { parseColor, parseSize } from '@src/components/basic/utils'
import React from 'react'

type Props = TextProps & {
  label?: string
  disabled?: boolean
  onClick?: () => void
  type?: 'button' | 'submit'
  extendClickRange?: boolean
  underlineOffset?: Size
}

export const UnderlineButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      label,
      onClick,
      disabled,
      type = 'button',
      fontColor = 'gray.900',
      extendClickRange,
      underlineOffset,
      ...props
    }: Props,
    ref
  ) => {
    return (
      <ButtonWrapper
        ref={ref}
        type={type}
        disabled={disabled}
        onClick={onClick}
        extendClickRange={extendClickRange}
        fontColor={disabled ? 'gray.200' : fontColor}
      >
        <Text
          fontSize={12}
          fontColor={disabled ? 'gray.200' : fontColor}
          fontWeight="light"
          lineHeight={1}
          {...props}
        >
          {label}
        </Text>
      </ButtonWrapper>
    )
  }
)

UnderlineButton.displayName = 'UnderlineButton'

const ButtonWrapper = styled.button<
  Pick<Props, 'fontColor' | 'extendClickRange' | 'underlineOffset'>
>`
  padding-left: ${({ extendClickRange }) => extendClickRange && parseSize(50)};

  > span {
    border-bottom: ${({ fontColor }) => `1px solid ${parseColor(fontColor)}`};
    padding-bottom: ${({ underlineOffset }) => parseSize(underlineOffset)};
  }
`
