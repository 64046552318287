import { Keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Box, BoxProps } from '@src/components/basic'
import { ModalProps } from '@src/hook/use-modal'
import { fadeIn, fadeOut } from '../common/keyframes'
import { Overlay } from '../common/overlay'
import { OverlayWrapper } from '../common/overlay-wrapper'

type Props = ModalProps &
  BoxProps & {
    children?: React.ReactNode
  }

export const Modal = ({
  isOpen,
  onClose,
  children,
  zIndex,
  ...boxProps
}: Props) => {
  return (
    <OverlayWrapper isOpen={isOpen} zIndex={zIndex}>
      <Overlay isOpen={isOpen} onClose={onClose} />
      <StyledModal
        animation={isOpen ? fadeIn : fadeOut}
        backgroundColor="white"
        position="absolute"
        absoluteCentered
        {...boxProps}
      >
        {children}
      </StyledModal>
    </OverlayWrapper>
  )
}

type StyledModalProps = {
  animation: Keyframes
}

const StyledModal = styled(Box)<StyledModalProps>`
  animation: 0.15s ease-in ${({ animation }) => animation} forwards;
`
