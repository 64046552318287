import styled from '@emotion/styled'
import { colors } from '@src/styles/theme'

type LoadingSpinnerProps = {
  variant?: 'default' | 'white'
}

export const LoadingSpinner = ({
  variant = 'default',
}: LoadingSpinnerProps) => {
  return (
    <Wrapper>
      <Spinner variant={variant} />
    </Wrapper>
  )
}

const Spinner = styled.span<LoadingSpinnerProps>`
  width: 22px;
  height: 22px;
  border: 3px solid
    ${({ variant }) => (variant === 'default' ? colors.primary : colors.white)};
  border-bottom-color: ${({ variant }) =>
    variant === 'default' ? 'transparent' : colors.primary};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default LoadingSpinner
