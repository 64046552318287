import { queryKeys } from '@api/constants/query-keys'
import { api } from '@api/index'
import { ApiListResult2, InfiniteQueryOptions } from '@api/types'
import { environment } from '@src/config'
import { useCustomInfiniteQueryResult } from '@src/hook/use-custom-infinite-query-result'
import { BasicSettings } from '@src/types/channel'
import {
  BaseProduct,
  GetProductsRequestDto,
  InventoryStatusEnum,
  ProductDto,
} from '@src/types/product'
import { useInfiniteQuery } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { getNextPageParam } from '../_utils/get-next-page-param'
import { selectInfiniteData } from '../_utils/select-infinite-data'
import { useDesignThemeSettingsQuery } from '../channel/use-design-theme-settings'

type Options = InfiniteQueryOptions<
  ApiListResult2<ProductDto>,
  ApiListResult2<BaseProduct>
>

export const useProductsInfiniteQuery = (
  searchParams: GetProductsRequestDto,
  { enabled = true, ...options }: Options = {}
) => {
  const designThemeSettings = useDesignThemeSettingsQuery()

  const query = useInfiniteQuery(
    [queryKeys.product.searchResult, searchParams],
    async ({ pageParam = 1 }) => {
      const productsDto = await getProducts({
        ...searchParams,
        page: pageParam,
      })

      return {
        ...productsDto,
        items: productsDto.items.filter((productDto) => {
          if (
            designThemeSettings.data?.isStockBadgeOn &&
            productDto.inventoryStatus === InventoryStatusEnum.SoldOut
          ) {
            return false
          }

          return true
        }),
      }
    },
    {
      ...options,
      enabled,
      getNextPageParam,
      select: (data) => selectInfiniteData(data, (dto) => new BaseProduct(dto)),
    }
  )

  return useCustomInfiniteQueryResult(query)
}

const getProducts = (dto: GetProductsRequestDto) => {
  return api.productClient.get<ApiListResult2<ProductDto>>(
    `${environment.harmonyEndpoint}/${BasicSettings.getChannelId()}/products`,
    {
      params: snakecaseKeys(dto),
    }
  )
}
