import { Layout } from '@src/components/layout'
import { CssReset } from '@src/styles/css-reset'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { useEffect } from 'react'
import { CombinedProvider } from '@src/contexts'
import { useUpdatePrevPath } from '@src/hook/use-prev-path'
import { AppProps } from 'next/app'
import { RouterLoadingSpinner } from '@src/components/home/router-loading-spinner'
import { SEO, SEOProps } from '@src/components/seo'
import { PWA } from '@src/components/pwa'
import ErrorBoundary from '@src/components/error-boundary'
import { Fallback } from '@src/components/error-boundary/fallback'
import { useBasicSettingsQuery } from '@api/hooks/channel/use-basic-settings'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

type OGProps = {
  og: SEOProps
}

const MyApp = ({ Component, pageProps }: AppProps<OGProps>) => {
  useUpdatePrevPath()

  useEffect(() => {
    if (location.host.startsWith('www.')) {
      location.replace(location.href.replace('www.', ''))
    }
  }, [])

  return (
    <React.StrictMode>
      <CssReset />
      <SEO {...pageProps.og} />
      <PWA />
      <QueryClientProvider client={queryClient}>
        <CombinedProvider>
          <Layout>
            <ErrorBoundary fallback={<Fallback />}>
              <Component {...pageProps} />
            </ErrorBoundary>
            {/* <CartButton pathname={router.pathname} /> */}
            <RouterLoadingSpinner />
          </Layout>
        </CombinedProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.StrictMode>
  )
}

export default MyApp
