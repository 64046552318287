import React from 'react'
import { Box, Text } from '@src/components/basic'
type PopupGuideProps = {
  fontSize?: number
}
export const PopupGuide = ({ fontSize = 12 }: PopupGuideProps) => {
  return (
    <Text fontSize={fontSize} textAlign={'center'} color={'gray.500'}>
      본인인증 화면으로 이동하지 않는다면{'\n'}
      팝업 차단을 해제하시고 다시 진행해 주시기 바랍니다.
    </Text>
  )
}
