import styled from '@emotion/styled'
import {
  EffectProps,
  effectStyles,
  LayoutProps,
  layoutStyles,
  ParagraphProps,
  paragraphStyles,
} from './core-styles'

export type TextProps = LayoutProps & ParagraphProps & EffectProps

export const Text = styled.span<TextProps>`
  display: flex;
  white-space: pre-wrap;
  ${(props) => layoutStyles(props)}
  ${(props) => paragraphStyles(props)}
  ${(props) => effectStyles(props)}
`
