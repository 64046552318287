import styled from '@emotion/styled'
import { Box, BoxProps, Text, TextProps } from '@src/components/basic'
import React, { useEffect, useMemo } from 'react'

const htmlImageLoadedEventKey = 'html-image-loaded'

type HtmlRendererProps = BoxProps &
  TextProps & {
    html: string
    onImageLoaded?: () => void
  }

export const HtmlRenderer = React.forwardRef<HTMLDivElement, HtmlRendererProps>(
  ({ html, onImageLoaded, ...props }: HtmlRendererProps, ref) => {
    const isHtmlIncludesImg = html.toLowerCase().includes('<img')
    const defaultPadding = isHtmlIncludesImg ? 0 : 16

    const htmlWithImageLoadedEvent = useMemo(() => {
      return !!onImageLoaded
        ? html.replace(
            /<img|<IMG/g,
            `<img onload="window.dispatchEvent(new Event('${htmlImageLoadedEventKey}'))"`
          )
        : html
    }, [html, onImageLoaded])

    useEffect(() => {
      if (!onImageLoaded) {
        return
      }

      window.addEventListener(htmlImageLoadedEventKey, () => {
        onImageLoaded()
      })
      return () =>
        window.removeEventListener(htmlImageLoadedEventKey, onImageLoaded)
    }, [onImageLoaded])

    return (
      <Container
        ref={ref}
        dangerouslySetInnerHTML={{ __html: htmlWithImageLoadedEvent }}
        whiteSpace="pre-wrap"
        fontSize={14}
        padding={defaultPadding}
        {...props}
      />
    )
  }
)

HtmlRenderer.displayName = 'HtmlRenderer'

const Container = styled(Text)`
  & * {
    max-width: 100%;
  }
`.withComponent(Box)
