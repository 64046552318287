import { globalImages } from '@src/common/constants/images'
import { routes } from '@src/common/constants/routes'
import Image from 'next/legacy/image'
import Link from 'next/link'

type LogoProps = {}

export const Logo = ({}: LogoProps) => {
  return (
    <Link href={routes.base.home}>
      <Image
        src={globalImages.logo}
        width={80}
        height={24}
        layout={'fixed'}
        alt="유찜 메인 로고"
        priority
      />
    </Link>
  )
}
