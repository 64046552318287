import { useUnreceivedCardCount } from '@api/hooks/card/use-my-published-cards-count'
import styled from '@emotion/styled'
import { bottomBarIconImages } from '@src/common/constants/images'
import { routes } from '@src/common/constants/routes'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { Drawer, DrawerHeader, VDivider } from '@src/components/common'
import { Icon } from '@src/components/common/icon'
import { ModalProps, useModal } from '@src/hook/use-modal'
import { colors, maxScreenWidth, zIndices } from '@src/styles/theme'
import { MyCardTypeEnum } from '@src/types/card'
import { isLogin } from '@src/utils/is-login'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { parseSize } from '../basic/utils'

export const BottomNavbar = () => {
  const router = useRouter()

  const isButtonActive = (targetPath: string) => {
    return router.pathname.includes(targetPath)
  }

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [isCardStudioDrawerOpen, openCardStudioDrawer, closeCardStudioDrawer] =
    useModal()

  useEffect(() => {
    setIsUserLoggedIn(isLogin())
  }, [])

  return (
    <BottomNavbarContainer>
      <BottomNavbarButton
        iconUrl={bottomBarIconImages.home}
        activeIconUrl={bottomBarIconImages.homeFilled}
        buttonName="홈"
        targetRoute={routes.base.home}
        isActive={router.pathname === routes.base.home}
      />
      <BottomNavbarButton
        iconUrl={bottomBarIconImages.cardShop}
        activeIconUrl={bottomBarIconImages.cardShopFilled}
        buttonName="모바일상품권"
        targetRoute={routes.voucher.home}
        isActive={isButtonActive(routes.voucher.home)}
      />
      <Box flex={1} centered>
        <CardStudioButton onClick={openCardStudioDrawer} />
      </Box>
      <BottomNavbarButton
        iconUrl={bottomBarIconImages.shopping}
        activeIconUrl={bottomBarIconImages.shoppingFilled}
        buttonName="쇼핑"
        targetRoute={routes.shopping.home}
        isActive={isButtonActive(routes.shopping.home)}
      />
      <BottomNavbarButton
        iconUrl={bottomBarIconImages.myPage}
        activeIconUrl={bottomBarIconImages.myPageFilled}
        buttonName="마이페이지"
        targetRoute={isUserLoggedIn ? routes.my.home : routes.my.login}
        isActive={isButtonActive(routes.my.home)}
        data-et={'route-to-my-page'}
      />
      <CardStudioDrawer
        onClose={closeCardStudioDrawer}
        isOpen={isCardStudioDrawerOpen}
      />
    </BottomNavbarContainer>
  )
}

export const BottomNavbarContainer = styled.nav`
  width: 100%;
  max-width: ${parseSize(maxScreenWidth)};
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
  padding: 16px;
  border-top: 1px solid ${colors.gray[100]};
  z-index: ${zIndices.floating};
`

type BottomNavbarButtonProps = {
  iconUrl: string
  activeIconUrl: string
  buttonName: string
  targetRoute: string
  isActive: boolean
}

export const BottomNavbarButton = ({
  iconUrl,
  activeIconUrl,
  buttonName,
  targetRoute,
  isActive,
  ...props
}: BottomNavbarButtonProps) => {
  return (
    <Box flex={1}>
      <Link href={targetRoute} {...props}>
        <VStack alignItems="center" spacing={4}>
          <Box position="relative" size={20}>
            <Image
              src={isActive ? activeIconUrl : iconUrl}
              layout="fill"
              alt={buttonName}
            />
          </Box>
          <Text
            color={isActive ? 'purple.dark.200' : 'gray.400'}
            fontSize={12}
            fontWeight={isActive ? 'bold' : 'regular'}
          >
            {buttonName}
          </Text>
        </VStack>
      </Link>
    </Box>
  )
}

type CardStudioButtonProps = {
  onClick: () => void
}

const CardStudioButton = ({ onClick }: CardStudioButtonProps) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      backgroundColor="primary"
      size={40}
      borderRadius={10}
      centered
      boxShadow="0px 3px 5px rgba(0, 0, 0, 0.3);"
    >
      <Icon iconName="add" color="white" size={24} />
    </Box>
  )
}

const CardStudioDrawer = ({ onClose, isOpen }: ModalProps) => {
  const myPublishedCardCounts = useUnreceivedCardCount()

  return (
    <Drawer onClose={onClose} isOpen={isOpen}>
      <DrawerHeader title={'내 기프트'} onClose={onClose} />
      <VStack
        spacing={8}
        width={'100%'}
        alignItems={'center'}
        paddingBottom={16}
      >
        <HStack
          height={120}
          padding={16}
          width={'100%'}
          justifyContent={'space-evenly'}
          alignItems={'center'}
          spacing={16}
        >
          <Link
            href={isLogin() ? routes.studio.uzzimStudio.home : routes.my.login}
          >
            <VStack alignItems={'center'} spacing={8}>
              <Icon iconName={'addCircleOutline'} size={40} />
              <Text fontSize={16} color={'purple.dark.200'}>
                기프트 생성하기
              </Text>
            </VStack>
          </Link>
          <VDivider thickness={2} length={'80%'} />
          <Link
            href={
              isLogin()
                ? {
                    pathname: routes.my.cards,
                    query: {
                      tab: MyCardTypeEnum.PUBLISH,
                    },
                  }
                : routes.my.login
            }
          >
            <VStack alignItems={'center'} spacing={8}>
              <Icon iconName={'image'} variant={'outlined'} size={40} />
              <Text fontSize={16} color={'purple.dark.200'}>
                발행한 기프트
              </Text>
            </VStack>
          </Link>
        </HStack>
        {isLogin() &&
          myPublishedCardCounts.data &&
          myPublishedCardCounts.data.total > 0 && (
            <Link
              href={{
                pathname: routes.my.cards,
                query: {
                  tab: MyCardTypeEnum.GIFT_OWNED,
                  subTab: MyCardTypeEnum.GIFT_RECEIVING,
                },
              }}
            >
              <Text underline color={'gray.800'} fontSize={14}>
                확인하지 않은 선물이 {myPublishedCardCounts.data.total}개
                있어요!
              </Text>
            </Link>
          )}
      </VStack>
    </Drawer>
  )
}
