import { queryKeys } from '@api/constants/query-keys'
import { api } from '@api/index'
import { QueryOptions } from '@api/types'
import { initialOrderInfo } from '@src/common/constants/initial-data/shopping'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { Cart, CartDto, DirectOrderRequestDto } from '@src/types/cart'
import { useQuery } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'

type Options = QueryOptions<CartDto>

const defaultOptions: Options = {
  enabled: true,
}

export const useDirectOrderInfoQuery = (
  dto: DirectOrderRequestDto | null,
  options = defaultOptions
) => {
  return useQuery(
    [queryKeys.transaction.directOrder, dto],
    () => {
      if (!dto || dto.skus.length === 0) {
        return initialOrderInfo
      }

      return getDirectOrderInfo(dto)
    },
    {
      enabled: !!dto && options.enabled && dto.postalCode !== '',
      keepPreviousData: true,
      select: (data) => new Cart(data),
    }
  )
}

const getDirectOrderInfo = ({ skus, ...dto }: DirectOrderRequestDto) => {
  return api.transactionClient.get<CartDto>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/direct/order?skus=${skus
      .map(({ skuId, quantity }) => `${skuId}__${quantity}`)
      .join('&skus=')}`,
    {
      params: snakecaseKeys(dto),
    }
  )
}
