export type NoticeInfo = {
  label: string
  value: string
  list: Array<{
    readonly label: string
    readonly key: string
  }>
}

export const noticeInfoList: NoticeInfo[] = [
  {
    label: '의류',
    value: '001',
    list: [
      {
        label: '제품 소재',
        key: 'fabric',
      },
      {
        label: '색상',
        key: 'color',
      },
      {
        label: '치수',
        key: 'size',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '세탁방법 및 취급시 주의사항',
        key: 'instruction',
      },
      {
        label: '제조연월',
        key: 'createdDt',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '구두/신발',
    value: '002',
    list: [
      {
        label: '제품 주소재',
        key: 'fabric1',
      },
      {
        label: '색상',
        key: 'color',
      },
      {
        label: '치수',
        key: 'size',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '취급시 주의사항',
        key: 'instruction',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '가방',
    value: '003',
    list: [
      {
        label: '종류',
        key: 'kind',
      },
      {
        label: '소재',
        key: 'fabric',
      },
      {
        label: '색상',
        key: 'color',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '취급시 주의사항',
        key: 'instruction',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '패션잡화(모자/벨트/액세서리)',
    value: '004',
    list: [
      {
        label: '종류',
        key: 'kind',
      },
      {
        label: '소재',
        key: 'fabric',
      },
      {
        label: '치수',
        key: 'size',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '취급시 주의사항',
        key: 'instruction',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '침구류/커튼',
    value: '005',
    list: [
      {
        label: '제품 소재',
        key: 'fabric',
      },
      {
        label: '색상',
        key: 'color',
      },
      {
        label: '치수',
        key: 'size',
      },
      {
        label: '제품구성',
        key: 'components',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '세탁방법 및 취급시 주의사항',
        key: 'instruction',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '가구(침대/소파/싱크대/DIY제품)',
    value: '006',
    list: [
      {
        label: '품명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '색상',
        key: 'color',
      },
      {
        label: '구성품',
        key: 'components',
      },
      {
        label: '주요 소재',
        key: 'fabric',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '배송·설치 비용',
        key: 'deliveryPrice',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '영상가전(TV류)',
    value: '007',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '정격전압/소비전력',
        key: 'ratedVoltage',
      },
      {
        label: '에너지소비효율등급',
        key: 'energyGrade',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '화면사양',
        key: 'specification',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '가정용 전기제품(냉장고/세탁기/식기세척기/전자레인지)',
    value: '008',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '정격전압 / 소비전력',
        key: 'ratedVoltage',
      },
      {
        label: '에너지소비효율등급',
        key: 'energyGrade',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '계절가전(에어컨/온풍기)',
    value: '009',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '정격전압 / 소비전력',
        key: 'ratedVoltage',
      },
      {
        label: '에너지소비효율등급',
        key: 'energyGrade',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '냉난방면적',
        key: 'area',
      },
      {
        label: '추가설치비용',
        key: 'additionalCost',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '사무용기기(컴퓨터/노트북/프린터)',
    value: '010',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '정격전압 / 소비전력',
        key: 'ratedVoltage',
      },
      {
        label: '에너지소비효율등급',
        key: 'energyGrade',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기, 무게',
        key: 'size',
      },
      {
        label: '주요 사양',
        key: 'specification',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '광학기기(디지털카메라/캠코더)',
    value: '011',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기, 무게',
        key: 'size',
      },
      {
        label: '주요 사양',
        key: 'specification',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '소형전자(MP3/전자사전 등)',
    value: '012',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '정격전압 / 소비전력',
        key: 'ratedVoltage',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기, 무게',
        key: 'size',
      },
      {
        label: '주요 사양',
        key: 'specification',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '휴대폰',
    value: '013',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기, 무게',
        key: 'size',
      },
      {
        label: '이동통신사',
        key: 'mobileOperator',
      },
      {
        label: '가입 절차',
        key: 'registrationProcess',
      },
      {
        label: '소비자의 추가적인 부담사항',
        key: 'additionalBurdenOnConsumer',
      },
      {
        label: '주요 사양',
        key: 'specification',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '네비게이션',
    value: '014',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '정격전압 / 소비전력',
        key: 'ratedVoltage',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기, 무게',
        key: 'size',
      },
      {
        label: '주요 사양',
        key: 'specification',
      },
      {
        label: '맵 업데이트 비용 및 무상기간',
        key: 'costAndFreePeriod',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '자동차용품(자동차부품/기타 자동차용품)',
    value: '015',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: 'KC 인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '적용차종',
        key: 'car',
      },
      {
        label: '제품사용으로 인한 위험 및 유의사항',
        key: 'instruction',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: '검사 합격증 번호',
        key: 'passNumber',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '의료기기',
    value: '016',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: '의료기기법상 허가·신고 번호',
        key: 'permitNumber',
      },
      {
        label: '광고사전심의필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '정격전압 / 소비전력',
        key: 'ratedVoltage',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '제품의 사용목적 및 사용방법',
        key: 'howToUse',
      },
      {
        label: '취급시 주의사항',
        key: 'instruction',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '주방용품',
    value: '017',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: '재질',
        key: 'fabric',
      },
      {
        label: '구성품',
        key: 'components',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label:
          '수입식품안전관리특별법에 따른 수입 기구·용기의 경우 "수입식품안전관리특별법에 따른 수입신고를 필함"의 문구',
        key: 'text',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '화장품',
    value: '018',
    list: [
      {
        label: '용량 또는 중량',
        key: 'volume',
      },
      {
        label: '제품 주요 사양',
        key: 'specification',
      },
      {
        label: '사용기한 또는 개봉 후 사용기간',
        key: 'dateOfUse',
      },
      {
        label: '사용방법',
        key: 'howToUse',
      },
      {
        label: '화장품제조업자 및 화장품책임판매업자',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '화장품법에 따라 기재 표기해야 하는 모든 성분',
        key: 'ingredients',
      },
      {
        label:
          '기능성 화장품의 경우 화장품법에 따른 식품의약품안전처 심사 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '사용할 때 주의사항',
        key: 'instructions',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: '소비자상담관련 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '귀금속/보석/시계류',
    value: '019',
    list: [
      {
        label: '소재/순도/밴드재질',
        key: 'fabric',
      },
      {
        label: '중량',
        key: 'weight',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '치수',
        key: 'size',
      },
      {
        label: '착용 시 주의사항',
        key: 'instruction',
      },
      {
        label: '주요 사양',
        key: 'specification',
      },
      {
        label: '귀금속, 보석류 - 등급',
        key: 'grade',
      },
      {
        label: '시계 - 기능, 방수 등',
        key: 'function',
      },
      {
        label: '보증서 제공여부',
        key: 'guarantee',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '식품(농수축산물)',
    value: '020',
    list: [
      {
        label: '품목 또는 명칭',
        key: 'name',
      },
      {
        label: '포장단위별 내용물의 용량(중량), 수량, 크기',
        key: 'size',
      },
      {
        label: '생산자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '농수산물의 원산지 표시에 관한 법률에 따른 원산지',
        key: 'origin',
      },
      {
        label: '제조연월일, 유통기한',
        key: 'expirationDate',
      },
      {
        label:
          '농산물 - 농수산물품질관리법상 유전자변형농산물 표시, 지리적 표시',
        key: 'agriculturalProducts',
      },
      {
        label: '축산물 - 축산법에 따른 등급 표시',
        key: 'livestock',
      },
      {
        label:
          '수산물 - 농수산물품질관리법상 유전자변형수산물 표시, 지리적 표시',
        key: 'seafood',
      },
      {
        label:
          '수입식품에 해당하는 경우 "수입식품안전관리특별법에 따른 수입신고를 필함"의 문구',
        key: 'importedFood',
      },
      {
        label: '상품구성',
        key: 'composition',
      },
      {
        label: '보관방법 또는 취급방법',
        key: 'handlingMethod',
      },
      {
        label: '식품등에표시·광고에관한법률에 따른 소비자안전을 위한 주의사항',
        key: 'instruction',
      },
      {
        label: '소비자상담관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '가공식품',
    value: '021',
    list: [
      {
        label: '제품명',
        key: 'name',
      },
      {
        label: '식품의 유형',
        key: 'categories',
      },
      {
        label: '생산자 및 소재지',
        key: 'manufacturerAndLocation',
      },
      {
        label: '제조연월일, 유통기한 또는 품질유지기한',
        key: 'expirationDate',
      },
      {
        label: '포장단위별 내용물의 용량(중량), 수량',
        key: 'weight',
      },
      {
        label: '원재료명 및 함량',
        key: 'materialName',
      },
      {
        label: '영양성분',
        key: 'ingredients',
      },
      {
        label: '유전자변형식품에 해당하는 경우의 표시',
        key: 'geneticModification',
      },
      {
        label: '소비자안전을 위한 주의사항',
        key: 'instruction',
      },
      {
        label:
          '수입식품에 해당하는 경우 "수입식품안전관리특별법에 따른 수입신고를 필함"의 문구',
        key: 'isGuaranteed',
      },
      {
        label: '소비자상담관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '건강기능식품',
    value: '022',
    list: [
      {
        label: '제품명',
        key: 'name',
      },
      {
        label: '식품의 유형',
        key: 'categories',
      },
      {
        label: '제조업소의 명칭과 소재지',
        key: 'manufacturer',
      },
      {
        label: '제조연월일, 유통기한',
        key: 'expirationDate',
      },
      {
        label: '포장단위별 내용물의 용량(중량), 수량',
        key: 'weight',
      },
      {
        label: '원재료명 및 함량',
        key: 'materialName',
      },
      {
        label: '영양정보',
        key: 'nutrition',
      },
      {
        label: '기능정보',
        key: 'function ',
      },
      {
        label: '섭취량, 섭취방법 및 섭취 시 주의사항 및 부작용 가능성',
        key: 'intakeDescription',
      },
      {
        label: '질병의 예방 및 치료를 위한 의약품이 아니라는 내용의 표현',
        key: 'description',
      },
      {
        label: '유전자변형건강기능식품에 해당하는 경우의 표시',
        key: 'geneticModification',
      },
      {
        label:
          '수입식품에 해당하는 경우 "수입식품안전관리특별법에 따른 수입신고를 필함"의 문구',
        key: 'isGuaranteed',
      },
      {
        label: '소비자안전을 위한 주의사항',
        key: 'instruction',
      },
      {
        label: '소비자상담관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '영유아용품',
    value: '023',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label:
          '어린이제품안전특별법상 안전인증대상어린이제품, 안전확인대상어린이제품, 공급자적합성확인대상어린이제품에 대한 KC인증 필 유무',
        key: 'isGuaranteed',
      },
      {
        label: '크기, 중량',
        key: 'size',
      },
      {
        label: '색상',
        key: 'color',
      },
      {
        label: '재질',
        key: 'fabric',
      },
      {
        label: '사용연령 또는 체중범위',
        key: 'ageOfUse',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '취급방법 및 취급시 주의사항, 안전표시',
        key: 'instruction',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '악기',
    value: '024',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '색상',
        key: 'color',
      },
      {
        label: '재질',
        key: 'fabric',
      },
      {
        label: '제품 구성',
        key: 'components',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '상품별 세부 사양',
        key: 'specification',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '스포츠용품',
    value: '025',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: '크기, 중량',
        key: 'size',
      },
      {
        label: '색상',
        key: 'color',
      },
      {
        label: '재질',
        key: 'fabric',
      },
      {
        label: '제품 구성',
        key: 'components',
      },
      {
        label: '동일모델의 출시년월',
        key: 'releaseDtOfSameModel',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: '제조국',
        key: 'manufacturerCountry',
      },
      {
        label: '상품별 세부 사양',
        key: 'specification',
      },
      {
        label: '품질보증기준',
        key: 'qa',
      },
      {
        label: 'A/S 책임자와 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '서적',
    value: '026',
    list: [
      {
        label: '도서명',
        key: 'name',
      },
      {
        label: '저자, 출판사',
        key: 'publisher',
      },
      {
        label: '크기',
        key: 'size',
      },
      {
        label: '쪽수',
        key: 'pages',
      },
      {
        label: '제품 구성',
        key: 'components',
      },
      {
        label: '출간일',
        key: 'releaseDt',
      },
      {
        label: '목차 또는 책 소재',
        key: 'index',
      },
    ],
  },
  {
    label: '호텔/펜션 예약',
    value: '027',
    list: [
      {
        label: '국가 또는 지역명',
        key: 'country',
      },
      {
        label: '숙소 형태',
        key: 'typeOfAccomodation',
      },
      {
        label: '등급, 객실타입',
        key: 'grade',
      },
      {
        label: '사용가능 인원, 인원 추가 시 비용',
        key: 'capacity',
      },
      {
        label: '부대시설, 제공 서비스',
        key: 'amenities',
      },
      {
        label: '취소규정',
        key: 'cancellationPolicy',
      },
      {
        label: '예약담당 연락처',
        key: 'reservationContact',
      },
    ],
  },
  {
    label: '여행패키지',
    value: '028',
    list: [
      {
        label: '여행사',
        key: 'travelAgency',
      },
      {
        label: '이용항공편',
        key: 'flight',
      },
      {
        label: '여행기간 및 일정',
        key: 'calendar',
      },
      {
        label: '총 예정 인원, 출발 가능 인원',
        key: 'totalNumber',
      },
      {
        label: '숙박정보',
        key: 'accomodation',
      },
      {
        label: '여행상품 가격',
        key: 'price',
      },
      {
        label: '선택경비 유무',
        key: 'optionalPrice',
      },
      {
        label: '선택관광 및 대체일정',
        key: 'alternativeItinerary',
      },
      {
        label: '가이드 팁',
        key: 'tip',
      },
      {
        label: '취소 규정',
        key: 'cancellationPolicy',
      },
      {
        label: '해외여행의 경우 외교부가 지정하는 여행경보단계',
        key: 'travelAlertStage',
      },
      {
        label: '예약담당 연락처',
        key: 'reservationContact',
      },
    ],
  },
  {
    label: '항공권',
    value: '029',
    list: [
      {
        label: '요금조건, 왕복·편도 여부',
        key: 'feeTerms',
      },
      {
        label: '유효기간',
        key: 'duration',
      },
      {
        label: '제한사항',
        key: 'restrictions',
      },
      {
        label: '티켓수령방법',
        key: 'howToReceiveTicket',
      },
      {
        label: '좌석종류',
        key: 'typeOfSeats',
      },
      {
        label: '가격에 포함되지 않은 내역 및 금액',
        key: 'priceNotIncluded',
      },
      {
        label: '취소 규정',
        key: 'cancellationPolicy',
      },
      {
        label: '예약담당 연락처',
        key: 'reservationContact',
      },
    ],
  },
  {
    label: '자동차 대여 서비스(렌터카)',
    value: '030',
    list: [
      {
        label: '차종',
        key: 'carType',
      },
      {
        label: '소유권 이전 조건',
        key: 'transferOfOwnership',
      },
      {
        label: '추가 선택 시 비용',
        key: 'additionalCost',
      },
      {
        label: '차량 반환 시 연료대금 정산 방법',
        key: 'howToSettle',
      },
      {
        label: '차량의 고장·훼손시 소비자책임',
        key: 'consumerResponsibility',
      },
      {
        label: '예약 취소 또는 중도 해약 시 환불 기준',
        key: 'refundCriteria',
      },
      {
        label: '소비자상담 관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '물품대여 서비스(정수기/비데/공기청정기 등)',
    value: '031',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: '소유권 이전 조건',
        key: 'transferOfOwnership',
      },
      {
        label: '유지보수 조건',
        key: 'maintenanceConditions',
      },
      {
        label: '상품의 고장·분실·훼손시 소비자책임',
        key: 'consumerResponsibility',
      },
      {
        label: '중도 해약 시 환불 기준',
        key: 'refundCriteria',
      },
      {
        label: '제품 사양',
        key: 'specification',
      },
      {
        label: '소비자상담 관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '물품대여 서비스(서적/유아용품/행사용품 등)',
    value: '032',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label: '소유권 이전 조건',
        key: 'transferOfOwnership',
      },
      {
        label: '상품의 고장·분실·훼손시 소비자책임',
        key: 'consumerResponsibility',
      },
      {
        label: '중도 해약 시 환불 기준',
        key: 'refundCriteria',
      },
      {
        label: '소비자상담 관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '디지털 콘텐츠(음원, 게임, 인터넷강의 등)',
    value: '033',
    list: [
      {
        label: '제작자 또는 공급자',
        key: 'manufacturer',
      },
      {
        label: '이용조건, 이용기간',
        key: 'duration',
      },
      {
        label: '상품 제공 방식',
        key: 'deliveryMethod',
      },
      {
        label: '최소 시스템 사양, 필수 소프트웨어',
        key: 'minimumSpecifications',
      },
      {
        label: '청약철회 또는 계약의 해제·해지에 따른 효과',
        key: 'withdrawal',
      },
      {
        label: '소비자상담 관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '상품권/쿠폰',
    value: '034',
    list: [
      {
        label: '발행자',
        key: 'publisher',
      },
      {
        label: '유효기간',
        key: 'duration',
      },
      {
        label: '이용가능매장',
        key: 'availableStore',
      },
      {
        label: '이용조건, 환불 조건 및 방법',
        key: 'fullRefundTerms',
      },
      {
        label: '소비자상담 관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '모바일 쿠폰',
    value: '035',
    list: [
      {
        label: '발행자',
        key: 'publisher',
      },
      {
        label: '유효기간, 이용조건',
        key: 'duration',
      },
      {
        label: '이용 가능 매장',
        key: 'availableStore',
      },
      {
        label: '환불조건 및 방법',
        key: 'refundTerms',
      },
      {
        label: '소비자상담 관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '영화·공연',
    value: '036',
    list: [
      {
        label: '주최 또는 기획',
        key: 'hosting',
      },
      {
        label: '주연',
        key: 'mainActor',
      },
      {
        label: '관람등급',
        key: 'filmRating',
      },
      {
        label: '상영·공연시간',
        key: 'screenTime',
      },
      {
        label: '상영·공연장소',
        key: 'theater',
      },
      {
        label: '예매 취소 조건',
        key: 'conditionsOfCancellation',
      },
      {
        label: '취소·환불방법',
        key: 'howToCancel',
      },
      {
        label: '소비자상담 관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '생활화학제품',
    value: '037',
    list: [
      {
        label: '품목 및 제품명',
        key: 'name',
      },
      {
        label: '용도',
        key: 'purpose',
      },
      {
        label: '제조연월 및 유통기한',
        key: 'expirationDate',
      },
      {
        label: '중량·용량·매수',
        key: 'size',
      },
      {
        label: '효과·효능',
        key: 'effect',
      },
      {
        label: '수입자, 제조국 및 제조사',
        key: 'manufacturer',
      },
      {
        label: '어린이 보호포장 대상 제품 유무',
        key: 'whetherProductsForChildren',
      },
      {
        label: '제품에 사용된 화학물질 명칭',
        key: 'chemicalSubstance',
      },
      {
        label: '사용상 주의사항',
        key: 'instruction',
      },
      {
        label:
          '안전기준 적합 확인 신고번호 또는 안전확인대상 생활화학제품 승인번호',
        key: 'approvalNumber',
      },
      {
        label: '소비자상담관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '살생물제품',
    value: '038',
    list: [
      {
        label: '제품명 및 제품유형',
        key: 'name',
      },
      {
        label: '중량 또는 용량 및 표준사용량',
        key: 'size',
      },
      {
        label: '효과·효능',
        key: 'effect',
      },
      {
        label: '사용대상자 및 사용범위',
        key: 'target',
      },
      {
        label: '수입자, 제조국 및 제조사',
        key: 'manufacturer',
      },
      {
        label: '어린이 보호포장 대상 제품 유무',
        key: 'whetherProductsForChildren',
      },
      {
        label: '살생물물질, 나노물질, 기타 화학물질의 명칭',
        key: 'chemicalSubstance',
      },
      {
        label: '제품 유해성·위해성 표시',
        key: 'hazardStatements',
      },
      {
        label: '사용방법 및 사용상 주의사항',
        key: 'instruction',
      },
      {
        label: '승인번호',
        key: 'approvalNumber',
      },
      {
        label: '소비자상담관련 전화번호',
        key: 'consumerContact',
      },
    ],
  },
  {
    label: '기타 용역',
    value: '039',
    list: [
      {
        label: '서비스 제공 사업자',
        key: 'businessman',
      },
      {
        label:
          '법에 의한 인증·허가 등을 받았음을 확인할 수 있는 경우 그에 대한 사항',
        key: 'isGuaranteed',
      },
      {
        label: '이용조건',
        key: 'condition',
      },
      {
        label: '취소·중도해약·해지조건 및 환불기준',
        key: 'refundCriteria',
      },
      {
        label: '취소·환불 방법',
        key: 'howToCancel',
      },
      {
        label: '소비자상담 관련 전화번호',
        key: 'qaContact',
      },
    ],
  },
  {
    label: '기타 재화',
    value: '040',
    list: [
      {
        label: '품명 및 모델명',
        key: 'name',
      },
      {
        label:
          '법에 의한 인증·허가 등을 받았음을 확인할 수 있는 경우 그에 대한 사항',
        key: 'isGuaranteed',
      },
      {
        label: '제조국 또는 원산지',
        key: 'originCountry',
      },
      {
        label: '제조자, 수입품의 경우 수입자를 함께 표기',
        key: 'manufacturer',
      },
      {
        label: 'A/S 책임자와 전화번호 또는 소비자상담 관련 전화번호',
        key: 'qaContact',
      },
    ],
  },
]
