import { ValueOf } from '@src/types/util-types/value-of'

export const storageKeys = {
  recentKeywords: 'recent-keywords',
  directOrderDetail: 'direct-order-cart',
  loginRedirect: 'login-redirect',
  verificationRedirect: 'verification-redirect',
  openBankingRedirect: 'open-banking-redirect',
  currentPath: 'current-path',
  previousPath: 'previous-path',
  cartLines: 'cart-lines',
  toWishlist: 'to-wishlist',
  isSendGiftTutorialShowed: 'is-send-gift-tutorial-showed',
} as const

export type StorageKey = ValueOf<typeof storageKeys>
