import { Accordion, HtmlRenderer } from '@src/components/common'
import { NoticeInfoValue } from './common'

type PurchaseNoteProps = {
  note: string
}

export const PurchaseNoteSection = ({ note }: PurchaseNoteProps) => {
  return (
    <Accordion label={'유의사항'} labelBoxProps={{ paddingY: 12 }}>
      <NoticeInfoValue>
        <HtmlRenderer html={note} padding={0} />
      </NoticeInfoValue>
    </Accordion>
  )
}
