import { Box, HStack, Text } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import {
  Button,
  Drawer,
  DrawerFooter,
  DrawerHeader,
} from '@src/components/common'
import { ModalProps } from '@src/hook/use-modal'
import { GenderEnum } from '@src/types/review'
import { Selectable } from '@src/types/util-types/selectable'
import { useEffect, useState } from 'react'
import { DetailFilterProps } from './_hooks/use-detail-filter'

export const filterAge: Selectable<string>[] = [
  { label: '10대', value: '10' },
  { label: '20대', value: '20' },
  { label: '30대', value: '30' },
  { label: '40대+', value: '40' },
]

export const filterScore: Selectable<string>[] = [
  { label: '5점', value: '5' },
  { label: '4점', value: '4' },
  { label: '3점', value: '3' },
  { label: '2점', value: '2' },
  { label: '1점', value: '1' },
]

type Props = ModalProps & DetailFilterProps

export const ReviewDetailFilterDrawer = ({
  isOpen,
  onClose,
  detailFilter,
  setDetailFilter,
}: Props) => {
  const [tempAges, setTempAges] = useState<string[]>([])
  const [tempScores, setTempScores] = useState<string[]>([])
  const [tempGender, setTempGender] = useState<GenderEnum>(GenderEnum.None)

  useEffect(() => {
    setTempAges(detailFilter.ages)
    setTempScores(detailFilter.scores)
    setTempGender(detailFilter.gender)
  }, [detailFilter])

  const isSubmittable =
    tempAges.length > 0 || tempScores.length > 0 || !!tempGender

  const handleToggleAge = (targetAge: string) => {
    if (tempAges.includes(targetAge)) {
      return setTempAges((prev) => prev.filter((age) => age !== targetAge))
    }

    setTempAges([...tempAges, targetAge])
  }

  const handleToggleScore = (targetScore: string) => {
    if (tempScores.includes(targetScore)) {
      return setTempScores((prev) => prev.filter((age) => age !== targetScore))
    }

    setTempScores([...tempScores, targetScore])
  }

  const handleSubmit = () => {
    if (!isSubmittable) {
      return
    }

    setDetailFilter(tempAges, tempScores, tempGender)
    onClose()
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerHeader title="상세필터" onClose={onClose} />
      <Box
        as="dl"
        paddingTop={16}
        paddingX={16}
        paddingBottom={18}
        display="grid"
        gridTemplateColumns={`${parseSize(56)} 1fr`}
        rowGap={8}
      >
        <Label label="연령대" />
        <HStack as="dd">
          {filterAge.map(({ label, value }) => (
            <FilterItemButton
              key={value}
              label={label}
              applied={tempAges.includes(value)}
              onClick={() => handleToggleAge(value)}
            />
          ))}
        </HStack>
        <Label label="별점" />
        <HStack as="dd">
          {filterScore.map(({ label, value }) => (
            <FilterItemButton
              key={value}
              label={label}
              applied={tempScores.includes(value)}
              onClick={() => handleToggleScore(value)}
            />
          ))}
        </HStack>
        <Label label="성별" />
        <HStack as="dd">
          <FilterItemButton
            label={'여성'}
            applied={tempGender === GenderEnum.Female}
            onClick={() =>
              tempGender === GenderEnum.Female
                ? setTempGender(GenderEnum.None)
                : setTempGender(GenderEnum.Female)
            }
          />
          <FilterItemButton
            label={'남성'}
            applied={tempGender === GenderEnum.Male}
            onClick={() =>
              tempGender === GenderEnum.Male
                ? setTempGender(GenderEnum.None)
                : setTempGender(GenderEnum.Male)
            }
          />
        </HStack>
      </Box>
      <DrawerFooter>
        <Button label="적용" onClick={handleSubmit} disabled={!isSubmittable} />
      </DrawerFooter>
    </Drawer>
  )
}

type LabelProps = {
  label: string
}

const Label = ({ label }: LabelProps) => {
  return (
    <Box as="dt" height={30} justifyContent="center">
      <Text fontSize={12} lineHeight={1}>
        {label}
      </Text>
    </Box>
  )
}

type FilterItemButtonProps = {
  applied?: boolean
  onClick?: () => void
  label: string
}

const FilterItemButton = ({
  applied,
  onClick,
  label,
}: FilterItemButtonProps) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      border={[1, 'solid', applied ? 'black' : 'gray.200']}
      width={46}
      height={30}
      marginRight={-1}
      zIndex={applied ? 'above' : 'base'}
      centered
    >
      <Text fontSize={12} fontWeight={applied ? 'regular' : 'light'}>
        {label}
      </Text>
    </Box>
  )
}
