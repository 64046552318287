import { ProductClassEnum } from '@src/types/product'
import { CartProduct, DeliveryGroup } from '.'
import { CartDto, CartMetadataDto } from '../dto'
import { FinalCartCalculations } from './final-cart-calculations.entity'

export class Cart {
  public readonly cartMetadata: CartMetadataDto
  public readonly deliveryGroupedProducts: DeliveryGroup[]
  public readonly nonDeliveryGroupedProducts: CartProduct[]
  public readonly finalCartCalculations: FinalCartCalculations

  public readonly products: CartProduct[]
  public readonly productClass: ProductClassEnum

  constructor(dto: CartDto) {
    this.cartMetadata = dto.cartMetadata
    this.deliveryGroupedProducts = dto.deliveryGroupedProducts.map(
      (dto) => new DeliveryGroup(dto)
    )
    this.nonDeliveryGroupedProducts = dto.nonDeliveryGroupedProducts.map(
      (dto) => new CartProduct(dto)
    )
    this.finalCartCalculations = new FinalCartCalculations(
      dto.finalCartCalculations
    )

    this.products = this._getProducts()
    this.productClass = this._getProductClass()
  }

  private _getProducts() {
    return [
      ...this.deliveryGroupedProducts.flatMap(
        (deliveryGroup) => deliveryGroup.products
      ),
      ...this.nonDeliveryGroupedProducts,
    ].filter((item) => item.isProductSelected)
  }

  private _getProductClass() {
    return this.products[0]?.productClass || ProductClassEnum.Tangible
  }
}
