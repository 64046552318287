import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { colors, fontWeights } from '@src/styles/theme'
import {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import { Icon } from '../icon'
import { ErrorMessage } from '../text'

type Props = {
  value?: string | number
  type?: InputHTMLAttributes<HTMLInputElement>['type']
  onChange?: (inputValue: string) => void
  onBlur?: (inputValue: string) => void
  placeholder?: string
  parse?: (value: string) => string
  validate?: (value: string) => boolean
  displayFormat?: (value: string) => string
  disabled?: boolean
  readOnly?: boolean
  error?: boolean
  errorMessage?: string
  flex?: number
  maxLength?: number
  isCardStudio?: boolean
}

export const Input = ({
  value,
  type = 'text',
  onChange,
  onBlur,
  placeholder,
  parse,
  validate,
  displayFormat = (value: string) => value,
  disabled,
  readOnly,
  error,
  errorMessage,
  flex,
  maxLength,
  isCardStudio = false,
  ...props
}: Props) => {
  const [inputValue, setInputValue] = useState<string>(value ? `${value}` : '')
  const [isFocused, setIsFocused] = useState<boolean>(false)

  useEffect(() => {
    setInputValue(value ? `${value}` : '')
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newInputValue = parse ? parse(e.target.value) : e.target.value
    if (validate && !validate(newInputValue)) {
      return
    }

    setInputValue(newInputValue)
    onChange && onChange(newInputValue)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(e.target.value)
    setIsFocused(false)
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const resetInput = () => {
    const newInputValue = ''
    setInputValue(newInputValue)
    onChange && onChange(newInputValue)
    onBlur && onBlur(newInputValue)
  }

  return (
    <VStack spacing={6} flex={flex || 1}>
      <InputWrapper
        disabled={disabled}
        error={error || !!errorMessage}
        isCardStudio={isCardStudio}
      >
        <StyledInput
          type={type}
          value={isFocused ? inputValue : displayFormat(inputValue)}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          {...props}
        />
        {inputValue && isFocused && (
          <ResetButton onPointerDown={resetInput} tabIndex={-1} type="button">
            <Icon iconName="cancel" size={24} color="gray.300" />
          </ResetButton>
        )}
      </InputWrapper>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </VStack>
  )
}

const InputWrapper = styled.div<
  Pick<Props, 'flex' | 'disabled' | 'error' | 'isCardStudio'>
>`
  width: 100%;
  border: 1px solid ${colors.gray[200]};
  border-radius: ${({ isCardStudio }) => isCardStudio && parseSize(10)};
  background-color: ${colors.white};
  padding: ${parseSize(8)} ${parseSize(16)};
  padding-right: ${parseSize(12)};

  display: flex;
  align-items: center;
  column-gap: ${parseSize(8)};

  ${({ disabled }) => disabled && disabledStyles}
  ${({ error }) => error && errorStyles}

  :focus-within {
    ${({ isCardStudio, error }) =>
      !isCardStudio ? normalStyles : cardStudioStyles(error)}
  }
`

const normalStyles = css`
  border: 1px solid ${colors.black};
`

const cardStudioStyles = (error: boolean = false) => css`
  border: 1px solid ${error ? colors.error : colors.primary};
`

const disabledStyles = css`
  color: ${colors.gray[500]};
  background-color: ${colors.gray[100]};
`

const errorStyles = css`
  border: 1px solid ${colors.error};
`

const StyledInput = styled.input`
  border: none;
  padding: 0;
  width: 100%;
  flex: 1;

  font-size: ${parseSize(14)};
  line-height: ${parseSize(24)};

  ::placeholder {
    color: ${colors.gray[300]};
  }

  :disabled {
    background-color: transparent;
    pointer-events: none;

    ::placeholder {
      color: transparent;
    }
  }

  :read-only {
    background-color: transparent;
    pointer-events: none;
  }

  :focus {
    outline: none;
  }
`

const ResetButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
`
