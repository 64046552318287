import { usePrevPath } from '@src/hook/use-prev-path'
import { useRouter } from 'next/router'
import { defaultBackActionMap } from '../_constants/default-back-action-map'

export const useBackAction = (onClickBack?: () => void) => {
  const router = useRouter()
  const { prevPath } = usePrevPath()

  const handleClickBack = () => {
    if (onClickBack) {
      onClickBack()
      return
    }

    const defaultBackAction = defaultBackActionMap[router.pathname]
    if (defaultBackAction && prevPath?.pathname !== defaultBackAction.backUrl) {
      router.push(
        defaultBackAction.checkUrlQuery &&
          Object.keys(router.query).length !== 0
          ? router.pathname
          : defaultBackAction.backUrl,
        undefined,
        { scroll: false }
      )

      return
    }

    router.beforePopState((state) => {
      state.options.scroll = false
      return true
    })

    router.back()
  }

  return { handleClickBack }
}
