import styled from '@emotion/styled'
import { Color, Size } from '@src/components/basic/types'
import { Clickable } from '../clickable'
import { Icon } from '../icon'

type Props = {
  expanded: boolean
  onClick?: () => void
  size?: Size
  color?: Color
}

export const ExpandButton = ({
  expanded,
  onClick,
  size = 24,
  color = 'black',
}: Props) => {
  return (
    <Clickable onClick={onClick}>
      <ExpandButtonWrapper isExpanded={expanded}>
        <Icon iconName="expandMore" size={size} color={color} />
      </ExpandButtonWrapper>
    </Clickable>
  )
}

const ExpandButtonWrapper = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  transform: rotate(${({ isExpanded }) => (isExpanded ? '180deg' : 0)});
  transition: transform 0.2s;
`
