import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { FontWeight, Size } from '@src/components/basic/types'
import { parseSize } from '@src/components/basic/utils'
import { LoadingSpinner } from '@src/components/common'
import { fontWeights } from '@src/styles/theme'
import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react'

type ButtonVariant = 'solid' | 'outline'

type ButtonStyleProps = {
  variant?: ButtonVariant
  fontSize?: Size
  fontWeight?: FontWeight
  width?: Size
  height?: Size
  borderRadius?: Size
  clickable?: boolean
}

export type ButtonProps = ButtonStyleProps & {
  label?: React.ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  loading?: boolean
  type?: ButtonHTMLAttributes<HTMLInputElement>['type']
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      variant = 'solid',
      onClick,
      disabled,
      loading,
      type = 'button',
      fontSize = 16,
      fontWeight = 'bold',
      width = '100%',
      height = 48,
      borderRadius = '0',
      ...props
    },
    ref
  ) => {
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      if (loading || disabled) {
        return
      }

      onClick && onClick(e)
    }

    return (
      <StyledButton
        variant={variant}
        onClick={handleClick}
        disabled={disabled}
        type={type}
        ref={ref}
        fontSize={fontSize}
        fontWeight={fontWeight}
        width={width}
        height={height}
        borderRadius={borderRadius}
        clickable={!loading}
        {...props}
      >
        {loading ? (
          <LoadingSpinner
            variant={variant === 'outline' ? 'default' : 'white'}
          />
        ) : (
          label
        )}
      </StyledButton>
    )
  }
)

Button.displayName = 'Button'

const buttonStyles: Record<string, SerializedStyles> = {
  solid: css`
    border: 1px solid #4902a3;
    background-color: #4902a3;
    color: #ffffff;
  `,
  outline: css`
    border: 1px solid #4902a3;
    background-color: #ffffff;
    color: #4902a3;
  `,
  disabled: css`
    border: 1px solid #c2c2c2;
    background-color: #c2c2c2;
    color: #ffffff;
    cursor: not-allowed;
  `,
}

const StyledButton = styled.button<Required<ButtonStyleProps>>`
  flex: 1;
  width: ${({ width }) => parseSize(width)};
  min-height: ${({ height }) => parseSize(height)};
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${({ fontSize }) => parseSize(fontSize)};
  font-weight: ${({ fontWeight }) => fontWeights[fontWeight]};
  letter-spacing: -0.04em;
  border-radius: ${({ borderRadius }) => parseSize(borderRadius)};
  ${(props) => buttonStyles[props.variant]}

  &:disabled {
    ${buttonStyles['disabled']}
  }

  pointer-events: ${({ clickable }) => !clickable && 'none'};
`
