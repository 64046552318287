import {
  ActiveStatusEnum,
  InventoryStatusEnum,
  SkuOptionDto,
} from '@src/types/product'
import { CartProductSkuDto } from '../dto'

export class CartProductSku {
  public readonly id: string
  public readonly activeStatus: ActiveStatusEnum
  public readonly calculatedSkuDeliveryPrice: number
  public readonly calculatedSkuSellPrice: number
  public readonly isSelected: boolean
  public readonly leftInventoryCount: number
  public readonly leftInventoryStatus: InventoryStatusEnum
  public readonly options: SkuOptionDto[]
  public readonly quantity: number
  public readonly sellPrice: number
  public readonly productId: string
  public readonly title: string

  constructor(dto: CartProductSkuDto) {
    this.id = dto.skuId
    this.activeStatus = dto.activeStatus
    this.calculatedSkuDeliveryPrice = dto.calculatedSkuDeliveryPrice
    this.calculatedSkuSellPrice = dto.calculatedSkuSellPrice
    this.isSelected = dto.isSelected
    this.leftInventoryCount = dto.leftInventoryCount
    this.leftInventoryStatus = dto.leftInventoryStatus
    this.options = dto.options
    this.quantity = dto.quantity
    this.sellPrice = dto.sellPrice
    this.productId = dto.sellableProductId
    this.title = dto.title
  }
}
