import { api } from '@api/index'
import { MutationOptions } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { CreateReviewReportRequestDto } from '@src/types/review'
import { useMutation } from '@tanstack/react-query'

type Options = MutationOptions<void, CreateReviewReportRequestDto>

export const useCreateReviewReportMutation = (options: Options = {}) => {
  return useMutation((dto: CreateReviewReportRequestDto) => {
    return createReviewReport(dto)
  }, options)
}

const createReviewReport = ({
  reviewId,
  ...dto
}: CreateReviewReportRequestDto) => {
  return api.reviewClient.post(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/reviews/${reviewId}/reports`,
    dto
  )
}
