import { routes } from '@src/common/constants/routes'
import { TopNavbarProps } from '../top-navbar'

type PathName = string

export const topNavBarPropsMap: Record<PathName, TopNavbarProps> = {
  [routes.base.home]: {
    isLogoActive: true,
    hasBack: false,
    hasNotification: true,
  },
  [routes.cardShop.home]: {
    title: '기프트샵',
    hasBack: false,
    hasNotification: true,
  },
  [routes.info.notice]: {
    title: '공지사항',
  },
  [routes.info.privacyPolicy]: {
    title: '개인정보처리방침',
    hasBack: false,
    hasClose: true,
  },
  [routes.info.termsOfService]: {
    title: '서비스 이용약관',
    hasBack: false,
    hasClose: true,
  },
  [routes.info.uzzimMoney]: {
    title: 'Uzzim 머니 약관',
    hasBack: false,
    hasClose: true,
  },
  [routes.info.faq]: {
    title: 'FAQ',
  },
  [routes.info.inquiry]: {
    title: '1:1문의',
  },
  [routes.info.inquiryCreate]: {
    title: '1:1문의 작성',
  },
  [routes.info.inquiryEdit]: {
    title: '1:1문의 수정',
  },
  [routes.shopping.home]: {
    hasCustomNavbar: true,
  },
  [routes.shopping.productDetail]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.shopping.cart]: {
    title: '장바구니',
  },
  [routes.shopping.category]: {
    title: '카테고리',
  },
  [routes.shopping.categoryResult]: {
    title: '카테고리',
  },
  [routes.shopping.search]: {
    title: '검색',
  },
  [routes.shopping.search]: {
    title: '검색',
  },
  [routes.shopping.cartOrder]: {
    title: '주문하기',
  },
  [routes.shopping.directOrder]: {
    title: '주문하기',
  },
  [routes.shopping.orderSuccess]: {
    title: '주문완료',
    hasBack: false,
  },
  [routes.shopping.orderFail]: {
    title: '주문실패',
    hasBack: false,
  },
  [routes.my.home]: {
    title: '마이페이지',
    hasBack: false,
    hasNotification: true,
  },
  [routes.my.login]: {
    title: 'Login',
  },
  [routes.my.signUp]: {
    title: 'Sign Up',
  },
  [routes.my.signUpFail]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.my.settings]: {
    title: '내 정보',
  },
  [routes.my.cards]: {
    title: '내 기프트박스',
  },
  [routes.my.cardDeleteSuccess]: {
    title: 'Uzzim',
  },
  [routes.my.giftReceivedHistory]: {
    title: '선물 내역',
  },
  [routes.my.giftSentHistory]: {
    title: '선물 내역',
  },
  [routes.my.order.history]: {
    title: '주문내역',
  },
  [routes.my.order.claims]: {
    title: '취소/교환/반품 내역',
  },
  [routes.my.order.cancelRequest]: {
    title: '주문취소',
  },
  [routes.my.order.cancelRequestSuccess]: {
    title: '주문취소',
    hasBack: false,
  },
  [routes.my.order.exChangeRequest]: {
    title: '교환요청',
  },
  [routes.my.order.refundRequest]: {
    title: '반품요청',
  },
  [routes.my.address]: {
    title: '배송지 관리',
  },
  [routes.my.wishlist]: {
    title: '찜한 상품',
  },
  [routes.my.reviews]: {
    title: '리뷰 내역',
  },
  [routes.my.productInquiries]: {
    title: '상품문의 내역',
  },
  [routes.my.notification]: {
    title: '알림',
  },
  [routes.my.notificationSettings]: {
    title: '알림설정',
  },
  [routes.my.uzzimMoneyHistory]: {
    title: '유찜 이용내역',
  },
  [routes.my.uzzimMoneyWithdraw]: {
    title: '인출하기',
  },
  [routes.my.uzzimMoneyWithdrawSuccess]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.my.uzzimMoneyWithdrawFail]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.my.settingsEditMyInfo]: {
    title: '개인정보 수정',
  },
  [routes.my.withdrawUser]: {
    title: '회원탈퇴 요청',
  },
  [routes.my.withdrawUserSuccess]: {
    hasBack: false,
    isLogoActive: true,
  },
  [routes.my.withdrawUserFail]: {
    hasCustomNavbar: true,
  },
  [routes.my.verification]: {
    hasCustomNavbar: true,
  },
  [routes.my.verificationProcess]: {
    hasCustomNavbar: true,
  },
  [routes.my.verificationFail]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.my.verificationMismatchPhoneNumber]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.my.uzzimMoneyCharge]: {
    title: '유찜머니 충전하기',
  },
  [routes.my.uzzimMoneyChargeSuccess]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.my.uzzimMoneyChargeFail]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.my.openBanking]: {
    title: '연결 계좌 관리',
  },
  [routes.my.openBankingAddAccountProcess]: {
    hasCustomNavbar: true,
  },
  [routes.card.chargeCard]: {
    title: '충전하기',
  },
  [routes.card.chargeCardSuccess]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.card.chargeCardFail]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.card.sendGift]: {
    hasCustomNavbar: true,
  },
  [routes.card.sendGiftBasic]: {
    hasCustomNavbar: true,
  },
  [routes.card.sendGiftSuccess]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.card.sendGiftFail]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.card.exchangeCard]: {
    title: '유찜머니로 전환하기',
  },
  [routes.card.exchangeCardSuccess]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.card.exchangeCardFail]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.card.receiveGift]: {
    isLogoActive: true,
    hasBack: false,
    hasClose: true,
  },
  [routes.card.alreadyReceived]: {
    isLogoActive: true,
    hasBack: false,
  },
  [routes.card.history]: {
    hasCustomNavbar: true,
  },
  [routes.card.refundCardHistory]: {
    title: '환불카드',
  },
  [routes.card.refundHistory]: {
    title: '환불카드',
  },
  [routes.shopping.custom]: {
    hasCustomNavbar: true,
  },
  [routes.studio.uzzimStudio.home]: {
    hasCustomNavbar: true,
  },
  [routes.studio.uzzimStudio.new]: {
    title: '기프트 스튜디오',
  },
  [routes.studio.uzzimStudio.image]: {
    hasCustomNavbar: true,
  },
  [routes.studio.uzzimStudio.publishSuccess]: {
    title: 'UZZIM',
    hasBack: false,
  },
  [routes.voucher.home]: {
    title: '모바일상품권 내역',
    hasBack: false,
    hasNotification: true,
  },
  [routes.error.blocked]: {
    hasCustomNavbar: true,
  },
}
