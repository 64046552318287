import { GenderEnum } from '@src/types/review'
import { RegistrationPathEnum, UserProfileDto, UzzimGenderEnum } from '../dto'

export class UserProfile {
  public readonly id: string
  public readonly name: string
  public readonly phone: string
  public readonly birthday: string
  public readonly gender: GenderEnum
  public readonly path: RegistrationPathEnum
  public readonly email: string
  public readonly profileImage: string
  public readonly ci: string
  public readonly ciChecked: boolean
  public readonly harmonyId: string

  constructor(dto: UserProfileDto) {
    this.id = dto.id
    this.name = dto.name
    this.phone = dto.phone
    this.birthday = dto.birthday
    this.gender = this._getGender(dto.gender)
    this.path = dto.path
    this.email = dto.email
    this.profileImage = dto.profileImage
    this.ci = dto.ci
    this.ciChecked = dto.ciChecked
    this.harmonyId = dto.harmonyId
  }

  private _getGender(gender: UzzimGenderEnum) {
    if (gender === UzzimGenderEnum.Male) {
      return GenderEnum.Male
    }

    if (gender === UzzimGenderEnum.Female) {
      return GenderEnum.Female
    }

    return GenderEnum.None
  }
}
