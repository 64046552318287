import { ProductsOrderingEnum } from '@src/types/product'
import {
  ProductPageRecListDisplayTypeEnum,
  ProductPageSettingDto,
} from '../dto'

export class ProductPageSettings {
  public readonly channelId: string
  public readonly bannerImages: string[]
  public readonly recListTitle: string
  public readonly recListDisplayType: ProductPageRecListDisplayTypeEnum
  public readonly recListDisplaySortOrder: ProductsOrderingEnum
  public readonly recProductCount: number

  public readonly isDisplayRecommendedListByPartner: boolean
  public readonly isDisplayRecommendedListByCategory: boolean

  constructor(dto: ProductPageSettingDto) {
    this.channelId = dto.channelId
    this.bannerImages = dto.bannerImages
    this.recListTitle = dto.recListTitle
    this.recListDisplayType = dto.recListDisplayType
    this.recListDisplaySortOrder = dto.recListDisplaySortOrder
    this.recProductCount = dto.recProductCount

    this.isDisplayRecommendedListByPartner =
      this._getIsDisplayRecommendedListByPartner()
    this.isDisplayRecommendedListByCategory =
      this._getIsDisplayRecommendedListByCategory()
  }

  private _getIsDisplayRecommendedListByPartner = () => {
    return this.recListDisplayType === ProductPageRecListDisplayTypeEnum.Partner
  }

  private _getIsDisplayRecommendedListByCategory = () => {
    return (
      this.recListDisplayType === ProductPageRecListDisplayTypeEnum.Category
    )
  }
}
