import { queryKeys } from '@api/constants/query-keys'
import { api } from '@api/index'
import { ApiListResult2 } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { useCustomInfiniteQueryResult } from '@src/hook/use-custom-infinite-query-result'
import { GetProductReviewRequestDto, ReviewDto } from '@src/types/review'
import { useInfiniteQuery } from '@tanstack/react-query'
import snakecaseKeys from 'snakecase-keys'
import { getNextPageParam } from '../_utils/get-next-page-param'

type Params = Omit<GetProductReviewRequestDto, 'age' | 'score'> & {
  ages: string[]
  scores: string[]
}

export const useProductReviewsInfiniteQuery = (params: Params) => {
  const {
    productId,
    sellableSkuId,
    ordering,
    ages,
    scores,
    gender,
    itemsPerPage = 5,
  } = params

  const query = useInfiniteQuery(
    [queryKeys.product.productReviews, params],
    ({ pageParam = 1 }) =>
      getProductReviews({
        productId,
        sellableSkuId,
        ordering,
        age: ages.length > 0 ? ages.join(',') : undefined,
        score: scores.length > 0 ? scores.join(',') : undefined,
        gender: gender ? gender : undefined,
        page: pageParam,
        itemsPerPage,
      }),
    {
      keepPreviousData: true,
      enabled: !!params.productId,
      getNextPageParam: (lastPage) => getNextPageParam(lastPage),
    }
  )

  return useCustomInfiniteQueryResult(query)
}

const getProductReviews = ({
  productId,
  ...params
}: GetProductReviewRequestDto) => {
  return api.reviewClient.get<ApiListResult2<ReviewDto>>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/products/${productId}/reviews`,
    {
      params: snakecaseKeys(params),
    }
  )
}
