import styled from '@emotion/styled'
import { ModalProps } from '@src/hook/use-modal'
import { Modal } from '../overlay'

type Props = ModalProps & {
  buttons: {
    label: string
    onClick: () => void
    disabled?: boolean
  }[]
}

export const ButtonGroupModal = ({ isOpen, onClose, buttons }: Props) => {
  return (
    <Modal
      isOpen={!!isOpen}
      onClose={onClose}
      width="70%"
      borderRadius={10}
      paddingY={8}
    >
      {buttons.map((button, index) => (
        <Button
          key={index}
          onClick={() => {
            button.onClick()
            onClose()
          }}
          disabled={button.disabled}
        >
          {button.label}
        </Button>
      ))}
    </Modal>
  )
}

const Button = styled.button`
  display: flex;
  padding: 0.5rem 1.5rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;

  :disabled {
    opacity: 0.4;
  }

  &:not(:first-of-type) {
    border-top: 1px solid #f5f5f5;
  }
`
