import { BasicAddressDto } from '@src/types/address'
import { DeliveryGroupInfoDto } from '@src/types/cart'

export type DeliveryInfoDto = {
  isInstallationFee: boolean
  isBaseReleaseAddress: boolean
  releaseAddress: BasicAddressDto
  deliveryMethod: DeliveryMethodEnum
  deliveryPricingMethod: DeliveryPricingMethodEnum
  deliveryPricingUnit: DeliveryPricingUnitEnum
  baseDeliveryFee: number
  unitChargeStandard: number
  conditionalChargeStandard: number
  isGroupDelivery: boolean
  deliveryGroupId?: string
  deliveryGroup?: DeliveryGroupInfoDto
  isAdditionalPricingSet: boolean
  regionDivisionLevel: number
  division2Fee: number
  division3JejuFee: number
  division3OutsideJejuFee: number
  refundAddress: BasicAddressDto
  refundDeliveryFee: number
  refundDeliveryFeeIfFreeDelivery: number
  exchangeDeliveryFee: number
  refundExchangeDeliveryPolicy: string
  internationalShipmentPolicy: string
  defaultCarrier: CarrierDto
  defaultCarrierId: string
  refundCarrier: CarrierDto
  refundCarrierId: string
}

export enum DeliveryMethodEnum {
  ParcelDeliver = 'parcel_delivery',
  DirectDelivery = 'direct_delivery',
}

export enum DeliveryPricingMethodEnum {
  ConditionalCharge = 'conditional_charge',
  UnitCharge = 'unit_charge',
  RegularCharge = 'regular_charge',
  Free = 'free',
}

export enum DeliveryPricingUnitEnum {
  Product = 'product',
  Sku = 'sku',
}

export type CarrierDto = {
  id: string
  name: string
  code: number
  isLocal: boolean
}
