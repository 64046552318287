import styled from '@emotion/styled'
import { VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { ExpandButton } from '@src/components/common'
import { maxScreenWidth, zIndices } from '@src/styles/theme'
import { Product } from '@src/types/product'
import React, { useEffect, useState } from 'react'
import { DirectOrderCalculations } from './direct-order-calculations'
import { MultiOption } from './multi-option'
import { SingleOption } from './single-option'

type OptionDrawerProps = {
  isOpen: boolean
  toggleIsOpen: () => void
  productDetail: Product
  disabled?: boolean
}

export const OptionDrawer = ({
  isOpen,
  toggleIsOpen,
  productDetail,
  disabled,
}: OptionDrawerProps) => {
  const [overflow, setOverflow] =
    useState<React.CSSProperties['overflow']>('hidden')

  const handleTransactionEnd = () => {
    if (isOpen) {
      setOverflow('visible')
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setOverflow('hidden')
    }
  }, [isOpen])

  return (
    <Wrapper overflow={overflow}>
      <Container isOpen={isOpen} onTransitionEnd={handleTransactionEnd}>
        <Trigger onClick={toggleIsOpen} disabled={disabled}>
          <TriggerIcon>
            <ExpandButton expanded={!isOpen} />
          </TriggerIcon>
        </Trigger>

        <VStack paddingTop={24} paddingX={16}>
          <VStack spacing={8}>
            <ProductTitle>{productDetail.title}</ProductTitle>
            {productDetail.skuList.length <= 1 ? (
              <SingleOption productDetail={productDetail} />
            ) : (
              <MultiOption productDetail={productDetail} />
            )}
          </VStack>
          <DirectOrderCalculations productDetail={productDetail} />
        </VStack>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ overflow: React.CSSProperties['overflow'] }>`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: ${parseSize(72)};
  overflow: ${({ overflow }) => overflow};
  z-index: ${zIndices.above};
  pointer-events: none;
`

const Container = styled.div<Pick<OptionDrawerProps, 'isOpen'>>`
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: ${parseSize(maxScreenWidth)};
  min-height: 1.875rem;
  background-color: #f2f2f2;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.15);
  transform: ${({ isOpen }) =>
    isOpen ? 'translateY(0)' : `translateY(calc(100% - ${parseSize(21)}))`};
  transition: transform 0.2s;
  pointer-events: auto;
`

const Trigger = styled.button<Pick<OptionDrawerProps, 'disabled'>>`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(0px -1px 0.5px rgba(0, 0, 0, 0.15));

  width: 90px;
  height: 0;
  border-bottom: 21px solid #f2f2f2;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

const TriggerIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`

const ProductTitle = styled.div`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.04em;
`
