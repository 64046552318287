export type CustomPageDto = {
  pageType: PageTypeEnum
  status: PageStatusEnum
  startDt: string
  endDt: string
  items: PageItemDto[]
  linkedComponent: string
  isInPage: boolean
}

export type PageItemDto = {
  pageType: PageTypeEnum
  title: string
  subTitle: string
  index: number
  image: string
  imageRatio?: [number, number]
  status: PageStatusEnum
  productList: string[]
  startDt?: string
  endDt?: string
  categories?: PageCategoryDto[]
  eventDetailPagePath?: string
  contents?: string
}

export enum PageTypeEnum {
  TxtProduct = 'txt_product',
  ImgTxtProduct = 'img_txt_product',
  ImgProduct = 'img_product',
  EventList = 'event_list',
  EventListWTab = 'event_list_w_tab',
  EventDetail = 'event_detail',
  CategoryRank = 'category_rank',
  SingleCountdown = 'single_countdown',
  MultiCountdown = 'multi_countdown',
  MultiPromotion = 'multi_promotion',
}

type PageCategoryDto = {
  categoryId: string
  categoryIndex: number
  categoryName: string
  isActive: boolean
  productList: string[]
}

enum PageStatusEnum {
  Pending = 'pending',
  Always = 'always',
  Active = 'active',
  Exp = 'exp',
}
