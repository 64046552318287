import { StorageKey } from '@src/common/constants/storage-keys'

type StorageType = 'local' | 'session'

class StorageService {
  private _storageType: StorageType
  private get _storage() {
    return this._storageType === 'local' ? localStorage : sessionStorage
  }

  constructor(storageType: StorageType) {
    this._storageType = storageType
  }

  set(key: StorageKey, value: unknown) {
    const storageItem =
      typeof value === 'object' ? JSON.stringify(value) : `${value}`
    this._storage.setItem(key, storageItem)
  }

  get<T = unknown>(key: StorageKey): T {
    const storageItem = this._storage.getItem(key)

    const storageData =
      storageItem?.startsWith('{') ||
      storageItem?.startsWith('[') ||
      storageItem === 'null' ||
      !isNaN(Number(storageItem))
        ? JSON.parse(`${storageItem}`)
        : storageItem

    return storageData
  }

  pop<T = unknown>(key: StorageKey): T {
    const storageData = this.get<T>(key)
    this._storage.removeItem(key)
    return storageData
  }

  remove(key: StorageKey) {
    this._storage.removeItem(key)
  }
}

export const localStorageService = new StorageService('local')
export const sessionStorageService = new StorageService('session')
