import { ImageUrlsDto } from '@src/types/image'
import { CancelSkuRequestDto } from '.'
import { SkuStatusEnum, UserNotesDto } from '..'

export type UpdateSkuRequestDto = CancelSkuRequestDto & {
  skuId: string
  updateContext: UpdateSkuContextEnum
  userNotes?: UserNotesDto
  imageUrls?: ImageUrlsDto
}

export enum UpdateSkuContextEnum {
  Refund = 'refund_requested',
  Exchange = 'exchange_requested',
  OrderFinish = 'order_finished',
}
