import styled from '@emotion/styled'
import { Box, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { CustomImage } from '@src/components/common'
import { Clickable } from '@src/components/common/clickable'
import { useProductDetailDrawer } from '@src/contexts/product-detail-drawer-context'
import { colors } from '@src/styles/theme'
import { BaseProduct, InventoryStatusEnum } from '@src/types/product'
import { Price } from '../../common/price'
import { ProductTitle } from '../../common/product-title'
import { SoldOutOverlay } from '../../common/sold-out-overlay'
import { TextBadge } from '../../common/text-badge'
import { TimeSaleChip } from '../../common/time-sale-chip'
import { WishlistButton } from '../../common/wishlist-button'

type Props = {
  product: BaseProduct
}

export const SwipeProductItem = ({ product }: Props) => {
  const { openProductDetail } = useProductDetailDrawer()
  const {
    id,
    images,
    wishlistId,
    title,
    brand,
    marketPrice,
    minSellPrice,
    timesaleAppliedNow,
    inventoryStatus,
  } = product

  return (
    <Clickable
      onClick={() => openProductDetail(id)}
      flex={1}
      width="100%"
      maxWidth={130}
      minWidth={130}
    >
      <VStack>
        <ImageWrapper>
          <CustomImage
            src={images[0] || require('@src/assets/shopping/empty_130.png')}
            alt={title}
            layout="responsive"
            width={1}
            height={1}
          />
          <Box position="absolute" top={4} right={4}>
            <WishlistButton productId={id} wishItemId={wishlistId} />
          </Box>
          {timesaleAppliedNow && <TimeSaleChip top={8} left={8} />}
          {inventoryStatus === InventoryStatusEnum.SoldOut && (
            <SoldOutOverlay />
          )}
        </ImageWrapper>

        <VStack spacing={6} minHeight={100} width="100%" paddingY={4}>
          <ProductTitle title={title} brand={brand} titleLineCount={2} />
          <Price marketPrice={marketPrice} sellPrice={minSellPrice} />
          <TextBadge direction="column" />
        </VStack>
      </VStack>
    </Clickable>
  )
}

const ImageWrapper = styled.div`
  position: relative;
  border: ${parseSize(1)} solid ${colors.gray[100]};
`
