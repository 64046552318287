import IMAGE_DOMAINS from '@src/image-domains.config'

export const isKnownImageDomain = (url: string) => {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return false
  }

  const { host } = new URL(url)

  return IMAGE_DOMAINS.includes(host)
}
