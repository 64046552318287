import { useCallback, useState } from 'react'

export const useToggle = (
  initialValue = false,
  callback?: (isTrue: boolean) => void
): [boolean, () => void] => {
  const [isTrue, setIsTrue] = useState<boolean>(initialValue)

  const toggleIsTrue = useCallback(() => {
    setIsTrue((prev) => {
      callback && callback(!prev)
      return !prev
    })
  }, [callback])

  return [isTrue, toggleIsTrue]
}
