import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { ProductInquiry, ProductInquiryDto } from '@src/types/product-inquiry'
import { useQuery } from '@tanstack/react-query'

export const useProductInquiryQuery = (inquiryId = '') => {
  return useQuery(
    [queryKeys.product.productInquiry, inquiryId],
    () =>
      getProductInquiry({
        inquiryId,
      }),
    {
      enabled: !!inquiryId,
      staleTime: staleTime.short,
      select: (dto) => new ProductInquiry(dto),
    }
  )
}

const getProductInquiry = ({ inquiryId }: { inquiryId: string }) => {
  return api.productClient.get<ProductInquiryDto>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/inquiries/${inquiryId}`
  )
}
