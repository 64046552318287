import { useModal } from '@src/hook/use-modal'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { Popup, PopupProps } from './popup'

type ProviderProps = {
  children?: React.ReactNode
}

type ContextValue = {
  showPopup: (props: PopupProps) => void
}

const PopupContext = createContext<ContextValue | null>(null)

export const PopupProvider = ({ children }: ProviderProps) => {
  const [isOpen, open, close] = useModal()
  const [popupProps, setPopupProps] = useState<PopupProps | null>(null)

  const showPopup = useCallback(
    (popupProps: PopupProps) => {
      setPopupProps(popupProps)
      open()
    },
    [open]
  )

  const contextValue = useMemo(() => ({ showPopup }), [showPopup])

  return (
    <PopupContext.Provider value={contextValue}>
      {children}
      <Popup isOpen={isOpen} onClose={close} zIndex="modal" {...popupProps} />
    </PopupContext.Provider>
  )
}

export const usePopup = () => {
  const contextValue = useContext(PopupContext)
  if (!contextValue) {
    throw Error('Cannot found PopupProvider!')
  }

  const { showPopup } = contextValue

  return showPopup
}
