import { environment } from '@src/config'
import {
  BasicSettingDto,
  ChannelStatusEnum,
  CustomerServiceInfoDto,
  PartnerDisplayScopeDto,
  PlatformTypeEnum,
  SalesSettingTypeEnum,
  ThemeDesignSettingDto,
} from '..'

export class BasicSettings {
  private static _id = environment.channelId

  public readonly channelName: string
  public readonly channelStatus: ChannelStatusEnum
  public readonly channelType: string
  public readonly country: string
  public readonly createDt: string
  public readonly updateDt: string
  public readonly platformType: PlatformTypeEnum
  public readonly salesSettingType: SalesSettingTypeEnum
  public readonly description: string
  public readonly isUsingCustomDomain: boolean
  public readonly domain: string
  public readonly logoImage: string
  public readonly customerServiceInfo: CustomerServiceInfoDto
  public readonly partnerDisplayScope: PartnerDisplayScopeDto
  public readonly themeDesignSetting: ThemeDesignSettingDto

  constructor(dto: BasicSettingDto) {
    BasicSettings._id = dto.id

    this.channelName = dto.channelName
    this.channelStatus = dto.channelStatus
    this.channelType = dto.channelType
    this.country = dto.country
    this.createDt = dto.createDt
    this.updateDt = dto.updateDt
    this.platformType = dto.platformType
    this.salesSettingType = dto.salesSettingType
    this.description = dto.description
    this.isUsingCustomDomain = dto.isUsingCustomDomain
    this.domain = dto.domain
    this.logoImage = dto.logoImage
    this.customerServiceInfo = dto.customerServiceInfo
    this.partnerDisplayScope = dto.partnerDisplayScope
    this.themeDesignSetting = dto.themeDesignSetting
  }

  static getChannelId() {
    return this._id
  }
}
