import { ExtensionCodeEnum, ExtensionInstallStatusDto } from '../dto'
import ExtensionInstallStatus from './extension-install-status.entity'

class ExtensionSettings {
  public readonly installStatusMap: Record<
    ExtensionCodeEnum,
    ExtensionInstallStatus
  >
  public readonly invalidPages: string[]

  constructor(installStatusDtoList: ExtensionInstallStatusDto[]) {
    this.installStatusMap = this._getInstallStatusMap(installStatusDtoList)
    this.invalidPages = this._getInvalidPages()
  }

  private _getInstallStatusMap(
    installStatusDtoList: ExtensionInstallStatusDto[]
  ) {
    const entries = installStatusDtoList.map<
      [ExtensionCodeEnum, ExtensionInstallStatus]
    >((dto) => {
      return [dto.extensionCode, new ExtensionInstallStatus(dto)]
    })
    return Object.fromEntries(entries) as Record<
      ExtensionCodeEnum,
      ExtensionInstallStatus
    >
  }

  private _getInvalidPages() {
    let invalidPages: string[] = []
    Object.values(this.installStatusMap).forEach(
      ({ isInstalled, relatedPages }) => {
        if (!isInstalled) {
          invalidPages = invalidPages.concat(relatedPages)
        }
      }
    )
    return invalidPages
  }

  get isWishlistInstalled() {
    return !!this.installStatusMap[ExtensionCodeEnum.Wishlist]?.isInstalled
  }

  get isReviewInstalled() {
    return !!this.installStatusMap[ExtensionCodeEnum.Review]?.isInstalled
  }

  isInvalidPage(route: string) {
    return this.invalidPages.includes(route)
  }
}

export default ExtensionSettings
