import styled from '@emotion/styled'
import { VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { ProductSkuDto } from '@src/types/product'
import { useSkuCounts } from '../../../_contexts/sku-counts-context'
import { SelectedSkuItem } from './selected-sku-item'

type Props = {
  skus: ProductSkuDto[]
  disabled?: boolean
}

export const SelectedSkuList = ({ skus, disabled }: Props) => {
  const { skuCounts } = useSkuCounts()
  if (Object.keys(skuCounts).length === 0) {
    return null
  }

  return (
    <Container>
      <VStack spacing={8}>
        {skus.map(
          (sku) =>
            skuCounts[sku.id] && (
              <SelectedSkuItem
                key={sku.id}
                sku={sku}
                skuCount={skuCounts[sku.id]}
                disabled={disabled}
              />
            )
        )}
      </VStack>
    </Container>
  )
}

const Container = styled.div`
  height: fit-content;
  max-height: calc(100vh - ${parseSize(350)});
  overflow: scroll;
`

export default SelectedSkuList
