import { BaseProduct } from '@src/types/product'
import { HStack } from '@src/components/basic'
import styled from '@emotion/styled'
import { SwipeProductItem } from './swipe-product-item'
import { EmptyProductPlaceholder } from '../../common/empty-product-placeholder'

type Props = {
  productList?: BaseProduct[]
}

export const SwipeProductList = ({ productList }: Props) => {
  if (productList?.length === 0) {
    return <EmptyProductPlaceholder />
  }

  return (
    <Container spacing={8}>
      {productList?.map((product, index) => (
        <SwipeProductItem key={index} product={product} />
      ))}
    </Container>
  )
}

const Container = styled(HStack)`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 1rem;
`
