import { HStack, Text } from '@src/components/basic'
import { Icon } from '@src/components/common'

type Props = {
  onClick?: () => void
}

export const FilterButton = ({ onClick }: Props) => {
  return (
    <HStack onClick={onClick} as="button" spacing={2} alignItems="center">
      <Text
        fontSize={12}
        fontColor="gray.700"
        lineHeight={1.5}
        letterSpacing="wider"
        fontWeight="light"
      >
        상세필터
      </Text>
      <Icon iconName="filter" size={14} color="black" />
    </HStack>
  )
}
