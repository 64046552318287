import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettingDto, BasicSettings } from '@src/types/channel'
import { useQuery } from '@tanstack/react-query'

export const useBasicSettingsQuery = () => {
  return useQuery([queryKeys.channel.basicSetting], () => getBasicSetting(), {
    staleTime: staleTime.infinity,
    select: (data) => new BasicSettings(data),
  })
}

const getBasicSetting = () => {
  return api.channelClient.get<BasicSettingDto>(
    `${environment.harmonyEndpoint}/basic-setting`,
    {
      params: {
        domain: environment.uzzimDomain,
      },
    }
  )
}
