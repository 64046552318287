export type UserProfileDto = {
  id: string
  name: string
  phone: string
  birthday: string
  gender: UzzimGenderEnum
  path: RegistrationPathEnum
  email: string
  profileImage: string
  ci: string
  ciChecked: boolean
  harmonyId: string
}

export enum UzzimGenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum RegistrationPathEnum {
  Naver = 'NAVER',
  Kakao = 'KAKAO',
  Google = 'GOOGLE',
}
