import { BaseParams } from '@api/types'
import { StatusInEnum } from '@src/components/shopping/order/order-history/voucher-counts'
import { ProductClassEnum } from '@src/types/product'

export type GetSkuRequestDto = BaseParams<SkuOrdering> & {
  statusIn: StatusInEnum
  monthRange?: number
  productClass?: ProductClassEnum
}

export enum SkuOrdering {
  ExpirationDateAsc = 'expiration_date',
  ExpirationDateDesc = '-expiration_date',
  IssuedDateAsc = 'issued_date',
  IssuedDateDesc = '-issued_date',
}
