import { api } from '@api/index'
import { MutationOptions } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { WishlistItemDto } from '@src/types/product'
import { useMutation } from '@tanstack/react-query'

type Options = MutationOptions<WishlistItemDto, string>

export const useAddWishItemMutation = (productId: string, options: Options) => {
  return useMutation(() => {
    return addWishItem(productId)
  }, options)
}

const addWishItem = (productId: string) => {
  return api.productClient.post<WishlistItemDto>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/wishlist/${productId}`
  )
}
