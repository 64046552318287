import { Review } from '@src/types/review'
import { useMemo, useState } from 'react'

export const useSelectReview = (
  reviews?: Review[]
): [Review | undefined, (selected: string | null) => void, () => void] => {
  const [reviewId, setReviewId] = useState<string | null>(null)

  const resetReviewId = () => {
    setReviewId(null)
  }

  const selectedReview = useMemo(
    () => reviews?.find((reivew) => reivew.id === reviewId),
    [reviewId, reviews]
  )

  return [selectedReview, setReviewId, resetReviewId]
}
