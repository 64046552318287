import { ProductClassEnum } from '@src/types/product'
import { subDays } from 'date-fns'
import { PublisherMethodEnum, SkuStatusEnum, TransactionSkuDto } from '..'

export class TransactionSku {
  private static _cancelablePeriod = 7

  public readonly id: string
  public readonly title: string
  public readonly sellableProductId: string
  public readonly productTitle: string
  public readonly image: string
  public readonly status: SkuStatusEnum
  public readonly quantity: number
  public readonly sellPrice: number
  public readonly totalAmount: number
  public readonly pointAmount: number
  public readonly skuProductType?: ProductClassEnum.GiftCard
  public readonly skuProductClass: ProductClassEnum

  // 이하 상품권용 필드
  public readonly pinNumber?: string
  public readonly expirationDate?: string
  public readonly publisherName?: string
  public readonly publisherMethod?: PublisherMethodEnum
  public readonly giftcardCurrentAmount: number // 금액권이 아니면 -1
  public readonly giftcardOriginalAmount: number // 금액권이 아니면 -1

  public readonly isShowBarcode: boolean
  public readonly isCancellable: boolean
  public readonly isExpired: boolean
  public readonly hasAmount: boolean

  constructor(dto: TransactionSkuDto, paidDate: string) {
    this.id = dto.id
    this.title = dto.title
    this.sellableProductId = dto.sellableProductId
    this.productTitle = dto.productTitle
    this.image = dto.image
    this.status = dto.status
    this.quantity = dto.quantity
    this.sellPrice = dto.sellPrice
    this.totalAmount = dto.totalAmount
    this.pointAmount = dto.pointAmount
    this.skuProductType = dto.skuProductType
    this.skuProductClass = dto.skuProductClass
    this.pinNumber = dto.pinNumber
    this.expirationDate = dto.expirationDate
    this.publisherName = dto.publisherName
    this.publisherMethod = dto.publisherMethod
    this.giftcardCurrentAmount = dto.giftcardCurrentAmount
    this.giftcardOriginalAmount = dto.giftcardOriginalAmount

    this.isShowBarcode = this._getIsShowBarcode()
    this.isCancellable = this._getIsCancellable(paidDate)
    this.isExpired = this._getIsExpired()
    this.hasAmount = this._getHasAmount()
  }

  private _getIsShowBarcode() {
    return (
      this.publisherMethod === PublisherMethodEnum.Api &&
      [
        SkuStatusEnum.Received,
        SkuStatusEnum.Used,
        SkuStatusEnum.UsedPartial,
        SkuStatusEnum.UsedConfirmed,
      ].includes(this.status)
    )
  }

  private _getIsCancellable(paidDate: string) {
    return (
      subDays(new Date(), TransactionSku._cancelablePeriod) <
        new Date(paidDate) &&
      [SkuStatusEnum.Received, SkuStatusEnum.ManuallyIssueRequired].includes(
        this.status
      )
    )
  }

  private _getIsExpired() {
    return [SkuStatusEnum.Expired, SkuStatusEnum.ExpiredConfirmed].includes(
      this.status
    )
  }

  private _getHasAmount() {
    return this.giftcardCurrentAmount !== -1
  }
}
