import { aws } from '@src/config'
import Head from 'next/head'

export const PWA = () => {
  return (
    <Head>
      <link rel="manifest" href="/manifest.json" />
    </Head>
  )
}
