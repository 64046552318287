import {
  CheckPasscodeRequestDto,
  CheckPasscodeResultDto,
  CreateNiceParamsRequestDto,
  GetNiceOtpRequestDto,
  NiceModuleParamsDto,
  NiceOtpDto,
  PostNiceCiRequestDto,
  RefreshTokenDto,
  RegistrationPathEnum,
  RereportRequestDto,
  SetupPasscodeRequestDto,
  UserPasscodeStatusDto,
  UserProfileDto,
  UserTokenDto,
} from '@src/types/auth'
import { AxiosResponse } from 'axios'
import { api } from '.'
import { ApiService } from './api.service'
import { UzzimError } from './types'

class AuthClient {
  static async oauthLogin({
    registrationAt,
    code,
  }: {
    registrationAt: RegistrationPathEnum
    code: string
  }) {
    const response = await api.uzzimClient.instance.post<
      UserTokenDto,
      AxiosResponse<UserTokenDto, UzzimError>
    >(`/api/auth/oauth2/${registrationAt}/?code=${code}`)
    ApiService.setAccessToken(response.data.token)
    ApiService.setRefreshToken(response.data.refreshToken)

    return response.data
  }

  static async signTerms(eventAgreed: boolean) {
    return api.uzzimClient.post('/api/terms/sign-terms', { eventAgreed })
  }

  static async testLogin() {
    const response = await api.uzzimClient.get<UserTokenDto>(
      '/api/dev/test-user-token'
    )
    ApiService.setAccessToken(response.token)
    ApiService.setRefreshToken(response.refreshToken)
  }

  static async refreshAccessToken({ refreshToken }: { refreshToken?: string }) {
    const response = await api.uzzimClient.post<RefreshTokenDto>(
      '/api/auth/refresh-token',
      { refreshToken }
    )

    ApiService.setAccessToken(response.token)
  }

  /**
   * 로그아웃하기
   */
  static async logout() {
    api.uzzimClient.get('/api/auth/sign-out')
    ApiService.deleteTokens()
  }

  /**
   * 로그인 유저 프로필 정보 얻기
   */
  static async getUserProfile() {
    return api.uzzimClient.get<UserProfileDto>('/api/user/me')
  }

  /**
   * 로그인 유저 프로파일 이미지 업데이트(업로드)
   * @param data
   */
  static async updateUserProfileImage(data: FormData) {
    return api.uzzimClient.post('/api/user/profile', data)
  }

  /**
   * 로그인 유저 프로파일 이미지 삭제하기
   */
  static async deleteUserProfileImage() {
    return api.uzzimClient.delete<UserProfileDto>('/api/user/profile')
  }

  static async getNiceModuleParams({
    returnUrl,
    errorUrl,
  }: CreateNiceParamsRequestDto) {
    return api.uzzimClient.post<NiceModuleParamsDto>(
      '/api/nice/request-encData',
      {
        authType: 'DEFAULT',
        customize: 'DEFAULT',
        sreturnUrl: returnUrl,
        serrorUrl: errorUrl,
      }
    )
  }

  static async postNiceCi({ encodeData }: PostNiceCiRequestDto) {
    return api.uzzimClient.post<string>('/api/nice/request-decData', {
      encodeData,
    })
  }

  static async createNiceOtp({
    encodeData,
    updatePhone = true,
  }: GetNiceOtpRequestDto) {
    return api.uzzimClient.post<NiceOtpDto>(
      '/api/nice/one-time-request-decData',
      {
        encodeData,
        updatePhone,
      }
    )
  }

  static async rereportBc({ rereportTarget }: RereportRequestDto) {
    return api.uzzimClient.post(`/api/bc/rereport/${rereportTarget}`)
  }

  static async checkPasscode(dto: CheckPasscodeRequestDto) {
    return api.uzzimClient.post<CheckPasscodeResultDto>(
      `/api/passcode/check`,
      dto
    )
  }

  static async setupPasscode(dto: SetupPasscodeRequestDto) {
    return api.uzzimClient.post('/api/passcode', dto)
  }

  static async getUserPasscodeStatus() {
    return api.uzzimClient.get<UserPasscodeStatusDto>('/api/passcode/user')
  }

  static async withdrawUser() {
    return api.uzzimClient.get('/api/auth/withdraw')
  }

  static async validateWithdrawUser() {
    return api.uzzimClient.get('/api/auth/validate-withdraw')
  }

  static async checkNiceCiForWithdrawUser(dto: PostNiceCiRequestDto) {
    return api.uzzimClient.post('/api/nice/withdraw-request-decData', dto)
  }
}

export { AuthClient }
