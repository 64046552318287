import { isKnownImageDomain } from '@src/utils/is-known-image-domain'
import Image, { ImageProps } from 'next/legacy/image'
import { blurImageUrl } from '../card'

type CustomImageProps = ImageProps & {
  fallbackImage?: string
}

export const CustomImage = ({
  src,
  alt,
  fallbackImage = blurImageUrl,
  ...props
}: CustomImageProps) => {
  const unoptimized = typeof src !== 'string' ? false : !isKnownImageDomain(src)

  return (
    <Image
      src={src || fallbackImage}
      alt={alt}
      unoptimized={unoptimized}
      {...props}
    />
  )
}
