import {
  DesignSettingDto,
  ProductCardSettingEnum,
  ThemeDesignSettingDto,
  ThemeSettingDto,
} from '../dto'

export class ThemeDesignSettings {
  public readonly createDt: Date
  public readonly updateDt: Date
  public readonly id: string
  public readonly themeSetting: ThemeSettingDto
  public readonly designSetting: DesignSettingDto
  public readonly productCardSetting: ProductCardSettingEnum
  public readonly isStockBadgeOn: boolean
  public readonly isTextBadgeOn: boolean
  public readonly textBadgeContent: string

  public readonly isShowMarketPrice: boolean
  public readonly isShowDiscountRate: boolean

  constructor(dto: ThemeDesignSettingDto) {
    this.createDt = dto.createDt
    this.updateDt = dto.updateDt
    this.id = dto.id
    this.themeSetting = dto.themeSetting
    this.designSetting = dto.designSetting
    this.productCardSetting = dto.productCardSetting
    this.isStockBadgeOn = dto.isStockBadgeOn
    this.isTextBadgeOn = dto.isTextBadgeOn
    this.textBadgeContent = dto.textBadgeContent

    this.isShowMarketPrice = this._getIsShowMarketPrice()
    this.isShowDiscountRate = this._getIsShowDiscountRate()
  }

  private _getIsShowMarketPrice() {
    return this.productCardSetting === ProductCardSettingEnum.Max
  }

  private _getIsShowDiscountRate() {
    return this.productCardSetting !== ProductCardSettingEnum.Min
  }
}
