import { Box } from '@src/components/basic'
import { ZIndex } from '@src/components/basic/types'
import { useEffect, useState } from 'react'
import { OverlayPortal } from './portal'

type Props = {
  children?: React.ReactNode
  isOpen?: boolean
  zIndex?: ZIndex
}

export const OverlayWrapper = ({
  children,
  isOpen,
  zIndex = 'floating',
}: Props) => {
  const [display, setDisplay] = useState<'block' | 'none'>(
    isOpen ? 'block' : 'none'
  )

  useEffect(() => {
    if (isOpen) {
      setDisplay('block')
    }
  }, [isOpen])

  const handleAnimationEnd = () => {
    if (!isOpen) {
      setDisplay('none')
    }
  }

  return (
    <OverlayPortal>
      <Box
        position="fixed"
        inset="0"
        style={{ display }}
        onAnimationEnd={handleAnimationEnd}
        zIndex={zIndex}
      >
        {children}
      </Box>
    </OverlayPortal>
  )
}
