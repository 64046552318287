import { BaseTransactionDto } from './base-transaction.dto'

export type TransactionDto = BaseTransactionDto & {
  createDt: string
  updateDt: string
  sn: string
  userId: string
  transactionChannelInfoId: string
  totalPointAmount: number
  paymentInfo: PaymentInfoDto
  paymentMethod: PaymentMethodDto
  deliveryInfo: TransactionDeliveryInfoDto
  refundInfo: RefundInfoDto
  currency: CurrencyEnum
  transactionInfo: TransactionInfoDto
  transactionPointAmount: number
}

export type PaymentInfoDto = {
  appliedDeliveryFee: number
  cardNum: string
  cardProviderName: string
  cardQuota: string
  pgPaidAmountWithDeliveryFee: number
  totalAmount: number
  totalPointAmount: number
  transactionPointAmount: number
}

export type PaymentMethodDto = {
  cardNum: string | null
  cardProviderName: string | null
  cardQuota: string | null
  paymentType: string
  mPaymentType: string
  pgType: PgTypeEnum
  payDevice: PayDeviceEnum
}

export enum PayDeviceEnum {
  PC = 'PC',
  Mobile = 'Mobile',
}

export enum PgTypeEnum {
  KgInicis = 'kginicis',
  Smartro = 'smartro',
}

export type TransactionDeliveryInfoDto = {
  appliedDeliveryFee: number
  totalDeliveryFee: number
  groupDeliveryDiscount: number
  regionAdditionalDeliveryFee: number
}

export type RefundInfoDto = {
  refundMethod: RefundMethodDto
  refundAmount: number
  pointRefundAmount: number
}

export type RefundMethodDto = {
  cardNum: string
  cardProviderName: string
  cardQuota: string
  mPaymentType: string
  payDevice: PayDeviceEnum
  pgType: PgTypeEnum
}

export enum CurrencyEnum {
  KRW = 'KRW',
  UDS = 'UDS',
}

export type TransactionInfoDto = {
  senderName: string
  senderPhone: string
  senderEmail: string
  deliveryNote: string
  receiverName: string
  receiverPhones: string
  receiverAddress: ReceiverAddressDto
}

export type ReceiverAddressDto = {
  main: string
  extra: string
  postalCode: string
  regionId?: string
}
