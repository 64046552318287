import {
  baseFontSize,
  colors,
  letterSpacings,
  zIndices,
} from '@src/styles/theme'
import { Color, LetterSpacing, MapOrString, Size, ZIndex } from '../types'

export const parseSize = (size?: Size): string | undefined => {
  if (typeof size === 'number') {
    return `${size / baseFontSize}rem`
  }

  if (size === 'full') {
    return '9999px'
  }

  return size
}

export const parseColor = (() => {
  const colorMap = new Map<Color, string>()

  return (color?: Color): string | undefined => {
    if (!color || color.startsWith('#') || color.startsWith('rgb')) {
      return color
    }

    if (colorMap.has(color)) {
      return colorMap.get(color)
    }

    const keys = color.split('.')
    let parsed: MapOrString = colors

    while (keys.length && typeof parsed === 'object') {
      const key = keys.shift()
      if (!key) {
        break
      }

      parsed = parsed[key]
    }

    if (typeof parsed !== 'string') {
      return undefined
    }

    colorMap.set(color, parsed)
    return parsed
  }
})()

export const parseLetterSpacing = (
  letterSpacing?: LetterSpacing
): string | undefined => {
  if (isLetterSpacingAlias(letterSpacing)) {
    return letterSpacings[letterSpacing]
  }

  return letterSpacing
}

const isLetterSpacingAlias = (
  letterSpacing?: LetterSpacing
): letterSpacing is keyof typeof letterSpacings => {
  if (!letterSpacing) {
    return false
  }

  return letterSpacing in letterSpacings
}

export const parseZIndex = (zIndex?: ZIndex): 'auto' | number | undefined => {
  if (!zIndex) {
    return zIndex
  }

  if (Array.isArray(zIndex)) {
    const mapped = zIndex.map(parseZIndex)
    if (!isNumberArray(mapped)) {
      return 'auto'
    }

    return mapped.reduce((total, value) => total + value, 0)
  }

  return typeof zIndex === 'number' ? zIndex : zIndices[zIndex]
}

const isNumberArray = (array: unknown[]): array is number[] => {
  return array.every((value) => typeof value === 'number')
}
