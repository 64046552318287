import { useUserProfile } from '@api/hooks/auth/use-user-profile'
import { Box, HStack, Text, TextProps, VStack } from '@src/components/basic'
import { ComponentProps } from '@src/components/basic/types'
import { parseSize } from '@src/components/basic/utils'
import {
  ExpandButton,
  ExpandableBox,
  HDivider,
  Icon,
} from '@src/components/common'
import { useToggle } from '@src/hook/use-toggle'
import { ProductInquiry } from '@src/types/product-inquiry'
import { DateFormatTypes, formatDate } from '@src/utils/format-date'
import { isLogin } from '@src/utils/is-login'
import { InquiryStatusChip } from './inquiry-status-chip'

type Props = {
  inquiry: ProductInquiry
  onClickDetail?: (inquiryId: string) => void
}

export const InquiryItem = ({ inquiry, onClickDetail }: Props) => {
  const profile = useUserProfile({ enabled: isLogin() })
  const [isExpanded, toggleIsExpanded] = useToggle()

  const handleClickDetail = () => {
    onClickDetail && onClickDetail(inquiry.id)
  }

  const isDisplayed =
    inquiry.isDisplayed || inquiry.inquirerId === profile.data?.harmonyId

  return (
    <>
      <VStack spacing={16} padding={16}>
        <HStack justifyContent="space-between">
          <HStack spacing={5}>
            <InquiryStatusChip status={inquiry.status} />
            <InquiryInfoText minWidth={0} maxWidth="75%" lineClamp={1}>
              {inquiry.name || inquiry.inquirerId}
            </InquiryInfoText>
          </HStack>
          <InquiryInfoText>
            {formatDate(inquiry.createDt, DateFormatTypes.DateOnly)}
          </InquiryInfoText>
        </HStack>

        <HStack alignItems="flex-end">
          <Box flex={1} minWidth={0}>
            {isDisplayed && (
              <VStack>
                <Text
                  fontSize={13}
                  letterSpacing="wide"
                  lineHeight={parseSize(20)}
                  lineClamp={isExpanded ? 2 : 1}
                >
                  {inquiry.title}
                </Text>
                <ExpandableBox expanded={isExpanded}>
                  <Box paddingTop={16}>
                    <Text
                      fontSize={13}
                      fontWeight="light"
                      fontColor="gray.700"
                      letterSpacing="wide"
                    >
                      {inquiry.content}
                    </Text>
                  </Box>
                </ExpandableBox>
              </VStack>
            )}
            {!isDisplayed && (
              <HStack spacing={8}>
                <Icon
                  iconName="private"
                  size={16}
                  color="black"
                  variant="outlined"
                />
                <Text
                  fontSize={13}
                  letterSpacing="wide"
                  lineHeight={parseSize(20)}
                >
                  비공개 문의입니다.
                </Text>
              </HStack>
            )}
          </Box>
          {isDisplayed && (
            <ExpandButton expanded={isExpanded} onClick={toggleIsExpanded} />
          )}
        </HStack>

        {inquiry.isAnswered && isDisplayed && (
          <Box as="button" onClick={handleClickDetail} alignItems="flex-end">
            <Text
              fontSize={12}
              fontColor="gray.900"
              fontWeight="light"
              letterSpacing="wide"
              textAlign="right"
              underline
            >
              답변보기
            </Text>
          </Box>
        )}
      </VStack>
      <HDivider thickness={1} backgroundColor="gray.100" />
    </>
  )
}

const InquiryInfoText = ({ children, ...props }: ComponentProps<TextProps>) => {
  return (
    <Text fontWeight="light" fontColor="gray.600" fontSize={12} {...props}>
      {children}
    </Text>
  )
}
