import { useExtensionSettingsQuery } from '@api/hooks/channel/use-extension-settings'
import { useProductPageSettingsQuery } from '@api/hooks/channel/use-product-page-settings'
import { useProductInquiriesInfiniteQuery } from '@api/hooks/product-inquiry/use-product-inquiries'
import { useProductReviewAggregationQuery } from '@api/hooks/review/use-product-review-aggregation'
import { routes } from '@src/common/constants/routes'
import { Box, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { PageWrapper, TabBar } from '@src/components/common'
import { useAlert } from '@src/contexts/alert-context'
import { useModal } from '@src/hook/use-modal'
import { usePrevPath } from '@src/hook/use-prev-path'
import { useScroll } from '@src/hook/use-scroll'
import { topBarHeight } from '@src/styles/theme'
import { Product } from '@src/types/product'
import { Selectable } from '@src/types/util-types/selectable'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { ProductDetailBanner } from './banner'
import { ProductDetailBottomBar } from './bottom-bar'
import { ProductDetailDescription } from './description'
import { ProductDetailImage } from './image'
import { ProductDetailInquiries } from './inquiries'
import { ProductDetailPurchaseInfo } from './purchase-info'
import { ProductDetailRecommendedProducts } from './recommended-products'
import { ProductDetailReviews } from './reviews'
import { ShareDrawer } from './share-drawer'
import { ProductDetailSummary } from './summary'

enum TabEnum {
  Detail,
  PurchaseInfo,
  Review,
  Qna,
}

type Props = {
  productDetail: Product
  isDrawer?: boolean
}

export const ProductDetail = ({ productDetail, isDrawer }: Props) => {
  const productPageSettingsQuery = useProductPageSettingsQuery()
  const extensionSettingsQuery = useExtensionSettingsQuery()
  const productReviewAggregationQuery = useProductReviewAggregationQuery(
    productDetail.id
  )
  const { total } = useProductInquiriesInfiniteQuery(productDetail.id)
  const [isOpenShareDrawer, openShareDrawer, closeShareDrawer] = useModal()
  const showAlert = useAlert()
  const { prevPath } = usePrevPath()
  const router = useRouter()

  const tabRef = useRef<HTMLDivElement>(null)
  const {
    scrollToTop,
    scrollToElement,
    scrollToTopInBox,
    scrollToElementInBox,
  } = useScroll()

  const tabs: Selectable<TabEnum>[] = useMemo(
    () => [
      { label: '상세정보', value: TabEnum.Detail },
      { label: '구매정보', value: TabEnum.PurchaseInfo },
      ...(productDetail.isUnlinkedVoucher ||
      !extensionSettingsQuery.data?.isReviewInstalled
        ? []
        : [
            {
              label: `리뷰(${productReviewAggregationQuery.data?.count || 0})`,
              value: TabEnum.Review,
            },
          ]),
      {
        label: `Q&A(${total || 0})`,
        value: TabEnum.Qna,
      },
    ],
    [
      extensionSettingsQuery.data?.isReviewInstalled,
      productDetail.isUnlinkedVoucher,
      productReviewAggregationQuery.data?.count,
      total,
    ]
  )

  const [selectedTab, setSelectedTab] = useState<TabEnum>(tabs[0].value)

  const handleChangeTab = (tab: TabEnum) => {
    setSelectedTab(tab)
    if (isDrawer) {
      scrollToElementInBox(tabRef.current)
    } else {
      scrollToElement(tabRef.current)
    }
  }

  const handleClickReviewTab = () => {
    handleChangeTab(TabEnum.Review)
  }

  useEffect(() => {
    if (isDrawer) {
      scrollToTopInBox(tabRef.current)
    } else {
      scrollToTop()
    }
  }, [isDrawer, productDetail.id, scrollToTop, scrollToTopInBox])

  useEffect(() => {
    if (!productDetail.isSelling) {
      showAlert({
        content: '구매 불가능한 상품입니다.\n이전 페이지로 돌아갑니다.',
        buttons: [
          {
            label: '확인',
          },
        ],
        closeCallback: () => {
          if (!!prevPath) {
            router.back()
            return
          }

          router.push(routes.shopping.home)
        },
      })
    }
  }, [prevPath, productDetail.isSelling, showAlert])

  return (
    <>
      <PageWrapper>
        <ProductDetailImage productDetail={productDetail} />
        <ProductDetailSummary
          productDetail={productDetail}
          productReviewAggregation={productReviewAggregationQuery}
          onClickShare={openShareDrawer}
          onClickReview={handleClickReviewTab}
        />
        <ProductDetailBanner
          productPageSetting={productPageSettingsQuery.data}
        />

        <TabBar
          ref={tabRef}
          tabs={tabs}
          justifyContent="space-around"
          selectedTab={selectedTab}
          onChange={handleChangeTab}
        />

        <Box minHeight={`calc(100vh - ${parseSize(topBarHeight)})`} flex={1}>
          <VStack paddingBottom={64}>
            {selectedTab === TabEnum.Detail && (
              <>
                <ProductDetailDescription
                  description={productDetail.description}
                  isUnlinkedVoucher={productDetail.isUnlinkedVoucher}
                />
                <ProductDetailRecommendedProducts
                  productDetail={productDetail}
                />
              </>
            )}
            {selectedTab === TabEnum.PurchaseInfo && (
              <ProductDetailPurchaseInfo productDetail={productDetail} />
            )}
            {selectedTab === TabEnum.Review &&
              !productDetail.isUnlinkedVoucher && (
                <ProductDetailReviews
                  productId={productDetail.id}
                  skuList={productDetail.skuList}
                  isManyOptions={productDetail.isManyOptions}
                  reviewAggregation={productReviewAggregationQuery.data}
                />
              )}
            {selectedTab === TabEnum.Qna && (
              <ProductDetailInquiries productDetail={productDetail} />
            )}
          </VStack>
        </Box>
        {/* {selectedTab === Tab.Detail && (
          <CartButton pathname="productDetail" bottom={122} zIndex={'above'} />
        )} */}
      </PageWrapper>
      <ProductDetailBottomBar productDetail={productDetail} />
      <ShareDrawer
        isOpen={isOpenShareDrawer}
        onClose={closeShareDrawer}
        productId={productDetail.id}
        productTitle={productDetail.title}
        productImageUrl={productDetail.images[0]}
      />
    </>
  )
}
