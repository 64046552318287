import styled from '@emotion/styled'
import { Box, HStack, Text } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { ExpandButton } from '@src/components/common'
import { HtmlRenderer } from '@src/components/common/html-renderer'
import { fadeIn } from '@src/components/common/overlay/common/keyframes'
import { useToggle } from '@src/hook/use-toggle'
import { useCallback, useRef, useState } from 'react'
import { UnlinkedVoucherNotification } from '../common/unlink-voucher-notification'

const foldedHeight = 588

type ProductDetailDescriptionProps = {
  description: string
  isUnlinkedVoucher: boolean
}

export const ProductDetailDescription = ({
  description,
  isUnlinkedVoucher,
}: ProductDetailDescriptionProps) => {
  const [isExpanded, toggleIsExpanded] = useToggle()
  const ref = useRef<HTMLDivElement>(null)
  const [hasExpandButton, setHasExpandButton] = useState<boolean>(false)

  const handleImageLoaded = useCallback(() => {
    if (!ref.current) {
      return
    }

    setHasExpandButton(ref.current.scrollHeight > foldedHeight)
  }, [])

  return (
    <Box>
      {isUnlinkedVoucher && <UnlinkedVoucherNotification />}
      <Box
        maxHeight={(!isExpanded && hasExpandButton && foldedHeight) || ''}
        overflowY="hidden"
        position="relative"
      >
        <HtmlRenderer
          ref={ref}
          html={description}
          onImageLoaded={handleImageLoaded}
        />
        {!isExpanded && hasExpandButton && <DetailGradient />}
      </Box>
      <Box
        paddingX={16}
        paddingTop={20}
        paddingBottom={24}
        onClick={toggleIsExpanded}
      >
        {hasExpandButton && (
          <Box
            as="button"
            centered
            height={40}
            border={[1, 'solid', 'gray.300']}
          >
            <HStack>
              <Text fontSize={12} fontColor="gray.700">
                {isExpanded && '상세정보 접기'}
                {!isExpanded && '상세정보 펼쳐보기'}
              </Text>
              <ExpandButton expanded={isExpanded} />
            </HStack>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const DetailGradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: ${parseSize(280)};
  background: linear-gradient(0deg, white, transparent);
  animation: ${fadeIn} 0.1s ease-out forwards;
`
