import { HStack, Text } from '@src/components/basic'
import { Color, Size } from '@src/components/basic/types'
import { Icon, IconName } from '../icon'
import { Chip, ChipProps } from './chip'

type IconChipProps = Omit<ChipProps, 'children'> & {
  iconName: IconName
  iconColor?: Color
  iconSize?: Size
  label: string
}

export const IconChip = ({
  iconName,
  iconColor = 'primary',
  iconSize,
  label,
  ...chipProps
}: IconChipProps) => {
  return (
    <Chip {...chipProps}>
      <HStack spacing={2}>
        <Icon
          iconName={iconName}
          size={16}
          variant="outlined"
          color={iconColor}
        />
        <Text wordBreak="keep-all" fontSize={11} fontWeight="light">
          {label}
        </Text>
      </HStack>
    </Chip>
  )
}
