export type BasicSettingDto = {
  id: string
  channelName: string
  channelStatus: ChannelStatusEnum
  channelType: string
  country: string
  createDt: string
  updateDt: string
  platformType: PlatformTypeEnum
  salesSettingType: SalesSettingTypeEnum
  description: string
  isUsingCustomDomain: boolean
  domain: string
  logoImage: string
  customerServiceInfo: CustomerServiceInfoDto
  partnerDisplayScope: PartnerDisplayScopeDto
  themeDesignSetting: ThemeDesignSettingDto
}

export enum ChannelStatusEnum {
  Draft = 'draft',
  Selling = 'selling',
  Stop = 'stop',
  Banned = 'banned',
  Deleted = 'deleted',
}

export enum SalesSettingTypeEnum {
  Normal = 'normal',
  Subscription = 'subscription',
  Hybrid = 'hybrid',
}

export enum PlatformTypeEnum {
  Open = 'open',
  Closed = 'closed',
}

export type CustomerServiceInfoDto = {
  createDt: string
  updateDt: string
  contactNumber: string
  csHours: string
}

export type PartnerDisplayScopeDto = {
  createDt: string
  updateDt: string
  isPartnerDisplayed: boolean
  isDisplayedOnProductDetail: boolean
  isDisplayedOnReview: boolean
  isDisplayedOnProductQa: boolean
}

export type ThemeDesignSettingDto = {
  createDt: Date
  updateDt: Date
  id: string
  themeSetting: ThemeSettingDto
  designSetting: DesignSettingDto
  productCardSetting: ProductCardSettingEnum
  isStockBadgeOn: boolean
  isTextBadgeOn: boolean
  textBadgeContent: string
}

export enum ProductCardSettingEnum {
  Min = 'min', // 기본형: 상품이미지, 상품명, 판매가, 찜버튼
  MinPlus = 'min_plus', // 기본형 + 할인율
  Max = 'max', // 기본형 + 시중가 + 할인율
}

export type ThemeSettingDto = {
  id: string
  code: string
  name: string
  description: string
}

export type DesignSettingDto = {
  id: string
  code: string
  name: string
}
