import {
  Box,
  BoxProps,
  HStack,
  StackProps,
  Text,
  TextProps,
  VStack,
} from '@src/components/basic'
import { useToggle } from '@src/hook/use-toggle'
import { Clickable, ExpandableBox, ExpandButton, HDivider } from '..'

type Props = {
  label: React.ReactNode
  children: React.ReactNode
  initialExpanded?: boolean
  labelTextProps?: TextProps
  labelBoxProps?: StackProps
  contentBoxProps?: BoxProps
}

export const Accordion = ({
  label,
  children,
  initialExpanded,
  labelTextProps,
  labelBoxProps,
  contentBoxProps,
}: Props) => {
  const [isExpanded, toggleIsExpanded] = useToggle(initialExpanded)

  return (
    <VStack>
      <VStack>
        <Clickable role="button" onClick={toggleIsExpanded}>
          <HStack
            justifyContent="space-between"
            paddingX={16}
            paddingY={10}
            {...labelBoxProps}
          >
            <Text
              fontSize={14}
              fontColor="gray.800"
              lineHeight={1}
              {...labelTextProps}
            >
              {label}
            </Text>
            <ExpandButton expanded={isExpanded} />
          </HStack>
        </Clickable>
        <HDivider backgroundColor="gray.100" thickness={1} />
      </VStack>
      <ExpandableBox expanded={isExpanded}>
        <Box backgroundColor="gray.100" padding={16} {...contentBoxProps}>
          {children}
        </Box>
      </ExpandableBox>
    </VStack>
  )
}
