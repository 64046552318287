import { Keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { ModalProps } from '@src/hook/use-modal'
import { fadeIn, fadeOut } from './keyframes'

type Props = ModalProps

export const Overlay = ({ isOpen, onClose }: Props) => {
  return (
    <StyledOverlay animation={isOpen ? fadeIn : fadeOut} onClick={onClose} />
  )
}

type StyledOverlayProps = {
  animation: Keyframes
}

const StyledOverlay = styled.div<StyledOverlayProps>`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  animation: 0.2s ease-out ${({ animation }) => animation} forwards;
`
