import { useProductReviewsInfiniteQuery } from '@api/hooks/review/use-product-reviews'
import { Box, VStack } from '@src/components/basic'
import {
  HDivider,
  InfiniteScroll,
  LoadingSpinner,
  Select,
} from '@src/components/common'
import { useModal } from '@src/hook/use-modal'
import { ProductSkuDto } from '@src/types/product'
import { ReviewAggregationDto, ReviewOrderingEnum } from '@src/types/review'
import { Selectable } from '@src/types/util-types/selectable'
import { useMemo, useState } from 'react'
import { useDetailFilter } from '../review/_hooks/use-detail-filter'
import { useSelectReview } from '../review/_hooks/use-select-review'
import {
  PhotoReviewCollectionDrawer,
  PhotoReviewPreview,
} from '../review/photo-review'
import { ProductReviewList } from '../review/product-review-list'
import { ReviewDetailFilterDrawer } from '../review/review-detail-filter-drawer'
import { ReviewFilter } from '../review/review-filter'
import { ReviewRepliesDrawer } from '../review/review-replies-drawer'
import { ReviewReportDrawer } from '../review/review-report-drawer'
import { ReviewSummary } from '../review/review-summary'

const reviewItemsPerPage = 10

type ProductDetailReviewsProps = {
  productId: string
  skuList: ProductSkuDto[]
  isManyOptions: boolean
  reviewAggregation?: ReviewAggregationDto
}

export const ProductDetailReviews = ({
  productId,
  skuList,
  isManyOptions,
  reviewAggregation,
}: ProductDetailReviewsProps) => {
  const [
    isOpenDetailOptionDrawer,
    openDetailOptionDrawer,
    closeDetailOptionDrawer,
  ] = useModal()
  const skuOptions: Selectable<string | undefined>[] = useMemo(
    () => [
      { label: '전체', value: undefined },
      ...skuList.map((sku) => ({
        label: sku.title,
        value: sku.id,
      })),
    ],
    [skuList]
  )

  const [skuId, setSkuId] = useState<string | undefined>(skuOptions[0].value)
  const { detailFilter, ...detailFilterProps } = useDetailFilter()
  const [reviewOrdering, setReviewOrdering] = useState<ReviewOrderingEnum>(
    ReviewOrderingEnum.Like
  )

  const productReviewsInfiniteQuery = useProductReviewsInfiniteQuery({
    productId,
    sellableSkuId: skuId,
    ordering: reviewOrdering,
    ...detailFilter,
    itemsPerPage: reviewItemsPerPage,
  })

  const [repliesReview, setRepliesReviewId, resetRepliesReviewId] =
    useSelectReview(productReviewsInfiniteQuery.data || [])
  const [reportReviewId, setReportReviewId] = useState<string>()
  const [isCollectionDrawerOpen, openCollectionDrawer, closeCollectionDrawer] =
    useModal()

  if (!reviewAggregation || !productReviewsInfiniteQuery.data) {
    return (
      <Box height={180}>
        <LoadingSpinner />
      </Box>
    )
  }

  return (
    <>
      <VStack>
        <Box paddingTop={8} paddingX={16} paddingBottom={16}>
          <ReviewSummary reviewAggregation={reviewAggregation} />
          {isManyOptions && (
            <Select
              options={skuOptions}
              initialSelected={skuOptions[0]}
              onChange={setSkuId}
            />
          )}
          <PhotoReviewPreview
            productId={productId}
            skuId={skuId}
            onClickMoreButton={openCollectionDrawer}
          />
        </Box>

        <HDivider backgroundColor="gray.100" />

        <ReviewFilter
          {...detailFilterProps}
          detailFilter={detailFilter}
          initialOrdering={reviewOrdering}
          onChangeOrdering={setReviewOrdering}
          onClickDetailDrawerOpen={openDetailOptionDrawer}
        />

        <InfiniteScroll {...productReviewsInfiniteQuery}>
          <ProductReviewList
            reviews={productReviewsInfiniteQuery.data}
            onClickReplies={setRepliesReviewId}
            onClickReport={setReportReviewId}
          />
        </InfiniteScroll>
      </VStack>

      <ReviewDetailFilterDrawer
        {...detailFilterProps}
        detailFilter={detailFilter}
        isOpen={isOpenDetailOptionDrawer}
        onClose={closeDetailOptionDrawer}
      />
      <ReviewRepliesDrawer
        isOpen={!!repliesReview}
        onClose={resetRepliesReviewId}
        review={repliesReview}
      />
      <ReviewReportDrawer
        isOpen={!!reportReviewId}
        onClose={() => setReportReviewId(undefined)}
        reviewId={reportReviewId}
      />
      <PhotoReviewCollectionDrawer
        isOpen={isCollectionDrawerOpen}
        onClose={closeCollectionDrawer}
        productId={productId}
        skuId={skuId}
      />
    </>
  )
}
