import { queryKeys } from '@api/constants/query-keys'
import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { ReviewAggregationDto } from '@src/types/review'
import { useQuery } from '@tanstack/react-query'

export const useProductReviewAggregationQuery = (productId: string) => {
  return useQuery(
    [queryKeys.product.productReviewAggregation, productId],
    () =>
      getProductReviewAggregation({
        productId,
      }),
    {
      enabled: !!productId,
    }
  )
}

const getProductReviewAggregation = ({ productId }: { productId: string }) => {
  return api.reviewClient.get<ReviewAggregationDto>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/products/${productId}/reviews/aggregation`
  )
}
