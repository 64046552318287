import { createContext, useContext, useMemo, useState } from 'react'

type ProviderProps = {
  children?: React.ReactNode
}

type ContextValue = {
  tempWishlist: Map<string, number | null>
  addToTempWishlist: (productId: string, WishItemId: number) => void
  deleteFromTempWishlist: (productId: string) => void
}

const TempWishlistContext = createContext<ContextValue | null>(null)

export const TempWishlistProvider = ({ children }: ProviderProps) => {
  const [tempWishlist, setTempWishlist] = useState<Map<string, number | null>>(
    new Map()
  )

  const addToTempWishlist = (productId: string, wishItemId: number) => {
    setTempWishlist((prev) => prev.set(productId, wishItemId))
  }

  const deleteFromTempWishlist = (productId: string) => {
    setTempWishlist((prev) => prev.set(productId, null))
  }

  const contextValue = useMemo<ContextValue>(
    () => ({
      tempWishlist,
      addToTempWishlist,
      deleteFromTempWishlist,
    }),
    [tempWishlist]
  )

  return (
    <TempWishlistContext.Provider value={contextValue}>
      {children}
    </TempWishlistContext.Provider>
  )
}

export const useTempWishlist = () => {
  const contextValue = useContext(TempWishlistContext)
  if (!contextValue) {
    throw new Error('Cannot found tempWishlistProvider!')
  }

  return contextValue
}
