import { fullDrawerName } from '@src/components/common/overlay/drawer/full-drawer'
import { topBarHeight } from '@src/styles/theme'
import { useCallback } from 'react'

export const useScroll = () => {
  const scrollToTop = useCallback(() => {
    if (typeof window === 'undefined') {
      return
    }

    const { documentElement } = document

    documentElement.scrollTo({ top: 0 })
  }, [])

  const scrollToElement = useCallback(
    (element?: HTMLElement | null, offset = topBarHeight) => {
      if (!element) {
        return
      }

      const { documentElement } = document

      const elementRect = element.getBoundingClientRect()
      const elementPosition = elementRect.top - offset
      const newScrollTop = elementPosition + documentElement.scrollTop

      documentElement.scrollTo({ top: newScrollTop, behavior: 'smooth' })
    },
    []
  )

  const scrollToTopInBox = useCallback((element?: HTMLElement | null) => {
    if (!element) {
      return
    }

    element.scrollTo(0, 0)
  }, [])

  const scrollToElementInBox = useCallback(
    (element?: HTMLElement | null, offset = topBarHeight) => {
      const scrollContainer = element?.closest(`[data-name=${fullDrawerName}]`)
      if (!element || !scrollContainer) {
        return
      }

      scrollContainer.scrollTop = element.offsetTop - offset
    },
    []
  )

  return {
    scrollToTop,
    scrollToElement,
    scrollToTopInBox,
    scrollToElementInBox,
  }
}
