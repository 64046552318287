import styled from '@emotion/styled'
import { HStack, VStack } from '@src/components/basic'
import { ProductSkuDto } from '@src/types/product'
import { formatAmount } from '@src/utils/format-amount'

type Props = {
  sku: ProductSkuDto
  onClick: (skuId: string) => void
}

export const SkuItem = ({ sku, onClick }: Props) => {
  const handleClick = () => {
    onClick(sku.id)
  }

  return (
    <button onClick={handleClick}>
      <VStack spacing={3} alignItems="flex-start">
        <InventoryStatus count={sku.leftInventoryCount} />
        <HStack spacing={6} width="100%" alignItems="center">
          <SkuTitle>{sku.title}</SkuTitle>
          <SkuPrice>{formatAmount(sku.sellPrice)}</SkuPrice>
        </HStack>
      </VStack>
    </button>
  )
}

const InventoryStatus = styled.span<{ count: number }>`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: -0.04em;
  color: ${({ count }) => (count === 0 ? '#d23b27' : '#757575')};

  ::before {
    content: '${({ count }) => (count === 0 ? '[품절]' : '[남은수량: ')}';
  }

  ::after {
    content: '${({ count }) => (count === 0 ? '' : `${count}개]`)}';
  }
`

const SkuTitle = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: -0.03em;
  color: #000000;
  text-align: left;
  flex: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const SkuPrice = styled.span`
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: -0.03em;
  color: #000000;

  ::after {
    content: '원';
    font-weight: 400;
  }
`

export default SkuItem
