import { ProductDetailDrawer } from '@src/components/shopping/common/product-detail-drawer'
import { usePrevPath } from '@src/hook/use-prev-path'
import { useUrlQuery } from '@src/hook/use-url-query'
import { useRouter } from 'next/router'
import { createContext, useCallback, useContext, useMemo } from 'react'

type ProviderProps = {
  children?: React.ReactNode
}

type ContextValue = {
  openProductDetail: (productId: string) => void
}

const ProductDetailDrawerContext = createContext<ContextValue | null>(null)

export const ProductDetailDrawerProvider = ({ children }: ProviderProps) => {
  const router = useRouter()
  const { pid = '' } = useUrlQuery()
  const { prevPath } = usePrevPath()

  const openProductDetail = useCallback(
    (pid: string) => {
      router.push({ query: { ...router.query, pid } }, undefined, {
        scroll: false,
      })
    },
    [router]
  )

  const closeProductDetail = useCallback(() => {
    const { pid, ...others } = router.query
    if (!prevPath || prevPath.pathname !== router.pathname) {
      router.replace({ query: others }, undefined, { scroll: false })
      return
    }

    router.back()
  }, [prevPath, router])

  const contextValue = useMemo(
    () => ({ openProductDetail }),
    [openProductDetail]
  )

  return (
    <ProductDetailDrawerContext.Provider value={contextValue}>
      {children}
      <ProductDetailDrawer
        isOpen={!!pid}
        onClose={closeProductDetail}
        productId={pid}
      />
    </ProductDetailDrawerContext.Provider>
  )
}

export const useProductDetailDrawer = () => {
  const values = useContext(ProductDetailDrawerContext)
  if (!values) {
    throw Error('Cannot found ProductDetailDrawerProvider!')
  }

  return values
}
