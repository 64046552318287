import { HStack } from '@src/components/basic'
import { Icon } from '@src/components/common'

type Props = {
  score: number
}

export const ReviewScoreStars = ({ score }: Props) => {
  return (
    <HStack>
      {Array(5)
        .fill(null)
        .map((_, index) => (
          <Icon
            key={index}
            iconName="star"
            size={16}
            color={score >= index + 1 ? 'yellow.primary' : 'gray.100'}
          />
        ))}
    </HStack>
  )
}
