import { CartProduct } from '@src/types/cart'
import {
  DeliveryInfoDto,
  DeliveryPricingMethodEnum,
  DeliveryPricingUnitEnum,
} from '@src/types/product'
import { formatAmount } from '@src/utils/format-amount'

export const getAdditionalDeliveryFeeNotice = ({
  deliveryPricingMethod,
  deliveryPricingUnit,
  conditionalChargeStandard,
  unitChargeStandard,
}: CartProduct | DeliveryInfoDto) => {
  const isUnitCharge =
    deliveryPricingMethod === DeliveryPricingMethodEnum.UnitCharge
  const isConditionalCharge =
    deliveryPricingMethod === DeliveryPricingMethodEnum.ConditionalCharge
  const isProductPricingUnit =
    deliveryPricingUnit === DeliveryPricingUnitEnum.Product
  const isSkuPricingUnit = deliveryPricingUnit === DeliveryPricingUnitEnum.Sku

  const conditionalChargeStandardAmount = formatAmount(
    conditionalChargeStandard
  )

  if (isUnitCharge && isProductPricingUnit) {
    return `상품 수량 ${unitChargeStandard}개마다 배송비 반복부과`
  }

  if (isUnitCharge && isSkuPricingUnit) {
    return `옵션 수량 ${unitChargeStandard}개마다 배송비 반복부과`
  }

  if (isConditionalCharge && isProductPricingUnit) {
    return `${conditionalChargeStandardAmount}원 이상 구매 시 배송비 무료`
  }

  if (isConditionalCharge && isSkuPricingUnit) {
    return `${conditionalChargeStandardAmount}원 이상 구매 시 배송비 무료`
  }

  return null
}
