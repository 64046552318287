import { Box, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { maxScreenWidth } from '@src/styles/theme'
import Image from 'next/legacy/image'

type Props = {
  imageUrls: string[]
}

export const PhotoReviewImages = ({ imageUrls }: Props) => {
  return (
    <VStack spacing={8}>
      {imageUrls.map((imageUrl, index) => {
        return (
          <Box
            key={index}
            size={`min(100vw, ${parseSize(maxScreenWidth)})`}
            position="relative"
          >
            <Image
              src={`${process.env.NEXT_PUBLIC_IMAGE_HOST}/${imageUrl}`}
              alt="review-image"
              unoptimized
              layout="fill"
            />
          </Box>
        )
      })}
    </VStack>
  )
}
