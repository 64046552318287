import styled from '@emotion/styled'
import { Box, BoxProps } from '@src/components/basic'
import { ButtonHTMLAttributes } from 'react'

type ClickableProps = {
  children?: React.ReactNode
} & ButtonHTMLAttributes<HTMLDivElement> &
  BoxProps

export const Clickable = ({ children, ...props }: ClickableProps) => {
  return (
    <BoxButton
      as={props.type ? 'button' : undefined}
      role="button"
      type="button"
      {...props}
    >
      {children}
    </BoxButton>
  )
}

const BoxButton = styled(Box)<Omit<ClickableProps, 'children'>>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`
