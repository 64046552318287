import { routes } from '@src/common/constants/routes'
import { StorageKey, storageKeys } from '@src/common/constants/storage-keys'
import { sessionStorageService } from '@src/services/storage-service'
import { LinkProps } from 'next/link'
import { useRouter } from 'next/router'

type NavigateInfo = { url: LinkProps['href']; storageKey: StorageKey }

const navigateInfoMap = {
  login: {
    url: routes.my.login,
    storageKey: storageKeys.loginRedirect,
  },
  ci: {
    url: routes.my.verification,
    storageKey: storageKeys.verificationRedirect,
  },
} as const

type Options = {
  to: keyof typeof navigateInfoMap
  redirectPath?: string
}

export const useNavigateWithRedirectPath = () => {
  const router = useRouter()

  const pushWithRedirectPath = (options: Options) => {
    navigateWithRedirect(router.push, options)
  }

  const replaceWithRedirectPath = (options: Options) => {
    navigateWithRedirect(router.replace, options)
  }

  const navigateWithRedirect = (
    navigate: (url: NavigateInfo['url']) => void,
    { redirectPath, ...options }: Options
  ) => {
    const { url, storageKey } = navigateInfoMap[options.to]
    navigate(url)
    setRedirectPath({ redirectPath, storageKey })
  }

  const setRedirectPath = ({
    redirectPath,
    storageKey,
  }: Pick<Options, 'redirectPath'> & Pick<NavigateInfo, 'storageKey'>) => {
    sessionStorageService.set(storageKey, redirectPath || router.asPath)
  }

  return {
    isReady: router.isReady,
    pushWithRedirectPath,
    replaceWithRedirectPath,
  }
}
