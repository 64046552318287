import { Box } from '@src/components/basic'
import {
  FullDrawer,
  FullDrawerHeader,
  Icon,
  PageWrapper,
} from '@src/components/common'
import { ModalProps } from '@src/hook/use-modal'
import { Review } from '@src/types/review'
import { useEffect, useState } from 'react'
import { ReviewContent } from '../../common/review-content'
import { PhotoReviewImages } from './photo-review-images'

import { LoadingSpinner } from '@src/components/common'
import { Swiper as SwiperType } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

type Props = ModalProps & {
  reviews: Review[]
  initialIndex: number | null
}

export const PhotoReviewDrawer = ({
  reviews,
  initialIndex,
  isOpen,
  onClose,
}: Props) => {
  const [index, setIndex] = useState<number | null>(null)
  const [controlledSwiper, setControlledSwiper] = useState<SwiperType>()

  const minIndex = 0
  const maxIndex = reviews.length - 1

  const handleClickNext = () => {
    if (index === null) {
      return
    }

    const newIndex = index >= maxIndex ? maxIndex : index + 1
    setIndex(newIndex)
  }

  const handleClickPrev = () => {
    if (index === null) {
      return
    }

    const newIndex = index <= minIndex ? minIndex : index - 1
    setIndex(newIndex)
  }

  const handleSlideChange = (swiper: SwiperType) => {
    setIndex(swiper.activeIndex)
  }

  useEffect(() => {
    if (initialIndex === null) {
      return
    }

    setIndex(initialIndex)
  }, [initialIndex])

  useEffect(() => {
    if (index === null) {
      return
    }

    controlledSwiper?.slideTo(index, 200)
  }, [controlledSwiper, index])

  return (
    <FullDrawer isOpen={isOpen}>
      <FullDrawerHeader title="포토리뷰" onBack={onClose} />
      <PageWrapper>
        {initialIndex === null ? (
          <LoadingSpinner />
        ) : (
          <Box display="block" position="relative" flex={1} height="100%">
            {isOpen && (
              <Swiper
                slidesPerView={1}
                centeredSlides
                loop={false}
                initialSlide={index !== null ? index : undefined}
                onSlideChange={handleSlideChange}
                onSwiper={setControlledSwiper}
                style={{ height: '100%' }}
              >
                {reviews.map((review, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Box
                        height="100%"
                        maxHeight="100%"
                        justifyContent="space-between"
                        position="relative"
                        overflowY="scroll"
                      >
                        <PhotoReviewImages imageUrls={review.images.mobile} />
                        <Box
                          position="sticky"
                          bottom="0"
                          backgroundColor="white"
                        >
                          <ReviewContent review={review} />
                        </Box>
                      </Box>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            )}
            {index !== null && index > minIndex && (
              <NavigationButton onClick={handleClickPrev} direction="back" />
            )}
            {index !== null && index < maxIndex && (
              <NavigationButton onClick={handleClickNext} direction="next" />
            )}
          </Box>
        )}
      </PageWrapper>
    </FullDrawer>
  )
}

type NavigationButtonProps = {
  onClick: () => void
  direction: 'next' | 'back'
}

const NavigationButton = ({ onClick, direction }: NavigationButtonProps) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      backgroundColor="rgba(0, 0, 0, 0.3)"
      position="absolute"
      right={direction === 'next' ? '0' : undefined}
      left={direction === 'back' ? '0' : undefined}
      top="50vw"
      size={40}
      centered
      zIndex="above"
    >
      <Icon iconName={direction} color="white" size={28} />
    </Box>
  )
}
