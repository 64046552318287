import { useCallback, useState } from 'react'

export type ModalProps = {
  isOpen?: boolean
  onClose: () => void
}

type Option = {
  initialState?: boolean
  openCallback?: () => void
  closeCallback?: () => void
}

/**
 * A hook for managing the open state of a modal.
 * @param option.openCallback - It fires when the modal is open.
 * @param option.closeCallback - It fires when the modal is close.
 *
 * @returns [`isModalOpen`, `openModal`, `closeModal`]
 */
export const useModal = ({
  openCallback,
  closeCallback,
  initialState,
}: Option = {}): [boolean, () => void, () => void] => {
  const [isModalOpen, setModalOpen] = useState<boolean>(initialState || false)

  const openModal = useCallback(() => {
    setModalOpen(true)
    if (openCallback) openCallback()
  }, [openCallback])

  const closeModal = useCallback(() => {
    setModalOpen(false)
    if (closeCallback) closeCallback()
  }, [closeCallback])

  return [isModalOpen, openModal, closeModal]
}
