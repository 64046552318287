import { BasicAddressDto } from '../dto'

export class BasicAddress {
  public readonly postalCode: string
  public readonly baseAddress: string
  public readonly detailAddress: string

  public readonly fullAddress: string

  constructor(dto: BasicAddressDto) {
    this.postalCode = dto.postalCode
    this.baseAddress = dto.baseAddress
    this.detailAddress = dto.detailAddress

    this.fullAddress = this._getFullAddress()
  }

  private _getFullAddress() {
    return `[${this.postalCode}] ${this.baseAddress} ${this.detailAddress}`
  }
}
