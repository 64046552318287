import { InquiryStatusEnum, ProductInquiryDto } from '../dto'

export class ProductInquiry {
  public readonly id: string
  public readonly channelId: string
  public readonly status: InquiryStatusEnum
  public readonly email: string
  public readonly inquirerId: string
  public readonly title: string
  public readonly content: string
  public readonly isDisplayed: boolean
  public readonly name: string
  public readonly phone: string
  public readonly productBrand: string
  public readonly productId: string
  public readonly productImages: string[]
  public readonly productSkuId: string
  public readonly productSkuTitle: string
  public readonly productTitle: string
  public readonly createDt: string
  public readonly updateDt: string

  public readonly isAnswered: boolean
  public readonly isNotAnswered: boolean

  constructor(dto: ProductInquiryDto) {
    this.channelId = dto.channelId
    this.createDt = dto.createDt
    this.email = dto.email
    this.id = dto.id
    this.inquirerId = dto.inquirerId
    this.title = dto.inquiryTitle
    this.content = dto.inquiryContents
    this.isDisplayed = dto.isDisplayed
    this.name = dto.name
    this.phone = dto.phone
    this.productBrand = dto.productBrand
    this.productId = dto.productId
    this.productImages = dto.productImages
    this.productSkuId = dto.productSkuId
    this.productSkuTitle = dto.productSkuTitle
    this.productTitle = dto.productTitle
    this.status = dto.status
    this.updateDt = dto.updateDt

    this.isAnswered = this._getIsAnswered()
    this.isNotAnswered = this._getIsNotAnswered()
  }

  private _getIsAnswered() {
    return this.status === InquiryStatusEnum.Answered
  }

  private _getIsNotAnswered() {
    return this.status === InquiryStatusEnum.NotAnswered
  }
}
