import { Color, Size } from '@src/components/basic/types'
import { parseColor, parseSize } from '@src/components/basic/utils'
import React from 'react'
import styled from '@emotion/styled'

type HDividerProps = {
  backgroundColor?: Color
  thickness?: Size
  length?: Size
}

export const HDivider = ({
  backgroundColor = 'gray.200',
  thickness = 8,
  length = '100%',
}: HDividerProps) => {
  return (
    <HorizontalDivider
      backgroundColor={parseColor(backgroundColor)}
      thickness={parseSize(thickness)}
      length={parseSize(length)}
    />
  )
}

const HorizontalDivider = styled.hr<HDividerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ thickness }) => thickness};
  width: ${({ length }) => length};
  flex-shrink: 0;
`

type VDividerProps = {
  backgroundColor?: Color
  length?: Size
  thickness?: Size
}

export const VDivider = ({
  backgroundColor = 'gray.200',
  length = '100%',
  thickness = 2,
}: VDividerProps) => {
  return (
    <VerticalDivider
      backgroundColor={parseColor(backgroundColor)}
      length={parseSize(length)}
      thickness={parseSize(thickness)}
    />
  )
}

const VerticalDivider = styled.hr<VDividerProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ length }) => length};
  width: ${({ thickness }) => thickness};
  flex-shrink: 0;
`
