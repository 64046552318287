import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { ThemeDesignSettings } from '@src/types/channel'
import { useQuery } from '@tanstack/react-query'
import { useBasicSettingsQuery } from './use-basic-settings'

export const useDesignThemeSettingsQuery = () => {
  const basicSettingsQuery = useBasicSettingsQuery()

  return useQuery(
    [queryKeys.channel.designThemeSettings],
    () => basicSettingsQuery.data!.themeDesignSetting,
    {
      staleTime: staleTime.infinity,
      enabled: basicSettingsQuery.isSuccess,
      select: (data) => new ThemeDesignSettings(data),
    }
  )
}
