import { aws } from '@src/config'

export const globalImages = {
  logo: '/logo.svg',
  kakaoLogo: `${aws.imageBaseUrl}/logo/kakao.webp`,
}

export const iconImages = {
  naver: '/icons/naver.svg',
  google: '/icons/google.svg',
  kakao: '/icons/kakao.svg',
}

export const bottomBarIconImages = {
  home: '/icons/bottom-bar/home.svg',
  homeFilled: '/icons/bottom-bar/home-filled.svg',
  cardShop: '/icons/bottom-bar/card-shop.svg',
  cardShopFilled: '/icons/bottom-bar/card-shop-filled.svg',
  history: '/icons/bottom-bar/history.svg',
  historyFilled: '/icons/bottom-bar/history-filled.svg',
  shopping: '/icons/bottom-bar/shopping.svg',
  shoppingFilled: '/icons/bottom-bar/shopping-filled.svg',
  myPage: '/icons/bottom-bar/my-page.svg',
  myPageFilled: '/icons/bottom-bar/my-page-filled.svg',
}

export const shoppingImages = {
  kakaoLogoIcon: '/shopping/kakao-logo-icon.svg',
  emptyProduct: '/shopping/empty-item.svg',
}

export const homeImages = {
  noRecentGift: '/icons/home/no-recent-gift.svg',
}

export const giftImages = {
  kakaoShareImage: `${aws.imageBaseUrl}/card/kakao-share.png`,
}
