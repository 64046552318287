import { Box, BoxProps, HStack, Text } from '@src/components/basic'
import { Color, ComponentProps } from '@src/components/basic/types'
import { VDivider } from '../dividers'

type BorderedBoxProps = ComponentProps<BoxProps>

export const BorderedBox = ({ children, ...props }: BorderedBoxProps) => {
  return (
    <Box
      border={[1, 'solid', 'gray.200']}
      borderRadius={8}
      backgroundColor="white"
      padding={[10, 16, 10, 16]}
      {...props}
    >
      {children}
    </Box>
  )
}

type BorderedLabelValueBoxProps = {
  label: string
  value: string | number
  valueColor?: Color
}

export const BorderedLabelValueBox = ({
  label,
  value,
  valueColor,
}: BorderedLabelValueBoxProps) => {
  return (
    <BorderedBox flexDirection={'row'} columnGap={16} alignItems={'center'}>
      <Text color={'gray.600'} fontSize={13} width={68}>
        {label}
      </Text>
      <VDivider length={14} thickness={1} />
      <HStack justifyContent={'center'} flex={1}>
        <Text fontSize={13} fontWeight={'semilight'} color={valueColor}>
          {value}
        </Text>
      </HStack>
    </BorderedBox>
  )
}
