import { Size } from '@src/components/basic/types'
import React, { ForwardedRef } from 'react'
import { parseSize } from '@src/components/basic/utils'
import styled from '@emotion/styled'
import Image from 'next/image'
import { Box } from '@src/components/basic'
import {
  defaultCardHeight,
  defaultCardWidth,
  IntermediateWrapper,
} from '../card'
import { parseBoolean } from '@src/utils/parse-boolean'
import { CardComponentType } from '@src/types/card'
import { zIndices } from '@src/styles/theme'

type VideoProps = {
  mediaUrl: string
  thumbnail: string
  hasControls?: boolean
  autoPlay?: boolean
  width?: number
  height?: number
  inView?: boolean
  shadowType?: 'none' | 'default' | 'light' | 'smallCard'
  onClick?: () => void
  isHorizontal?: boolean
  cardComponentType: CardComponentType
}

const boxShadowStyles: Record<
  'none' | 'default' | 'light' | 'smallCard',
  string
> = {
  none: 'none',
  default: '0px 3px 7px rgba(0, 0, 0, 0.3)',
  light: '0px 0px 6px rgba(0, 0, 0, 0.15)',
  smallCard: '0px 1px 7px rgba(0, 0, 0, 0.3)',
}

type VideoWrapperProps = Pick<VideoProps, 'shadowType'> & {
  width?: Size
  height?: Size
  isHorizontal?: boolean
}

export const Video = React.forwardRef(
  (
    {
      hasControls = false,
      mediaUrl,
      thumbnail,
      autoPlay = false,
      shadowType = 'default',
      onClick,
      inView = true,
      width = defaultCardWidth,
      height = defaultCardHeight,
      isHorizontal = true,
      cardComponentType,
    }: VideoProps,
    videoRef: ForwardedRef<HTMLVideoElement>
  ) => {
    const videoSize = {
      width: parseBoolean(isHorizontal) ? width : height,
      height: parseBoolean(isHorizontal) ? height : width,
    }

    return (
      <VideoWrapper
        as={!!onClick ? 'button' : 'div'}
        onClick={onClick}
        shadowType={shadowType}
        width={cardComponentType === 'list' ? width : videoSize.width}
        height={cardComponentType === 'list' ? height : videoSize.height}
        centered
      >
        {cardComponentType === 'list' && (
          <IntermediateWrapper horizontal={isHorizontal} {...videoSize}>
            <video
              ref={videoRef}
              src={mediaUrl}
              poster={thumbnail}
              playsInline
              loop
              muted
              autoPlay={autoPlay}
              controls={hasControls}
            />
            <VideoPoster
              inView={inView}
              className={'video-poster'}
              thumbnail={thumbnail}
              width={videoSize.width}
              height={videoSize.height}
            />
          </IntermediateWrapper>
        )}
        {cardComponentType === 'detail' && (
          <>
            <video
              ref={videoRef}
              src={mediaUrl}
              poster={thumbnail}
              playsInline
              loop
              muted
              autoPlay={autoPlay}
              controls={hasControls}
            />
            <VideoPoster
              inView={inView}
              className={'video-poster'}
              thumbnail={thumbnail}
              width={videoSize.width}
              height={videoSize.height}
            />
          </>
        )}
      </VideoWrapper>
    )
  }
)

type VideoPosterProps = {
  className: string
  inView: boolean
  width: number
  height: number
  thumbnail: string
  alt?: string
}

const VideoPoster = ({
  inView,
  width,
  height,
  thumbnail,
  className,
  alt = 'video poster',
}: VideoPosterProps) => {
  return (
    <Box zIndex={inView ? zIndices.below : zIndices.above}>
      <Image
        className={className}
        src={thumbnail}
        width={width}
        height={height}
        alt={alt}
      />
    </Box>
  )
}

const VideoWrapper = styled(Box)<VideoWrapperProps>`
  width: ${({ width }) => parseSize(width)};
  height: ${({ height }) => parseSize(height)};
  border-radius: ${parseSize(10)};
  overflow: hidden;
  box-shadow: ${({ shadowType }) => shadowType && boxShadowStyles[shadowType]};
  position: relative;
  & video {
    object-fit: fill;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`

Video.displayName = 'Video'
