import { Box, HStack, Text, VStack } from '@src/components/basic'
import {
  Clickable,
  HDivider,
  Icon,
  IconChip,
  MoneyText,
} from '@src/components/common'
import { Product, ProductClassEnum } from '@src/types/product'
import { ReviewAggregationDto } from '@src/types/review'
import { UseQueryResult } from '@tanstack/react-query'
import { Price } from '../common/price'
import { ProductClassChip } from '../common/product-class-chip'

type ProductDetailSummaryProps = {
  productDetail: Product
  productReviewAggregation: UseQueryResult<ReviewAggregationDto>
  onClickShare: () => void
  onClickReview: () => void
}

export const ProductDetailSummary = ({
  productDetail,
  productReviewAggregation,
  onClickShare,
  onClickReview,
}: ProductDetailSummaryProps) => {
  const {
    brand,
    title,
    marketPrice,
    minSellPrice,
    productClass,
    deliveryInfo,
  } = productDetail
  const { mean, count } = productReviewAggregation.data || {}

  return (
    <VStack>
      <Box
        position="relative"
        padding={16}
        paddingBottom={productClass === ProductClassEnum.Tangible ? 16 : 24}
      >
        <VStack spacing={24}>
          <HStack justifyContent={'space-between'} alignItems="flex-start">
            <VStack spacing={8} flex={1}>
              <VStack spacing={6}>
                {brand && (
                  <Text
                    fontSize={14}
                    fontColor="gray.600"
                    fontWeight="light"
                    lineHeight={1.15}
                  >
                    [{brand}]
                  </Text>
                )}
                <Text lineHeight={1.2} wordBreak="break-all">
                  {title}
                </Text>
              </VStack>

              <HStack>
                <Icon iconName="star" color={'yellow.primary'} size={16} />
                <HStack spacing={2}>
                  <Text
                    fontSize={12}
                    fontColor="gray.700"
                    fontWeight="bold"
                    lineHeight={1}
                    opacity={productReviewAggregation.isLoading ? 0.4 : 1}
                  >
                    {(mean || 0).toFixed(1)}
                  </Text>
                  <Text
                    fontSize={12}
                    fontColor="gray.700"
                    fontWeight="light"
                    lineHeight={1}
                    onClick={onClickReview}
                    opacity={productReviewAggregation.isLoading ? 0.4 : 1}
                  >
                    ({count || 0})
                  </Text>
                </HStack>
              </HStack>
            </VStack>

            <Clickable onClick={onClickShare}>
              <Icon iconName="share" variant="outlined" color="black" />
            </Clickable>
          </HStack>

          <HStack
            justifyContent="space-between"
            alignItems="flex-end"
            paddingBottom={2}
          >
            <Price
              marketPrice={marketPrice}
              sellPrice={minSellPrice}
              at="detail"
            />

            <Box marginBottom={-2}>
              <ProductClassChip productClass={productClass} />
            </Box>
          </HStack>
        </VStack>
      </Box>

      {productClass === ProductClassEnum.Tangible && deliveryInfo && (
        <VStack>
          <HDivider thickness={1} backgroundColor="gray.100" />
          <HStack padding={16} justifyContent="space-between">
            <Box marginBottom={-2}>
              <IconChip
                iconName={'shipping'}
                label={'배송비'}
                iconColor="black"
              />
            </Box>
            <HStack>
              {deliveryInfo.baseDeliveryFee === 0 ? (
                <Text fontSize={14}>무료배송</Text>
              ) : (
                <MoneyText
                  moneyAmount={deliveryInfo.baseDeliveryFee}
                  fontColor="gray.800"
                  moneyFontSize={14}
                  unitFontSize={14}
                />
              )}
              <Text fontSize={14} fontColor="gray.800">
                {' / '}
                <b>{deliveryInfo.defaultCarrier.name}</b>
              </Text>
            </HStack>
          </HStack>
        </VStack>
      )}
      <HDivider backgroundColor="gray.100" />
    </VStack>
  )
}
