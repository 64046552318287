export const formatAmount = (value?: string | number | null): string => {
  const parsed = Number(value)

  if (typeof value === undefined || value === null) {
    return ''
  }

  if (typeof value === 'string' && isNaN(parsed)) {
    return value
  }

  return parsed.toLocaleString()
}
