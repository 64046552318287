import { Box, VStack } from '@src/components/basic'
import { HDivider } from '../dividers'
import { CardListItemSkeleton } from './card-list-item-skeleton'

const defaultCardSkeletonCount = 5

type Props = {
  cardSkeletonCount?: number
}

export const CardListSkeleton = ({
  cardSkeletonCount = defaultCardSkeletonCount,
}: Props) => {
  return (
    <VStack>
      {new Array(cardSkeletonCount).fill('').map((_, index) => (
        <Box key={index}>
          <CardListItemSkeleton />
          {index !== cardSkeletonCount - 1 && (
            <HDivider
              thickness={1}
              length={'100%'}
              backgroundColor={'gray.100'}
            />
          )}
        </Box>
      ))}
    </VStack>
  )
}
