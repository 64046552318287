import { Box, Text } from '@src/components/basic'
import { Size } from '@src/components/basic/types'
import { TextComponentProps } from '@src/components/common'

type Props = TextComponentProps & {
  message?: string
  height?: Size
  minHeight?: Size
}

export const EmptyMessage = ({
  message,
  height,
  minHeight = 'inherit',
  ...props
}: Props) => {
  return (
    <Box
      alignItems={'center'}
      justifyContent={'center'}
      height={height}
      minHeight={minHeight}
    >
      <Text
        fontWeight={'light'}
        fontSize={13}
        whiteSpace={'pre-line'}
        textAlign={'center'}
        {...props}
      >
        {message}
      </Text>
    </Box>
  )
}
