import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { Product, ProductDto } from '@src/types/product'
import { useQuery } from '@tanstack/react-query'

export const useProductDetailQuery = (productId?: string) => {
  return useQuery(
    [queryKeys.product.productDetail, productId],
    () => getProductDetail(productId!),
    {
      enabled: !!productId,
      staleTime: staleTime.short,
      select: (data) => new Product(data),
    }
  )
}

export const getProductDetail = (id: string) => {
  return api.productClient.get<ProductDto>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/products/${id}`
  )
}
