import { usePhotoReviewsInfiniteQuery } from '@api/hooks/review/use-photo-reviews'
import { Box } from '@src/components/basic'
import {
  FullDrawer,
  FullDrawerHeader,
  InfiniteScroll,
  LoadingSpinner,
} from '@src/components/common'
import { ModalProps } from '@src/hook/use-modal'
import { usePhotoReviewDetailDrawer } from '../_hooks/use-photo-review-detail'
import { PhotoItem } from './photo-item'
import { PhotoReviewDrawer } from './photo-review-detail/photo-review-drawer'

type Props = ModalProps & {
  productId: string
  skuId?: string
}

export const PhotoReviewCollectionDrawer = ({
  isOpen,
  onClose,
  productId,
  skuId,
}: Props) => {
  const photoReviewsInfiniteQuery = usePhotoReviewsInfiniteQuery({
    productId,
    itemsPerPage: 40,
    skuId,
  })
  const {
    isPhotoReviewOpen,
    openPhotoReview,
    closePhotoReview,
    photoReviewIndex,
  } = usePhotoReviewDetailDrawer()

  if (!photoReviewsInfiniteQuery.data) {
    return (
      <FullDrawer isOpen={isOpen}>
        <FullDrawerHeader title="포토리뷰 모아보기" onBack={onClose} />
        <LoadingSpinner />
      </FullDrawer>
    )
  }

  return (
    <FullDrawer isOpen={isOpen}>
      <FullDrawerHeader title="포토리뷰 모아보기" onBack={onClose} />
      <InfiniteScroll {...photoReviewsInfiniteQuery}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap={8}
          paddingX={16}
          paddingY={8}
        >
          {photoReviewsInfiniteQuery.data?.map(
            (review, index) =>
              !!review.images.mobile.length && (
                <PhotoItem
                  key={review.id}
                  imageUrl={review.images.mobile[0]}
                  imageCount={review.images.mobile.length}
                  onClick={() => {
                    openPhotoReview(index)
                  }}
                />
              )
          )}
        </Box>
      </InfiniteScroll>

      <PhotoReviewDrawer
        isOpen={isPhotoReviewOpen}
        onClose={closePhotoReview}
        initialIndex={photoReviewIndex}
        reviews={photoReviewsInfiniteQuery.data}
      />
    </FullDrawer>
  )
}
