import { BaseProductDto } from './base-product.dto'
import { DeliveryInfoDto } from './delivery-info.dto'
import { ProductSkuDto } from './product-sku.dto'

export type ProductDto = BaseProductDto & {
  createDt: string
  updateDt: string
  supplierPortalId: string
  channelPortalId: string
  channelId: string
  sellerPortalId: string
  channelCategoryId: string
  masterProductId: string
  country: string
  description: string
  sn: string
  origin: string
  manufacturer: string
  isVat: boolean
  noticeId: string
  noticeInfo: NoticeInfoDto
  isImageRegisterByOption: boolean
  note: string
  faq: string[]
  currency: string
  isManyOptions: boolean
  skuCount: number
  soldCount: number
  minSupplyPrice: number
  maxSupplyPrice: number
  maxSellPrice: number
  discountRate: number
  registerStatus: RegisterStatusEnum
  activeStatus: ActiveStatusEnum
  type: string
  ratings: number
  viewCount: number
  likeCount: number
  keywords: string[]
  minSellPriceBeforeReduction: number
  maxSellPriceBeforeReduction: number
  deliveryInfo?: DeliveryInfoDto
  sellerInfo: SellerInfoDto
  optionList: ProductOptionDto[]
  skuList: ProductSkuDto[]
  limitDate?: string
  validityPeriod?: number
  pgSettingId?: string
  issuanceType?: IssuanceTypeEnum
}

export enum RegisterStatusEnum {
  Requested = 'requested',
  RequestedAgain = 'requested_again',
  Rejected = 'rejected',
  Approved = 'approved',
}

export enum ActiveStatusEnum {
  Active = 'active',
  Inactive = 'inactive',
  Deleted = 'deleted',
}

export enum IssuanceTypeEnum {
  Instant = 'instant',
  External = 'external',
}

export type NoticeInfoDto = Record<string, string>

export type SellerInfoDto = {
  portalId: string
  sellerCompanyName: string
  sellerCountryCode: string
  sellerContactNumber: string
  sellerAddress1: string
  sellerAddress2: string
  sellerPostalCode: string
}

export type ProductOptionDto = {
  name: string
  values: string[]
}
