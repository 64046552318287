import { GenderEnum } from '@src/types/review'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'

export type DetailFilterProps = ReturnType<typeof useDetailFilter>
export type DetailFilter = DetailFilterProps['detailFilter']

export const useDetailFilter = () => {
  const [ages, setAges, removeAge] = useList()
  const [scores, setScores, removeScore] = useList()
  const [gender, setGender] = useState<GenderEnum>(GenderEnum.None)

  const setDetailFilter = (
    ages: string[],
    scores: string[],
    gender: GenderEnum
  ) => {
    setAges(ages)
    setScores(scores)
    setGender(gender)
  }

  return {
    detailFilter: { ages, scores, gender },
    setDetailFilter,
    removeAge,
    removeScore,
    setGender,
  }
}

const useList = (): [
  string[],
  Dispatch<SetStateAction<string[]>>,
  (item: string) => void
] => {
  const [list, setList] = useState<string[]>([])

  const removeItem = useCallback((item: string) => {
    setList((prev) => prev.filter((value) => value !== item))
  }, [])

  return [list, setList, removeItem]
}
