import { globalImages } from '@src/common/constants/images'
import { Box, Text, VStack } from '@src/components/basic'
import Image from 'next/legacy/image'

type ErrorPageContentProps = {
  content: string
}

export const ErrorPageContent = ({ content }: ErrorPageContentProps) => {
  return (
    <VStack spacing={16} alignItems="center">
      <Box
        position="relative"
        width={160}
        height={48}
        filter="grayscale(100%)"
        opacity={0.4}
      >
        <Image src={globalImages.logo} alt="logo-grayscale" layout="fill" />
      </Box>
      <Text fontColor="gray.200" whiteSpace="pre-wrap" textAlign="center">
        {content}
      </Text>
    </VStack>
  )
}
