import styled from '@emotion/styled'
import { Color, FontWeight, Size } from '@src/components/basic/types'
import { parseColor, parseSize } from '@src/components/basic/utils'
import React, { CSSProperties } from 'react'

export type ChipProps = ChipContainerProps &
  ChipTextProps & {
    children: React.ReactNode
  }

export type ChipContainerProps = {
  color?: Color
  backgroundColor?: Color
  borderColor?: Color
  width?: Size
  height?: Size
  isSquare?: boolean
  textAlign?: CSSProperties['textAlign']
  padding?: CSSProperties['padding']
  justifyContent?: CSSProperties['justifyContent']
  textSize?: Size
  gap?: Size
  background?: CSSProperties['background']
}

type ChipTextProps = {
  textSize?: Size
  fontWeight?: FontWeight
}

export const Chip = React.forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      children,
      borderColor,
      width,
      height,
      color = 'black',
      backgroundColor = 'gray.100',
      background,
      isSquare = false,
      textAlign = 'center',
      padding = '6px 12px',
      justifyContent = 'flex-start',
      textSize = '13px',
      gap = 4,
    },
    ref
  ) => {
    return (
      <ChipContainer
        color={parseColor(color)}
        backgroundColor={parseColor(backgroundColor)}
        background={background}
        borderColor={parseColor(borderColor)}
        width={parseSize(width)}
        height={parseSize(height)}
        isSquare={isSquare}
        textAlign={textAlign}
        padding={padding}
        justifyContent={justifyContent}
        textSize={parseSize(textSize)}
        gap={parseSize(gap)}
        ref={ref}
      >
        {children}
      </ChipContainer>
    )
  }
)

const ChipContainer = styled.div<ChipContainerProps>`
  color: ${({ color }) => color};
  background: ${({ background }) => background};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  border-radius: ${({ isSquare }) => (isSquare ? '8px' : '72px')};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  font-size: ${({ textSize }) => textSize};
  line-height: 1;
  gap: ${({ gap }) => gap};
`

Chip.displayName = 'Chip'
