import { GiftReceivedHistoryDetailProps } from '@api/hooks/gift/use-gift-received-history-detail'
import { ApiListResult3, OtpRequest } from '@api/types'
import {
  BasicCardResponseDto,
  CardDetailRequestDto,
  CardDetailResponseDto,
  CardDivisionEnum,
  CardExchangeRequestDto,
  CardLimitRequestDto,
  CardLimitResponseDto,
  CardUsageHistoryRequestDto,
  CardUsageHistoryResponseDto,
  ChargeCardRequestDto,
  ChargeCardResponseDto,
  MyCardCountResponseDto,
  MyCardResponseDto,
  MyCardSortEnum,
  MyCardTypeEnum,
  RefundCardHistoryRequestDto,
  RefundCardHistoryResponseDto,
  SwipeCardListResponseDto,
  UnreceivedCardResponseDto,
} from '@src/types/card'
import {
  GiftAcceptRequestDto,
  GiftAcceptResponseDto,
  GiftHistoryDetailRequestDto,
  GiftHistoryResponseDto,
  GiftLimitResponseDto,
  GiftReceiveHistoryDetailResponseDto,
  GiftReceiveHistoryResponseDto,
  GiftSendHistoryDetailResponseDto,
  GiftSendHistoryResponseDto,
  SendMasterRequestDto,
  SendSecondaryRequestDto,
  SendThanksMessageRequestDto,
} from '@src/types/gift'
import { api } from '.'

class CardClient {
  /**
   * 로그인 유저가 소유한 기프트 리스트
   */
  static async getMyCards({
    cardType,
    page,
    itemsPerPage,
  }: {
    cardType: MyCardTypeEnum
    page?: number
    itemsPerPage?: number
  }) {
    return api.uzzimClient.get<ApiListResult3<MyCardResponseDto>>(
      `/api/card/my-cards/${cardType}`,
      {
        params: {
          page,
          size: itemsPerPage,
        },
      }
    )
  }

  /**
   * 쇼핑에서 사용 할 나의 기프트 목록
   */
  static async getMyShoppingCards({
    sort,
    page,
    itemsPerPage,
  }: {
    sort: MyCardSortEnum
    page?: number
    itemsPerPage?: number
  }) {
    return api.uzzimClient.get<ApiListResult3<MyCardResponseDto>>(
      `/api/card/list/shopping/${sort}`,
      {
        params: {
          page,
          size: itemsPerPage,
        },
      }
    )
  }

  /**
   * 로그인 유저가 소유한 기프트 갯수
   */
  static async getMyCardTotalCount() {
    return api.uzzimClient.get<MyCardCountResponseDto>('/api/card/count')
  }

  static async getGiftLimit() {
    return api.uzzimClient.get<GiftLimitResponseDto>(`/api/gift/limit`)
  }

  /**
   * 마스터기프트 선물
   */
  static async sendMasterCard(dto: OtpRequest<SendMasterRequestDto>) {
    return api.uzzimClient.post('/api/gift/send-master', dto)
  }

  /**
   * 세컨더리기프트 선물
   */
  static async sendSecondaryCard(dto: OtpRequest<SendSecondaryRequestDto>) {
    return api.uzzimClient.post('/api/gift/send-secondary', dto)
  }

  /**
   * 선물받은 기프트 등록하기
   */
  static async giftCardAccept({ secondaryCardId }: GiftAcceptRequestDto) {
    return api.uzzimClient.post<GiftAcceptResponseDto>('/api/gift/accept', {
      secondaryCardId,
    })
  }

  /**
   * 감사메시지 보내기
   */
  static async sendThanksMessage({
    secondaryCardId,
    thankMsgFromReceiver,
    cardHistoryId,
  }: SendThanksMessageRequestDto) {
    return api.uzzimClient.post('/api/gift/send-msg', {
      cardHistoryId,
      secondaryCardId,
      thankMsgFromReceiver,
    })
  }

  /**
   * 기프트 취소하기
   */
  static async giftCardCancel(secondaryCardId: number) {
    return api.uzzimClient.post('/api/gift/cancel', {
      secondaryCardId: secondaryCardId,
    })
  }

  /**
   * 기프트 삭제하기
   */
  static async deleteCard({
    cardId,
    cardDivision,
  }: {
    cardId: number
    cardDivision: CardDivisionEnum
  }) {
    return api.uzzimClient.patch<{ message: string }>(
      `/api/card/delete/${cardDivision}/${cardId}`
    )
  }

  /**
   * 보낸 선물 내역
   */
  static async giftSendHistory() {
    return api.uzzimClient.get<GiftSendHistoryResponseDto[]>(
      '/api/gift/history/send-history'
    )
  }

  /**
   * 보낸 선물내역 상세
   */
  static async giftSendHistoryDetail({
    division,
    cardHistoryId,
    senderCardId,
  }: GiftHistoryDetailRequestDto): Promise<GiftSendHistoryDetailResponseDto> {
    return api.uzzimClient.get<GiftSendHistoryDetailResponseDto>(
      `/api/gift/history/send-history/detail/${division}/${cardHistoryId}/${senderCardId}`
    )
  }

  /**
   * 받은 선물 내역
   */
  static async giftReceiveHistory() {
    return api.uzzimClient.get<GiftReceiveHistoryResponseDto[]>(
      '/api/gift/history/receive-history'
    )
  }

  /**
   * 받은 선물 내역 상세
   */
  static async giftReceiveHistoryDetail({
    cardHistoryId,
    senderCardId,
  }: GiftReceivedHistoryDetailProps) {
    return api.uzzimClient.get<GiftReceiveHistoryDetailResponseDto>(
      `/api/gift/history/receive-history/detail/${cardHistoryId}/${senderCardId}`
    )
  }

  /**
   * 나의 스와이프 기프트 목록
   */
  static async getSwipeCardList(): Promise<SwipeCardListResponseDto> {
    return api.uzzimClient.get<SwipeCardListResponseDto>(
      '/api/card/swipe-cards/'
    )
  }

  /**
   * 기본 기프트 목록
   */
  static async getBasicCardList() {
    return api.uzzimClient.get<ApiListResult3<BasicCardResponseDto>>(
      '/api/card/basic-cards'
    )
  }

  /**
   * 선물 시 기프트에 대한 정보
   */
  static async getGiftCardDetail({
    cardId,
    cardDivision,
  }: CardDetailRequestDto) {
    return api.uzzimClient.get<CardDetailResponseDto>(
      `/api/card/detail/${cardDivision}/${cardId}`
    )
  }

  /**
   * 선물 목록 조회
   */
  static async giftHistory(page: number, size: number) {
    return api.uzzimClient.get<ApiListResult3<GiftHistoryResponseDto>>(
      '/api/gift/history/list',
      {
        params: {
          page,
          size,
        },
      }
    )
  }

  /**
   * 미확인 선물 기프트 상세정보
   */
  static async giftCardUnReceivedDetail(id: number) {
    return api.uzzimClient.get<UnreceivedCardResponseDto>(
      `/api/card/received/${id}`
    )
  }

  static async chargeCard(dto: ChargeCardRequestDto) {
    return api.uzzimClient.post<ChargeCardResponseDto>('/api/charge/card', dto)
  }

  static async getRefundCardHistory(dto: RefundCardHistoryRequestDto) {
    return api.uzzimClient.get<ApiListResult3<RefundCardHistoryResponseDto>>(
      '/api/card/refund-card/history',
      {
        params: dto,
      }
    )
  }

  /**
   * 기프트머니에서 유찜머니로 전환 전 상세 정보 학인
   */
  static async getCardLimit({ cardDivision, cardId }: CardLimitRequestDto) {
    return api.uzzimClient.get<CardLimitResponseDto>(
      `/api/card/exchange/${cardDivision}/${cardId}`
    )
  }

  /**
   * 기프트머니에서 유찜머니로 전환 요청
   */
  static async exchangeCard(dto: CardExchangeRequestDto) {
    return api.uzzimClient.post<CardLimitResponseDto>(`/api/card/exchange`, dto)
  }

  static async getCardUsageHistory(dto: CardUsageHistoryRequestDto) {
    return api.uzzimClient.get<ApiListResult3<CardUsageHistoryResponseDto>>(
      `/api/money/history/${dto.cardDivision}/${dto.id}`,
      {
        params: {
          year: dto.year,
          month: dto.month,
          actionCodeList: dto.actionCodeList,
        },
        paramsSerializer: function (actionCodeList) {
          const params = new URLSearchParams()
          for (const key in actionCodeList) {
            params.append(key, actionCodeList[key])
          }
          return params.toString()
        },
      }
    )
  }
}

export { CardClient }
