const IMAGE_DOMAINS = [
  's3.ap-northeast-2.amazonaws.com',
  'uzzim-test.s3.ap-northeast-2.amazonaws.com',
  'flanb-data-test.s3.ap-northeast-2.amazonaws.com',
  'lge-concierge.oss-ap-northeast-1.aliyuncs.com',
  'harmony-static-dev.travelflan.com',
  'harmony-static-stg.travelflan.com',
  'harmony-static.travelflan.com',
]

module.exports = IMAGE_DOMAINS
