import { queryKeys } from '@api/constants/query-keys'
import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { CreateCartLineRequestDto } from '@src/types/cart'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useCreateCartLineMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (dto: CreateCartLineRequestDto) => {
      return createCartLine(dto)
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries([queryKeys.cart.lines])
      },
    }
  )
}

const createCartLine = (dto: CreateCartLineRequestDto) => {
  return api.transactionClient.post(
    `${environment.harmonyEndpoint}/${BasicSettings.getChannelId()}/cart/lines`,
    dto
  )
}
