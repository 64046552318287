import styled from '@emotion/styled'
import { Icon } from '@src/components/common'

type Props = {
  title?: string
  onClose?: () => void
}

export const DrawerHeader = ({ title, onClose }: Props) => {
  return (
    <Header>
      {title}
      {onClose && (
        <CloseButton type="button" onClick={onClose}>
          <Icon iconName={'close'} color={'black'} size={24} />
        </CloseButton>
      )}
    </Header>
  )
}

const Header = styled.header`
  box-sizing: content-box;
  height: 1.25rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 0;

  color: #000000;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.04em;
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
`
