import { useProductDetailQuery } from '@api/hooks/product/use-product-detail'
import {
  FullDrawer,
  FullDrawerHeader,
  LoadingSpinner,
  PageWrapper,
} from '@src/components/common'
import { ErrorPageContent } from '@src/components/common/error-page-content'
import { ModalProps } from '@src/hook/use-modal'
import { usePrevPath } from '@src/hook/use-prev-path'
import { ProductDetail } from '../../product-detail'

type Props = ModalProps & {
  productId: string
}

export const ProductDetailDrawer = ({ isOpen, onClose, productId }: Props) => {
  const productDetailQuery = useProductDetailQuery(productId)
  const { prevPath } = usePrevPath()

  return (
    <FullDrawer isOpen={isOpen}>
      <FullDrawerHeader
        hasLogo={!prevPath}
        onBack={!!prevPath ? onClose : undefined}
      />
      {productDetailQuery.isLoading ? (
        <LoadingSpinner />
      ) : productDetailQuery.isError ? (
        <PageWrapper centered>
          <ErrorPageContent
            content={`상품 정보를 불러오는 데에 실패했습니다.\n잠시 후 다시 시도해주시기 바랍니다.`}
          />
        </PageWrapper>
      ) : (
        <>
          <ProductDetail productDetail={productDetailQuery.data} isDrawer />
        </>
      )}
    </FullDrawer>
  )
}
