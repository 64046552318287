import { format, parseISO } from 'date-fns'

export const defaultDate = new Date().toDateString()

export enum DateFormatTypes {
  FullDate = 'yyyy.MM.dd HH:mm:ss',
  DateOnly = 'yyyy.MM.dd',
  DateOnlyWithSpace = 'yyyy. MM. dd',
  DateAndTime = 'yyyy.MM.dd HH:mm',
  StartDate = 'yyyy-MM-dd 00:00:00',
  EndDate = 'yyyy-MM-dd 23:59:59',
  DoubleLine = 'yyyy.MM.dd\nHH:mm:ss',
  DateInKorean = 'yyyy년 MM월 dd일',
  YearAndMonth = 'yyyy.MM',
  YearAndMonthWithoutPeriod = 'yyyyMM',
  YearOnly = 'yyyy',
  MonthOnly = 'MM',
  TimeOnly = 'HH:mm',
  FileFormat = 'yyyy_MM_dd_HH_mm_ss',
}

export function formatDate(
  date: Date | string | undefined,
  dateFormat: DateFormatTypes = DateFormatTypes.FullDate
): string {
  if (date === undefined) {
    return ''
  }

  if (typeof date === 'string') {
    date = parseISO(date)
    date = new Date(date)
  }

  if (dateFormat === DateFormatTypes.DoubleLine) {
    const lines = DateFormatTypes.DoubleLine.split('\n')
    return `${format(date, lines[0])}\n${format(date, lines[1])}`
  }

  return format(date, dateFormat)
}
