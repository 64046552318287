import { Box, HStack, Text, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import {
  ExpandableBox,
  ExpandButton,
  HDivider,
  UnderlineButton,
} from '@src/components/common'
import { useToggle } from '@src/hook/use-toggle'
import { LikeOrDislike, Review } from '@src/types/review'
import { DateFormatTypes, formatDate } from '@src/utils/format-date'
import { isLogin } from '@src/utils/is-login'
import { useEffect, useRef, useState } from 'react'
import { PhotoItem } from '../photo-review/photo-item'
import { LikeDislike } from './like-dislike'
import { ReviewScoreStars } from './review-score-stars'

const commentsLineHeight = 17

export type ReviewContentProps = {
  review: Review
  isMine?: boolean
  ellipseComments?: boolean
  showLikeDislike?: boolean
  onClickReport?: (reviewId: string) => void
  onClickReplies?: (reviewId: string) => void
  onClickPhoto?: (reviewId: string) => void
}

export const ReviewContent = ({
  review,
  isMine,
  ellipseComments,
  showLikeDislike = true,
  onClickReport,
  onClickReplies,
  onClickPhoto,
}: ReviewContentProps) => {
  const {
    id,
    score,
    userNickname,
    userId,
    createDt,
    transactionSkuTitle,
    comments,
    likeCount,
    dislikeCount,
    reviewReplies,
    isUserLikesDislikesOnThisReview,
  } = review

  const userLikeOrDislike: LikeOrDislike =
    isUserLikesDislikesOnThisReview === true
      ? 'like'
      : isUserLikesDislikesOnThisReview === false
      ? 'dislike'
      : null

  return (
    <Box>
      <VStack spacing={16} padding={20}>
        <HStack spacing={8}>
          <ReviewScoreStars score={score} />
          <Text
            fontSize={12}
            fontColor="gray.600"
            fontWeight="light"
            lineHeight={1.2}
          >
            {userNickname || userId || ''}
          </Text>
          <Text
            fontSize={12}
            fontColor="gray.600"
            fontWeight="light"
            lineHeight={1.2}
          >
            {formatDate(createDt, DateFormatTypes.DateOnly)}
          </Text>
        </HStack>

        <VStack spacing={10}>
          <VStack spacing={8}>
            <Text
              fontSize={12}
              fontColor="gray.500"
              fontWeight="light"
              lineHeight={1.3}
            >
              구매옵션: {transactionSkuTitle}
            </Text>
          </VStack>
          <Comments comments={comments} ellipseComments={ellipseComments} />
          {review.images.mobile.length > 0 && !!onClickPhoto && (
            <Box
              display="grid"
              gridTemplateColumns="repeat(4, 1fr)"
              columnGap={8}
            >
              {review.images.mobile.slice(0, 4).map((image, index) => (
                <PhotoItem
                  key={index}
                  imageUrl={image}
                  isMoreButton={review.images.mobile.length > 4 && index === 3}
                  onClick={() => onClickPhoto(review.id)}
                />
              ))}
            </Box>
          )}
          {(showLikeDislike || !!onClickReport || !!onClickReplies) && (
            <HStack justifyContent="space-between">
              {showLikeDislike && (
                <LikeDislike
                  likeCount={likeCount}
                  dislikeCount={dislikeCount}
                  userLikeOrDislike={userLikeOrDislike}
                  reviewId={id}
                  disabled={!isLogin() || isMine}
                />
              )}
              <HStack spacing={32}>
                {!!onClickReport && !isMine && (
                  <UnderlineButton
                    label="신고하기"
                    onClick={() => onClickReport(review.id)}
                  />
                )}
                {!!onClickReplies && reviewReplies.length > 0 && (
                  <UnderlineButton
                    label="답글보기"
                    onClick={() => onClickReplies(review.id)}
                  />
                )}
              </HStack>
            </HStack>
          )}
        </VStack>
      </VStack>
      <HDivider thickness={1} backgroundColor="gray.100" />
    </Box>
  )
}

type CommentsProps = {
  comments: string
  ellipseComments?: boolean
}

const Comments = ({ comments, ellipseComments }: CommentsProps) => {
  const textRef = useRef<HTMLSpanElement>(null)

  const [isEllipse, setIsEllipse] = useState<boolean>(false)
  const [isOpen, toggleIsOpen] = useToggle(!ellipseComments)

  useEffect(() => {
    const text = textRef.current
    const parent = text?.parentElement

    if (!text || !parent || !ellipseComments) {
      return
    }

    const isEllipse =
      !!ellipseComments && text.clientHeight > parent.clientHeight
    setIsEllipse(isEllipse)
  }, [ellipseComments])

  return (
    <HStack alignItems="flex-end">
      <ExpandableBox
        minHeight={commentsLineHeight * 2}
        flex={1}
        expanded={isOpen}
        position="relative"
      >
        {isEllipse && !isOpen && (
          <Box position="absolute" top="0" left="0">
            <Text
              fontSize={13}
              lineHeight={parseSize(commentsLineHeight)}
              fontWeight="light"
              lineClamp={2}
            >
              {comments}
            </Text>
          </Box>
        )}
        <Text
          ref={textRef}
          fontSize={13}
          lineHeight={parseSize(commentsLineHeight)}
          opacity={!isEllipse || isOpen ? 1 : 0}
          fontWeight="light"
        >
          {comments}
        </Text>
      </ExpandableBox>
      {isEllipse && (
        <ExpandButton size={20} expanded={isOpen} onClick={toggleIsOpen} />
      )}
    </HStack>
  )
}
