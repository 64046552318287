import styled from '@emotion/styled'
import { Color, Size } from '@src/components/basic/types'
import { parseColor, parseSize } from '@src/components/basic/utils'

const iconVariant = {
  filled: 'material-icons',
  outlined: 'material-icons-outlined',
} as const

const iconNames = {
  home: 'home',
  person: 'person',
  people: 'people',
  card: 'credit_card',
  basket: 'shopping_basket',
  my: 'person',
  notifications: 'notifications',
  hexagonNotifications: 'report',
  fileDownload: 'file_download',
  moreOptions: 'more_vert',
  expandMore: 'expand_more',
  expandLess: 'expand_less',
  addImage: 'add_photo_alternate',
  image: 'image',
  add: 'add',
  cancel: 'cancel',
  close: 'close',
  frame: 'crop_free',
  editNote: 'edit_note',
  redo: 'turn_right',
  undo: 'turn_left',
  edit: 'edit',
  highlight: 'border_color',
  spray: 'auto_fix_high',
  brush: 'brush',
  fileUpload: 'file_upload',
  radioChecked: 'radio_button_checked',
  radioUnchecked: 'circle',
  verified: 'verified',
  private: 'lock',
  gift: 'card_giftcard',
  menu: 'menu',
  heart: 'favorite',
  barcode: 'qr_code_2',
  heartEmpty: 'favorite_border',
  ciUnchecked: 'gpp_bad',
  settings: 'settings',
  information: 'info',
  star: 'star',
  moneyCharge: 'outbox',
  moneyWithdraw: 'local_atm',
  moneyExchange: 'swap_horiz',
  back: 'arrow_back_ios_new',
  next: 'arrow_forward_ios',
  done: 'check_circle',
  camera: 'photo_camera',
  checkboxBlank: 'check_box_outline_blank',
  checkboxChecked: 'check_box',
  checkboxCircleBlank: 'radio_button_unchecked',
  checkboxCircleChecked: 'check_circle',
  multipleStars: 'auto_awesome',
  box: 'inventory_2',
  voucher: 'confirmation_number',
  share: 'share',
  shipping: 'local_shipping',
  link: 'link',
  remove: 'remove',
  backspace: 'backspace',
  arrowDropDown: 'arrow_drop_down',
  warehouse: 'warehouse',
  rocket: 'rocket_launch',
  filter: 'filter_list',
  check: 'done',
  search: 'search',
  title: 'title',
  gesture: 'gesture',
  colorFill: 'format_color_fill',
  like: 'thumb_up_alt',
  dislike: 'thumb_down_alt',
  delete: 'delete',
  cart: 'shopping_cart',
  campaign: 'campaign',
  expand: 'expand',
  colorize: 'colorize',
  linearGradient: 'east',
  verticalGradient: 'arrow_downward',
  diagonalGradient: 'south_east',
  reverseLinearGradient: 'sync_alt',
  formatBold: 'format_bold',
  formatItalic: 'format_italic',
  formatUnderline: 'format_underlined',
  formatStrikeThrough: 'format_strikethrough',
  invertColors: 'invert_colors',
  formatAlignLeft: 'format_align_left',
  formatAlignRight: 'format_align_right',
  formatAlignCenter: 'format_align_center',
  addCircleOutline: 'add_circle_outline',
  refresh: 'refresh',
  info: 'info',
}

type IconVariant = keyof typeof iconVariant
export type IconName = keyof typeof iconNames

type IconProps = {
  variant?: IconVariant
  size?: Size
  color?: Color
  backgroundColor?: Color
  iconName: IconName
  onClick?: () => void
}

export const Icon = ({
  variant = 'filled',
  size = 26,
  backgroundColor = 'unset',
  iconName,
  color = 'purple.dark.200',
  onClick,
}: IconProps) => {
  return (
    <MaterialIcon
      size={size}
      color={color}
      backgroundColor={backgroundColor}
      className={iconVariant[variant]}
      onClick={onClick}
    >
      {iconNames[iconName]}
    </MaterialIcon>
  )
}

type MaterialIconProps = {
  color: Color
  size: Size
  backgroundColor?: Color
}

const MaterialIcon = styled.span<MaterialIconProps>`
  color: ${({ color }) => parseColor(color)};
  background-color: ${({ backgroundColor }) => parseColor(backgroundColor)};
  vertical-align: middle;
  cursor: inherit;
  user-select: none;
  &.material-icons,
  &.material-icons-outlined {
    font-size: ${({ size }) => parseSize(size)};
  }
`
