import { QuantityCounter } from '@src/components/shopping/common/quantity-counter'
import { Product } from '@src/types/product'
import { useCallback } from 'react'
import { maxOrderCount } from '../../../_constants/max-order-count'
import {
  useSkuCounts,
  useSkuCountsUpdater,
} from '../../_contexts/sku-counts-context'

type Props = {
  productDetail: Product
}

export const SingleOption = ({ productDetail }: Props) => {
  const { skuCounts } = useSkuCounts()
  const { addSku, subSku } = useSkuCountsUpdater()

  const sku = productDetail.skuList[0]
  const skuCount = skuCounts[sku.id]
  const maxCount = Math.min(sku.leftInventoryCount, maxOrderCount)

  const add = useCallback(() => addSku(sku.id), [addSku, sku.id])
  const sub = useCallback(() => subSku(sku.id), [subSku, sku.id])

  return (
    <QuantityCounter
      value={skuCount}
      maxValue={maxCount}
      onClickAdd={add}
      onClickSub={sub}
      variant="product-detail"
      disabled={productDetail.isVoucher}
    />
  )
}
