import { Box, BoxProps, HStack, Text } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { Clickable, HDivider, Modal } from '@src/components/common'
import { ModalProps } from '@src/hook/use-modal'
import { maxScreenWidth } from '@src/styles/theme'
import { MouseEventHandler } from 'react'

export type PopupProps = BoxProps & {
  content?: React.ReactNode
  closeCallback?: () => void
  buttons?: {
    label: string
    onClick?: MouseEventHandler<HTMLElement>
  }[]
}

type Props = PopupProps & ModalProps

export const Popup = ({
  isOpen,
  onClose,
  content,
  closeCallback,
  buttons = [{ label: '확인' }],
  ...boxProps
}: Props) => {
  const handleClose = () => {
    onClose()
    closeCallback && closeCallback()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      width={`calc(min(100%, ${parseSize(maxScreenWidth)}) - ${parseSize(32)})`}
      borderRadius={10}
      paddingBottom={4}
      {...boxProps}
    >
      <Box
        centered
        minHeight={124}
        paddingTop={48}
        paddingBottom={32}
        paddingX={16}
      >
        <Text
          fontSize={15}
          fontWeight="medium"
          whiteSpace="pre-wrap"
          textAlign="center"
        >
          {content}
        </Text>
      </Box>
      <HDivider backgroundColor="gray.100" thickness={1} />
      <HStack>
        {buttons.map((button, index) => (
          <Clickable
            key={index}
            onClick={(e) => {
              button.onClick && button.onClick(e)
              handleClose()
            }}
            type="button"
            height={40}
            flexGrow={1}
            centered
          >
            <Text fontColor="primary" fontWeight="bold">
              {button.label}
            </Text>
          </Clickable>
        ))}
      </HStack>
    </Modal>
  )
}
