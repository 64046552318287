import styled from '@emotion/styled'
import Alert, { AlertProps } from '@src/contexts/alert-context/alert'
import { LoadingSpinner } from '@src/components/common'
import { useModal } from '@src/hook/use-modal'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

type ProviderProps = {
  children?: React.ReactNode
}

type ContextValue = {
  showAlert: (props: AlertProps) => void
  setIsLoading: (isLoading: boolean) => void
}

type HookOptions = {
  isLoading?: boolean
}

const AlertContext = createContext<ContextValue | null>(null)

export const AlertProvider = ({ children }: ProviderProps) => {
  const [isOpen, open, close] = useModal()
  const [alertProps, setAlertProps] = useState<AlertProps | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const showAlert = useCallback(
    (alertProps: AlertProps) => {
      setAlertProps(alertProps)
      open()
    },
    [open]
  )

  const contextValue = useMemo(() => ({ showAlert, setIsLoading }), [showAlert])

  return (
    <AlertContext.Provider value={contextValue}>
      {children}
      <Alert isOpen={isOpen} onClose={close} {...alertProps} />
      {isLoading && (
        <LoadingOverlay>
          <LoadingSpinner />
        </LoadingOverlay>
      )}
    </AlertContext.Provider>
  )
}

const LoadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
`

export const useAlert = ({ isLoading }: HookOptions = {}) => {
  const contextValue = useContext(AlertContext)
  if (!contextValue) {
    throw Error('Cannot found AlertProvider!')
  }

  const { showAlert, setIsLoading } = contextValue

  useEffect(() => {
    setIsLoading(!!isLoading)
  }, [isLoading, setIsLoading])

  return showAlert
}
