import { routes } from '@src/common/constants/routes'

type PathName = string

type BackAction = {
  backUrl: string
  /**
   * If URL query exist, push to pathname before push to `backUrl`.
   *
   * For full-drawer pages.
   */
  checkUrlQuery?: boolean
}

export const defaultBackActionMap: Record<PathName, BackAction> = {
  [routes.my.login]: {
    backUrl: routes.base.home,
  },
  [routes.my.cards]: {
    backUrl: routes.my.home,
  },
  [routes.my.order.history]: {
    backUrl: routes.my.home,
    checkUrlQuery: true,
  },
  [routes.my.order.claims]: {
    backUrl: routes.my.home,
    checkUrlQuery: true,
  },
  [routes.my.reviews]: {
    backUrl: routes.my.home,
  },
  [routes.my.productInquiries]: {
    backUrl: routes.my.home,
  },
  [routes.my.wishlist]: {
    backUrl: routes.my.home,
  },
  [routes.my.address]: {
    backUrl: routes.my.home,
  },
  [routes.shopping.category]: {
    backUrl: routes.shopping.home,
  },
  [routes.shopping.categoryResult]: {
    backUrl: routes.shopping.category,
  },
  [routes.shopping.custom]: {
    backUrl: routes.shopping.home,
  },
  [routes.shopping.cartOrder]: {
    backUrl: routes.shopping.home,
  },
  [routes.shopping.directOrder]: {
    backUrl: routes.shopping.home,
  },
}
