import { Box } from '@src/components/basic'
import { HDivider, LoadingSpinner } from '@src/components/common'
import { blurImageUrl } from '@src/components/common/card'
import { ProductPageSettingDto } from '@src/types/channel'
import Image from 'next/image'

type ProductDetailBannerProps = {
  productPageSetting?: ProductPageSettingDto
}

export const ProductDetailBanner = ({
  productPageSetting,
}: ProductDetailBannerProps) => {
  const bannerImage = productPageSetting?.bannerImages[0]

  if (productPageSetting && !bannerImage) {
    return null
  }

  return (
    <>
      <Box centered flexShrink={0} backgroundColor="gray.200">
        {!productPageSetting && <LoadingSpinner />}

        {productPageSetting && (
          <Image
            src={productPageSetting.bannerImages[0] || blurImageUrl}
            alt="banner"
            width={0}
            height={0}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
          />
        )}
      </Box>
      <HDivider backgroundColor="gray.100" />
    </>
  )
}
