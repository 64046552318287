import { Text } from '@src/components/basic'
import { TextComponentProps } from '@src/components/common'
import React from 'react'

type BrandNameProps = TextComponentProps & {
  value?: string | null
}

export const BrandName = ({ value, ...props }: BrandNameProps) => {
  if (!value) {
    return null
  }

  return (
    <Text
      as={'h2'}
      color={'purple.light.100'}
      fontSize={12}
      fontWeight={'light'}
      {...props}
    >
      [{value}]
    </Text>
  )
}
