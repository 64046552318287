export type ProductInquiryDto = {
  channelId: string
  createDt: string
  email: string
  id: string
  inquirerId: string
  inquiryContents: string
  inquiryTitle: string
  isDisplayed: boolean
  name: string
  phone: string
  productBrand: string
  productId: string
  productImages: string[]
  productSkuId: string
  productSkuTitle: string
  productTitle: string
  status: InquiryStatusEnum
  updateDt: string
}

export enum InquiryStatusEnum {
  NotAnswered = 'not_answered',
  Answered = 'answered',
  DeletedByInquirer = 'deleted_by_inquirer',
}
