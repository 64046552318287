import { SkuStatusEnum } from '@src/types/transaction'
import { SkuButtonTypeEnum } from './sku-button-label-map'

export const statusInfoMap: Record<
  SkuStatusEnum,
  {
    name: string
    detailName: string
    buttonList: SkuButtonTypeEnum[]
  }
> = {
  [SkuStatusEnum.ShipOkDirectDelivery]: {
    name: '출고완료',
    detailName: '직접배송',
    buttonList: [],
  },
  [SkuStatusEnum.OrderFinished]: {
    name: '구매확정',
    detailName: '',
    buttonList: [SkuButtonTypeEnum.WriteReview],
  },
  [SkuStatusEnum.OrderFinishedReviewed]: {
    name: '구매확정',
    detailName: '',
    buttonList: [SkuButtonTypeEnum.ReviewCompleted],
  },
  [SkuStatusEnum.PaymentRequired]: {
    name: '결제대기',
    detailName: '',
    buttonList: [SkuButtonTypeEnum.CancelOrder],
  },
  [SkuStatusEnum.PaymentFailValidation]: {
    name: '결제인증 실패',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.PaymentFailInvalidPaymentTrial]: {
    name: '잘못된 방식의 결제 유도',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.PaymentFailTimeOut]: {
    name: '결제실패',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.PaymentFailError]: {
    name: '결제실패',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.CheckRequired]: {
    name: '결제완료',
    detailName: '',
    buttonList: [SkuButtonTypeEnum.CancelOrder],
  },
  [SkuStatusEnum.OrderFailCheckRejected]: {
    name: '주문실패',
    detailName: '접수거절',
    buttonList: [],
  },
  [SkuStatusEnum.ShipRequired]: {
    name: '접수완료',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.ShipOk]: {
    name: '출고완료',
    detailName: '출고완료',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.OrderFailShipRejected]: {
    name: '주문실패',
    detailName: '출고거절',
    buttonList: [],
  },
  [SkuStatusEnum.ShipDelay]: {
    name: '출고지연',
    detailName: '출고지연',
    buttonList: [],
  },
  [SkuStatusEnum.DeliveryIng]: {
    name: '배송중',
    detailName: '',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.DeliveryOk]: {
    name: '배송완료',
    detailName: '',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.DeliveryDelay]: {
    name: '배송지연',
    detailName: '',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.RefundRequested]: {
    name: '반품요청',
    detailName: '반품요청',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestCompleted,
      SkuButtonTypeEnum.ExchangeRequestDisabled,
    ],
  },
  [SkuStatusEnum.RefundChecked]: {
    name: '반품요청',
    detailName: '접수완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestCompleted,
      SkuButtonTypeEnum.ExchangeRequestDisabled,
    ],
  },
  [SkuStatusEnum.RefundAgreed]: {
    name: '반품진행중',
    detailName: '판매자 동의완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestCompleted,
      SkuButtonTypeEnum.ExchangeRequestDisabled,
    ],
  },
  [SkuStatusEnum.RefundFailCheckRejected]: {
    name: '반품거절',
    detailName: '반품접수거절',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.RefundFailAgreeRejected]: {
    name: '반품거절',
    detailName: '반품동의거절',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.RefundFailInspectRejected]: {
    name: '반품실패',
    detailName: '검수부결-기존 상품 배송중',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.RefundFailInspectRejectedDo]: {
    name: '반품실패',
    detailName: '검수부결-기존 상품 배송완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.RefundFailInspectRejectedDd]: {
    name: '반품실패',
    detailName: '검수부결-기존 상품 배송지연',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.RefundFailReturnNo]: {
    name: '반품실패',
    detailName: '반품 미수령',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.RefundReturnOk]: {
    name: '반품진행중',
    detailName: '상품 수령완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestCompleted,
      SkuButtonTypeEnum.ExchangeRequestDisabled,
    ],
  },
  [SkuStatusEnum.RefundInspectPass]: {
    name: '반품진행중',
    detailName: '검수완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestCompleted,
      SkuButtonTypeEnum.ExchangeRequestDisabled,
    ],
  },
  [SkuStatusEnum.RefundIngOrderCanceled]: {
    name: '환불대기중',
    detailName: '주문취소',
    buttonList: [SkuButtonTypeEnum.CancelOrderCompleted],
  },
  [SkuStatusEnum.RefundFinishedNormal]: {
    name: '환불완료',
    detailName: '반품완료',
    buttonList: [],
  },
  [SkuStatusEnum.RefundFinishedOrderCanceled]: {
    name: '환불완료',
    detailName: '주문취소',
    buttonList: [],
  },
  [SkuStatusEnum.RefundFinishedOrderCanceledConfirmed]: {
    name: '환불완료',
    detailName: '주문취소확인',
    buttonList: [],
  },
  [SkuStatusEnum.RefundFinishedCheckRejected]: {
    name: '환불완료',
    detailName: '접수거절',
    buttonList: [],
  },
  [SkuStatusEnum.RefundFinishedShipRejected]: {
    name: '환불완료',
    detailName: '출고거절',
    buttonList: [],
  },
  [SkuStatusEnum.ExchangeRequested]: {
    name: '교환요청',
    detailName: '교환요청',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestDisabled,
      SkuButtonTypeEnum.ExchangeRequestCompleted,
    ],
  },
  [SkuStatusEnum.ExchangeChecked]: {
    name: '교환요청',
    detailName: '접수완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestDisabled,
      SkuButtonTypeEnum.ExchangeRequestCompleted,
    ],
  },
  [SkuStatusEnum.ExchangeAgreed]: {
    name: '교환진행중',
    detailName: '판매자 동의완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestDisabled,
      SkuButtonTypeEnum.ExchangeRequestCompleted,
    ],
  },
  [SkuStatusEnum.ExchangeFailCheckRejected]: {
    name: '교환실패',
    detailName: '교환접수거절',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.ExchangeFailAgreeRejected]: {
    name: '교환실패',
    detailName: '교환동의거절',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.ExchangeFailInspectRejected]: {
    name: '교환실패',
    detailName: '기존 상품 배송중',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.ExchangeFailInspectRejectedDo]: {
    name: '교환거절',
    detailName: '기존 상품 배송완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.ExchangeFailInspectRejectedDd]: {
    name: '교환거절',
    detailName: '기존 상품 배송지연',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.ExchangeFailReshipRejected]: {
    name: '교환거절',
    detailName: '기존 상품 배송중',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.ExchangeFailReshipRejectedDo]: {
    name: '교환거절',
    detailName: '기존 상품 배송완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.ExchangeFailReshipRejectedDd]: {
    name: '교환거절',
    detailName: '기존 상품 배송지연',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.ExchangeFailReturnNo]: {
    name: '교환실패',
    detailName: '기존 상품 미수령',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.ExchangeReturnOk]: {
    name: '교환진행중',
    detailName: '기존 상품 수령완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestDisabled,
      SkuButtonTypeEnum.ExchangeRequestCompleted,
    ],
  },
  [SkuStatusEnum.ExchangeInspectPass]: {
    name: '교환진행중',
    detailName: '기존 상품 검수완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestDisabled,
      SkuButtonTypeEnum.ExchangeRequestCompleted,
    ],
  },
  [SkuStatusEnum.ExchangeReshipOk]: {
    name: '교환진행중',
    detailName: '새상품 출고완료',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestDisabled,
      SkuButtonTypeEnum.ExchangeRequestCompleted,
    ],
  },
  [SkuStatusEnum.ExchangeReshipDelay]: {
    name: '새상품 출고지연',
    detailName: '',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchaseDisabled,
      SkuButtonTypeEnum.RefundRequestDisabled,
      SkuButtonTypeEnum.ExchangeRequestCompleted,
    ],
  },
  [SkuStatusEnum.ExchangeDeliveryIng]: {
    name: '교환진행중',
    detailName: '새상품 배송중',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.ExchangeDeliveryOk]: {
    name: '교환완료',
    detailName: '',
    buttonList: [
      SkuButtonTypeEnum.ConfirmPurchase,
      SkuButtonTypeEnum.RefundRequest,
      SkuButtonTypeEnum.ExchangeRequest,
    ],
  },
  [SkuStatusEnum.ExchangeDeliveryDelay]: {
    name: '교환진행중',
    detailName: '새상품 배송지연',
    buttonList: [SkuButtonTypeEnum.DeliveryTracking],
  },
  [SkuStatusEnum.OrderCancelled]: {
    name: '',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.CheckRejected]: {
    name: '',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.ShipRejected]: {
    name: '',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.Received]: {
    name: '사용가능',
    detailName: '',
    buttonList: [SkuButtonTypeEnum.CancelOrder],
  },
  [SkuStatusEnum.Expired]: {
    name: '기간만료',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.ExpiredConfirmed]: {
    name: '환불완료',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.Used]: {
    name: '사용완료',
    detailName: '',
    // NOTE: (Hans) 상품권 리뷰기능 정상화될때까지 주석처리
    buttonList: [], // [StatusButtonLabel.WriteReview],
  },
  [SkuStatusEnum.UsedPartial]: {
    name: '사용가능(부분사용)',
    detailName: '',
    // NOTE: (Hans) 상품권 리뷰기능 정상화될때까지 주석처리
    buttonList: [], // [StatusButtonLabel.WriteReview],
  },
  [SkuStatusEnum.UsedConfirmed]: {
    name: '사용완료',
    detailName: '',
    // NOTE: (Hans) 상품권 리뷰기능 정상화될때까지 주석처리
    buttonList: [], // [StatusButtonLabel.WriteReview],
  },
  [SkuStatusEnum.IssueCancelled]: {
    name: '환불완료',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.ManuallyIssueRequired]: {
    name: '발행예정',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.ManuallyIssueConfirmed]: {
    name: '발행완료',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.ManuallyIssueCancelling]: {
    name: '취소요청',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.RefundFinishedManuallyIssueRejected]: {
    name: '환불완료',
    detailName: '',
    buttonList: [],
  },
  [SkuStatusEnum.RefundFinishedManuallyIssueCancelled]: {
    name: '환불완료',
    detailName: '',
    buttonList: [],
  },
}
