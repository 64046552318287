import { Box } from '@src/components/basic'
import { Color } from '@src/components/basic/types'
import { LoadingSpinner } from '@src/components/common'

type LoadingOverlayProps = {
  fixed?: boolean
  overlayColor?: Color
}

export const LoadingOverlay = ({
  fixed,
  overlayColor,
}: LoadingOverlayProps) => {
  return (
    <Box
      position={fixed ? 'fixed' : 'absolute'}
      inset="0"
      backgroundColor={overlayColor}
    >
      <LoadingSpinner />
    </Box>
  )
}
