import { RefObject, useRef } from 'react'

export const useComputedHeight = (
  defaultHeight = 0
): [RefObject<HTMLDivElement>, number] => {
  const ref = useRef<HTMLDivElement>(null)
  const computedHeight = ref.current?.scrollHeight || defaultHeight

  return [ref, computedHeight]
}
