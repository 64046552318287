import { useProductDetailQuery } from '@api/hooks/product/use-product-detail'
import { initiatorTypeLabelMap } from '@src/common/constants/maps/initiator-type-label'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import {
  Accordion,
  FullDrawer,
  FullDrawerHeader,
  HDivider,
  LoadingSpinner,
  PageWrapper,
} from '@src/components/common'
import { ModalProps } from '@src/hook/use-modal'
import { Review } from '@src/types/review'
import { InitiatorType } from '@src/types/transaction'
import { DateFormatTypes, formatDate } from '@src/utils/format-date'
import { InquiryProductInfo } from '../product-detail/inquiries/inquiry-product-info'
import { ReviewContent } from './common/review-content'

type Props = ModalProps & {
  review?: Review
}

export const ReviewRepliesDrawer = ({ isOpen, onClose, review }: Props) => {
  const productDetailQuery = useProductDetailQuery(review?.sellableProductId)

  return (
    <FullDrawer isOpen={isOpen}>
      <FullDrawerHeader title="리뷰 답글보기" onClose={onClose} />
      <PageWrapper>
        {!review || !productDetailQuery.isSuccess ? (
          <LoadingSpinner />
        ) : (
          <>
            <Box padding={16}>
              <InquiryProductInfo
                title={productDetailQuery.data.title}
                brand={productDetailQuery.data.brand}
                imageUrl={productDetailQuery.data.images[0]}
                skuTitle={
                  productDetailQuery.data.isManyOptions
                    ? review.transactionSkuTitle
                    : undefined
                }
              />
            </Box>
            <HDivider backgroundColor="gray.100" thickness={1} />
            <ReviewContent review={review} showLikeDislike={false} />
            <HDivider backgroundColor="gray.100" thickness={7} />
            <VStack>
              {review.reviewReplies.map((reply, index) => (
                <Accordion
                  key={reply.id}
                  label={
                    review.reviewReplies.length === 1
                      ? '답변'
                      : `답변${index + 1}`
                  }
                  initialExpanded={index === 0}
                >
                  <VStack spacing={8}>
                    <HStack justifyContent="space-between">
                      <Text
                        fontSize={13}
                        fontWeight="light"
                        fontColor="purple.dark.200"
                      >
                        {reply.initiatorType === InitiatorType.ChannelOwner
                          ? `[${reply.initiatorName}]`
                          : `${initiatorTypeLabelMap[reply.initiatorType]}`}
                      </Text>
                      <Text
                        fontSize={13}
                        fontWeight="light"
                        fontColor="purple.dark.200"
                      >
                        {formatDate(reply.createDt, DateFormatTypes.DateOnly)}
                      </Text>
                    </HStack>

                    <Text fontSize={13} fontWeight="light" fontColor="gray.700">
                      {reply.comments}
                    </Text>
                  </VStack>
                </Accordion>
              ))}
            </VStack>
          </>
        )}
      </PageWrapper>
    </FullDrawer>
  )
}
