import { Box, BoxProps } from '@src/components/basic'
import { colors } from '@src/styles/theme'
import { CardDivisionEnum, CardTypeEnum } from '@src/types/card'
import {
  isEventLimitedCard,
  isPublishedCard,
} from '@src/utils/card-classification-utils'
import { Badge } from '../badge'

type Props = BoxProps & {
  cardDivision: CardDivisionEnum
  cardType: CardTypeEnum
}

export const CardBadge = ({ cardDivision, cardType, ...boxProps }: Props) => {
  return (
    <Box {...boxProps}>
      {isPublishedCard({ cardDivision, cardType }) && (
        <Badge
          text={'MY DESIGN'}
          backgroundColor={'purple.dark.100'}
          padding={'7px 8px'}
          fontSize={11}
        />
      )}
      {isEventLimitedCard({ cardDivision, cardType }) && (
        <Badge
          text={'Limited'}
          iconName={'multipleStars'}
          padding={'7px 8px'}
          background={colors.gradient.primary}
          fontSize={11}
        />
      )}
    </Box>
  )
}
