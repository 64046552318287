import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import { Color } from '../types'
import { parseColor } from '../utils'

export type BackgroundProps = {
  background?: CSSProperties['background']
  backgroundColor?: Color
  backgroundClip?: CSSProperties['backgroundClip']
}

export const backgroundStyles = (props: BackgroundProps) => {
  const { background, backgroundColor, backgroundClip } = props

  return css`
    background: ${background};
    background-color: ${parseColor(backgroundColor)};
    background-clip: ${backgroundClip};
  `
}
