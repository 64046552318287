import { SkuButtonTypeEnum } from '@src/components/shopping/order/_constants/sku-button-label-map'
import { statusInfoMap } from '@src/components/shopping/order/_constants/status-info-map'
import { BasicAddressDto } from '@src/types/address'
import { ImageUrlsDto } from '@src/types/image'
import {
  PaymentInfoDto,
  PaymentMethodDto,
  ReceiverAddressDto,
  SkuClaimInfoDto,
  SkuStatusEnum,
} from '../dto'

export class SkuClaimInfo {
  public readonly senderName: string
  public readonly senderPhone: string
  public readonly image: string
  public readonly productTitle: string
  public readonly title: string
  public readonly quantity: number
  public readonly sellPrice: number
  public readonly id: string
  public readonly status: SkuStatusEnum
  public readonly refundDeliveryFee: number
  public readonly exchangeDeliveryFee: number
  public readonly refundAmount: number
  public readonly transactionSn: string
  public readonly paymentMethod: PaymentMethodDto
  public readonly paymentInfo: PaymentInfoDto
  public readonly receiverAddress: ReceiverAddressDto
  public readonly refundAddress: BasicAddressDto
  public readonly message: string
  public readonly imageUrls?: ImageUrlsDto
  public readonly initialDeliveryFeeFree: boolean
  public readonly note?: string
  public readonly attributeOptions?: {
    sellableSkuId: string
    title: string
  }

  public readonly isClaimed: boolean

  constructor(dto: SkuClaimInfoDto) {
    this.senderName = dto.senderName
    this.senderPhone = dto.senderPhone
    this.image = dto.image
    this.productTitle = dto.productTitle
    this.title = dto.title
    this.quantity = dto.quantity
    this.sellPrice = dto.sellPrice
    this.id = dto.id
    this.status = dto.status
    this.refundDeliveryFee = dto.refundDeliveryFee
    this.exchangeDeliveryFee = dto.exchangeDeliveryFee
    this.refundAmount = dto.refundAmount
    this.transactionSn = dto.transactionSn
    this.paymentMethod = dto.paymentMethod
    this.paymentInfo = dto.paymentInfo
    this.receiverAddress = dto.receiverAddress
    this.refundAddress = dto.refundAddress
    this.message = dto.message
    this.imageUrls = dto.imageUrls
    this.initialDeliveryFeeFree = dto.initialDeliveryFeeFree
    this.note = dto.note
    this.attributeOptions = dto.attributeOptions

    this.isClaimed = this._getIsClaimed()
  }

  private _getIsClaimed() {
    return statusInfoMap[this.status].buttonList.some((buttonLabel) =>
      [
        SkuButtonTypeEnum.RefundRequestCompleted,
        SkuButtonTypeEnum.ExchangeRequestCompleted,
      ].includes(buttonLabel)
    )
  }
}
