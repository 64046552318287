import { BaseParams } from '@api/types'
import { ProductClassEnum } from '@src/types/product'

export type GetTransactionsRequestDto = BaseParams<TransactionOrderingEnum> & {
  statusIn?: string
  monthRange?: number
  productClass?: ProductClassEnum
}

enum TransactionOrderingEnum {
  PaidDateDesc = '-paid_date',
}
