import { queryKeys } from '@api/constants/query-keys'
import { staleTime } from '@api/constants/stale-time'
import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { ExtensionInstallStatusDto } from '@src/types/channel'
import ExtensionSettings from '@src/types/channel/entities/extension-settings.entity'
import { useQuery } from '@tanstack/react-query'

export const useExtensionSettingsQuery = () => {
  return useQuery(
    [queryKeys.channel.extensionSettings],
    () => getInstallStatus(),
    {
      staleTime: staleTime.infinity,
      select: (data) => new ExtensionSettings(data),
    }
  )
}

const getInstallStatus = () => {
  return api.channelClient.get<ExtensionInstallStatusDto[]>(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/extensions/install-status`
  )
}
