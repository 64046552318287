import { apiKeys } from '@src/config'
import { KakaoShareCustomMessage } from '@src/types/util-types/kakao-api'

type Params = Partial<KakaoShareCustomMessage['templateArgs']>

export const shareKakaoMessage = ({
  url,
  title,
  description,
  imageUrl,
  buttonLabel,
}: Params) => {
  if (typeof window === 'undefined' || !window.Kakao) {
    return
  }

  if (!window.Kakao.isInitialized()) {
    window.Kakao.init(apiKeys.kakaotalkJavaScriptKey)
  }

  window.Kakao.Share.sendCustom({
    templateId: apiKeys.kakaoMessageTemplateId,
    templateArgs: {
      url: url || '',
      homeUrl: window.location.origin,
      title: title || '',
      description: description || '',
      imageUrl: imageUrl || '',
      buttonLabel: buttonLabel || '',
    },
  })
}
