import { Text } from '@src/components/basic'
import { TextComponentProps } from '../text'

export const ResultHeading = ({ children, ...props }: TextComponentProps) => {
  return (
    <Text
      as="h1"
      fontSize={20}
      fontWeight="bold"
      fontColor={'purple.dark.200'}
      textAlign="center"
      {...props}
    >
      {children}
    </Text>
  )
}

export const ResultSubHeading = ({
  children,
  ...props
}: TextComponentProps) => {
  return (
    <Text as="h2" fontSize={11} fontColor="gray.700" {...props}>
      {children}
    </Text>
  )
}
