import { ImageUrlsDto } from '@src/types/image'
import { InitiatorType } from '@src/types/transaction'

export type ReviewDto = {
  id: string
  createDt: string
  score: number
  comments: string
  images: ImageUrlsDto
  status: ReviewStatusEnum
  likeCount: number
  dislikeCount: number
  isUserLikesDislikesOnThisReview: boolean | null
  userId: string
  userNickname: string
  transactionId: string
  transactionSkuId: string
  transactionSkuTitle: string
  quantity: number
  reportCount: number
  replyCount?: number
  transactionSn: string
  transactionProductSn: string
  sellableProductId: string
  sellableSkuId: string
  sellerPortalId: string
  supplierPortalId: string
  channelPortalId: string
  channelId: string
  channelName: string
  transactionProductTitle: string
  sellerName: string
  age: number
  gender: string
  reviewReplies: ReviewReplyDto[]
  reviewReports: ReviewReportDto[]
}

export type ReviewReplyDto = {
  id: string
  initiatorId: string
  initiatorPortalId: string
  comments: string
  status: string
  initiatorType: InitiatorType
  initiatorName: string
  createDt: string
}

export type ReviewReportDto = {
  id: string
  reviewId: string
  userId: string
  createDt: string
  reasonForAction: ReasonForActionEnum
  userName: string
  comments: string
}

export enum ReviewStatusEnum {
  Posted = 'posted',
  DeletedByCustomer = 'deleted_by_customer', // 삭제됨(작성자)
  DeletedByChannel = 'deleted_by_channel', // 삭제됨(관리자)
}

export enum ReasonForActionEnum {
  IrrelevantContent = 'irrelevant_content', // 상품과 관련 없는 내용
  CommercialPromotion = 'commercial_promotion', // 상업적 홍보
  PersonalDataLeakage = 'personal_data_leakage', // 개인정보 노출 위험
  ReproductionWithoutPermission = 'reproduction_without_permission', // 저작권 불법 도용 (타인의 글/사진 복제)
  OtherReason = 'other_reason', // 기타 (직접입력)
}
