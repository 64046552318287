import { VStack } from '@src/components/basic'
import { ProductClassEnum } from '@src/types/product'
import { Product } from '@src/types/product'
import { useSkuCounts } from '../../_contexts/sku-counts-context'
import { SelectedSkuList } from './selected-sku-list'
import { SkuSelect } from './sku-select'

type Props = {
  productDetail: Product
}

export const MultiOption = ({ productDetail }: Props) => {
  const { skuCounts } = useSkuCounts()

  return (
    <VStack spacing={8}>
      <SkuSelect
        skus={productDetail.skuList}
        disabled={Object.keys(skuCounts).length > 0}
      />
      <SelectedSkuList
        skus={productDetail.skuList}
        disabled={productDetail.productClass === ProductClassEnum.GiftCard}
      />
    </VStack>
  )
}
