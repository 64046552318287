import {
  ApiListResult,
  ApiListResult2,
  ApiListResult3,
  TempApiListResult, // NOTE: (Mia) 알림페이지의 하모니 api 사용을 위하여 임시로 추가한 타입입니다. 유찜 알림 api 업데이트 후 삭제가 필요합니다.
} from '@api/types'

export const getNextPageParam = (
  lastPage:
    | ApiListResult<unknown>
    | ApiListResult2<unknown>
    | ApiListResult3<unknown>
    | TempApiListResult<unknown> // NOTE: (Mia) 알림페이지의 하모니 api 사용을 위하여 임시로 추가한 타입입니다. 유찜 알림 api 업데이트 후 삭제가 필요합니다.
) => {
  // ApiListResult3
  if ('hasNextPage' in lastPage) {
    return lastPage.hasNextPage ? lastPage.page + 1 : null
  }

  // ApiListResult
  if ('nextAvailable' in lastPage) {
    return lastPage.nextAvailable ? lastPage.page + 1 : null
  }

  // ApiListResult2
  return lastPage.page + 1 <=
    Math.ceil(lastPage.total / lastPage.itemsPerPage) &&
    lastPage.items.length > 0
    ? lastPage.page + 1
    : null
}
