import { Box, HStack, Text, VStack } from '@src/components/basic'
import { Dropdown, HDivider, Icon } from '@src/components/common'
import { GenderEnum, ReviewOrderingEnum } from '@src/types/review'
import { Selectable } from '@src/types/util-types/selectable'
import { FilterButton } from '../common/filter-button'
import { DetailFilterProps } from './_hooks/use-detail-filter'
import { filterAge, filterScore } from './review-detail-filter-drawer'

const orderingOptions: Selectable<ReviewOrderingEnum>[] = [
  { label: '좋아요순', value: ReviewOrderingEnum.Like },
  { label: '최신순', value: ReviewOrderingEnum.Recent },
  { label: '평점 높은순', value: ReviewOrderingEnum.MaxScore },
  { label: '평점 낮은순', value: ReviewOrderingEnum.MinScore },
]

type Props = DetailFilterProps & {
  initialOrdering: ReviewOrderingEnum
  onChangeOrdering: (ordering: ReviewOrderingEnum) => void
  onClickDetailDrawerOpen: () => void
}

export const ReviewFilter = ({
  detailFilter,
  removeAge,
  removeScore,
  setGender,
  initialOrdering,
  onChangeOrdering,
  onClickDetailDrawerOpen,
}: Props) => {
  const { ages, scores, gender } = detailFilter
  const isFilterApplied = ages.length > 0 || scores.length > 0 || !!gender

  return (
    <VStack>
      <HStack height={50} paddingX={16} justifyContent="space-between">
        <FilterButton onClick={onClickDetailDrawerOpen} />
        <Dropdown
          initialSelected={orderingOptions.find(
            (option) => option.value === initialOrdering
          )}
          onChange={onChangeOrdering}
          options={orderingOptions}
          align="right"
          zIndex="dropdown"
        />
      </HStack>

      {isFilterApplied && (
        <HStack height={40} paddingX={16} spacing={6} overflowX="scroll">
          {filterAge.map(
            ({ value, label }) =>
              ages.includes(value) && (
                <AppliedFilterItem
                  key={value}
                  label={label}
                  value={value}
                  onClickRemove={removeAge}
                />
              )
          )}
          {filterScore
            .slice()
            .reverse()
            .map(
              ({ value, label }) =>
                scores.includes(value) && (
                  <AppliedFilterItem
                    key={value}
                    label={label}
                    value={value}
                    onClickRemove={removeScore}
                  />
                )
            )}
          {!!gender && (
            <AppliedFilterItem
              label={gender === GenderEnum.Female ? '여성' : '남성'}
              value={gender}
              onClickRemove={() => setGender(GenderEnum.None)}
            />
          )}
        </HStack>
      )}
      <HDivider thickness={1} backgroundColor="gray.100" />
    </VStack>
  )
}

type AppliedFilterItemProps = {
  label: string
  value: string
  onClickRemove: (value: string) => void
}

const AppliedFilterItem = ({
  label,
  value,
  onClickRemove,
}: AppliedFilterItemProps) => {
  const handleClick = () => {
    onClickRemove(value)
  }

  return (
    <Box
      borderRadius="full"
      height={28}
      paddingX={12}
      centered
      backgroundColor="gray.100"
    >
      <HStack spacing={2}>
        <Text fontSize={10} fontColor="gray.700" whiteSpace="nowrap">
          {label}
        </Text>
        <Box as="button" onClick={handleClick}>
          <Icon iconName="close" size={14} color="black" />
        </Box>
      </HStack>
    </Box>
  )
}
