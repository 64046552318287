import styled from '@emotion/styled'
import CloseIcon from '@src/assets/common/close_24.svg'
import { HStack, VStack } from '@src/components/basic'
import { QuantityCounter } from '@src/components/shopping/common/quantity-counter'
import { maxOrderCount } from '@src/components/shopping/product-detail/_constants/max-order-count'
import { ProductSkuDto } from '@src/types/product'
import Image from 'next/legacy/image'
import { useSkuCountsUpdater } from '../../../_contexts/sku-counts-context'

type Props = {
  sku: ProductSkuDto
  skuCount: number
  disabled?: boolean
}

export const SelectedSkuItem = ({ sku, skuCount, disabled }: Props) => {
  const { addSku, subSku, removeSku } = useSkuCountsUpdater()
  const price = skuCount * sku.sellPrice
  const maxCount = Math.min(sku.leftInventoryCount, maxOrderCount)

  const add = () => addSku(sku.id)
  const sub = () => subSku(sku.id)
  const remove = () => removeSku(sku.id)

  return (
    <SelectedSkuContainer key={sku.id}>
      <VStack spacing={8} padding={16} alignItems="flex-start">
        <SkuTitle>{sku.title}</SkuTitle>
        <HStack justifyContent="space-between" alignItems="center" width="100%">
          <QuantityCounter
            onClickAdd={add}
            onClickSub={sub}
            disabled={disabled}
            value={skuCount}
            maxValue={maxCount}
            variant="product-detail"
            maxWidth={140}
          />
          <Price>{price.toLocaleString()}</Price>
        </HStack>
      </VStack>
      <RemoveButton onClick={remove}>
        <Image src={CloseIcon} alt="" layout="fixed" width={16} height={16} />
      </RemoveButton>
    </SelectedSkuContainer>
  )
}

const SelectedSkuContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
`

const SkuTitle = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.03em;
  color: #000000;

  width: 100%;
  padding-right: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`

const Price = styled.span`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25;
  letter-spacing: -0.03em;

  ::after {
    content: '원';
    font-weight: 400;
  }
`

const RemoveButton = styled.button`
  display: flex;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

export default SelectedSkuItem
