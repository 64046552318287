import { AuthClient } from '@api/auth-client'
import { queryKeys } from '@api/constants/query-keys'
import { QueryOptions } from '@api/types'
import { UserProfile, UserProfileDto } from '@src/types/auth'
import { useQuery } from '@tanstack/react-query'

type Options = QueryOptions<UserProfileDto, UserProfile>

export const useUserProfile = (options?: Options) => {
  return useQuery(
    [queryKeys.auth.userProfile],
    () => AuthClient.getUserProfile(),
    { ...options, select: (dto) => new UserProfile(dto) }
  )
}
