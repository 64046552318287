import { useDesignThemeSettingsQuery } from '@api/hooks/channel/use-design-theme-settings'
import { HStack, Text, VStack } from '@src/components/basic'
import { LoadingSpinner, MoneyText } from '@src/components/common'

type Props = {
  marketPrice: number
  sellPrice: number
  at?: 'card' | 'detail'
}

export const Price = ({ marketPrice, sellPrice, at = 'card' }: Props) => {
  const discountRate = Math.round((1 - sellPrice / marketPrice) * 100)
  const designThemeSettingsQuery = useDesignThemeSettingsQuery()

  if (!designThemeSettingsQuery.isSuccess) {
    return <LoadingSpinner />
  }

  const { isShowDiscountRate, isShowMarketPrice } =
    designThemeSettingsQuery.data

  return (
    <VStack spacing={at === 'card' ? 2 : 6} width="100%">
      {!!sellPrice && discountRate > 0 && isShowMarketPrice && (
        <Text
          fontSize={at === 'card' ? 12 : 14}
          fontColor="gray.300"
          fontWeight="light"
          lineHeight={1}
          lineThrough
        >
          {Math.floor(marketPrice).toLocaleString()}
        </Text>
      )}
      <HStack spacing={at === 'card' ? 3 : 4}>
        {discountRate > 0 && isShowDiscountRate && (
          <Text
            fontColor="primary"
            fontWeight="bold"
            fontSize={at === 'card' ? 15 : 20}
            lineHeight={1}
          >
            {discountRate}%
          </Text>
        )}
        <MoneyText
          moneyAmount={Math.floor(sellPrice)}
          fontColor="black"
          moneyFontSize={at === 'card' ? 15 : 20}
          moneyLineHeight={1}
          unitFontSize={at === 'card' ? 11 : 15}
        />
      </HStack>
    </VStack>
  )
}
