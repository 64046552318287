import { LoadingSpinner } from '@src/components/common'
import { useCallback, useEffect, useRef } from 'react'
import { Box, BoxProps } from '../../basic'

type Props = {
  children?: React.ReactNode
  hasMore?: boolean
  fetchMore: () => void
  isLoading?: boolean
  loadingSpinnerBoxProps?: BoxProps
}

export const InfiniteScroll = ({
  children,
  hasMore,
  fetchMore,
  isLoading,
  loadingSpinnerBoxProps,
}: Props) => {
  const observerRef = useRef<HTMLDivElement>(null)
  const loadingRef = useRef<boolean>(!!isLoading)

  const handleIntersect = useCallback(
    async (
      [entry]: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) => {
      if (!hasMore || isLoading || loadingRef.current) {
        return
      }
      if (entry.isIntersecting) {
        observer.unobserve(entry.target)
        fetchMore()
        loadingRef.current = true
        observer.observe(entry.target)
      }
    },
    [fetchMore, hasMore, isLoading]
  )

  useEffect(() => {
    if (!observerRef.current) {
      return
    }

    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 0.5,
    })
    observer.observe(observerRef.current)
    return () => observer.disconnect()
  }, [handleIntersect, hasMore, isLoading])

  useEffect(() => {
    if (!isLoading) {
      loadingRef.current = false
    }
  }, [isLoading])

  return (
    <>
      {children}
      <div ref={observerRef}>
        {hasMore && (
          <Box {...loadingSpinnerBoxProps}>
            <LoadingSpinner />
          </Box>
        )}
      </div>
    </>
  )
}
