import { Box, HStack, Text, TextProps } from '@src/components/basic'
import { Size } from '@src/components/basic/types'
import { Clickable, Icon, VDivider } from '@src/components/common'

type QuantityCounterVariant = 'cart' | 'product-detail'

const quantityCounterStyleMap: Record<
  QuantityCounterVariant,
  { height: Size; textProps: TextProps }
> = {
  ['cart']: {
    height: 32,
    textProps: {
      fontSize: 14,
    },
  },
  ['product-detail']: {
    height: 40,
    textProps: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
}

type Props = {
  value: number
  maxValue: number
  onClickAdd: () => void
  onClickSub: () => void
  disabled?: boolean
  variant: QuantityCounterVariant
  maxWidth?: Size
}

export const QuantityCounter = ({
  value,
  maxValue,
  onClickAdd,
  onClickSub,
  disabled,
  variant,
  maxWidth,
}: Props) => {
  const isSubButtonDisabled = disabled || value <= 1
  const isAddButtonDisabled = disabled || value >= maxValue

  const handleClickSub = () => {
    if (isSubButtonDisabled) {
      return
    }

    onClickSub()
  }

  const handleClickAdd = () => {
    if (isAddButtonDisabled) {
      return
    }

    onClickAdd()
  }

  const { height, textProps } = quantityCounterStyleMap[variant]

  return (
    <Box border={[1, 'solid', 'gray.200']} backgroundColor="white">
      <HStack width={maxWidth} height={height}>
        <Clickable width={height} onClick={handleClickSub} centered>
          <Icon
            iconName="remove"
            color={isSubButtonDisabled ? 'gray.200' : 'black'}
            size={20}
          />
        </Clickable>
        <VDivider thickness={1} backgroundColor="gray.200" />
        <Box flexGrow={1} centered>
          <Text {...textProps} fontColor={disabled ? 'gray.200' : 'black'}>
            {value}
          </Text>
        </Box>
        <VDivider thickness={1} backgroundColor="gray.200" />
        <Clickable width={height} centered onClick={handleClickAdd}>
          <Icon
            iconName="add"
            color={isAddButtonDisabled ? 'gray.200' : 'black'}
            size={20}
          />
        </Clickable>
      </HStack>
    </Box>
  )
}
