import { css } from '@emotion/react'
import { CSSProperties } from 'react'
import { Color, Size } from '../types'
import { parseColor, parseSize } from '../utils'

export type BorderProps = {
  border?: [Size, CSSProperties['borderStyle'], Color] | 'none'
  borderWidth?: Size
  borderColor?: Color
  borderRadius?: Size
  borderStyle?: CSSProperties['borderStyle']
}

export const borderStyles = (props: BorderProps) => {
  const { border, borderRadius } = props

  if (border === 'none') {
    return css`
      border: none;
    `
  }

  const borderWidth = props.borderWidth || (border && border[0])
  const borderStyle = props.borderStyle || (border && border[1])
  const borderColor = props.borderColor || (border && border[2])

  return css`
    border-width: ${parseSize(borderWidth)};
    border-style: ${borderStyle};
    border-color: ${parseColor(borderColor)};
    border-radius: ${parseSize(borderRadius)};
  `
}
