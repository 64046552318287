import { Box, Text, VStack } from '@src/components/basic'
import { Icon } from '@src/components/common'
import Image from 'next/legacy/image'

type Props = {
  imageUrl: string
  imageCount?: number
  onClick?: () => void
  isMoreButton?: boolean
}

export const PhotoItem = ({
  onClick,
  imageUrl,
  imageCount,
  isMoreButton,
}: Props) => {
  return (
    <Box
      onClick={onClick}
      flex={1}
      position="relative"
      border={[1, 'solid', 'gray.100']}
    >
      <Image
        src={`${process.env.NEXT_PUBLIC_IMAGE_HOST}/${imageUrl}`}
        width={1}
        height={1}
        layout="responsive"
        alt="review-photo"
        objectFit="cover"
        unoptimized
      />
      {!!imageCount && imageCount > 1 && !isMoreButton && (
        <Box
          size={24}
          position="absolute"
          bottom="0"
          right="0"
          backgroundColor="rgba(0, 0, 0, 0.4)"
          centered
        >
          <Text fontSize={12} fontColor="white">
            {imageCount}
          </Text>
        </Box>
      )}
      {isMoreButton && (
        <Box
          position="absolute"
          inset="0"
          backgroundColor="rgba(0, 0, 0, 0.4)"
          centered
        >
          <VStack spacing={1} alignItems="center">
            <Icon iconName="add" color="white" />
            <Text fontSize={12} fontColor="white" lineHeight={1.3}>
              더보기
            </Text>
          </VStack>
        </Box>
      )}
    </Box>
  )
}
