import { api } from '@api/index'
import { MutationOptions } from '@api/types'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { useMutation } from '@tanstack/react-query'

type Options = MutationOptions<void, number>

export const useDeleteWishItemMutation = (options: Options) => {
  return useMutation((wishItemId: number) => {
    return deleteWishItem(wishItemId)
  }, options)
}

const deleteWishItem = (wishItemId: number) => {
  return api.productClient.delete(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/wishlist/${wishItemId}`
  )
}
