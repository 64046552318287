import styled from '@emotion/styled'
import { Box, Text, VStack } from '@src/components/basic'
import { Color } from '@src/components/basic/types'
import { parseSize } from '@src/components/basic/utils'
import { colors, fontWeights } from '@src/styles/theme'
import { ChangeEvent, FocusEvent, useEffect, useState } from 'react'

type Props = {
  value?: string
  onChange?: (value: string, target?: HTMLTextAreaElement) => void
  onBlur?: (value: string, target?: HTMLTextAreaElement) => void
  placeholder?: string
  disabled?: boolean
  readOnly?: boolean
  lengthLimit?: number
  rows?: number
  error?: boolean
}

export const Textarea = ({
  value = '',
  onChange,
  onBlur,
  placeholder,
  disabled,
  readOnly,
  lengthLimit = 200,
  rows = 6,
  error = false,
}: Props) => {
  const [textValue, setTextValue] = useState<string>(value)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  useEffect(() => {
    setTextValue(value || '')
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = e
    let { value } = target

    if (value.length > lengthLimit) {
      value = value.slice(0, lengthLimit)
    }

    setTextValue(value)
    onChange && onChange(value, target)
  }

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    const { target } = e
    const { value } = target
    onBlur && onBlur(value, target)
    setTimeout(() => setIsFocused(false))
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  return (
    <Box
      backgroundColor={getTextareaBackgroundColor({ readOnly, disabled })}
      border={[
        1,
        'solid',
        getBorderColor({ isFocused, error, readOnly, disabled }),
      ]}
      paddingX={16}
      paddingY={8}
      width="100%"
    >
      <VStack spacing={8} alignItems="flex-end">
        <StyledTextarea
          value={textValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          rows={rows}
        />
        <Text
          fontSize={12}
          fontColor={getTextLengthIndicatorColor({
            isFocused,
            readOnly,
            disabled,
          })}
        >
          {textValue.length} / {lengthLimit}
        </Text>
      </VStack>
    </Box>
  )
}

const StyledTextarea = styled.textarea`
  width: 100%;
  font-size: ${parseSize(13)};
  font-weight: ${fontWeights.light};

  ::placeholder {
    color: ${colors.gray[300]};
  }
`

type GetColorParams = Pick<Props, 'readOnly' | 'disabled' | 'error'> & {
  isFocused?: boolean
}

const getBorderColor = ({
  readOnly,
  disabled,
  error,
  isFocused,
}: GetColorParams): Color => {
  if (readOnly || disabled) {
    return 'gray.200'
  }

  if (isFocused) {
    return 'black'
  }

  if (error) {
    return 'error'
  }

  return 'gray.200'
}

const getTextareaBackgroundColor = ({
  readOnly,
  disabled,
}: GetColorParams): Color => {
  if (readOnly || disabled) {
    return 'gray.100'
  }

  return 'white'
}

const getTextLengthIndicatorColor = ({
  readOnly,
  disabled,
  isFocused,
}: GetColorParams): Color => {
  if (readOnly || disabled) {
    return 'gray.200'
  }

  if (isFocused) {
    return 'black'
  }

  return 'gray.200'
}
