import { useBasicSettingsQuery } from '@api/hooks/channel/use-basic-settings'
import { useExtensionSettingsQuery } from '@api/hooks/channel/use-extension-settings'
import { routes } from '@src/common/constants/routes'
import { BottomNavbar } from '@src/components/layout/bottom-navbar'
import { TopNavbar } from '@src/components/layout/top-navbar'
import {
  bottomBarHeight,
  maxScreenWidth,
  topBarHeight,
} from '@src/styles/theme'
import { useRouter } from 'next/router'
import React from 'react'
import { Box, VStack } from '../basic'
import { parseSize } from '../basic/utils'
import { topNavBarPropsMap } from './_constants/top-nav-bar-props-map'

const bottomNavbarDisplayRoutes: string[] = [
  routes.shopping.home,
  routes.base.home,
  routes.my.home,
  routes.cardShop.home,
  routes.voucher.home,
]

type LayoutProps = {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  const router = useRouter()
  useBasicSettingsQuery()
  const extensionSettingsQuery = useExtensionSettingsQuery()

  const paramRegex = /\/\[.+\]/
  const route =
    router.pathname === routes.shopping.custom
      ? router.pathname
      : router.pathname.replace(paramRegex, '')

  const topNavBarProps = topNavBarPropsMap[route]

  const hasBottomNavbar = bottomNavbarDisplayRoutes.includes(router.pathname)

  if (extensionSettingsQuery.data?.isInvalidPage(router.pathname)) {
    router.replace(routes.base.home)
    return null
  }

  return (
    <VStack
      maxWidth={maxScreenWidth}
      margin="0 auto"
      paddingBottom={hasBottomNavbar ? parseSize(bottomBarHeight) : 0}
    >
      <TopNavbar {...topNavBarProps} />
      <Box
        as="main"
        position="relative"
        minHeight={`calc(100vh - ${
          topNavBarProps?.hasCustomNavbar ? '0px' : parseSize(topBarHeight)
        })`}
      >
        {children}
      </Box>
      {hasBottomNavbar && <BottomNavbar />}
    </VStack>
  )
}
