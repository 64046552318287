import { FinalCartCalculationsDto } from '..'

export class FinalCartCalculations {
  public readonly groupDeliveryDiscount: number
  public readonly totalDeliveryFee: number
  public readonly totalProductPrice: number
  public readonly totalPgAmountToPay: number
  public readonly regionalFees: number

  public readonly totalDeliveryFeeAfterCalculate: number

  constructor(dto: FinalCartCalculationsDto) {
    this.groupDeliveryDiscount = dto.groupDeliveryDiscount
    this.totalDeliveryFee = dto.totalDeliveryFee
    this.totalProductPrice = dto.totalProductPrice
    this.totalPgAmountToPay = dto.totalPgAmountToPay
    this.regionalFees = dto.regionalFees

    this.totalDeliveryFeeAfterCalculate =
      this.totalDeliveryFee - this.groupDeliveryDiscount + this.regionalFees
  }

  public totalPgAmountToPayAfterPoint(totalPoint: number) {
    return Math.max(this.totalPgAmountToPay - totalPoint, 0)
  }
}
