import { Box, Text } from '@src/components/basic'
import { InquiryStatusEnum, ProductInquiry } from '@src/types/product-inquiry'

const inquiryStatusToLabelMap = {
  [InquiryStatusEnum.NotAnswered]: '답변대기',
  [InquiryStatusEnum.Answered]: '답변완료',
  [InquiryStatusEnum.DeletedByInquirer]: '삭제됨',
} as const

export const InquiryStatusChip = ({
  status,
}: Pick<ProductInquiry, 'status'>) => {
  return (
    <Box
      paddingX={8}
      paddingY={4}
      border={[1, 'solid', 'gray.700']}
      borderRadius="full"
      backgroundColor={
        status === InquiryStatusEnum.Answered ? 'gray.700' : 'white'
      }
    >
      <Text
        fontColor={status === InquiryStatusEnum.Answered ? 'white' : 'gray.700'}
        fontSize={10}
        lineHeight={1}
        letterSpacing="wide"
      >
        {inquiryStatusToLabelMap[status]}
      </Text>
    </Box>
  )
}
