import { useDesignThemeSettingsQuery } from '@api/hooks/channel/use-design-theme-settings'
import { Box, BoxProps } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { Chip } from '@src/components/common'

type Props = {
  direction: BoxProps['flexDirection']
}

export const TextBadge = ({ direction }: Props) => {
  const designThemeSettingsQuery = useDesignThemeSettingsQuery()

  if (
    !designThemeSettingsQuery.isSuccess ||
    !designThemeSettingsQuery.data.isTextBadgeOn ||
    !designThemeSettingsQuery.data.textBadgeContent
  ) {
    return null
  }

  const { textBadgeContent } = designThemeSettingsQuery.data
  const contents = [textBadgeContent]

  return (
    <Box
      gap={4}
      flexDirection={direction}
      paddingBottom={4}
      alignItems={'flex-start'}
    >
      {contents.map((content, index) => (
        <Chip
          key={index}
          padding={`${parseSize(4)} ${parseSize(6)}`}
          textSize={parseSize(12)}
          color={'gray.600'}
        >
          {content}
        </Chip>
      ))}
    </Box>
  )
}
