import { Text, TextProps } from '@src/components/basic'
import { ComponentProps } from '@src/components/basic/types'
import React from 'react'

export type TextComponentProps = ComponentProps<TextProps>

export const SectionTitle = ({ children, ...props }: TextComponentProps) => {
  return (
    <Text
      as="h2"
      fontWeight="bold"
      fontSize={16}
      fontColor="black"
      lineHeight={1}
      {...props}
    >
      {children}
    </Text>
  )
}

export const ErrorMessage = ({ children, ...props }: TextComponentProps) => {
  return (
    <Text fontSize={12} fontColor="error" letterSpacing="wide" {...props}>
      {children}
    </Text>
  )
}
