import { HStack, Text } from '@src/components/basic'
import { Icon } from '../icon'

type Props<T = unknown> = {
  label?: string
  value?: T
  isChecked?: boolean
  spacing?: number
  onClick?: (value?: T) => void
  disabled?: boolean
}

export const RadioButton = <T,>({
  label,
  value,
  isChecked,
  spacing,
  onClick,
  disabled,
}: Props<T>) => {
  const handleClick = () => {
    if (disabled) {
      return
    }

    onClick && onClick(value)
  }

  return (
    <HStack as="button" onClick={handleClick} spacing={spacing}>
      <Icon
        size={20}
        iconName={isChecked ? 'radioChecked' : 'radioUnchecked'}
        color={isChecked ? 'primary' : 'gray.200'}
        variant="outlined"
      />
      {label && (
        <Text
          fontSize={14}
          letterSpacing="wide"
          color={disabled ? 'gray.400' : 'black'}
        >
          {label}
        </Text>
      )}
    </HStack>
  )
}
