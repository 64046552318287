import { Color } from '@src/components/basic/types'
import { Icon } from '@src/components/common/icon'
import Image from 'next/legacy/image'
import { useState } from 'react'
import { Box } from '../../basic'

type Props = {
  imageUrl?: string
  size?: number
  iconColor?: Color
  backgroundColor?: Color
  borderColor?: Color
  isMulti?: boolean
}

export const Avatar = ({
  imageUrl,
  size = 44,
  iconColor = 'gray.400',
  backgroundColor = 'gray.100',
  borderColor = 'gray.100',
  isMulti,
}: Props) => {
  const [isError, setIsError] = useState<boolean>(false)

  return (
    <Box
      size={size}
      borderRadius="full"
      overflow="hidden"
      backgroundColor={backgroundColor}
      border={[1, 'solid', borderColor]}
      position="relative"
      centered
      flexShrink={0}
    >
      {!isError && (
        <Image
          src={imageUrl || '/'}
          alt="avatar"
          layout="fill"
          onError={() => setIsError(true)}
        />
      )}
      {isError && (
        <Icon
          iconName={isMulti ? 'people' : 'person'}
          color={iconColor}
          size={size / 2}
        />
      )}
    </Box>
  )
}
