import { Box } from '@src/components/basic'
import { colors } from '@src/styles/theme'
import { Icon, IconName } from '../icon'

type Props = {
  type?: 'success' | 'fail'
  size?: number
  iconName?: IconName
}

export const ResultIcon = ({ type: variant, size = 42, iconName }: Props) => {
  return (
    <Box size={size} position="relative">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 35 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.082 0L20.6988 3.50215L25.582 2.27757L26.9631 7.11889L31.8045 8.5L30.5799 13.3833L34.082 17L30.5799 20.6167L31.8045 25.5L26.9631 26.8811L25.582 31.7224L20.6988 30.4978L17.082 34L13.4653 30.4978L8.58203 31.7224L7.20092 26.8811L2.3596 25.5L3.58418 20.6167L0.0820312 17L3.58418 13.3833L2.3596 8.5L7.20092 7.11889L8.58203 2.27757L13.4653 3.50215L17.082 0Z"
          fill={variant === 'success' ? colors.purple.dark[200] : colors.error}
        />
      </svg>
      <Box position="absolute" absoluteCentered>
        <Icon
          iconName={iconName || (variant === 'success' ? 'done' : 'close')}
          variant="outlined"
          color="white"
          size={size / 2}
        />
      </Box>
    </Box>
  )
}
