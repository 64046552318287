export type BaseProductDto = {
  id: string
  images: string[]
  title: string
  brand: string
  minSellPrice: number
  marketPrice: number
  wishlistId: number
  timesaleAppliedNow: boolean
  inventoryStatus?: InventoryStatusEnum
  productClass?: ProductClassEnum
  leftInventoryStatus?: InventoryStatusEnum
  salesStatus?: SalesStatusEnum
}

export enum ProductClassEnum {
  Tangible = 'tangible',
  GiftCard = 'giftcard',
}

export enum InventoryStatusEnum {
  Enough = 'enough',
  Shortage = 'shortage',
  SoldOut = 'sold_out',
}
export enum SalesStatusEnum {
  Selling = 'selling',
  Stopped = 'stopped',
  Banned = 'banned',
  Deleted = 'deleted',
}
