import { Box, HStack, Text } from '@src/components/basic'
import { Dispatch, SetStateAction } from 'react'
import { PaginationButton } from './pagination-button'

type PaginationProps = {
  page: number
  lastPage: number
  onChangePage: Dispatch<SetStateAction<number>>
}

export const Pagination = ({
  page,
  lastPage,
  onChangePage,
}: PaginationProps) => {
  const handleClickPrev = () => {
    if (page <= 1) {
      return
    }

    return onChangePage((prev) => prev - 1)
  }

  const handleClickNext = () => {
    if (page >= lastPage) {
      return
    }

    return onChangePage((prev) => prev + 1)
  }

  return (
    <HStack width="100%" justifyContent="center">
      <PaginationButton
        type="prev"
        onClick={handleClickPrev}
        disabled={page <= 1}
      />
      <Box width={68} alignItems="center">
        <Text color="black" fontWeight="light" fontSize={13}>
          {page}
          <Text color={page === lastPage ? 'black' : 'gray.400'}>
            {' / '}
            {lastPage}
          </Text>
        </Text>
      </Box>
      <PaginationButton
        type="next"
        onClick={handleClickNext}
        disabled={page >= lastPage}
      />
    </HStack>
  )
}
