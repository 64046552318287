import { AlertProvider } from './alert-context'
import { GlobalScrollControlProvider } from './global-scroll-control-context'
import { PopupProvider } from './popup-context'
import { ProductDetailDrawerProvider } from './product-detail-drawer-context'
import { TempOptimisticDataProvider } from './temp-optimistic-data-context'
import ToastProvider from './toast-context'

type Props = {
  children?: React.ReactNode
}

export const CombinedProvider = ({ children }: Props) => {
  return (
    <TempOptimisticDataProvider>
      <ToastProvider>
        <AlertProvider>
          <PopupProvider>
            <GlobalScrollControlProvider>
              <ProductDetailDrawerProvider>
                {children}
              </ProductDetailDrawerProvider>
            </GlobalScrollControlProvider>
          </PopupProvider>
        </AlertProvider>
      </ToastProvider>
    </TempOptimisticDataProvider>
  )
}
