import { storageKeys } from '@src/common/constants/storage-keys'
import { sessionStorageService } from '@src/services/storage-service'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

export type Path = {
  href: string
  host: string
  pathname: string
  query: Record<string, string | string[]>
  search: string
}

export const usePrevPath = () => {
  const router = useRouter()
  const [prevPath, setPrevPath] = useState<Path | null>(null)

  useEffect(() => {
    const prevPath = sessionStorageService.get<Path>(storageKeys.previousPath)
    setPrevPath(prevPath || null)
  }, [router])

  return { prevPath }
}

export const useUpdatePrevPath = () => {
  const router = useRouter()

  useLayoutEffect(() => {
    if (!router.isReady) {
      return
    }

    const { href, host, search } = window.location
    const { pathname, query } = router

    const prevPath = sessionStorageService.get<Path>(storageKeys.currentPath)
    if (prevPath && prevPath.href !== href) {
      sessionStorageService.set(storageKeys.previousPath, prevPath)
    }

    sessionStorageService.set(storageKeys.currentPath, {
      pathname,
      query,
      href,
      host,
      search,
    })
  }, [router])
}

export const useResetPrevPath = () => {
  const resetPrevPath = useCallback(() => {
    sessionStorageService.remove(storageKeys.previousPath)
    sessionStorageService.remove(storageKeys.currentPath)
  }, [])

  return { resetPrevPath }
}
