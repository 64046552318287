import { Box, Text } from '@src/components/basic'
import { LayoutProps, PositionProps } from '@src/components/basic/core-styles'

type Props = LayoutProps &
  PositionProps & {
    isDetail?: boolean
  }

export const TimeSaleChip = ({ isDetail, ...props }: Props) => {
  return (
    <Box
      position="absolute"
      backgroundColor={isDetail ? 'primary' : 'black'}
      height={isDetail ? 28 : 20}
      paddingX={isDetail ? 12 : 8}
      borderRadius="full"
      centered
      {...props}
    >
      <Text
        fontColor={'white'}
        fontSize={isDetail ? 13 : 12}
        fontWeight={isDetail ? 'medium' : 'regular'}
        lineHeight={1}
      >
        특가
      </Text>
    </Box>
  )
}
