import { ProductClassEnum } from '@src/types/product'
import { BaseTransactionDto, SkuStatusEnum } from '../dto'
import { TransactionSku } from './transaction-sku.entity'

export class BaseTransaction {
  public readonly id: string
  public readonly paidDate: string
  public readonly transactionSkus: TransactionSku[]

  public readonly isVoucher: boolean
  public readonly isRefunded: boolean
  public readonly isCancellable: boolean

  constructor(dto: BaseTransactionDto) {
    this.id = dto.id
    this.paidDate = dto.paidDate
    this.transactionSkus = dto.transactionSkus.map(
      (dto) => new TransactionSku(dto, this.paidDate)
    )

    this.isVoucher = this._getIsVoucher()
    this.isRefunded = this._getIsRefunded()
    this.isCancellable = this._getIsCancellable()
  }

  private _getIsVoucher() {
    return this.transactionSkus[0]?.skuProductType === ProductClassEnum.GiftCard
  }

  private _getIsRefunded() {
    return this.transactionSkus.some((sku) =>
      [
        SkuStatusEnum.RefundFinishedOrderCanceled,
        SkuStatusEnum.RefundFinishedCheckRejected,
        SkuStatusEnum.RefundFinishedShipRejected,
        SkuStatusEnum.RefundFinishedNormal,
        SkuStatusEnum.IssueCancelled,
        SkuStatusEnum.ExpiredConfirmed,
        SkuStatusEnum.RefundFinishedManuallyIssueRejected,
        SkuStatusEnum.RefundFinishedManuallyIssueCancelled,
      ].includes(sku.status)
    )
  }

  private _getIsCancellable() {
    return this.transactionSkus.every((sku) =>
      [SkuStatusEnum.PaymentRequired, SkuStatusEnum.CheckRequired].includes(
        sku.status
      )
    )
  }
}
