import styled from '@emotion/styled'
import { Box, VStack } from '@src/components/basic'
import { parseSize } from '@src/components/basic/utils'
import { colors } from '@src/styles/theme'
import { useField } from 'formik'
import { InputHTMLAttributes, useRef, useState } from 'react'
import { ErrorMessage } from '..'
import { Clickable } from '../clickable'
import { Icon } from '../icon'

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> &
  ValidationProps &
  OptionsProps

type ValidationProps = {
  disabled?: boolean
  readOnly?: boolean
  error?: string
  touched?: boolean
}

type OptionsProps = {
  displayFormat?: (value: string) => string
}

export const InputField = ({
  value,
  displayFormat,
  name = '',
  ...props
}: InputFieldProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [_, __, helper] = useField(name)

  const touch = () => helper?.setTouched(true)

  const resetValue = () => {
    helper?.setValue('')
    ref.current?.focus()
  }

  const showResetButton = isFocused && !!value

  return (
    <VStack spacing={6} flex={1}>
      <Box position="relative">
        <StyledInput
          ref={ref}
          type="text"
          name={name}
          value={
            !isFocused && displayFormat ? displayFormat(`${value}`) : value
          }
          onClick={(e) => {
            props.onClick?.(e)
            touch()
            setIsFocused(true)
          }}
          onBlur={(e) => {
            props.onBlur?.(e)
            setTimeout(() => setIsFocused(false), 100)
          }}
          offset={showResetButton ? 24 : 0}
          {...props}
        />
        <Box position="absolute" top={8} right={12} paddingRight="inherit">
          {showResetButton && (
            <Clickable onClick={resetValue}>
              <Icon size={24} color="gray.300" iconName="cancel" />
            </Clickable>
          )}
        </Box>
      </Box>
      {props.touched && props.error && (
        <ErrorMessage>{props.error}</ErrorMessage>
      )}
    </VStack>
  )
}

const StyledInput = styled.input<ValidationProps & { offset?: number }>`
  flex: 1;
  font-size: ${parseSize(14)};
  line-height: ${parseSize(24)};
  letter-spacing: -0.04em;

  border: 1px solid ${colors.gray[200]};
  padding: ${parseSize(8)} ${parseSize(16)};
  padding-right: ${({ offset = 0 }) =>
    offset > 0 ? parseSize(8 + offset + 12) : undefined};

  ::placeholder {
    color: ${colors.gray[300]};
  }

  :disabled,
  :read-only {
    background-color: ${colors.gray[100]};
    border-color: ${colors.gray[200]};
  }

  ${({ touched, error }) =>
    touched && error !== undefined && `border-color: ${colors.error};`};

  :active,
  :focus {
    border-color: ${colors.black};
  }
`
