import { ApiListResult2 } from '@api/types'
import { UseInfiniteQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'

export const useCustomInfiniteQueryResult = <T>(
  query: UseInfiniteQueryResult<ApiListResult2<T>>
) => {
  const flattenedData = useMemo(
    () =>
      query.isSuccess
        ? query.data.pages.flatMap((data) => data.items)
        : undefined,
    [query.data?.pages, query.isSuccess]
  )
  const total = query.isSuccess ? query.data.pages[0].total : 0

  return {
    data: flattenedData,
    total,
    isLoading: query.isLoading || query.isFetchingNextPage,
    isError: query.isError,
    fetchMore: query.fetchNextPage,
    hasMore: query.hasNextPage,
  }
}
