import {
  ActiveStatusEnum,
  IssuanceTypeEnum,
  NoticeInfoDto,
  ProductDto,
  ProductOptionDto,
  ProductSkuDto,
  RegisterStatusEnum,
  SellerInfoDto,
} from '../dto'
import { BaseProduct } from './base-product.entity'
import { DeliveryInfo } from './delivery-info.entity'

export class Product extends BaseProduct {
  public readonly createDt: string
  public readonly updateDt: string
  public readonly supplierPortalId: string
  public readonly channelPortalId: string
  public readonly channelId: string
  public readonly sellerPortalId: string
  public readonly channelCategoryId: string
  public readonly masterProductId: string
  public readonly country: string
  public readonly description: string
  public readonly sn: string
  public readonly origin: string
  public readonly manufacturer: string
  public readonly isVat: boolean
  public readonly noticeId: string
  public readonly noticeInfo: NoticeInfoDto
  public readonly isImageRegisterByOption: boolean
  public readonly note: string
  public readonly faq: string[]
  public readonly currency: string
  public readonly isManyOptions: boolean
  public readonly skuCount: number
  public readonly soldCount: number
  public readonly minSupplyPrice: number
  public readonly maxSupplyPrice: number
  public readonly maxSellPrice: number
  public readonly discountRate: number
  public readonly registerStatus: RegisterStatusEnum
  public readonly activeStatus: ActiveStatusEnum
  public readonly type: string
  public readonly ratings: number
  public readonly viewCount: number
  public readonly likeCount: number
  public readonly keywords: string[]
  public readonly minSellPriceBeforeReduction: number
  public readonly maxSellPriceBeforeReduction: number
  public readonly deliveryInfo?: DeliveryInfo
  public readonly sellerInfo: SellerInfoDto
  public readonly optionList: ProductOptionDto[]
  public readonly skuList: ProductSkuDto[]
  public readonly limitDate?: string
  public readonly validityPeriod?: number
  public readonly pgSettingId?: string
  public readonly issuanceType?: IssuanceTypeEnum

  public readonly isSelling: boolean
  public readonly isNotSellable: boolean
  public readonly hasMid: boolean
  public readonly isLinkedVoucher: boolean
  public readonly isUnlinkedVoucher: boolean

  constructor(dto: ProductDto) {
    super(dto)
    this.createDt = dto.createDt
    this.updateDt = dto.updateDt
    this.supplierPortalId = dto.supplierPortalId
    this.channelPortalId = dto.channelPortalId
    this.channelId = dto.channelId
    this.sellerPortalId = dto.sellerPortalId
    this.channelCategoryId = dto.channelCategoryId
    this.masterProductId = dto.masterProductId
    this.country = dto.country
    this.description = dto.description
    this.sn = dto.sn
    this.origin = dto.origin
    this.manufacturer = dto.manufacturer
    this.isVat = dto.isVat
    this.noticeId = dto.noticeId
    this.noticeInfo = dto.noticeInfo
    this.isImageRegisterByOption = dto.isImageRegisterByOption
    this.note = dto.note
    this.faq = dto.faq
    this.currency = dto.currency
    this.isManyOptions = dto.isManyOptions
    this.skuCount = dto.skuCount
    this.soldCount = dto.soldCount
    this.minSupplyPrice = dto.minSupplyPrice
    this.maxSupplyPrice = dto.maxSupplyPrice
    this.maxSellPrice = dto.maxSellPrice
    this.discountRate = dto.discountRate
    this.registerStatus = dto.registerStatus
    this.activeStatus = dto.activeStatus
    this.type = dto.type
    this.ratings = dto.ratings
    this.viewCount = dto.viewCount
    this.likeCount = dto.likeCount
    this.keywords = dto.keywords
    this.minSellPriceBeforeReduction = dto.minSellPriceBeforeReduction
    this.maxSellPriceBeforeReduction = dto.maxSellPriceBeforeReduction
    this.deliveryInfo = dto.deliveryInfo && new DeliveryInfo(dto.deliveryInfo)
    this.sellerInfo = dto.sellerInfo
    this.optionList = dto.optionList
    this.skuList = dto.skuList
    this.limitDate = dto.limitDate
    this.validityPeriod = dto.validityPeriod
    this.pgSettingId = dto.pgSettingId
    this.issuanceType = dto.issuanceType

    this.isSelling = this._getIsSelling()
    this.isNotSellable = this._getIsNotSellable()
    this.hasMid = this._getHasMid()
    this.isUnlinkedVoucher = this._getIsUnlinkedVoucher()
    this.isLinkedVoucher = this._getIsLinkedVoucher()
  }

  protected _getIsSelling() {
    return (
      super._getIsSelling() && this.activeStatus === ActiveStatusEnum.Active
    )
  }

  private _getIsNotSellable() {
    return !this.isSelling || this.isSoldOut
  }

  private _getHasMid() {
    return !!this.pgSettingId
  }

  private _getIsUnlinkedVoucher() {
    return this.issuanceType === IssuanceTypeEnum.External
  }

  private _getIsLinkedVoucher() {
    return this.issuanceType === IssuanceTypeEnum.Instant
  }
}
