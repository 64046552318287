const card = {
  myCards: 'my-cards',
  myCardCount: 'my-card-count',
  cardUsageHistory: 'card-usage-history',
  refundCardHistory: 'refund-card-history',
  cardLimit: 'card-limit',
  swipeCardList: 'swipe-card-list',
  cardReceived: 'card-received',
  basicCardList: 'basic-card-list',
}

const product = {
  componentProducts: 'component-products',
  productReviews: 'product-reviews',
  myReviews: 'my-reviews',
  photoReviews: 'photo-reviews',
  reviewReplies: 'review-replies',
  productReviewAggregation: 'product-review-aggregation',
  categoryRecommendedProducts: 'category-recommended-products',
  productInquiry: 'product-inquiry',
  productInquiries: 'product-inquiries',
  productInquiryAnswers: 'product-inquiry-answers',
  productDetail: 'product-detail',
  popularKeywords: 'popular-keywords',
  searchResult: 'search-result',
  wishlist: 'wishlist',
}

const cart = {
  lines: 'cart-lines',
}

const channel = {
  basicSetting: 'basic-setting',
  productPageSettings: 'product-page-setting',
  designThemeSettings: 'design-theme-settings',
  extensionSettings: 'extension-settings',
  customerServiceInfo: 'customer-service-info',
  customPage: 'custom-page',
  channelTerms: 'channel-terms',
}

const transaction = {
  claims: 'claims',
  deliveryTracking: 'delivery-tracking',
  cartOrder: 'cart-order-info',
  directOrder: 'direct-order-info',
  transactions: 'transactions',
  transactionCounts: 'transaction-counts',
  skuClaimInfo: 'skuClaimInfo',
  updateVoucherInfo: 'update-voucher-info',
  transactionDetail: 'transaction-detail',
  skuLogs: 'sku-logs',
  pgSettings: 'pg-settings',
}

const notification = {
  notificationList: 'notification-list',
  notificationSetting: 'notification-setting',
}

const gift = {
  giftSentHistory: 'gift-sent-history',
  giftSentCardDetail: 'gift-sent-card-detail',
  giftHistory: 'gift-history',
  giftCardDetail: 'gift-card-detail',
  giftReceivedHistory: 'gift-received-history',
  giftLimit: 'gift-limit',
}

const money = {
  moneyHistory: 'money-history',
  myUzzimMoney: 'my-uzzim-money',
  chargeableMoney: 'chargeable-money',
  chargeableTooltip: 'chargeable-tooltip',
  moneyLimit: 'money-limit',
}

const auth = {
  userProfile: 'user-profile',
  addresses: 'address-list',
  logOut: 'log-out',
  userPasscodeStatus: 'user-passcode-status',
  validateWithdrawUser: 'validate-withdraw-user',
}

const studio = {
  skins: 'skins',
}

const inquiry = {
  inquiries: 'inquiries',
}

const notice = {
  noticeList: 'notice-list',
  noticeItem: 'notice-item',
}

const terms = {
  termsAll: 'terms-all',
  signup: 'signup-terms',
}

const faq = {
  createFaq: 'create-faq',
  enableFaqList: 'faq-enabled-list',
  faqList: 'faq-list',
  updateFaq: 'update-faq',
}

const banner = {
  main: 'main-banner',
}

const shopping = {
  categories: 'categories',
  channelComponents: 'channel-components',
}

const openBanking = {
  info: 'open-banking-info',
  accounts: 'open-banking-accounts',
}

const withdrawal = {
  withdrawalCheck: 'withdrawal-check',
}

const voucher = {
  list: 'list',
  detail: 'detail',
}

const recent = {
  list: 'recent-contact-list',
}

export const queryKeys = {
  card,
  product,
  cart,
  channel,
  transaction,
  gift,
  notification,
  money,
  auth,
  studio,
  inquiry,
  faq,
  notice,
  terms,
  banner,
  shopping,
  openBanking,
  withdrawal,
  voucher,
  recent,
} as const
