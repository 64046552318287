import { api } from '@api/index'
import { environment } from '@src/config'
import { BasicSettings } from '@src/types/channel'
import { UpdateReviewLikeOrDislikeRequestDto } from '@src/types/review'
import { useMutation } from '@tanstack/react-query'

export const useUpdateReviewLikeOrDislikeMutation = () => {
  return useMutation(
    ({ reviewId, likeOrDislike }: UpdateReviewLikeOrDislikeRequestDto) =>
      updateReviewLikeOrDislike({
        reviewId,
        likeOrDislike,
      })
  )
}

const updateReviewLikeOrDislike = ({
  reviewId,
  likeOrDislike,
}: UpdateReviewLikeOrDislikeRequestDto) => {
  return api.reviewClient.patch(
    `${
      environment.harmonyEndpoint
    }/${BasicSettings.getChannelId()}/reviews/${reviewId}`,
    { likeOrDislike }
  )
}
