import {
  CurrencyEnum,
  PaymentInfoDto,
  PaymentMethodDto,
  RefundInfoDto,
  TransactionDeliveryInfoDto,
  TransactionDto,
  TransactionInfoDto,
} from '..'
import { BaseTransaction } from './base-transaction.entity'

export class Transaction extends BaseTransaction {
  public readonly createDt: string
  public readonly updateDt: string
  public readonly sn: string
  public readonly userId: string
  public readonly transactionChannelInfoId: string
  public readonly totalPointAmount: number
  public readonly paymentInfo: PaymentInfoDto
  public readonly paymentMethod: PaymentMethodDto
  public readonly deliveryInfo: TransactionDeliveryInfoDto
  public readonly refundInfo: RefundInfoDto
  public readonly currency: CurrencyEnum
  public readonly transactionInfo: TransactionInfoDto
  public readonly transactionPointAmount: number

  constructor(dto: TransactionDto) {
    super(dto)
    this.createDt = dto.createDt
    this.updateDt = dto.updateDt
    this.sn = dto.sn
    this.userId = dto.userId
    this.transactionChannelInfoId = dto.transactionChannelInfoId
    this.totalPointAmount = dto.totalPointAmount
    this.paymentInfo = dto.paymentInfo
    this.paymentMethod = dto.paymentMethod
    this.deliveryInfo = dto.deliveryInfo
    this.refundInfo = dto.refundInfo
    this.currency = dto.currency
    this.transactionInfo = dto.transactionInfo
    this.transactionPointAmount = dto.transactionPointAmount
  }
}
