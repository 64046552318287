import { useDesignThemeSettingsQuery } from '@api/hooks/channel/use-design-theme-settings'
import { Box, Text } from '@src/components/basic'

export const SoldOutOverlay = () => {
  const designThemeSettingsQuery = useDesignThemeSettingsQuery()

  if (!designThemeSettingsQuery.isSuccess) {
    return null
  }

  return (
    <Box
      backgroundColor="rgba(255, 255, 255, 0.7)"
      position="absolute"
      inset="0"
    >
      <Box position="absolute" absoluteCentered>
        <Text fontWeight="bold" fontSize={11} whiteSpace="nowrap">
          SOLD OUT
        </Text>
      </Box>
    </Box>
  )
}
