import styled from '@emotion/styled'
import { Box } from '@src/components/basic'
import Link, { LinkProps } from 'next/link'
import { Button, ButtonProps } from './button'

type LinkButtonProps = ButtonProps & {
  href: LinkProps['href']
}

export const LinkButton = ({ href, ...buttonProps }: LinkButtonProps) => {
  return (
    <Box flex={1} centered>
      <StyledLink href={href}>
        <Button {...buttonProps} />
      </StyledLink>
    </Box>
  )
}

const StyledLink = styled(Link)`
  width: 100%;
`
