import { IconChip, IconName } from '@src/components/common'
import { ProductClassEnum } from '@src/types/product'

const productClassMap: Record<
  ProductClassEnum,
  { label: string; iconName: IconName }
> = {
  [ProductClassEnum.Tangible]: {
    label: '배송상품',
    iconName: 'box',
  },
  [ProductClassEnum.GiftCard]: {
    label: '모바일상품권',
    iconName: 'voucher',
  },
}

type ProductClassChipProps = {
  productClass: ProductClassEnum
}

export const ProductClassChip = ({ productClass }: ProductClassChipProps) => {
  return <IconChip {...productClassMap[productClass]} />
}
