import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Box, HStack, Text, VStack } from '@src/components/basic'
import { ReviewAggregationDto } from '@src/types/review'
import { ReviewScoreStars } from './common/review-score-stars'

type Props = {
  reviewAggregation: ReviewAggregationDto
}

export const ReviewSummary = ({ reviewAggregation }: Props) => {
  const { mean, count, five, four, three, two, one } = reviewAggregation

  return (
    <VStack>
      <VStack spacing={2}>
        <HStack>
          <VStack spacing={8} minWidth={98} alignItems="center">
            <Text fontSize={16} fontWeight="bold" lineHeight={1}>
              {mean.toFixed(1)}
            </Text>
            <ReviewScoreStars score={mean} />
            <Text fontSize={13} lineHeight={1.2}>
              {count}개의 리뷰
            </Text>
          </VStack>

          <VStack spacing={4} padding={8} justifyContent="center" width="100%">
            <ReviewScoreGraph score={5} count={five} totalCount={count} />
            <ReviewScoreGraph score={4} count={four} totalCount={count} />
            <ReviewScoreGraph score={3} count={three} totalCount={count} />
            <ReviewScoreGraph score={2} count={two} totalCount={count} />
            <ReviewScoreGraph score={1} count={one} totalCount={count} />
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  )
}

type ReviewScoreGraph = {
  score: number
  count: number
  totalCount: number
}

const ReviewScoreGraph = ({ score, count, totalCount }: ReviewScoreGraph) => {
  return (
    <HStack spacing={4}>
      <Box centered width={20}>
        <Text fontSize={12} fontWeight="light" lineHeight={1.17}>
          {score}점
        </Text>
      </Box>
      <Box flex={1} backgroundColor="gray.100" position="relative" height={2}>
        <GraphBar
          backgroundColor="gray.800"
          left="0"
          height="100%"
          width={count === 0 ? 0 : `${(count / totalCount) * 100}%`}
        />
      </Box>
    </HStack>
  )
}

const horizontalFill = keyframes`
  0% {
    transform: scaleX(0%);
  }

  100% {
    transform: scaleX(100%);
  }
`

const GraphBar = styled(Box)`
  transform-origin: left;
  animation: ${horizontalFill} 0.5s ease-out forwards;
`
