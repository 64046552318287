import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useModal } from '@src/hook/use-modal'
import { colors, zIndices } from '@src/styles/theme'
import React from 'react'
import { Box, HStack, Text, VStack } from '../basic'
import { Color } from '../basic/types'
import { parseSize } from '../basic/utils'
import { Clickable } from './clickable'
import { Icon } from './icon'

type Props = {
  children?: React.ReactNode
  iconSize?: number
  iconColor?: Color
  iconPosition?: 'left' | 'right'
  tooltipContent: React.ReactNode
  position?: [
    vertical: 'top' | 'bottom',
    horizontal: 'left' | 'center' | 'right'
  ]
  padding?: number
}

export const Tooltip = ({
  children,
  tooltipContent,
  position = ['top', 'center'],
  iconSize = 16,
  iconColor = 'gray.300',
  iconPosition = 'left',
  padding,
}: Props) => {
  const [isOpen, open, close] = useModal()

  return (
    <>
      <Box position="relative">
        <Clickable onClick={open}>
          <HStack>
            {iconPosition === 'left' && (
              <Icon
                iconName={'information'}
                variant={'outlined'}
                color={iconColor}
                size={iconSize}
              />
            )}
            {children}
            {iconPosition === 'right' && (
              <Icon
                iconName={'information'}
                variant={'outlined'}
                color={iconColor}
                size={iconSize}
              />
            )}
          </HStack>
        </Clickable>
        <TooltipContent position={position} padding={padding} isOpen={isOpen}>
          <VStack spacing={6}>
            <HStack justifyContent={'flex-end'}>
              <Clickable onClick={close}>
                <Icon iconName="close" color="white" size={12} />
              </Clickable>
            </HStack>
            <HStack spacing={6} alignItems="flex-start">
              <Text
                width={'max-content'}
                maxWidth={192}
                fontSize={11}
                fontColor="white"
              >
                {tooltipContent}
              </Text>
            </HStack>
          </VStack>
        </TooltipContent>
        {isOpen && (
          <Box
            onPointerDown={close}
            position="fixed"
            inset="0"
            zIndex={['floating', 'below']}
          />
        )}
      </Box>
    </>
  )
}

const verticalPositionStyle = {
  top: css`
    bottom: 60%;
  `,
  bottom: css`
    top: 60%;
  `,
}

const horizontalPositionStyle = {
  left: css`
    right: 60%;
  `,
  right: css`
    left: 60%;
  `,
  center: css`
    left: 50%;
    transform: translateX(-50%);
  `,
}

const TooltipContent = styled.div<{
  position: Props['position']
  padding?: number
  isOpen: boolean
}>`
  position: absolute;
  padding: ${({ padding = 8 }) => parseSize(padding)};
  background-color: ${colors.gray[900]};
  border-radius: ${parseSize(6)};
  z-index: ${zIndices.floating};

  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.2s;

  ${({ position }) => position && verticalPositionStyle[position[0]]};
  ${({ position }) => position && horizontalPositionStyle[position[1]]};

  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
`

export default Tooltip
