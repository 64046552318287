import { useCreateProductInquiryMutation } from '@api/hooks/product-inquiry/use-create-product-inquiry'
import { useProductInquiriesInfiniteQuery } from '@api/hooks/product-inquiry/use-product-inquiries'
import { routes } from '@src/common/constants/routes'
import { Box, VStack } from '@src/components/basic'
import { Button, InfiniteScroll } from '@src/components/common'
import { useUrlQuery } from '@src/hook/use-url-query'
import { Product } from '@src/types/product'
import { isLogin } from '@src/utils/is-login'
import { useRouter } from 'next/router'
import { InquiryDetailDrawer } from './drawers/inquiry-detail-drawer'
import { InquiryFormDrawer } from './drawers/inquiry-form-drawer'
import { InquiryItem } from './inquiry-item'

enum DrawerType {
  Write = 'write',
  Detail = 'inquiry-detail',
}

type ProductDetailInquiriesProps = {
  productDetail: Product
}

export const ProductDetailInquiries = ({
  productDetail,
}: ProductDetailInquiriesProps) => {
  const router = useRouter()
  const productInquiriesInfiniteQuery = useProductInquiriesInfiniteQuery(
    productDetail.id
  )
  const { type: drawerType, id: inquiryId = '' } = useUrlQuery()

  router.beforePopState((state) => {
    state.options.scroll = false
    return true
  })

  const createProductInquiryMutation = useCreateProductInquiryMutation({
    onSuccess: router.back,
  })

  const handleClickInquiry = () => {
    if (!isLogin()) {
      return router.push(routes.my.login)
    }

    router.push(
      { query: { ...router.query, type: DrawerType.Write } },
      undefined,
      { scroll: false }
    )
  }

  return (
    <>
      <VStack paddingBottom={100}>
        <Box padding={16} paddingTop={24}>
          <Button
            label="문의하기"
            onClick={handleClickInquiry}
            variant="outline"
          />
        </Box>
        <VStack>
          <InfiniteScroll
            isLoading={productInquiriesInfiniteQuery.isLoading}
            hasMore={productInquiriesInfiniteQuery.hasMore}
            fetchMore={productInquiriesInfiniteQuery.fetchMore}
          >
            {productInquiriesInfiniteQuery.data?.map((inquiry) => (
              <InquiryItem
                key={inquiry.id}
                inquiry={inquiry}
                onClickDetail={() =>
                  router.push({
                    query: {
                      ...router.query,
                      type: DrawerType.Detail,
                      id: inquiry.id,
                    },
                  })
                }
              />
            ))}
          </InfiniteScroll>
        </VStack>
      </VStack>

      <InquiryFormDrawer
        isOpen={drawerType === DrawerType.Write}
        onClose={router.back}
        productDetail={productDetail}
        onSubmit={createProductInquiryMutation.mutate}
        isSubmitting={createProductInquiryMutation.isLoading}
      />

      <InquiryDetailDrawer
        isOpen={drawerType === DrawerType.Detail}
        onClose={router.back}
        inquiryId={inquiryId}
      />
    </>
  )
}
