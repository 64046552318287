import { accessTokenKey, refreshTokenKey } from '@api/constants/token-key'
import { ErrorCodeEnum } from '@src/common/constants/errors'
import { routes } from '@src/common/constants/routes'
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import camelcaseKeys from 'camelcase-keys'
import Cookies from 'js-cookie'
import snakecaseKeys from 'snakecase-keys'
import { AuthClient } from './auth-client'
import { HttpStatusCode, UzzimError } from './types'

type ApiServiceInstanceOptions = {
  useSnakecaseBody?: boolean
  useCredentials?: boolean
}

const defaultOptions: ApiServiceInstanceOptions = {
  useSnakecaseBody: true,
  useCredentials: true,
}

/**
 * API fetching service class that instantiates new axios instance when provided a base URL.
 * Response and error interceptors are also applied to each instance.
 */
export class ApiService {
  // days
  private static readonly _ACCESS_TOKEN_DURATION = 1 / 24 / 4 // 15 minutes
  private static readonly _REFRESH_TOKEN_DURATION = 24

  private _instance: AxiosInstance
  private _options: ApiServiceInstanceOptions

  constructor(baseURL: string, options?: ApiServiceInstanceOptions) {
    this._instance = axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
      },
    })

    this._instance.interceptors.response.use(
      this._responseIntercept,
      this._responseInterceptError(this._instance)
    )

    this._options = { ...defaultOptions, ...options }
  }

  private _responseIntercept = (response: AxiosResponse) => {
    const { data, ...rest } = response

    return {
      ...rest,
      data: camelcaseKeys(data, { deep: true }),
    }
  }

  private _responseInterceptError =
    (client: AxiosInstance) => async (error: AxiosError<UzzimError>) => {
      if (error.response?.status === HttpStatusCode.InternalServerError) {
        return Promise.reject(error)
      }

      if (
        error.response?.data.code === ErrorCodeEnum.blockedUser &&
        !error.config.url?.includes('oauth2')
      ) {
        window.location.replace(routes.error.blocked)
        return Promise.reject(error)
      }

      const originalRequest = error.config

      if (error.response?.status === HttpStatusCode.Unauthorized) {
        if (originalRequest.url?.includes('refresh-token')) {
          window.location.replace(routes.my.signUp)
          return Promise.reject(error)
        }

        if (!originalRequest.headers?.retry) {
          originalRequest.headers = {
            ...originalRequest.headers,
            retry: true,
          }

          try {
            await AuthClient.refreshAccessToken({
              refreshToken: ApiService.getRefreshToken(),
            })
          } catch (err) {
            return Promise.reject(err)
          }

          return client({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: ApiService.getAccessToken() || '',
            },
          })
        }

        window.location.replace(routes.my.login)
        return Promise.reject(error)
      }

      if (
        error.response?.status === HttpStatusCode.Forbidden &&
        originalRequest.url?.includes('refresh-token')
      ) {
        window.location.replace(routes.my.login)
        return Promise.reject(error)
      }

      return Promise.reject(error)
    }

  static getAccessToken(): string | undefined {
    if (typeof window === 'undefined') return
    return Cookies.get(accessTokenKey) || undefined
  }

  static getRefreshToken(): string | undefined {
    if (typeof window === 'undefined') return
    return Cookies.get(refreshTokenKey) || undefined
  }

  static setAccessToken(accessToken: string): void {
    Cookies.set(accessTokenKey, `Bearer ${accessToken}`, {
      expires: this._ACCESS_TOKEN_DURATION,
    })
  }

  static setRefreshToken(refreshToken: string): void {
    Cookies.set(refreshTokenKey, refreshToken, {
      expires: this._REFRESH_TOKEN_DURATION,
    })
  }

  static deleteTokens(): void {
    Cookies.remove(accessTokenKey)
    Cookies.remove(refreshTokenKey)
  }

  /**
   * Takes a request configuration and passes in the Authorization header if present
   */
  public configWithAuth(
    config?: AxiosRequestConfig
  ): AxiosRequestConfig | undefined {
    const accessToken = ApiService.getAccessToken()

    if (!accessToken) return config
    return {
      ...config,
      withCredentials: this._options.useCredentials,
      headers: {
        ...config?.headers,
        Authorization: accessToken,
      },
    }
  }

  private _snakecaseKeys(
    input: Record<string, unknown>,
    options?: { deep?: boolean }
  ) {
    if (!this._options.useSnakecaseBody) {
      return input
    }

    return snakecaseKeys(input, options)
  }

  /**
   * Use this if you need access to the raw response including status codes.
   * Otherwise, you can use the get, post, put, patch, and delete wrapper methods to only return data.
   *
   * NOTE: If using this, you must manually wrap change request data to snakecase keys.
   */
  public get instance(): AxiosInstance {
    return this._instance
  }

  public async get<T = void>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response = await this._instance.get<T>(
        url,
        this.configWithAuth(config)
      )
      return response.data
    } catch (err) {
      throw err
    }
  }

  public async post<T = void>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response = await this._instance.post<T>(
        url,
        this._snakecaseKeys(data || {}, { deep: true }),
        this.configWithAuth(config)
      )
      return response.data
    } catch (err) {
      throw err
    }
  }

  public async put<T = void>(
    url: string,
    data: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response = await this._instance.put<T>(
        url,
        this._snakecaseKeys(data || {}, { deep: true }),
        this.configWithAuth(config)
      )
      return response.data
    } catch (err) {
      throw err
    }
  }

  public async patch<T = void>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response = await this._instance.patch<T>(
        url,
        this._snakecaseKeys(data || {}, { deep: true }),
        this.configWithAuth(config)
      )
      return response.data
    } catch (err) {
      throw err
    }
  }

  public async delete<T = void>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<T> {
    try {
      const response = await this._instance.delete<T>(
        url,
        this.configWithAuth(config)
      )
      return response.data
    } catch (err) {
      throw err
    }
  }
}
