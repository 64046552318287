import { CartDto } from '@src/types/cart'

export const initialOrderInfo: CartDto = {
  cartMetadata: {
    channelId: '',
    lines: [],
    userId: '',
  },
  deliveryGroupedProducts: [],
  nonDeliveryGroupedProducts: [],
  finalCartCalculations: {
    groupDeliveryDiscount: 0,
    regionalFees: 0,
    totalDeliveryFee: 0,
    totalPgAmountToPay: 0,
    totalProductPrice: 0,
  },
}
