import { Box, BoxProps } from '@src/components/basic'

type EmptyMessageContainerProps = BoxProps & {
  children?: React.ReactNode
}

export const EmptyMessageContainer = ({
  children,
  ...props
}: EmptyMessageContainerProps) => {
  return (
    <Box flexGrow={1}>
      <Box height={`60vh`} centered {...props}>
        {children}
      </Box>
    </Box>
  )
}
